// satz: ";u_lod_psd_mitarbeiter;pnr#psd;duevo_vorname#psd;",
// existiert_hauptarbeitgeber: 49;u_lod_psd_steuer;pnr#psd;identifikationsnummer#psd;els_2_haupt_ag_kz#psd;
export type LodasMapping = {
  excel?: string;
  table?: string;
  column?: string;
  profile?: string;
  constraint?: string;
  default?: any;
  onlyOnInit?: boolean;
  in_bsp?: boolean;
  // this is only for claus-peter stein minimal anlage beispiel
  claus_peter_minimal?: boolean;
};

export const StammdatenLodasMapping: LodasMapping[] = [
  //arbeitszeit konto u_lod_mpd_azk
  {
    table: "u_lod_psd_arbeitszeitkonto",
    column: "azk_modell#psd",
    onlyOnInit: true,
    default: 98,
  },
  // Grunddaten Name
  {
    excel: "Ansprechpartner:Vorname",
    table: "u_lod_psd_mitarbeiter",
    column: "duevo_vorname#psd",
    profile: "vorname",
    in_bsp: true,
    claus_peter_minimal: true,
  },
  {
    excel: "Ansprechpartner:Name",
    table: "u_lod_psd_mitarbeiter",
    column: "duevo_familienname#psd",
    profile: "nachname",
    in_bsp: true,
    claus_peter_minimal: true,
  },
  // Grunddaten Anschrift/Bankverbindung
  {
    excel: "Straße",
    table: "u_lod_psd_mitarbeiter",
    column: "adresse_strassenname#psd",
    profile: "strasse",
    in_bsp: true,
    claus_peter_minimal: true,
  },
  {
    excel: "Hausnummer",
    table: "u_lod_psd_mitarbeiter",
    column: "adresse_strasse_nr#psd",
    profile: "hausnr",
    in_bsp: true,
    claus_peter_minimal: true,
  },
  {
    excel: "PLZ",
    table: "u_lod_psd_mitarbeiter",
    column: "adresse_plz#psd",
    profile: "plz",
    constraint: "wohnland == DE",
    in_bsp: true,
    claus_peter_minimal: true,
  },
  {
    table: "u_lod_psd_mitarbeiter",
    column: "adresse_nation_kz#psd",
    profile: "wohnland",
    default: "0",
  },
  {
    excel: "PLZ",
    table: "u_lod_psd_mitarbeiter",
    column: "adresse_plz_ausland#psd",
    profile: "plz",
    constraint: "wohnland != DE",
  },
  {
    excel: "Ort",
    table: "u_lod_psd_mitarbeiter",
    column: "adresse_ort#psd",
    profile: "ort",
    in_bsp: true,
    claus_peter_minimal: true,
  },
  {
    //abweichender Kontoinhaber?
    excel: "IBAN",
    table: "u_lod_psd_ma_bank",
    column: "ma_iban#psd",
    profile: "iban",
    in_bsp: true,
  },
  {
    excel: "BIC",
    table: "u_lod_psd_ma_bank",
    column: "ma_bic#psd",
    profile: "bic",
    in_bsp: true,
  },
  {
    excel: "Zahlungsart(5)",
    table: "u_lod_psd_ma_bank",
    column: "ma_bank_zahlungsart#psd",
    default: 5, //sepa,
    onlyOnInit: true,
    in_bsp: true,
  },
  // ### Grunddaten Allgemeines ###
  {
    excel: "Geburtsdatum",
    table: "u_lod_psd_mitarbeiter",
    column: "geburtsdatum_ttmmjj#psd",
    profile: "geburtsdatum",
    in_bsp: true,
    claus_peter_minimal: true,
  },
  {
    excel: "Geburtsort",
    table: "u_lod_psd_mitarbeiter",
    column: "gebort#psd",
    profile: "geburtsstadt",
  },
  {
    excel: "Geburtsland",
    table: "u_lod_psd_mitarbeiter",
    column: "geburtsland#psd",
    profile: "geburtsland",
  },
  {
    excel: "Geschlecht",
    table: "u_lod_psd_mitarbeiter",
    column: "geschlecht#psd",
    profile: "geschlecht",
    in_bsp: true,
    claus_peter_minimal: true,
  },

  {
    excel: "EintrittdatumAustrittdatum",
    table: "u_lod_psd_beschaeftigung",
    column: "eintrittdatum#psd;austrittdatum#psd",
    profile: "EintrittdatumAustrittdatum",
  },
  // {
  //   excel: "Eintrittsdatum",
  //   table: "u_lod_psd_beschaeftigung",
  //   column: "eintrittdatum#psd",
  //   profile: "Eintrittsdatum",
  //   in_bsp: true,
  //   claus_peter_minimal: true,
  // },
  // {
  //   excel: "Austrittsdatum",
  //   table: "u_lod_psd_beschaeftigung",
  //   column: "austrittdatum#psd",
  //   profile: "Austrittsdatum",
  //   in_bsp: true,
  //   claus_peter_minimal: true,
  // },
  // TODO: Fehlt: Arbeitsbescheinigung im Austrittsmonat elektronisch übermitteln
  {
    excel: "berufsbezeichnung#psd",
    table: "u_lod_psd_taetigkeit", // keine ahnung ob die richtige tabelle
    column: "berufsbezeichnung#psd",
    default: "Hostess/Host",
    onlyOnInit: true, // Tabelle sagt immer Hostess
    in_bsp: true,
  },
  {
    excel: "Arbeitnehmertyp",
    table: "u_lod_psd_taetigkeit",
    column: "rv_beitragsgruppe#psd",
    default: 1, // kurzfristigeBeschäftigung(Angestellte)
    onlyOnInit: true, // Tabelle sagt immer 1
    in_bsp: true,
  },
  // Grund für die Änderung der Wochenarbeitszeit -> keine Angabe
  {
    excel: "Staatsangehörigkeit",
    table: "u_lod_psd_mitarbeiter",
    column: "staatsangehoerigkeit#psd",
    profile: "staatsangehoerigkeit_arr", // entweder deutsch, oder das erste
    in_bsp: true,
    claus_peter_minimal: true,
  },
  // {
  //   table: "u_lod_psd_mitarbeiter",
  //   column: "loesch_nach_austr_unterdr#psd",
  //   onlyOnInit: true,
  //   default: 1,
  // },
  {
    // Beschäftigungsbetriebsnummer
    // TODO: Keine Ahnung ob das die richtige ist -> dazu kommt noch:
    // Betriebsnummer bei der Agentur für Arbeit: 45062276
    // Beschäftigungsbetriebsname: TOPTEAM-GmbH
    // Muss das gesetzt werden? -> in den Screenshots sind die schon gesetzt
    table: "u_lod_psd_taetigkeit", // keine ahnung ob die richtige tabelle
    column: "beschaeft_nr#psd",
    default: 1,
    onlyOnInit: true, // Tabelle sagt immer KfB
    in_bsp: true,
  },
  // Bescheinigung elektronisch übermitteln
  {
    table: "u_lod_psd_taetigkeit",
    column: "kz_besch_nebenbesch#psd",
    default: 0, // 0=nein, 1=ja
    onlyOnInit: true,
  },
  // Ehrenamtliche Tätigkeit
  {
    table: "u_lod_psd_taetigkeit",
    column: "ehrenamtliche_taetigkeit#psd",
    default: 0, // 0=nein, 1=ja
    onlyOnInit: true,
  },
  {
    excel: "Stammkostenstelle", // soll immer wie Stammkkostenträger sein (laut tabelle). dort aber anders geschrieben
    table: "u_lod_psd_taetigkeit", // keine ahnung ob die richtige tabelle
    column: "stammkostenstelle#psd",
    // default: '"KfB"', // KfB
    default: '"30"', // KfB
    onlyOnInit: true,
    in_bsp: true,
  },
  {
    table: "u_lod_psd_kstellen_verteil",
    column: "kostenstelle#psd",
    // default: '"KfB"', // KfB
    default: '"30"', // KfB
    onlyOnInit: true,
    in_bsp: true,
  },
  {
    table: "u_lod_psd_kstellen_verteil",
    column: "prozentsatz_kst#psd",
    default: 100, // KfB
    onlyOnInit: true,
    in_bsp: true,
  },
  {
    table: "u_lod_psd_ktraeger_verteil",
    column: "kostentraeger#psd",
    // default: '"KfB"', // KfB
    default: '"30"', // KfB
    onlyOnInit: true,
    in_bsp: true,
  },
  {
    table: "u_lod_psd_ktraeger_verteil",
    column: "prozentsatz_ktr#psd",
    default: 100, // KfB
    onlyOnInit: true,
    in_bsp: true,
  },
  {
    excel: "Stammkostenträger",
    table: "u_lod_psd_taetigkeit",
    column: "stammkostentraeger#psd",
    // default: '"KFB"', // ANUE, KfB, OF - eigentlich falsch geschrieben
    default: '"30"',
    onlyOnInit: true, // Tabelle sagt immer KFB, aber gibt jetzt auch ANUE vertragsvorlagen
    in_bsp: true,
  },
  {
    excel: "ausgeübteTätigkeit",
    table: "u_lod_psd_taetigkeit", // keine ahnung ob die richtige tabelle
    column: "ausg_taetigkeit#psd",
    default: 63401, //= Hostess
    onlyOnInit: true, // Tabelle sagt immer 63401
    in_bsp: true,
  },
  {
    table: "u_lod_psd_taetigkeit", // keine ahnung ob die richtige tabelle
    column: "ausg_taetigkeit_lfdnr#psd",
    default: 17044, //= Hostess
    onlyOnInit: true, // Tabelle sagt immer 63401
    in_bsp: true,
  },
  {
    excel: "HöchsterSchulabschluss",
    table: "u_lod_psd_taetigkeit", // keine ahnung ob die richtige tabelle
    column: "schulabschluss#psd",
    default: 9, //9=Abschlussunbekannt
    onlyOnInit: true, // Tabelle sagt immer 9
    in_bsp: true,
  },
  {
    excel: "höchsterberufl.Ausbildungsabschluss",
    table: "u_lod_psd_taetigkeit", // keine ahnung ob die richtige tabelle
    column: "ausbildungsabschluss#psd",
    default: 9, //9=Abschlussunbekannt
    onlyOnInit: true, // Tabelle sagt immer 9
    in_bsp: true,
  },
  {
    excel: "gewerbsmäßigeANÜberlassung",
    table: "u_lod_psd_taetigkeit", // keine ahnung ob die richtige tabelle
    column: "arbeitnehmerueberlassung#psd",
    default: 1, //0=wieMandantendaten, 1=nein, 2=ja
    onlyOnInit: true, // Tabelle sagt immer 0
    in_bsp: true,
  },
  {
    excel: "Vertragsform",
    table: "u_lod_psd_taetigkeit", // keine ahnung ob die richtige tabelle
    column: "vertragsform#psd",
    default: 4, //3=befristetVollzeit 4=befristetTeilzeit
    onlyOnInit: true, // Tabelle sagt immer 3, aber keine ahnung ob das sinn macht.
    in_bsp: true,
  },
  // ###
  // ### Grunddaten Sozialversicherung ###
  // ###
  // Beitragsgruppenschlüssel Krankenversicherung
  {
    table: "u_lod_psd_sozialversicherung",
    column: "kv_bgrs#psd",
    default: 0, // 0=kein Beitrag
    onlyOnInit: true,
    in_bsp: true,
  },
  {
    table: "u_lod_psd_sozialversicherung",
    column: "rv_bgrs#psd",
    default: 0, // 0=kein Beitrag
    onlyOnInit: true,
    in_bsp: true,
  },
  {
    table: "u_lod_psd_sozialversicherung",
    column: "av_bgrs#psd",
    default: 0, // 0=kein Beitrag
    onlyOnInit: true,
    in_bsp: true,
  },
  {
    table: "u_lod_psd_sozialversicherung",
    column: "pv_bgrs#psd",
    default: 0, // 0=kein Beitrag
    onlyOnInit: true,
    in_bsp: true,
  },
  //Privat krankenversichert
  // TODO: Abklären ob man da einfach den Standard drin lässt.
  {
    table: "u_lod_psd_priv_versicherung",
    column: "kv_priv_kz#psd",
    default: 0, // 0=nein
    onlyOnInit: true,
  },
  {
    // TODO: gar nicht sicher
    // Beitragsgruppenschlüssel Rentenversicherung
    table: "u_lod_psd_sonstiges",
    column: "wf_pnr_bgs_rv#psd",
    default: 0, // 0=kein Beitrag
    onlyOnInit: true,
  },
  {
    // TODO: gar nicht sicher
    // Beitragsgruppenschlüssel Arbeitslosenversicherung
    table: "u_lod_psd_sonstiges",
    column: "wf_pnr_bgs_av#psd",
    default: 0, // 0=kein Beitrag
    onlyOnInit: true,
  },
  {
    // TODO: gar nicht sicher
    // Beitragsgruppenschlüssel Pflegeversicherung
    table: "u_lod_psd_sonstiges",
    column: "wf_pnr_bgs_kv#psd",
    default: 0, // 0=kein Beitrag
    onlyOnInit: true,
  },
  {
    // TODO: gar nicht sicher
    // privat pflegeversichert
    table: "u_lod_psd_priv_versicherung",
    column: "pv_priv_kz#psd",
    default: 0, // 0=nein
    onlyOnInit: true,
  },
  {
    // Beitragszuschlag zur Pflegeversicherung für Kinderlose nicht berechnen/Elterneigenschaft nachgewiesen
    table: "u_lod_psd_sozialversicherung",
    column: "kz_zuschl_pv_kinderlose#psd",
    default: 0, // 0=nein
    onlyOnInit: true,
  },
  {
    excel: "Personengruppe",
    table: "u_lod_psd_taetigkeit", // keine ahnung ob die richtige tabelle
    column: "persgrs#psd",
    default: 110, // 109=geringfügigentlohnteBeschäftigte, 110=kurzfristigBeschäftigte
    onlyOnInit: true, // Tabelle sagt immer 110
    in_bsp: true,
  },

  // Kennzeichen Geringverdiener
  // TODO: derzeit falsch
  // Ziemlich sicher 4092 von den werten
  // folgende felder kommen in frage:
  //
  {
    table: "u_lod_psd_sozialversicherung",
    column: "gv_gf_schluessel#psd",
    default: 3, // 3=kurzfristig Beschäftigter, meldepflichtig
    onlyOnInit: true,
    in_bsp: true,
  },
  // Antrag zur Befreiung von der RV-Pflicht
  {
    table: "u_lod_psd_sozialversicherung",
    column: "kz_befrei_rv_pflicht_gab#psd",
    default: 0, // 0=Antrag liegt nicht vor
    onlyOnInit: true,
  },
  // Krankenkassen-Nr. gesetzlich versichert
  // TODO: Wenn hier eine Nummer im Profil vorliegt - einfügen?
  // bzw. aus liste parsen
  {
    table: "u_lod_psd_sozialversicherung",
    column: "kk_nr#psd",
    default: 17, // 17=BUN Knappschaft
    onlyOnInit: true,
    in_bsp: true,
  },
  // Krankenkassen-Name gesetzlich versichert
  // TODO: finde ich nicht - wird evt schon aus den angelegten KKs gezogen?

  // Höchstbeitrag freiw. KV
  {
    table: "u_lod_psd_sozialversicherung",
    column: "hoechstbeitrag#psd",
    default: 0, // 0=allgemein
    onlyOnInit: true,
  },
  // Arbeitgeber-Zuschuss freiw. KV
  {
    table: "u_lod_psd_sozialversicherung",
    column: "ag_zuschuss_freiw_kv#psd",
    default: 0, //0 = halber Gesamtbeitrag (ohne Zusatzbeitrag)
    onlyOnInit: true,
  },
  // Selbstzahler freiw. KV
  // TODO: Nicht sicher.
  {
    table: "u_lod_psd_sv_vorbw",
    column: "kz_selbstzah_freiw_kv_pv#psd",
    default: 0, // 0=nein
    onlyOnInit: true,
  },
  // Umlageart
  {
    excel: "Umlagenart",
    table: "u_lod_psd_sozialversicherung",
    column: "uml_schluessel#psd",
    default: 2,
    onlyOnInit: true,
    in_bsp: true,
  },
  // Versicherungsnummer
  {
    excel: "Sozialversicherungsnummer",
    table: "u_lod_psd_mitarbeiter",
    column: "sozialversicherung_nr#psd",
    profile: "sozialversicherungsnummer",
    in_bsp: true,
    claus_peter_minimal: true,
  },
  //Arbeitgeber-Zuschuss zur Pflegeversicherung
  {
    table: "u_lod_psd_sozialversicherung",
    column: "ag_zuschuss_pv#psd",
    default: 0, //0 = halber Gesamtbeitrag (ohne Zusatzbeitrag)
    onlyOnInit: true,
  },
  // Grund für den Ausschluss bzw. die Begrenzung einer bezahlten Freistellung bei Erkrankung/Verletzung des Kindes
  {
    table: "u_lod_psd_sozialversicherung",
    column: "grund_ausschl_freistell#psd",
    default: 0, //0 = wie Mandantendaten
    onlyOnInit: true,
  },
  // ###
  // ### Grunddaten Steuer ###
  // ###
  // Einheitliche Pauschsteuer
  {
    excel: "Pauschalsteuerberechnen",
    table: "u_lod_psd_steuer",
    column: "pausch_einhtl_2#psd",
    default: 0, // 0=nein
    onlyOnInit: true,
  },
  // Übernahme Pauschsteuer durch AN
  {
    excel: "ÜbernahmedurchAG",
    table: "u_lod_psd_steuer",
    column: "pausch_an_kz#psd",
    default: 0, // 0=Übernahme gemäß MPD
    onlyOnInit: true,
    in_bsp: true,
  },
  // Identifikationsnummer
  {
    excel: "Identnummer",
    table: "u_lod_psd_steuer",
    column: "identifikationsnummer#psd",
    profile: "steueridentifikationsnummer",
    in_bsp: true,
  },
  // Kennzeichen Arbeitgeber
  {
    excel: "KennzeichenArbeitgeber",
    table: "u_lod_psd_steuer",
    column: "els_2_haupt_ag_kz#psd",
    // default: 2, // 2 = Nebenarbeitgeber, 1 = Hauptarbeitgeber, 0 = keine Angabe
    profile: "existiert_hauptarbeitgeber",
    // constraint: "existiert_hauptarbeitgeber == true",
    // TODO: muss manuell gesetzt werden - done
    in_bsp: true,
  },

  {
    excel: "Familienstand",
    table: "u_lod_psd_mitarbeiter",
    column: "familienstand#psd",
    profile: "familienstand",
    in_bsp: true,
    claus_peter_minimal: true,
    // ledig oder verheiratet 0 = ledig, 1 = verheiratet,
  },
  // Steuerklasse
  {
    excel: "Steuerklasse",
    table: "u_lod_psd_steuer",
    column: "st_klasse#psd",
    profile: "steuerklasse",
    in_bsp: true,
  },
  // Konfession Arbeitnehmer
  {
    excel: "KonfessionStPfl.",
    table: "u_lod_psd_steuer",
    column: "konf_an#psd",
    profile: "religionszugehoerigkeit",
    // TODO: lodasmapping - done
    in_bsp: true,
  },

  // {
  //   excel: "kurzfristigeBeschäftigung",
  //   table: "u_lod_psd_beschaeftigung",
  //   column: "kurzfristige_beschaeftigung#psd",
  //   default: 3,
  //   onlyOnInit: true, // excel sagt immer 3
  // },

  // {
  //   table: "u_lod_psd_mitarbeiter",
  //   excel: "Ersteintrittsdatum",
  //   column: "ersteintrittsdatum#psd",
  //   default: "current_date",
  //   onlyOnInit: true,
  //   in_bsp: true,
  // }, // weiss nicht wie erfassen - ist das vielleicht nur pro projekt?

  {
    table: "u_lod_psd_mitarbeiter",
    column: "vorweg_abr_abruf_termin_kz#psd",
    default: 2,
    onlyOnInit: true,
    in_bsp: true,
    claus_peter_minimal: true,
  },
  {
    table: "u_lod_psd_mitarbeiter",
    column: "ma_gruppe#psd",
    default: '"KfB"',
    onlyOnInit: true,
    in_bsp: true,
    claus_peter_minimal: true,
  },

  {
    excel: "Abrechnungsgruppe",
    // satz: -> keine ahnung
    default: 2,
    onlyOnInit: true, // Tabelle sagt immer 2
  },
  {
    excel: "ZuordnungMitarbeitergruppe",
    table: "u_lod_psd_taetigkeit", // keine ahnung ob die richtige tabelle
    column: "gruppenzuordnung_pst#psd",
    default: '"KfB"',
    onlyOnInit: true, // Tabelle sagt immer KfB
  },

  {
    table: "u_lod_psd_beschaeftigung",
    column: "kz_arbbes_nae_abrech_autom#psd",
    default: 0, //sepa,
    onlyOnInit: true, // Tabelle sagt immer 5
  },

  {
    excel: "Arbeitsverhältnis",
    table: "u_lod_psd_beschaeftigung",
    column: "arbeitsverhaeltnis#psd",
    default: 1, // befristet,
    onlyOnInit: true, // Tabelle sagt immer 1
    in_bsp: true,
    claus_peter_minimal: true,
  },

  // {
  //   excel: "BetriebsnummerAG",
  //   table: "u_lod_mpd_beschaeftigungsbetr", // keine ahnung ob die richtige tabelle
  //   column: "betriebs_nr#mpd",
  //   default: "45062276", //TT
  //   onlyOnInit: true, // Tabelle sagt immer 45062276
  // },
  {
    excel: "Abteilung",
    table: "u_lod_psd_taetigkeit", // keine ahnung ob die richtige tabelle
    column: "kst_abteilungs_nr#psd",
    // default: '"KfB"',
    default: '"30"',
    onlyOnInit: true,
    in_bsp: true,
  },

  {
    excel: "KennzeichenKV",
    default: 0, // 0=Krankenversicherungsfrei
    onlyOnInit: true,
  },
  {
    excel: "KennzeichenRV",
    default: 0, // 0=Rentenversicherungsfrei
    onlyOnInit: true,
  },
  {
    excel: "KennzeichenAV",
    default: 0, // 0=Arbeitslosenversicherungsfrei
    onlyOnInit: true,
  },
  {
    excel: "KennzeichenPV",
    default: 0, // 0= Pflegeversicherungsfrei
    onlyOnInit: true,
  },
  {
    excel: "KKgesetzlichVersichert",
    default: 17, //17=Bundesknappschaft
    onlyOnInit: true,
  },
  {
    excel: "Entlohnungsform",
    table: "u_lod_psd_besonderheiten",
    column: "entlohnungsform#psd",
    // TODO: Weglassen oder anpassen (wenn pauschal ausgezahlt wird) bzw. nachfragen
    default: 0, // 0=Stundenlohn, 1=Leistungslohn, 2=Gehalt
    onlyOnInit: true,
    in_bsp: true,
  },

  // {
  //   excel: "Stundenlohn1",
  //   table: "u_lod_psd_lohn_gehalt_bezuege",
  //   column: "std_lohn_1#psd",
  //   default: 10,
  //   onlyOnInit: true,
  //   in_bsp: true,
  // },
  // {
  //   excel: "Stundenlohn2",
  //   table: "u_lod_psd_lohn_gehalt_bezuege",
  //   column: "std_lohn_2#psd",
  //   default: 1,
  //   onlyOnInit: true,
  //   in_bsp: true,
  // },
  // {
  //   table: "u_lod_psd_lohn_gehalt_bezuege",
  //   column: "std_lohn_3#psd",
  //   default: 1,
  //   onlyOnInit: true,
  //   in_bsp: true,
  // },
  { excel: "Kostenstelle" },
  { excel: "KostenstelleVerteilung" },
  { excel: "Kostenträger" },
  { excel: "KostenträgerVerteilung" },
];

export const ZulagenLohnartMapping = {
  Fahrkosten_eur: 11, // = Fahrgeld pausch. verst.
  Kilometergeld_eur_km: 10, // = Fahrgeld pausch. verst.
  // Kilometergeld_km: 0,
  Mitnahme_anderer_im_Fahrzeug_eur: 0, // = Fahrgeld pausch. verst.
  Bonus_eur: 21, // Bonus mtl. // was ist 22: Bonus mtl. Zulage
  Freunde_Bonus_eur: 300, // = Freunde werben Freunde
  // Auslage_fuer_description: 0,
  Auslage_fuer_eur: 9029, // = Auslagen -> nicht sicher weil unten nur hingekritzelt
  Durchhaltebonus_eur: 31, // = Durchhaltebonus
  // Aufschlag_h_eur: 0,
  Tagessatz_eur: 301, // = Tagessatz Melitta -> neue definieren? Soll ja nicht immer Melitta da stehen.
};

export const ZulagenLohnartMappingNeu: { [key: string]: any } = {
  Briefing_eur: 30,
  // Nachtzuschlag_eur: 503,
  Vorschuss_eur: 9079,
  Spesen_eur: 9979,
  Bonus_eur: 32,
  Auslage_fuer_eur: 9029,
  Freunde_Bonus_eur: 300,
  Durchhaltebonus_eur: 31,
  Kilometergeld_eur: 9978,
  Fahrkosten_eur: 11,
};

// 1 → Stundenlohn, Nachtzuschlag
// 2 → Boni,
// 3 → Spesen, Vorschuss, Auslagen, Kilometergeld
export const ZulagenBearbeitungsschluesselMapping: { [key: string]: any } = {
  Briefing_eur: 2,
  // Nachtzuschlag_eur: 503,
  Vorschuss_eur: 3,
  Spesen_eur: 3,
  Bonus_eur: 2,
  Auslage_fuer_eur: 3,
  Freunde_Bonus_eur: 2,
  Durchhaltebonus_eur: 2,
  Kilometergeld_eur: 3,
  Fahrkosten_eur: 2,
};
