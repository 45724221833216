export const staatsangehoearigkeit = [
  "deutsch",
  "schweizerisch",
  "österreichisch",
  "französisch",
  "afghanisch",
  "ägyptisch",
  "albanisch",
  "algerisch",
  "andorranisch",
  "angolanisch",
  "antiguanisch",
  "äquatorialguineisch",
  "argentinisch",
  "armenisch",
  "aserbaidschanisch",
  "äthiopisch",
  "australisch",
  "bahamaisch",
  "bahrainisch",
  "bangladeschisch",
  "barbadisch",
  "belgisch",
  "belizisch",
  "beninisch",
  "bhutanisch",
  "bolivianisch",
  "bosnisch-herzegowinisch",
  "botsuanisch",
  "brasilianisch",
  "bruneiisch",
  "bulgarisch",
  "burkinisch",
  "burundisch",
  "cabo-verdisch",
  "chilenisch",
  "chinesisch",
  "costa-ricanisch",
  "ivorisch",
  "dänisch",
  "dominicanisch",
  "dominikanisch",
  "dschibutisch",
  "ecuadorianisch",
  "salvadorianisch",
  "eritreisch",
  "estnisch",
  "eswatinisch",
  "fidschianisch",
  "finnisch",
  "gabunisch",
  "gambisch",
  "georgisch",
  "ghanaisch",
  "grenadisch",
  "griechisch",
  "guatemaltekisch",
  "guineisch",
  "guinea-bissauisch",
  "guyanisch",
  "haitianisch",
  "honduranisch",
  "chinesisch (Hongkong)",
  "indisch",
  "indonesisch",
  "irakisch",
  "iranisch",
  "irisch",
  "isländisch",
  "israelisch",
  "italienisch",
  "jamaikanisch",
  "japanisch",
  "jemenitisch",
  "jordanisch",
  "jugoslawisch",
  "kambodschanisch",
  "kamerunisch",
  "kanadisch",
  "kasachisch",
  "katarisch",
  "kenianisch",
  "kirgisisch",
  "kiribatisch",
  "kolumbianisch",
  "komorisch",
  "kongolesisch",
  "der Demokratischen Republik Kongo",
  "der Demokratischen Volksrepublik Korea",
  "der Republik Korea",
  "kosovarisch",
  "kroatisch",
  "kubanisch",
  "kuwaitisch",
  "laotisch",
  "lesothisch",
  "lettisch",
  "libanesisch",
  "liberianisch",
  "libysch",
  "liechtensteinisch",
  "litauisch",
  "luxemburgisch",
  "chinesisch (Macau)",
  "madagassisch",
  "malawisch",
  "malaysisch",
  "maledivisch",
  "malisch",
  "maltesisch",
  "marokkanisch",
  "marshallisch",
  "mauretanisch",
  "mauritisch",
  "mexikanisch",
  "mikronesisch",
  "moldauisch",
  "monegassisch",
  "mongolisch",
  "montenegrinisch",
  "mosambikanisch",
  "myanmarisch",
  "namibisch",
  "nauruisch",
  "nepalesisch",
  "neuseeländisch",
  "nicaraguanisch",
  "niederländisch",
  "nigrisch",
  "nigerianisch",
  "mazedonisch",
  "norwegisch",
  "omanisch",
  "pakistanisch",
  "palauisch",
  "panamaisch",
  "papua-neuguineisch",
  "paraguayisch",
  "peruanisch",
  "philippinisch",
  "polnisch",
  "portugiesisch",
  "ruandisch",
  "rumänisch",
  "russisch",
  "salomonisch",
  "sambisch",
  "samoanisch",
  "san-marinesisch",
  "são-toméisch",
  "saudi-arabisch",
  "schwedisch",
  "senegalesisch",
  "serbisch",
  "von Serbien und Montenegro",
  "seychellisch",
  "sierra-leonisch",
  "simbabwisch",
  "singapurisch",
  "slowakisch",
  "slowenisch",
  "somalisch",
  "sowjetisch",
  "spanisch",
  "sri-lankisch",
  "von St. Kitts und Nevis",
  "lucianisch",
  "vincentisch",
  "staatenlos",
  "südafrikanisch",
  "sudanesisch",
  "südsudanesisch",
  "surinamisch",
  "syrisch",
  "tadschikisch",
  "taiwanisch",
  "tansanisch",
  "thailändisch",
  "von Timor-Leste",
  "togoisch",
  "tongaisch",
  "von Trinidad und Tobago",
  "tschadisch",
  "tschechisch",
  "tunesisch",
  "türkisch",
  "turkmenisch",
  "tuvaluisch",
  "ugandisch",
  "ukrainisch",
  "ungarisch",
  "uruguayisch",
  "usbekisch",
  "vanuatuisch",
  "vatikanisch",
  "venezolanisch",
  "der Vereinigten Arabischen Emirate",
  "amerikanisch",
  "britisch",
  "vietnamesisch",
  "weißrussisch",
  "zentralafrikanisch",
  "zyprisch",

  "britisch (BOTC)",
  "jugoslawisch",
  "ohne Angabe",
  "ohne Bezeichnung",
  "palästinisch",
  "serbisch",
  "tschechoslowakisch",
  "ungeklärt",
];
