<!-- auswahl kontaktieren modal -->
<app-modal (close)="close.emit(true)" class="send-mail-modal">
  <h4 class="">Nachricht an:</h4>

  <h5>{{ selectedMails.join(", ") }}</h5>
  <label for="mail-subject"
    >Betreff:
    <input type="text" [(ngModel)]="subject" id="mail-subject" />
  </label>

  <!-- <quill-editor [(ngModel)]="messageToSend"></quill-editor> -->
  <app-editor [(html)]="messageToSend"></app-editor>
  <div class="buttons">
    <app-button
      [lessHeight]="true"
      color="primary80"
      background="primary10"
      (click)="close.emit(true)"
      >Abbrechen</app-button
    >
    <app-button
      [lessHeight]="true"
      color="secondary"
      background="primary10"
      (click)="sendMail()"
      [disabled]="isLoading"
      [isLoading]="isLoading"
      >Senden</app-button
    >
  </div>
</app-modal>
<!-- auswahl kontaktieren modal end -->
