import { Notify } from "notiflix";
import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/data.service";

@Component({
  selector: "app-ablauf",
  templateUrl: "./ablauf.component.html",
  styleUrls: ["./ablauf.component.scss"],
})
export class AblaufComponent implements OnInit {
  constructor(public data: DataService) {}

  leftMinWidth: number = 0;

  filterByTrueLength(arr: any[], key: string) {
    return arr.filter((el) => el[key]).length;
  }

  ngOnInit(): void {
    this.leftMinWidth = this.elements.reduce((acc, el) => {
      if (el.label.length > acc) {
        return el.label.length;
      }
      return acc;
    }, 0);
    this.leftMinWidth *= 12;
    console.log(
      `this.data.currentProject?.checkliste`,
      this.data.currentProject?.checkliste
    );

    if (!this.data.currentProject?.checkliste) {
      this.data.currentProject!.checkliste = this.elements;
    }
  }

  async uploadProject() {
    try {
      await this.data.uploadCurrentProject();
      Notify.success("Checkliste wurde gespeichert.");
    } catch (error) {
      Notify.failure("Checkliste konnte nicht gespeichert werden.");
      console.error(error);
    }
  }

  async ngOnDestroy() {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    // this.data.currentProject!.checkliste = this.elements;
    // await this.data.uploadCurrentProject();
  }

  elements = [
    {
      label: "Vorbereitung",
      items: [
        {
          text: "Projektplan erstellen",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Rekrutierung starten (Massenmail versenden + Anzeigen schalten)",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Teamleiter auswählen",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Sedcards verschicken",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Einsatzplan erstellen",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Vertragshandling",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Anreise planen & buchen",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Hotels buchen",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Briefing & Einsatzplan verschicken",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Notwendige Listen erstellen (Anwesenheit, Telefon, Bewertungen)",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Teamleiterordner erstellen",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Outfits zusammenstellen",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Checkcalls",
          checked: false,
          id: Math.random(),
        },
      ],
    },
    {
      label: "Live Betrieb",
      items: [
        {
          text: "Schulung und Briefing überwachen",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Anwesenheit der Topteamer kontrollieren",
          checked: false,
          id: Math.random(),
        },
        {
          text: "TL instruieren",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Zeiterfassung erklären",
          checked: false,
          id: Math.random(),
        },
        {
          text: "TL Stundenzettel unterschreiben lassen",
          checked: false,
          id: Math.random(),
        },
      ],
    },
    {
      label: "Nachbereitung",
      items: [
        {
          text: "Feedback beim Kunden einholen",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Topteamer abrechnen",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Topteamer bewerten, KFB Tage kontrollieren",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Kunden abrechnen: Rechnung erstellen und Budget danach",
          checked: false,
          id: Math.random(),
        },
        {
          text: "Nachbereitung Public: Alle Projekt-Infos (Stundenexcel, Rechnungen, Sedcards etc.) ablegen und Ordner verschieben….",
          checked: false,
          id: Math.random(),
        },
      ],
    },
  ];
}
