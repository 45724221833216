import { DataService } from "src/app/data.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SED, Profile } from "@ttc_types/types";
import { AuthService } from "src/app/shared/auth.service";
import { environment } from "../../../environments/environment";

@Component({
  selector: "page-daten",
  templateUrl: "./daten.component.html",
  styleUrls: ["./daten.component.scss"],
  host: { class: "host-full-width" },
})
export class DatenComponent implements OnInit {
  constructor(
    private data: DataService,
    public router: Router,
    public auth: AuthService
  ) {}

  sedCard: SED | undefined;

  profile: Profile | undefined;

  // uploadUrl: string = environment.apiUrl + '/uploads/';
  resourceUrl: string = `${environment.resourceUrl}/`;

  async ngOnInit() {
    this.data.spinner(true);
    try {
      const [sedCardRes, profileRes] = await Promise.all([
        this.data.getSedCard(),
        this.data.getProfile(),
      ]);
      console.log(`sedCardRes`, sedCardRes);
      console.log(`profileRes`, profileRes);
      this.sedCard = sedCardRes;
      this.profile = profileRes;
      this.data.spinner(false);
    } catch (error) {
      console.log(`ngOnInit error`, error);
      this.data.spinner(false);
    }
  }

  // draggable
}
