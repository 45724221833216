<div *ngIf="action.isLoading" class="loading-container">
  <p style="text-align: center">⌛ Scanner wird gestartet...</p>
</div>
<h4>Scanner / Kamera</h4>
<select
  #deviceSelect
  (change)="action.playDevice(deviceSelect.value)"
  class="form-select form-select-md"
>
  <option [value]="null" selected>Scanner oder Kamera wählen</option>
  <option
    *ngFor="let c of action.devices.value; let i = index"
    [value]="c.deviceId"
    [selected]="i == action.deviceIndexActive"
  >
    {{ c.label }}
  </option>
</select>
<ngx-scanner-qrcode
  #action="scanner"
  [config]="qrScannerConfig"
  (event)="onQrCodeScan($event)"
></ngx-scanner-qrcode>
