<!-- login start -->
<app-modal *ngIf="loginFormOpen" (close)="abortLogin()">
  <h4>Erneut anmelden</h4>
  <p>Du wurdest abgemeldet. Bitte logge dich erneut ein.</p>
  <form [formGroup]="loginForm" (ngSubmit)="login()">
    <div class="input-container">
      <label for="username">E-Mail Adresse</label>
      <input
        type="email"
        autocomplete="username"
        id="username"
        formControlName="email"
      />
    </div>
    <div class="input-container margin-top-10">
      <label for="current-password">Passwort</label>
      <input
        type="password"
        autocomplete="current-password"
        id="current-password"
        formControlName="password"
      />
    </div>
    <!-- <button class="btn ">Einloggen</button> -->
    <div class="input-container margin-top-28">
      <!-- [btnText]="'Einloggen'" -->
      <app-button
        type="submit"
        chevron="right"
        (click)="login()"
        [disabled]="loginButtonDisabled"
        [isLoading]="loginButtonLoading"
        background="primary10"
        >Einloggen</app-button
      >
    </div>
  </form>
</app-modal>
<!-- login end -->

<div
  class="main-wrapper"
  [ngClass]="{ 'no-sidebar': !showSidebar }"
  #mobileScrollTarget
>
  <div class="sidebar" *ngIf="showSidebar">
    <app-sidebar-nav
      [pages]="pages"
      [loggedIn]="auth.isLoggedIn"
    ></app-sidebar-nav>
  </div>
  <div class="content">
    <!-- <app-header-w-backbtn-logo [backBtn]="true">
      <div>asdfasdf<br />asdofjsaoidfj<br />alksjdfoisjdf</div>
    </app-header-w-backbtn-logo> -->
    <!-- <div class="content-header-mobile flex-center">
      <img
        src="assets/svgs/mobile-top-logo.svg"
        alt=""
        *ngIf="this.showContentHeaderMobile"
      />
    </div> -->
    <router-outlet></router-outlet>
  </div>
</div>
