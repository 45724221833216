import { Confirm, Notify } from "notiflix";
import { DataService } from "src/app/data.service";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Application, JobRole, Projekt } from "@ttc_types/types";
import { Subscription } from "rxjs";

@Component({
  selector: "app-job-roles-modal",
  templateUrl: "./job-roles-modal.component.html",
  styleUrls: ["./job-roles-modal.component.scss"],
})
export class JobRolesModalComponent implements OnInit {
  project?: Projekt;

  // applicants?: Application[];

  @Output()
  close = new EventEmitter<boolean>(false);

  currentJobRoleString: string = "";

  left_side_applications: Application[] = [];

  job_roles: JobRole[] = [];

  subs = new Subscription();

  constructor(private data: DataService) {}

  addJobRole() {
    if (this.currentJobRoleString == "") {
      Notify.warning("Rolle darf nicht leer sein.");
      return;
    }
    if (
      this.job_roles!.findIndex((jr) => jr.name == this.currentJobRoleString) >
      -1
    ) {
      Notify.warning("Rolle existiert bereits.");
      return;
    }
    this.job_roles?.push({ name: this.currentJobRoleString, applications: [] });
    this.currentJobRoleString = "";
  }

  removeJobRole(job_role: JobRole) {
    Confirm.show(
      `Rolle ${job_role.name} löschen?`,
      `Soll die Rolle ${job_role.name} wirklich gelöscht werden?`,
      "Nein",
      "Ja",
      () => {},
      () => {
        this.left_side_applications.push(...job_role.applications);
        this.job_roles = this.job_roles?.filter((jr) => jr != job_role);
      }
    );
  }

  async ngOnInit() {
    this.project = await this.data.postRequest("/project/getWithOptions", {
      project_id: this.data.currentProject?.id,
      with_application_status: [
        "auswahl_auftraggeber_invisible",
        "auswahl_auftraggeber_visible",
        "auftraggeber_akzeptiert",
        "warten_auf_vertrag",
        "vertrag_gegenzeichnen",
        "erledigt_verbindlich",
      ],
      with_application_user: true,
    });
    this.job_roles =
      this.project?.job_roles?.map((el) => ({ name: el, applications: [] })) ||
      [];
    this.job_roles =
      this.project?.applications?.reduce(
        (prev: JobRole[], curr: Application) => {
          if (curr.job_role) {
            const index = prev.findIndex((jr) => jr.name == curr.job_role);
            if (index > -1) {
              prev[index].applications.push(curr);
            } else {
              prev.push({ name: curr.job_role, applications: [curr] });
            }
          } else {
            this.left_side_applications.push(curr);
          }
          return prev;
        },
        this.job_roles
      ) || [];

    this.left_side_applications.sort((a, b) => {
      const vorname1 = a.user?.profile?.vorname;
      const vorname2 = b.user?.profile?.vorname;

      if (vorname1 && vorname2) {
        return vorname1.localeCompare(vorname2);
      }

      if (vorname1) {
        return -1;
      }

      if (vorname2) {
        return 1;
      }

      return 0;
    });
  }

  async moveIndexInJobRolesArray(i: number, up: boolean = false) {
    if (i == 0 && up) {
      return;
    }
    if (i == this.job_roles.length - 1 && !up) {
      return;
    }
    const tmp = this.job_roles[i];
    this.job_roles[i] = this.job_roles[i + (up ? -1 : 1)];
    this.job_roles[i + (up ? -1 : 1)] = tmp;
  }

  async saveJobRoles() {
    try {
      const body = {
        project_id: this.data.currentProject?.id,
        job_roles: this.job_roles.map((jr) => {
          return {
            name: jr.name,
            application_ids: jr.applications.map((u) => u.id),
          };
        }),
      };
      console.log(`body`, body);
      await this.data.postRequest("/project/saveJobRoles", body);
      Notify.success("Rollen erfolgreich gespeichert.");
      this.close.emit(true);
    } catch (error) {
      Notify.failure("Rollen konnten nicht gespeichert werden.");
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
