import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Application, EmploymentContract } from "@ttc_types/types";
import { DataService } from "src/app/data.service";

@Component({
  selector: "app-job-unterlagen",
  templateUrl: "./job-unterlagen.component.html",
  styleUrls: ["./job-unterlagen.component.scss"],
})
export class JobUnterlagenComponent implements OnInit {
  @Input() contractIsSigned: boolean = false;

  @Input() employmentContract: EmploymentContract | undefined;

  @Input() application!: Application;

  @Output() openContractPage = new EventEmitter<any>();

  constructor(private data: DataService) {}

  ngOnInit(): void {}

  showContractClicked() {
    console.log("showContractClicked");
    if (this.employmentContract && this.employmentContract.employee_signature) {
      this.getContractPDF();
    } else {
      this.openContractPage.emit();
    }
  }

  showDismissalClicked() {
    if (this.employmentContract && this.employmentContract.employee_signature) {
      this.getDismissalPDF();
    } else {
      this.openContractPage.emit();
    }
  }

  async getDismissalPDF() {
    console.log("getDismissalPDF");
    if (this.employmentContract && this.employmentContract.id) {
      console.log("this.employmentContract: ", this.employmentContract);
      const result = await this.data.getRequest(
        `/application/generateDismissalPDF/${this.employmentContract.id}`
      );
      console.log("getPDFTest answer", result);
      const tmpDate = new Date();
      let formattedDate = tmpDate.toISOString();
      formattedDate = formattedDate.substring(0, formattedDate.length - 5);
      const formattedFilename = `${formattedDate}_kuendigung`;
      this.downloadPdf(result.pdf64, formattedFilename);
    }
  }

  async getContractPDF() {
    console.log("getContractPDFClicked");
    if (this.employmentContract && this.employmentContract.id) {
      console.log("this.employmentContract: ", this.employmentContract);
      const result = await this.data.getPDFOfEmploymentContract(
        this.employmentContract.id
      );
      console.log("getPDFTest answer");
      const tmpDate = new Date();
      let formattedDate = tmpDate.toISOString();
      formattedDate = formattedDate.substring(0, formattedDate.length - 5);
      const formattedFilename = `${formattedDate}_arbeitsvertrag`;
      this.downloadPdf(result.pdf64, formattedFilename);
    }
  }

  public async downloadPdf(base64String: string, fileName: string) {
    const link = document.createElement("a");
    const blob = await this.b64toBlob(base64String);
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}.pdf`;
    link.target = "blank";
    link.click();
  }

  private async b64toBlob(base64: string, type = "application/octet-stream") {
    const res = await fetch(`data:${type};base64,${base64}`);
    return res.blob();
  }
}
