import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import {
  Application,
  Projekt,
  ApplicationStatusStringArbeitskraft,
} from "@ttc_types/types";
import { AuthService } from "../../shared/auth.service";

@Component({
  selector: "app-job-card",
  templateUrl: "./job-card.component.html",
  styleUrls: ["./job-card.component.scss"],
})
export class JobCardComponent implements OnInit {
  constructor(public auth: AuthService) {}

  ApplicationStatusStringArbeitskraft = ApplicationStatusStringArbeitskraft;

  ngOnInit(): void {}

  @Input()
  job: Projekt | undefined;

  @Input()
  application?: Application;

  @Input()
  zeiterfassungsButton: boolean = false;

  @Input()
  currentJob: boolean = false;

  @Input()
  showProjectFullBadge: boolean = false;

  @Input()
  nextJob: boolean = false;

  @Input()
  buttonBg: string = "white";

  @Output()
  detailButtonClicked: EventEmitter<Projekt> = new EventEmitter<Projekt>();
}
