<div class="unterlagen-container">
  <div *ngIf="!contractIsSigned" class="contract-box-wrapper">
    <h5>Arbeitsvertrag</h5>
    <p>
      Bitte bis zum
      {{ employmentContract?.time_sign_until_date | date : "dd.MM.YYYY" }}
      unterzeichnen
    </p>
    <app-button
      color="secondary-gradient"
      background="primary10"
      (click)="showContractClicked()"
      >Vertrag unterzeichnen</app-button
    >
  </div>
  <div *ngIf="contractIsSigned" class="contract-box-wrapper">
    <h5>Arbeitsvertrag</h5>
    <p>
      Am
      {{ employmentContract?.time_employee_signature | date : "dd.MM.YYYY" }}
      unterzeichnet
    </p>
    <app-button color="primary" (click)="showContractClicked()"
      >Vertrag ansehen</app-button
    >
  </div>
  <div
    *ngIf="application && application.status == 'gekuendigt'"
    class="contract-box-wrapper margin-top-10"
  >
    <h5>Kündigung</h5>
    <p>
      Am
      {{ employmentContract?.time_employee_signature | date : "dd.MM.YYYY" }}
      gekündigt
    </p>
    <app-button color="primary" (click)="showDismissalClicked()"
      >Kündigung ansehen</app-button
    >
  </div>
</div>
