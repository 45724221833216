<h5>Stammdaten Export Konfigurator</h5>
<div class="three-cols">
  <div class="left">
    <h2>Stammdaten Felder</h2>
    <div
      class="users user-wrap"
      [dragula]="'tags'"
      [(dragulaModel)]="selectableMasterDataFields"
    >
      <div
        class="user handle"
        *ngFor="let mDataField of selectableMasterDataFields || []"
      >
        <div class="inner">
          <span>
            {{ mDataField.displayName }}
          </span>
          <app-svgs
            type="enlarge-round-arrow-small"
            color="primary40"
          ></app-svgs>
        </div>
      </div>
    </div>
  </div>
  <div class="center">
    <div>
      <span>
        <p>
          Ziehe die Datenfelder welche du exportieren möchtest in die Auswahl
        </p>
        <app-svgs type="enlarge-round-arrow"></app-svgs>
      </span>
    </div>
  </div>
  <div class="right">
    <h2>Für Export ausgewählt</h2>
    <div
      class="users user-wrap"
      [dragula]="'tags'"
      [(dragulaModel)]="selectedMasterDataFields"
    >
      <div
        class="user handle"
        *ngFor="let mDataField of selectedMasterDataFields || []"
      >
        <div class="inner">
          <span>
            {{ mDataField.displayName }}
          </span>
          <app-svgs
            type="enlarge-round-arrow-small"
            color="primary40"
          ></app-svgs>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="buttons">
  <app-button (click)="exportAndDownload()" [lessHeight]="true">
    Download
  </app-button>
</div>
