<div
  class="two-cols"
  [formGroup]="sedForm"
  [ngClass]="{ disabled: sedForm.disabled, customerPreview }"
>
  <div class="left">
    <table>
      <!-- Basiscs Customer View -->
      <tr *ngIf="basicsForPersonal && basicsForPersonal?.geschlecht">
        <h4 class="no-margin-top">Basics</h4>
      </tr>
      <!-- Geschlecht -->
      <tr *ngIf="basicsForPersonal && basicsForPersonal?.geschlecht">
        <td>Geschlecht</td>
        <td>
          {{ geschlecht[basicsForPersonal!.geschlecht!] }}
        </td>
      </tr>
      <!-- Name -->
      <tr
        *ngIf="
          basicsForPersonal &&
          (basicsForPersonal?.vorname || basicsForPersonal?.nachname)
        "
      >
        <td>Name</td>
        <td>
          {{ basicsForPersonal.vorname }} {{ basicsForPersonal.nachname }}
        </td>
      </tr>
      <!-- Geboren am -->
      <tr *ngIf="basicsForPersonal && basicsForPersonal?.geburtsdatum">
        <td>Alter</td>
        <td>
          {{ ageFromBirthday }}
        </td>
      </tr>
      <!-- Staatsangehörigkeit -->
      <tr
        *ngIf="basicsForPersonal && basicsForPersonal?.staatsangehoerigkeit_arr"
      >
        <td>Staatsangehörigkeit</td>
        <td>
          {{ (basicsForPersonal.staatsangehoerigkeit_arr || []).join(", ") }}
        </td>
      </tr>

      <!-- Äußeres -->

      <tr>
        <h4 [ngClass]="{ 'no-margin-top': !basicsForPersonal }">Äußeres</h4>
      </tr>
      <!-- Haarfarbe -->
      <tr [ngClass]="{ changed: isChanged('haarfarbe') }">
        <td>
          Haarfarbe
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('haarfarbe')"
          ></app-svgs>
        </td>
        <td>
          <select name="haarfarbe" id="haarfarbe" formControlName="haarfarbe">
            <option value=""></option>
            <option *ngFor="let farbe of haarfarben" [ngValue]="farbe">
              {{ farbe }}
            </option>
          </select>
          <span class="undo" (click)="reset('haarfarbe')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('haarfarbe')"
            ></app-svgs>
          </span>
          <div
            *ngIf="sedForm.get('haarfarbe')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>

      <!-- Körpergröße -->
      <tr [ngClass]="{ changed: isChanged('koerpergroesse') }">
        <td>
          Körpergröße
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('koerpergroesse')"
          ></app-svgs>
        </td>
        <td>
          <div *ngIf="sedForm.disabled">
            {{ sedForm.get("koerpergroesse")?.value }} cm
          </div>
          <div class="cm-wrap" *ngIf="!sedForm.disabled">
            <input
              type="number"
              oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
              step="1"
              min="0"
              max="300"
              name="koerpergroesse"
              id="koerpergroesse"
              formControlName="koerpergroesse"
              class="cm"
            />
          </div>
          <span class="undo" (click)="reset('koerpergroesse')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('koerpergroesse')"
            ></app-svgs>
          </span>
          <div
            *ngIf="sedForm.get('koerpergroesse')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>

      <!-- Gewicht -->
      <tr [ngClass]="{ changed: isChanged('gewicht') }">
        <td>
          Gewicht
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('gewicht')"
          ></app-svgs>
        </td>
        <td>
          <div *ngIf="sedForm.disabled">
            {{ sedForm.get("gewicht")?.value }} kg
          </div>
          <div class="cm-wrap kg" *ngIf="!sedForm.disabled">
            <input
              type="number"
              oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
              step="1"
              min="0"
              max="300"
              name="gewicht"
              id="gewicht"
              formControlName="gewicht"
              class="kg"
            />
          </div>
          <span class="undo" (click)="reset('gewicht')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('gewicht')"
            ></app-svgs>
          </span>
          <div
            *ngIf="sedForm.get('gewicht')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- Äußeres -->
      <tr>
        <h4>Konfektionsgrössen</h4>
      </tr>
      <!-- Shirtgröße -->
      <tr [ngClass]="{ changed: isChanged('shirtgroesse') }">
        <td>
          Shirtgröße
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('shirtgroesse')"
          ></app-svgs>
        </td>
        <td>
          <select
            name="shirtgroesse"
            id="shirtgroesse"
            formControlName="shirtgroesse"
          >
            <option value=""></option>
            <option
              *ngFor="let groesse of shirtArr; let i = index"
              [ngValue]="i"
            >
              {{ groesse }}
            </option>
          </select>
          <span class="undo" (click)="reset('shirtgroesse')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('shirtgroesse')"
            ></app-svgs>
          </span>
          <div
            *ngIf="sedForm.get('shirtgroesse')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- Jacke / Sacko -->
      <tr [ngClass]="{ changed: isChanged('jacke_sacko') }">
        <td>
          Jacke / Sacko
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('jacke_sacko')"
          ></app-svgs>
        </td>
        <td>
          <select
            name="jacke_sacko"
            id="jacke_sacko"
            formControlName="jacke_sacko"
          >
            <option value=""></option>
            <option
              *ngFor="let groesse of jacke_sacko_groessen; let i = index"
              [ngValue]="groesse"
            >
              {{ groesse }}
            </option>
          </select>
          <span class="undo" (click)="reset('jacke_sacko')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('jacke_sacko')"
            ></app-svgs>
          </span>
          <div
            *ngIf="sedForm.get('jacke_sacko')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- Hose / Rock -->
      <tr [ngClass]="{ changed: isChanged('hose_rock') }">
        <td>
          Hose / Rock
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('hose_rock')"
          ></app-svgs>
        </td>
        <td>
          <select name="hose_rock" id="hose_rock" formControlName="hose_rock">
            <option value=""></option>
            <option
              *ngFor="let groesse of hose_rock_groessen; let i = index"
              [ngValue]="groesse"
            >
              {{ groesse }}
            </option>
          </select>
          <span class="undo" (click)="reset('hose_rock')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('hose_rock')"
            ></app-svgs>
          </span>
          <div
            *ngIf="sedForm.get('hose_rock')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- Jeansweite -->
      <tr [ngClass]="{ changed: isChanged('jeansweite') }">
        <td>
          Jeansweite
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('jeansweite')"
          ></app-svgs>
        </td>
        <td>
          <select
            name="jeansweite"
            id="jeansweite"
            formControlName="jeansweite"
          >
            <option value=""></option>
            <option *ngFor="let groesse of jeansweite" [ngValue]="groesse">
              {{ groesse }}
            </option>
          </select>
          <span class="undo" (click)="reset('jeansweite')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('jeansweite')"
            ></app-svgs>
          </span>
          <div
            *ngIf="sedForm.get('jeansweite')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- Jeanslänge -->
      <tr [ngClass]="{ changed: isChanged('jeanslaenge') }">
        <td>
          Jeanslänge
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('jeanslaenge')"
          ></app-svgs>
        </td>
        <td>
          <select
            name="jeanslaenge"
            id="jeanslaenge"
            formControlName="jeanslaenge"
          >
            <option value=""></option>
            <option *ngFor="let groesse of jeanslaenge" [ngValue]="groesse">
              {{ groesse }}
            </option>
          </select>
          <span class="undo" (click)="reset('jeanslaenge')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('jeanslaenge')"
            ></app-svgs>
          </span>
          <div
            *ngIf="sedForm.get('jeanslaenge')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- Schuhgröße -->
      <tr [ngClass]="{ changed: isChanged('schuhgroesse') }">
        <td>
          Schuhgröße
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('schuhgroesse')"
          ></app-svgs>
        </td>
        <td>
          <select
            name="schuhgroesse"
            id="schuhgroesse"
            formControlName="schuhgroesse"
          >
            <option value=""></option>
            <option *ngFor="let groesse of schuhgroesse" [ngValue]="groesse">
              {{ groesse }}
            </option>
          </select>
          <span class="undo" (click)="reset('schuhgroesse')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('schuhgroesse')"
            ></app-svgs>
          </span>
          <div
            *ngIf="sedForm.get('schuhgroesse')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <!-- Sprachen -->
      <tr>
        <h4>Sprachen</h4>
      </tr>
      <tr
        [ngClass]="{ changed: isChanged(s.key, 'sprachen') }"
        *ngFor="let s of sprachenKeysLabelArray"
        formGroupName="sprachen"
      >
        <td>
          {{ s.label }}
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged(s.key, 'sprachen')"
          ></app-svgs>
        </td>
        <td>
          <select [name]="s.key" [id]="s.key" [formControlName]="s.key">
            <option value=""></option>
            <option *ngFor="let niveau of sprachNiveau" [ngValue]="niveau">
              {{ niveau }}
            </option>
          </select>
          <span class="undo" (click)="reset(s.key, 'sprachen')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged(s.key, 'sprachen')"
            ></app-svgs>
          </span>
        </td>
      </tr>
      <!-- additional languages -->
      <div formGroupName="sprachen">
        <tr
          [ngClass]="{
            changed: isChanged(s.key, 'sprachen', 'weitereAsGroup')
          }"
          *ngFor="let s of weiterSprachenKeys"
          formGroupName="weitereAsGroup"
        >
          <td>
            {{ s.label }}
            <app-svgs
              type="warning-circle-outlined"
              color="fail"
              *ngIf="isChanged(s.key, 'sprachen', 'weitereAsGroup')"
            ></app-svgs>
          </td>
          <td>
            <select [name]="s.key" [id]="s.key" [formControlName]="s.key">
              <option value=""></option>
              <option *ngFor="let niveau of sprachNiveau" [ngValue]="niveau">
                {{ niveau }}
              </option>
            </select>
            <span
              class="undo"
              (click)="reset(s.key, 'sprachen', 'weitereAsGroup')"
            >
              <app-svgs
                type="undo-circle"
                color="secondary"
                *ngIf="isChanged(s.key, 'sprachen', 'weitereAsGroup')"
              ></app-svgs>
            </span>
          </td>
        </tr>
      </div>
      <!-- Mobilität -->
      <tr>
        <h4>Mobilität</h4>
      </tr>
      <tr [ngClass]="{ changed: isChanged('fuehrerschein') }">
        <td>
          Führerschein
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('fuehrerschein')"
          ></app-svgs>
        </td>
        <td>
          <select
            name="fuehrerschein"
            id="fuehrerschein"
            formControlName="fuehrerschein"
          >
            <option [ngValue]="''"></option>
            <option [ngValue]="true">Ja</option>
            <option [ngValue]="false">Nein</option>
          </select>
          <span class="undo" (click)="reset('fuehrerschein')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('fuehrerschein')"
            ></app-svgs>
          </span>
          <div
            *ngIf="sedForm.get('fuehrerschein')?.invalid && submitAttempt"
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
      <tr [ngClass]="{ changed: isChanged('PKW', 'vorhandene_mobilitaet') }">
        <td>
          PKW
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('PKW', 'vorhandene_mobilitaet')"
          ></app-svgs>
        </td>
        <td formGroupName="vorhandene_mobilitaet">
          <select name="PKW" id="PKW" formControlName="PKW">
            <option [ngValue]="''"></option>
            <option [ngValue]="true">Ja</option>
            <option [ngValue]="false">Nein</option>
          </select>
          <span class="undo" (click)="reset('PKW', 'vorhandene_mobilitaet')">
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('PKW', 'vorhandene_mobilitaet')"
            ></app-svgs>
          </span>
          <div
            *ngIf="
              sedForm.get('vorhandene_mobilitaet')?.invalid && submitAttempt
            "
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>

      <tr
        [ngClass]="{ changed: isChanged('Bahncard', 'vorhandene_mobilitaet') }"
      >
        <td>
          Bahncard
          <app-svgs
            type="warning-circle-outlined"
            color="fail"
            *ngIf="isChanged('Bahncard', 'vorhandene_mobilitaet')"
          ></app-svgs>
        </td>
        <td formGroupName="vorhandene_mobilitaet">
          <select name="Bahncard" id="Bahncard" formControlName="Bahncard">
            <option [ngValue]="''"></option>
            <option [ngValue]="true">Ja</option>
            <option [ngValue]="false">Nein</option>
          </select>
          <span
            class="undo"
            (click)="reset('Bahncard', 'vorhandene_mobilitaet')"
          >
            <app-svgs
              type="undo-circle"
              color="secondary"
              *ngIf="isChanged('Bahncard', 'vorhandene_mobilitaet')"
            ></app-svgs>
          </span>
          <div
            *ngIf="
              sedForm.get('vorhandene_mobilitaet')?.invalid && submitAttempt
            "
            class="alert"
          >
            Ein gültiger Wert ist erforderlich.
          </div>
        </td>
      </tr>
    </table>
  </div>

  <div class="right">
    <div *ngIf="personStati && personStati.length > 0">
      <h4 class="no-margin-top">Status</h4>
      <span *ngFor="let status of personStati; let i = index">
        {{ status + (i < personStati.length - 1 ? ", " : "") }}
      </span>
    </div>
    <div *ngIf="personProfession && personProfession.length > 0">
      <h4>Fachbereich / Beruf</h4>
      <span *ngFor="let profession of personProfession; let i = index">
        {{ profession + (i < personProfession.length - 1 ? ", " : "") }}
      </span>
    </div>
    <div>
      <h4>Fotos</h4>
      <app-photo-gallery
        *ngIf="!arbeitskreafteEdit"
        [media]="sedForm.value.fotos"
      >
      </app-photo-gallery>
      <app-fileupload
        *ngIf="arbeitskreafteEdit"
        [uploadFiles]="sedForm.value['fotos']"
        (uploadFilesChange)="sedForm.controls['fotos'].setValue($event)"
        (isUploading)="isUploading.emit($event)"
      >
      </app-fileupload>
    </div>
    <table *ngIf="berufserfahrungArr.controls.length > 0">
      <tr>
        <h4 class="flex space-between">
          <span>Erfahrung</span>
          <div
            class="flex gap-7 cursor-pointer flex-center-vertical"
            *ngIf="
              sedForm.enabled &&
              (berufserfahrungChangedAtIndex.length ||
                berufserfahrungArr.value.length !=
                  berufserfahrungOriginal.length)
            "
            (click)="resetBerufserfahrung()"
          >
            <strong class="secondary">Zurücksetzen</strong
            ><app-svgs type="undo-circle" color="secondary"></app-svgs>
          </div>
        </h4>
      </tr>
    </table>
    <div [formGroup]="berufserfahrung" class="berufserfahrung">
      <div formArrayName="cntrls">
        <table
          *ngFor="let be of berufserfahrungArr.controls; index as i"
          [formGroupName]="i"
          [ngClass]="{ changed: berufserfahrungChangedAtIndex.includes(i) }"
        >
          <tr>
            <td><strong>Veranstaltung</strong></td>
            <td>
              <input
                *ngIf="!customerPreview"
                formControlName="veranstaltung"
                type="text"
              />
              <span *ngIf="customerPreview">{{
                be.get("veranstaltung")?.value
              }}</span>
              <span class="undo" (click)="removeBerufserfahrungAtIndex(i)">
                <app-svgs type="trash" color="secondary"></app-svgs>
              </span>
              <div
                *ngIf="be.get('veranstaltung')?.invalid && submitAttempt"
                class="alert"
              >
                Ein gültiger Wert ist erforderlich.
              </div>
            </td>
          </tr>
          <tr>
            <td>Tätigkeit</td>
            <td>
              <input
                formControlName="taetigkeit"
                type="text"
                *ngIf="!customerPreview"
              />
              <span *ngIf="customerPreview">{{
                be.get("taetigkeit")?.value
              }}</span>
              <div
                *ngIf="be.get('taetigkeit')?.invalid && submitAttempt"
                class="alert"
              >
                Ein gültiger Wert ist erforderlich.
              </div>
            </td>
          </tr>
          <tr>
            <td>Markenname</td>
            <td>
              <input
                formControlName="markenname"
                type="text"
                *ngIf="!customerPreview"
              />
              <span *ngIf="customerPreview">{{
                be.get("markenname")?.value
              }}</span>
              <div
                *ngIf="be.get('markenname')?.invalid && submitAttempt"
                class="alert"
              >
                Ein gültiger Wert ist erforderlich.
              </div>
            </td>
          </tr>
        </table>
        <app-button
          *ngIf="!sedForm.disabled"
          (click)="addBerufserfahrung()"
          color="secondary-gradient"
          background="primary10"
          iconRight="plus"
          [lessHeight]="true"
          >Erfahrung hinzufügen</app-button
        >
      </div>
    </div>

    <div *ngIf="hatWeitereErfahrungen">
      <h4 class="flex gap-7 flex-center-vertical">
        Sonstige Erfahrungen
        <app-svgs
          type="warning-circle-outlined"
          color="fail"
          *ngIf="isChanged('weitere_erfahrungen')"
        ></app-svgs>
        <span class="undo" (click)="reset('weitere_erfahrungen')">
          <app-svgs
            type="undo-circle"
            color="secondary"
            *ngIf="isChanged('weitere_erfahrungen')"
          ></app-svgs>
        </span>
      </h4>
      <div [ngClass]="{ changed: isChanged('weitere_erfahrungen') }">
        <textarea
          formControlName="weitere_erfahrungen"
          cols="30"
          *ngIf="!customerPreview"
        ></textarea>
        <span *ngIf="customerPreview">
          <pre style="white-space: break-spaces">{{
            sedForm.get("weitere_erfahrungen")?.value
          }}</pre>
        </span>
      </div>
    </div>
  </div>
</div>
