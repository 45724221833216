<div class="meine-jobs">
  <div class="main">
    <app-header-w-backbtn-logo
      [backBtn]="!!current_detail_cat"
      routerLink="/meine-jobs"
      (backBtnClicked)="current_detail_cat = undefined"
    ></app-header-w-backbtn-logo>
    <div class="content" *ngIf="!current_detail_cat">
      <p class="card" *ngIf="!jobs || !jobs.length">
        Du hast noch keine Bewerbungen oder Jobs.
      </p>

      <!-- <div *ngIf="jobs && jobs.length" > -->
      <!-- Laufender Job -->
      <!-- <div *ngIf="current_jobs.length"> -->
      <app-job-card
        *ngFor="let job of current_jobs"
        [job]="job.project"
        [application]="job"
        [zeiterfassungsButton]="true"
        [currentJob]="true"
        buttonBg="primary10"
      >
      </app-job-card>
      <!-- </div> -->

      <!-- Nächster Job -->
      <!-- <div > -->
      <!-- *ngFor="let next of next_jobs"  -->
      <app-job-card
        *ngIf="!current_jobs.length && next_jobs.length"
        [job]="next_jobs[0].project"
        buttonBg="primary10"
        [application]="next_jobs[0]"
        [nextJob]="true"
      >
      </app-job-card>
      <!-- </div> -->
      <!-- </div> -->

      <div
        class="category"
        *ngFor="let cat of categories"
        (click)="
          cat.count ? navigateTo(cat) : undefined;
          current_detail_cat = cat.count ? cat : undefined
        "
      >
        <!-- (click)="" -->
        <div class="primary-badge white">{{ cat.count }}</div>
        <h4 class="white">{{ cat.name }}</h4>
        <app-svgs *ngIf="cat.count" type="chevron-right" color="white">
        </app-svgs>
      </div>
    </div>

    <div class="content" *ngIf="current_detail_cat">
      <h1 class="secondary">
        <span *ngIf="current_detail_cat.name == 'Bewerbungen'" class="white"
          >Meine <br /></span
        >{{ current_detail_cat.name }}
      </h1>
      <app-job-card
        *ngFor="let application of current_detail_cat.applications"
        [job]="application.project"
        [application]="application"
        buttonBg="primary10"
      >
      </app-job-card>
    </div>
  </div>
  <div class="detail">
    <div class="logo-container">
      <img src="assets/svgs/logo_big_claim.svg" alt="Logo Claim" class="logo" />
    </div>
  </div>
</div>
