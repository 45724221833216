<app-modal (close)="close.emit()" class="copy-personal-modal">
  <h4>Auswahl kopieren</h4>

  <h5>Derzeit ausgewählt:</h5>
  <ng-select
    [multiple]="true"
    [virtualScroll]="true"
    [(ngModel)]="selectedUserIds"
    [loading]="isLoading && !userItems.length"
    class="ng-select-multiple"
  >
    <ng-option *ngFor="let user of userItems" [value]="user!.id">{{
      user!.name
    }}</ng-option>
  </ng-select>
  <h5>In welches Projekt soll die Auswahl kopiert werden?</h5>
  <ng-select
    [(ngModel)]="selectedProjectId"
    [virtualScroll]="true"
    [loading]="isLoading && !projects?.length"
  >
    <ng-option *ngFor="let project of projects || []" [value]="project!.id"
      >{{ project!.titel }} ({{
        project?.zeitraum_von | date : "y"
      }})</ng-option
    >
  </ng-select>
  <p class="subscription">
    Es werden nur Bewerber berücksichtigt, welche schon einmal eine vollständige
    und durch Topteam bestätigte Bewerbung durchlaufen haben.
  </p>
  <div class="flex space-between">
    <app-button
      (click)="close.emit()"
      color="primary80"
      [disabled]="isLoading"
      [isLoading]="isLoading"
      [lessHeight]="true"
    >
      Abbrechen
    </app-button>
    <app-button
      (click)="copyAuswahlToProjekt()"
      [disabled]="isLoading"
      [isLoading]="isLoading"
      [lessHeight]="true"
    >
      Kopieren
    </app-button>
  </div>
</app-modal>
