<span
  *ngIf="lightboxMode"
  class="close-lightbox"
  (click)="lightboxMode = false"
>
  <app-svgs type="close" color="white"></app-svgs>
</span>
<swiper
  *ngIf="media && media.length"
  slidesPerView="auto"
  [spaceBetween]="spaceBetween"
  [grabCursor]="true"
  [navigation]="true"
>
  <!-- 'background-image': 'url(' + item.thumbnail + ')' -->
  <ng-template swiperSlide *ngFor="let item of filteredMedia">
    <!-- <div
      (click)="lightboxMode = true"
      [ngStyle]="{
        'background-image': 'url(' + (resourceUrl + item.nameOnDisk) + ')'
      }"
      class="swiper-img"
    ></div> -->
    <img
      (click)="lightboxMode = true"
      [src]="resourceUrl + item.nameOnDisk"
      class="swiper-img"
    />
  </ng-template>
</swiper>
