export const konfessionen = [
  { id: "VD", label: "Andere / Konfessionslos", lodas: "0" },
  { id: "EV", label: "Evangelisch", lodas: "1" },
  { id: "RK", label: "Römisch-katholisch", lodas: "2" },
  // former konfession values, not used anymore for now, keep this comment for reference
  // { id: "AK", label: "Alt-katholische Kirche", lodas: "3" },
  // { id: "RF", label: "Evangelisch-reformiert", lodas: "18" },
  // { id: "FR", label: "Französisch-reformiert", lodas: "8" },
  // { id: "FA", label: "Freie Religionsgemeinschaft Alzey", lodas: "4" },
  // { id: "FB", label: "Freireligiöse Landesgemeinde Baden", lodas: "5" },
  // { id: "FG", label: "Freireligiöse Landesgemeinde Pfalz", lodas: "6" },
  // { id: "FM", label: "Freireligiöse Landesgemeinde Mainz", lodas: "7" },
  // { id: "FS", label: "Freireligiöse Landesgemeinde Offenbach/M.", lodas: "9" },
  // { id: "IB", label: "Israelitische Religionsgemeinschaft Baden", lodas: "10" },
  // {
  //   id: "IW",
  //   label: "Israelitische Religionsgemeinschaft Württemberg",
  //   lodas: "14",
  // },
  // { id: "IS", label: "Israelitische Gemeinden in Bayern", lodas: "13" },
  // { id: "IL", label: "Israelitische Kultussteuer Land Hessen", lodas: "12" },
  // { id: "IS", label: "Israelitische Synagogengemeinde Saar", lodas: "14" },
  // {
  //   id: "IS",
  //   label: "Israelitische Kultussteuer Frankfurt (Hessen)",
  //   lodas: "14",
  // },
  // {
  //   id: "IS",
  //   label: "Jüdische Kultusgemeinden Koblenz und Bad Kreuznach",
  //   lodas: "14",
  // },
  // { id: "JD", label: "Jüdische Kultussteuer Nordrhein-Westfalen", lodas: "15" },
  // { id: "JH", label: "Jüdische Kultussteuer Hamburg", lodas: "16" },
  // { id: "Andere", label: "Andere", lodas: "0" },
];
