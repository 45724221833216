import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-ordering-icons",
  templateUrl: "./ordering-icons.component.html",
  styleUrls: ["./ordering-icons.component.scss"],
})
export class OrderingIconsComponent implements OnInit {
  @Input()
  sortDirection: number = 0;

  constructor() {}

  ngOnInit(): void {}
}
