import { Component, Input, OnInit } from "@angular/core";
import { User } from "@ttc_types/types";
import { Notify } from "notiflix";
import { BehaviorSubject, debounceTime } from "rxjs";
import { DataService } from "src/app/data.service";
import { AuthService } from "src/app/shared/auth.service";

@Component({
  selector: "app-bewerber-notiz",
  templateUrl: "./bewerber-notiz.component.html",
  styleUrls: ["./bewerber-notiz.component.scss"],
})
export class BewerberNotizComponent implements OnInit {
  @Input() public user!: User;

  public note: BehaviorSubject<string | null>;

  constructor(private data: DataService, private auth: AuthService) {
    this.note = new BehaviorSubject<string | null>(null);
  }

  ngOnInit(): void {
    this.note.next(this.user.profile?.note ?? null);
  }

  ngAfterViewInit(): void {
    this.note.pipe(debounceTime(1000)).subscribe(() => this.saveNote());
  }

  private async saveNote() {
    const userId = this.user.id;
    if (!userId) {
      return;
    }

    const noteValue = this.note.getValue();

    // if note is null, do not save
    if (noteValue === null) {
      return;
    }

    // if note has not changed, do not save
    if (this.user.profile?.note === this.note.getValue()) {
      return;
    }

    try {
      await this.data.saveNote(userId, noteValue);
    } catch (error) {
      console.error("error", error);
      Notify.failure("Etwas ist schief gelaufen.");
    }
  }
}
