import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import SignaturePadNative, { PointGroup } from "signature_pad";

/*
 * Angular component for touch signature using HTML5 canvas.
 * Copied into the project because of angular version incompatibilities with the original package and the package not being maintained anymore.
 *
 * @see https://www.npmjs.com/package/angular2-signaturepad
 * @see https://github.com/wulfsolter/angular2-signaturepad
 * @see https://github.com/wulfsolter/angular2-signaturepad/blob/master/projects/angular2-signaturepad/src/lib/angular2-signaturepad.component.ts
 */

@Component({
  template: "<canvas></canvas>",
  selector: "app-signature-pad",
})
export class SignaturePad implements AfterContentInit, OnDestroy {
  @Input() public options: any;

  @Output() public onBeginEvent: EventEmitter<boolean>;

  @Output() public onEndEvent: EventEmitter<boolean>;

  private signaturePad!: SignaturePadNative;

  private elementRef: ElementRef;

  constructor(elementRef: ElementRef) {
    // no op
    this.elementRef = elementRef;
    this.options = this.options || {};
    this.onBeginEvent = new EventEmitter();
    this.onEndEvent = new EventEmitter();
  }

  public ngAfterContentInit(): void {
    const canvas: any = this.elementRef.nativeElement.querySelector("canvas");

    if ((this.options as any).canvasHeight) {
      canvas.height = (this.options as any).canvasHeight;
    }

    if ((this.options as any).canvasWidth) {
      canvas.width = (this.options as any).canvasWidth;
    }

    this.signaturePad = new SignaturePadNative(canvas, this.options);
    this.signaturePad.addEventListener("beginStroke", () => this.onBegin());
    this.signaturePad.addEventListener("endStroke", () => this.onEnd());
  }

  public ngOnDestroy(): void {
    const canvas: HTMLCanvasElement =
      this.elementRef.nativeElement.querySelector("canvas");
    canvas.width = 0;
    canvas.height = 0;
  }

  public resizeCanvas(): void {
    if (!this.signaturePad) {
      return;
    }

    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    const ratio: number = Math.max(window.devicePixelRatio || 1, 1);
    const canvas = this.getCanvas();
    const context = canvas.getContext("2d");

    if (!context) {
      return;
    }

    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    context.scale(ratio, ratio);

    this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  }

  // Returns signature image as an array of point groups
  public toData() {
    if (this.signaturePad) {
      return this.signaturePad.toData();
    }

    return [];
  }

  // Draws signature image from an array of point groups
  public fromData(points: PointGroup[]): void {
    this.signaturePad.fromData(points as any);
  }

  // Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible paramters)
  public toDataURL(imageType?: string, quality?: number): string {
    return this.signaturePad.toDataURL(imageType, quality); // save image as data URL
  }

  // Draws signature image from data URL
  public fromDataURL(dataURL: string, options: any = {}): void {
    // set default height and width on read data from URL
    if (
      // eslint-disable-next-line no-prototype-builtins
      !options.hasOwnProperty("height") &&
      (this.options as any).canvasHeight
    ) {
      // eslint-disable-next-line no-param-reassign
      options.height = (this.options as any).canvasHeight;
    }
    // eslint-disable-next-line no-prototype-builtins
    if (!options.hasOwnProperty("width") && (this.options as any).canvasWidth) {
      // eslint-disable-next-line no-param-reassign
      options.width = (this.options as any).canvasWidth;
    }
    this.signaturePad.fromDataURL(dataURL, options);
  }

  // Clears the canvas
  public clear(): void {
    this.signaturePad.clear();
  }

  // Returns true if canvas is empty, otherwise returns false
  public isEmpty(): boolean {
    return this.signaturePad.isEmpty();
  }

  // Unbinds all event handlers
  public off(): void {
    this.signaturePad.off();
  }

  // Rebinds all event handlers
  public on(): void {
    this.signaturePad.on();
  }

  public getCanvas(): HTMLCanvasElement {
    const canvas: HTMLCanvasElement =
      this.elementRef.nativeElement.querySelector("canvas");

    if (!canvas) {
      throw new Error("Canvas element not found");
    }

    return canvas;
  }

  // notify subscribers on signature begin
  public onBegin(): void {
    this.onBeginEvent.emit(true);
  }

  // notify subscribers on signature end
  public onEnd(): void {
    this.onEndEvent.emit(true);
  }

  public queryPad(): any {
    return this.signaturePad;
  }
}
