import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { Media } from "@ttc_types/types";
import SwiperCore, { Navigation } from "swiper";
import { environment } from "../../../environments/environment";

SwiperCore.use([Navigation]);

@Component({
  selector: "app-photo-gallery",
  templateUrl: "./photo-gallery.component.html",
  styleUrls: ["./photo-gallery.component.scss"],
})
export class PhotoGalleryComponent implements OnInit {
  @Input()
  media: Media[] | undefined | null;

  @HostBinding("class.lightbox-mode")
  lightboxMode = false;

  @Input()
  spaceBetween: number = 15;

  get filteredMedia(): Media[] {
    return this.media?.filter((m) => !m.deleted) ?? [];
  }

  resourceUrl: string = `${environment.resourceUrl}/`;

  constructor() {}

  ngOnInit(): void {}
}
