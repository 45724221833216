<div class="import">
  <div class="main">
    <!-- <span class="secondary">vorlagen</span> -->
    <h1 class="white">Import from Lodas Excel</h1>
    <div class="white">
      <!-- <app-button (click)="vorlageHinzufuegen()">Vorlage hinzufügen</app-button> -->
      <input
        type="file"
        style="display: inline-block"
        (change)="incomingfile($event); upload()"
        placeholder="Upload file"
        accept=".xlsx"
      />

      <app-button
        (click)="upload()"
        [disabled]="isUploading"
        [isLoading]="isUploading"
        >Importieren</app-button
      >
      Prozent: {{ percent }} %
    </div>
    <h1 class="white">
      Import Last Lodas Austrittsdatum from Lodas Excel 23.12.2023
    </h1>
    <div class="white">
      <!-- <app-button (click)="vorlageHinzufuegen()">Vorlage hinzufügen</app-button> -->
      <input
        type="file"
        style="display: inline-block"
        (change)="incomingFileAustritt($event); uploadAustritt()"
        placeholder="Upload file"
        accept=".xlsx"
      />

      <app-button
        (click)="uploadAustritt()"
        [disabled]="isUploading"
        [isLoading]="isUploading"
        >Importieren</app-button
      >
      Prozent: {{ percent }} %
    </div>
  </div>
</div>
