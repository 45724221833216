/* eslint-disable no-continue */
import {
  Berufserfahrung,
  Review,
  Sprachen,
  Profile,
  SED,
  User,
  VorhandeneMobilitaet,
} from "@ttc_types/types";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { staatsangehoearigkeit } from "@ttc_types/staatsangehoerigkeit";
import { staaten } from "@ttc_types/staaten";
import { guertellaenge, haarfarben, shirtArr } from "@ttc_types/datasets";
/* eslint-disable no-case-declarations */
import { Notify } from "notiflix";
import { Component, OnInit } from "@angular/core";
import * as XLSX from "xlsx";

import { closest, distance } from "fastest-levenshtein";
import { DataService } from "src/app/data.service";
// import * as dayjs from "dayjs";
import { dayjs } from "src/app/shared/date-util";

@Component({
  selector: "app-import",
  templateUrl: "./import.component.html",
  styleUrls: ["./import.component.scss"],
})
export class ImportComponent implements OnInit {
  arrayBuffer: any;

  file: File | undefined;

  fileAustritt: File | undefined;

  isUploading: boolean = false;

  percent: number = 0;

  constructor(private data: DataService) {}

  ngOnInit(): void {}

  incomingfile($event: any) {
    // eslint-disable-next-line prefer-destructuring
    this.file = $event.target.files[0];

    console.log(`incomingfile`, $event);
  }

  incomingFileAustritt($event: any) {
    // eslint-disable-next-line prefer-destructuring
    this.fileAustritt = $event.target.files[0];
  }

  convertDateExcel(excelTimestamp?: number) {
    // 1. Subtract number of days between Jan 1, 1900 and Jan 1, 1970, plus 1 (Google "excel leap year bug")
    // 2. Convert to milliseconds.
    if (!excelTimestamp) return undefined;
    const secondsInDay = 24 * 60 * 60;
    const excelEpoch = new Date(1899, 11, 31);
    const excelEpochAsUnixTimestamp = excelEpoch.getTime();
    const missingLeapYearDay = secondsInDay * 1000;
    const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
    const excelTimestampAsUnixTimestamp = excelTimestamp * secondsInDay * 1000;
    const parsed = excelTimestampAsUnixTimestamp + delta;
    // eslint-disable-next-line no-restricted-globals
    return isNaN(parsed) ? undefined : new Date(parsed);
  }

  async uploadAustritt() {
    // read file and parse it with xlsx
    if (!this.fileAustritt) {
      Notify.failure("Bitte wähle eine Datei aus.");
      return;
    }
    try {
      this.isUploading = true;
      await new Promise((res) => {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          this.arrayBuffer = fileReader.result;
          const data = new Uint8Array(this.arrayBuffer);
          const arr = [];
          for (let i = 0; i != data.length; ++i)
            arr[i] = String.fromCharCode(data[i]);
          const bstr = arr.join("");
          const workbook = XLSX.read(bstr, { type: "binary" });
          const first_sheet_name = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[first_sheet_name];
          const json: { [key: string]: string | number }[] =
            XLSX.utils.sheet_to_json(worksheet, { raw: true });
          console.log(`json`, json);

          // parse data start

          const personalnummer_austritt: {
            [personalnummer: number]: {
              austritt: string;
              vorname: string;
              nachname: string;
            };
          } = {};

          // console.log(`json`, json.slice(0, 10));
          for (const row of json) {
            if (!row.Austritt) {
              continue;
            }
            const persNr = parseInt(`${row["Pers.Nr."]}`, 10);

            const oldDate = personalnummer_austritt[persNr]?.austritt
              ? dayjs(personalnummer_austritt[persNr]?.austritt, "DD.MM.YYYY")
              : undefined;

            const newDate = dayjs(`${row.Austritt}`, "DD.MM.YYYY");

            if (oldDate && oldDate.isAfter(newDate)) {
              continue;
            }

            personalnummer_austritt[parseInt(`${row["Pers.Nr."]}`, 10)] = {
              austritt: newDate.format("DD.MM.YYYY"),
              vorname: `${row.Vorname}`,
              nachname: `${row.Name}`,
            };

            // this.convertDateExcel(parseInt(`${row.Austritt!}`, 10));
          }
          // console.log(`personalnummer_austritt`, personalnummer_austritt);
          this.data.postRequest("/users/importAustrittsdatenFromLodasXLSX", {
            personalnummer_austritt,
          });
        };
        fileReader.readAsArrayBuffer(this.fileAustritt!);
      });
    } catch (error) {
      console.error(error);
    }
    this.isUploading = false;
  }

  async upload() {
    if (!this.file) {
      Notify.failure("Bitte wähle eine Datei aus.");
      return;
    }
    try {
      this.isUploading = true;

      await new Promise((res) => {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          this.arrayBuffer = fileReader.result;
          const data = new Uint8Array(this.arrayBuffer);
          const arr = [];
          for (let i = 0; i != data.length; ++i)
            arr[i] = String.fromCharCode(data[i]);
          const bstr = arr.join("");
          const workbook = XLSX.read(bstr, { type: "binary" });
          const first_sheet_name = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[first_sheet_name];
          const json: { [key: string]: string | number }[] =
            XLSX.utils.sheet_to_json(worksheet, { raw: true });
          console.log(`json`, json);

          // parse data start
          let i = 0;

          const users: User[] = [];

          for (const row of json) {
            const user: Partial<User> = {};
            const sed: Partial<SED> = {};
            const profile: Partial<Profile> = {};

            const arbeitsbeginn_status: { [key: string]: boolean } = {
              Studentin: false,
            };
            const arbeitsbeginn_status_additional: { [key: string]: string } =
              {};
            profile.arbeitsbeginn_status = arbeitsbeginn_status;
            profile.arbeitsbeginn_status_additional =
              arbeitsbeginn_status_additional;

            const vorhandene_mobilitaet: VorhandeneMobilitaet = {
              PKW: false,
              Bahncard: false,
            };

            const berufserfahrung: Berufserfahrung = {
              veranstaltung: "",
              taetigkeit: "",
              markenname: "",
            };

            const sprachen: Sprachen = {
              deutsch: "",
              englisch: "",
              spanisch: "",
              franzoesisch: "",
              italienisch: "",
              weitere: [],
            };

            const sonstige_sprache = {
              label: "",
              level: "2",
            };

            const review: Review = {
              review_text: "",
              // project_name?: string;
              // reviewer_name?: string;
              review_date: new Date(),
            };

            sed.vorhandene_mobilitaet = vorhandene_mobilitaet;
            sed.sprachen = sprachen;

            user.main_sed_card = sed as SED;
            user.profile = profile as Profile;

            let string_value = "";
            // eslint-disable-next-line guard-for-in
            for (const key in row as object) {
              string_value = `${row[key]}`.trim();
              if (
                !string_value ||
                string_value == "0" ||
                string_value == "-" ||
                string_value == "tba"
              ) {
                // eslint-disable-next-line no-continue
                continue;
              }

              switch (key) {
                case "Ansprechpartner::Anrede Herrn":
                  profile.geschlecht = ["Ms.", "Frau"].includes(string_value)
                    ? "w"
                    : "m";
                  break;
                case "Ansprechpartner::Vorname":
                  profile.vorname = string_value;
                  break;
                case "Ansprechpartner::Name":
                  profile.nachname = string_value;
                  break;
                case "Erstadresse: Straße":
                  const first_digit_index = string_value.search(/\d/);
                  const street = string_value.slice(0, first_digit_index);
                  const street_number = string_value.slice(first_digit_index);
                  profile.strasse = street.trim();
                  profile.hausnr = street_number.trim();
                  break;
                case "Erstadresse: PLZ":
                  profile.plz = string_value;
                  break;
                case "Erstadresse: Ort":
                  profile.ort = string_value;
                  break;
                case "Erstadresse: Land":
                  if (string_value == "D") {
                    profile.wohnland = "DE";
                  } else if (string_value == "A" || string_value == "AUT") {
                    profile.wohnland = "AT";
                  } else {
                    profile.wohnland = string_value;
                  }
                  break;
                case "Ansprechpartner::Geburtsdatum":
                  const date_tmp = string_value
                    ? this.convertDateExcel(parseInt(string_value, 10))
                    : undefined;
                  if (date_tmp) {
                    const dd_mm_yyyy = `${`0${date_tmp.getDate()}`.substring(
                      -2
                    )}.${`0${date_tmp.getMonth() + 1}`.substring(
                      -2
                    )}.${date_tmp.getFullYear()}`;
                    profile.geburtsdatum = dd_mm_yyyy;
                  }

                  break;
                case "Ansprechpartner::Email 1":
                  user.email = string_value;
                  break;
                case "Ansprechpartner::Mobilnummer":
                  profile.tel = string_value;
                  break;
                case "Stammdaten: Geburtsort":
                  const split_value = string_value.split(",");
                  const geburtsort = split_value[0].trim();
                  const geburtsland =
                    split_value.length > 1 ? split_value[1].trim() : undefined;
                  profile.geburtsstadt = geburtsort;
                  if (geburtsland) {
                    const filtered = staaten.filter(
                      (s) => s.label == geburtsland || s.id == geburtsland
                    );
                    if (filtered.length > 0) {
                      profile.geburtsland = filtered[0].id;
                    } else {
                      profile.geburtsland = geburtsland;
                    }
                  }
                  break;
                case "Stammdaten: Staatsangehörigkeit":
                  // Besprechen!!
                  // Hier haben einige mehrere Staatsangehörigkeiten angegeben, die mit "/" getrennt sind...
                  // Wir nehmen erstmal nur die erste

                  const whitespaceRemoved = string_value.replace(/\s/g, "");
                  const staats_res = [];
                  let staats_arr = [];
                  if (whitespaceRemoved.includes("/")) {
                    staats_arr = whitespaceRemoved.split("/");
                  } else if (whitespaceRemoved.includes(",")) {
                    staats_arr = whitespaceRemoved.split(",");
                  } else {
                    staats_arr = [whitespaceRemoved];
                  }

                  for (const staats_string of staats_arr) {
                    const closest_res = closest(
                      staats_string,
                      staatsangehoearigkeit
                    );
                    // console.log(
                    //   `distance(staats_string, closest_res)`,
                    //   staats_string,
                    //   closest_res,
                    //   distance(staats_string, closest_res)
                    // );
                    if (distance(staats_string, closest_res) <= 1) {
                      staats_res.push(closest_res);
                    }
                  }

                  if (staats_res.length) {
                    profile.staatsangehoerigkeit_arr = staats_res;
                  }
                  break;
                case "Stammdaten: Gesundheitszeugnis":
                  profile.gesundheitszeugnis = string_value == "ja";
                  break;
                case "Stammdaten: Gewerbeschein":
                  profile.gewerbeschein = string_value == "ja";
                  break;
                case "Stammdaten: Immatrikulationsbescheinigung":
                  arbeitsbeginn_status.Studentin = string_value == "ja";
                  break;
                case "Stammdaten: Immatrikulationsbescheinigung_2":
                  // if (typeof row[key] !== "string") break;
                  // const dateParts = `${row[key]}`.split(".");
                  // if (dateParts.length != 3) break;
                  // arbeitsbeginn_status_additional.Studentin_gueltig_bis =
                  //   dateParts.reverse().join("-");
                  break;
                case "Stammdaten: Familienstand":
                  // das hier gibt es noch nicht?
                  if (
                    ["ledig", "verheiratet"].includes(
                      string_value.toLowerCase()
                    )
                  )
                    profile.familienstand = string_value.toLowerCase() as
                      | "ledig"
                      | "verheiratet";
                  break;
                case "Stammdaten: Konfession":
                  if (
                    string_value == "keine Angabe" ||
                    string_value == "sonstige"
                  )
                    break;

                  if (string_value == "rk") {
                    profile.religionszugehoerigkeit = {
                      id: "RK",
                      label: "Römisch-katholisch",
                    };
                    break;
                  }
                  if (string_value == "ev") {
                    profile.religionszugehoerigkeit = {
                      id: "EV",
                      label: "Evangelisch",
                    };
                  }
                  if (string_value == "Andere / Konfessionslos") {
                    profile.religionszugehoerigkeit = {
                      id: "VD",
                      label: "Andere / Konfessionslos",
                    };
                    break;
                  }
                  break;
                case "Stammdaten: Sozialversicherungsnummer":
                  profile.sozialversicherungsnummer = string_value;
                  break;
                case "Stammdaten: Lohnsteuerkarte":
                  profile.steueridentifikationsnummer = string_value;
                  break;
                // "Stammdaten: Steuerklasse": 1,
                // "Stammdaten: Personalausweisnummer": "AXR319849",
                // "Stammdaten: Bankverbindung IBAN": "DE37 5007 0024 0134 9364 00",
                // "Stammdaten: Bankverbindung BLZ": "DEUTDEDBFRA",
                // "Stammdaten: Personalnummer": "10530",
                // "Outfit: Shirtgröße": "S",
                // "Outfit: Gürtellänge": 75,
                // "Outfit: Jacke Sakko": "38",
                // "Outfit: Jeans Länge": 32,
                // "Outfit: Jeans Weite": 27,
                // "Outfit: Körpergröße": 177,
                // "Outfit: Kragenweite": 0,
                // "Outfit: Rock Hose": "36/38",
                // "Outfit: Schuhgröße": "40",
                // "Stammdaten: Körpergewicht": 60,
                // "Stammdaten: Haarfarbe": "braun",
                // "Stammdaten: eigener PKW": "nein",
                // "Stammdaten: Führerscheinklasse": "B",
                // "Bahncard vorhanden": "nein",
                // "Ausbildung: Erfahrung": "Messe, Service, Event",
                // "Ausbildung: Erfahrung_Automobilhersteller": "BMW, MINI",
                // "Ausbildung: Erfahrung_Marken_Hersteller": "Deutsche Fußball-Liga",
                // "Ausbildung: Erfahrung_Messen_Events_Promo": "EC München 2022, IAA 2021, DFL Neujahrsempfang 2021, Deutscher Mittelstandssummit 2021 & 2022",
                // "Sprache: Deutsch": "1",
                // "Sprache: Englisch": "2",
                // "Sprache: Französisch": "3",
                // "Sprache: sonstige": "M",
                // "Sprache: sonstige welche": "polnisch",
                // "Beurteilung Fließtext": "EC MINI München (PB Hanna Scheffler, TL David Nyaku):\r\nHat einen guten Job gemacht, keine negativen Vorkommnisse.\r\n\r\nBMW IAA 2021 (PB YHE, FRI, SAW)\r\nGanz gut.",
                // "Stammdaten: Erfassungsdatum": 44411,
                // "Status Zusammenarbeit": "Servicekräfte",

                case "Stammdaten: Steuerklasse":
                  profile.steuerklasse = parseInt(string_value, 10);
                  break;
                case "Stammdaten: Personalausweisnummer":
                  profile.ausweisnummer = string_value;
                  break;
                case "Stammdaten: Bankverbindung IBAN":
                  profile.iban = string_value.replace(/ /g, "");
                  break;
                case "Stammdaten: Bankverbindung BLZ":
                  profile.bic = string_value;
                  break;
                case "Stammdaten: Personalnummer":
                  try {
                    user.personalnummer = parseInt(string_value, 10);
                  } catch (parseIntError) {
                    console.error(`parseIntError`, parseIntError);
                  }
                  break;
                case "Outfit: Shirtgröße":
                  // const shirt_gr = shirtArr.indexOf(row[key] as string);
                  const shirt_gr = [
                    ["XS", "36"].concat(["XXS"]), // letzteres XXXS größen - besprechen
                    ["S", "36S", "38"],
                    ["M", "40", "m", "M8"],
                    ["L", "42"],
                    ["XL", "44"],
                    ["XXL", "46"].concat(["48", "50", "52", "54", "56"]), // letzteres XXXL größen - besprechen
                  ];
                  // eslint-disable-next-line no-loop-func
                  const index_shirt_gr = shirt_gr.findIndex((s) =>
                    s.includes(string_value)
                  );
                  if (index_shirt_gr > -1) {
                    // eslint-disable-next-line prefer-destructuring
                    sed.shirtgroesse = index_shirt_gr;
                  }
                  break;
                case "Outfit: Gürtellänge":
                  let numbr = parseInt(string_value, 10);
                  if (numbr == 0 || numbr < 70 || numbr > 115) break;

                  // round to next 5er step
                  const diff = numbr % 5;
                  if (diff > 0) {
                    numbr = numbr + 5 - diff;
                  }
                  sed.guertellaenge = parseInt(string_value, 10);

                  // Besprechen
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const moegliche_werte = [
                    0, 36, 38, 40, 44, 56, 60, 62, 64, 65, 67, 68, 69, 70, 73,
                    74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88,
                    89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 100, 101, 102, 104,
                    105, 106, 108, 109, 110, 111, 112, 113, 115, 118, 120, 122,
                    125, 135, 140, 145, 150, 155, 160, 170, 175, 180, 185, 200,
                    900, 7075, 8085, 8590, 9095, 95100, 100105, 100120,
                  ];

                  if (typeof row[key] != "number") break;
                  sed.guertellaenge = row[key] as number;
                  break;
                case "Outfit: Jacke Sakko":
                  // wahrscheinlich 28/29 zwei werte  - besprechen
                  // range ist größer als vorgegeben - besprechen
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const jacke_sacko = [
                    "tba",
                    "98",
                    "95",
                    "94",
                    "92",
                    "58",
                    "56",
                    "55",
                    "54",
                    "52",
                    "50",
                    "48",
                    "46",
                    "45",
                    "44",
                    "42/44",
                    "42",
                    "40/42",
                    "40",
                    "38/40",
                    "38",
                    "36/38",
                    "36",
                    "34/36",
                    "34",
                    "32",
                    "26",
                    "110",
                    "106",
                    "102",
                  ];

                  if (string_value) {
                    const numba: string = `${string_value.split("/").pop()}`;
                    sed.jacke_sacko = parseInt(numba, 10);
                  }
                  break;
                case "Outfit: Jeans Länge":
                  // 28 noch rein
                  // out of range -besprechen
                  // const jeans_laenge_moegliche_werte = [
                  //   25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
                  // ];
                  if (!row[key] || row[key] == "") break;
                  sed.jeanslaenge = parseInt(`${row[key]}`, 10);
                  break;
                case "Outfit: Jeans Weite":
                  // out of range - besprechen
                  // jeansweite noch ne 25/24 dazu
                  const jeans_weite_moegliche_werte = [
                    25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
                  ];
                  sed.jeansweite = parseInt(string_value, 10);
                  break;
                case "Outfit: Körpergröße":
                  const koerpergr = parseFloat(string_value);
                  if (koerpergr < 3) {
                    sed.koerpergroesse = parseInt(
                      (koerpergr * 100).toFixed(0),
                      10
                    );
                  } else {
                    sed.koerpergroesse = parseInt(string_value, 10);
                  }
                  break;
                case "Outfit: Kragenweite":
                  // out of range - besprechen
                  // ist bei 6 inches gemeint? umrechnen?
                  const kragenweite_moegliche_werte = [
                    0, 6, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44,
                    45, 48, 50, 52, 54,
                  ];
                  if (
                    kragenweite_moegliche_werte.includes(
                      parseInt(string_value, 10)
                    )
                  ) {
                    sed.kragenweite = parseInt(string_value, 10);
                  }
                  break;
                case "Outfit: Rock Hose":
                  // wahrscheinlich 28/29 zwei werte -> überprüfen!! nimmt man immer das groessere?
                  sed.hose_rock = parseInt(
                    `${string_value.split("/").pop()}`,
                    10
                  );
                  break;
                case "Outfit: Schuhgröße":
                  sed.schuhgroesse = parseInt(string_value, 10);
                  break;
                case "Stammdaten: Körpergewicht":
                  sed.gewicht = parseFloat(string_value);
                  break;
                case "Stammdaten: Haarfarbe":
                  // bei haarfarbe gibt es einige mehr als die angegebenen - nachtragen oder reduzieren?
                  if (haarfarben.includes(string_value.toLowerCase())) {
                    sed.haarfarbe = string_value;
                  }
                  break;
                case "Stammdaten: eigener PKW":
                  vorhandene_mobilitaet.PKW = string_value == "ja";
                  break;
                case "Stammdaten: Führerscheinklasse":
                  if (string_value.includes("Keine")) break;
                  sed.fuehrerschein = true;
                  break;
                case "Bahncard vorhanden":
                  vorhandene_mobilitaet.Bahncard = string_value == "ja";
                  break;
                case "Ausbildung: Erfahrung":
                  berufserfahrung.taetigkeit += `${string_value},`;
                  break;
                case "Ausbildung: Erfahrung_Automobilhersteller":
                  berufserfahrung.markenname += `${string_value},`;
                  break;
                case "Ausbildung: Erfahrung_Marken_Hersteller":
                  berufserfahrung.markenname += `${string_value},`;
                  break;
                case "Ausbildung: Erfahrung_Messen_Events_Promo":
                  berufserfahrung.veranstaltung += `${string_value},`;
                  break;
                // sprachen
                case "Sprache: Deutsch":
                  sprachen.deutsch = string_value;
                  break;
                case "Sprache: Englisch":
                  sprachen.englisch = string_value;
                  break;
                case "Sprache: Französisch":
                  sprachen.franzoesisch = string_value;
                  break;
                case "Sprache: Italienisch":
                  sprachen.italienisch = string_value;
                  break;
                case "Sprache: Spanisch":
                  sprachen.spanisch = string_value;
                  break;
                case "Sprache: sonstige":
                  sonstige_sprache.level = string_value;
                  break;
                case "Sprache: sonstige welche":
                  sonstige_sprache.label = string_value;
                  break;
                case "Beurteilung Artikulation":
                  review.review_text += `Artikulation: ${string_value},\n\n`;
                  break;
                case "Beurteilung Engagement":
                  review.review_text += `Engagement: ${string_value},\n\n`;
                  break;
                case "Beurteilung Erscheinungsbild":
                  review.review_text += `Erscheinungsbild: ${string_value},\n\n`;
                  break;
                case "Beurteilung Höflichkeit":
                  review.review_text += `Höflichkeit: ${string_value},\n\n`;
                  break;
                case "Beurteilung Pünktlichkeit":
                  review.review_text += `Pünktlichkeit: ${string_value},\n\n`;
                  break;
                case "Beurteilung Teamgeist":
                  review.review_text += `Teamgeist: ${string_value},\n\n`;
                  break;
                case "Beurteilung Teamleiterpotentiale":
                  review.review_text += `Teamleiterpotentiale: ${string_value},\n\n`;
                  break;
                case "Beurteilung Verläßlichkeit":
                  review.review_text += `Verläßlichkeit: ${string_value},\n\n`;
                  break;
                case "Beurteilung Fließtext":
                  review.review_text += `Beurteilung Fließtext: ${string_value},\n\n`;
                  break;
                case "Bemerkungen":
                  review.review_text += `Bemerkungen: ${string_value},\n\n`;
                  break;
                case "Bemerkungen SetCards":
                  review.review_text += `Bemerkungen SedCards: ${string_value},\n\n`;
                  break;
                case "Stammdaten: Erfassungsdatum":
                  let date_string = "";
                  try {
                    date_string =
                      this.convertDateExcel(
                        parseInt(string_value, 10)
                      )?.toLocaleDateString("de-DE") || "";
                    // eslint-disable-next-line no-empty
                  } catch (error) {}

                  review.review_text += `Stammdaten Erfassungsdatum: ${date_string},\n\n`;
                  break;
                case "Status Zusammenarbeit":
                  // besprechen, was hier passiert.
                  review.review_text += `Status Zusammenarbeit: ${string_value},\n\n`;
                  break;
                default:
                  break;
              }
            }

            if (
              berufserfahrung.markenname ||
              berufserfahrung.taetigkeit ||
              berufserfahrung.veranstaltung
            ) {
              sed.berufserfahrung = [berufserfahrung];
            }

            if (review.review_text) {
              user.reviews = [review];
            }

            if (sonstige_sprache.label) {
              sprachen.weitere.push(sonstige_sprache);
            }

            // console.log(`user`, user);
            // console.log(`sed`, sed);
            // console.log(`profile`, profile);
            if (user.email) {
              users.push(user as User);
            }

            // eslint-disable-next-line no-plusplus
            i++;
            // console.log(`i`, i);

            // if (i > 10) break;
          }

          // parse data end
          console.log(`users`, users);
          const users_segmented = this.segmentArray(users.reverse(), 1);
          console.log(`users_segmented`, users_segmented);
          // console.log(`users_segmented`, users_segmented);
          // const random_index = Math.floor(
          //   Math.random() * users_segmented.length
          // );
          // users_segmented.splice(random_index);
          // users_segmented.splice(0, users_segmented.length - 2);

          for (let j = 0; j < users_segmented.length; j++) {
            // console.log("check");
            const uss = users_segmented[j];
            this.percent = Math.round((j / users_segmented.length) * 100);
            // eslint-disable-next-line no-await-in-loop
            const result = await this.data.importUsers(uss);
            console.log(`result nr.${j}`, result);
          }

          // const result = await Promise.allSettled(
          //   users_segmented.map((uss) => this.data.importUsers(uss))
          // );
          // console.log(`result`, result);

          res(1);
        };
        fileReader.readAsArrayBuffer(this.file!);
      });
      this.isUploading = false;
    } catch (error) {
      this.isUploading = false;
      console.error(`error`, error);
    }
  }

  public segmentArray(array: any[], size: number) {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }
}
