<div class="content-header-mobile flex-center">
  <!-- backbutton  -->
  <div
    class="left flex-left"
    *ngIf="backBtn"
    [ngClass]="{ absolute: !name.hasChildNodes() }"
    (click)="backBtnClicked.emit(true)"
  >
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 1L1 7L7 13"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
  <div class="right">
    <div #name>
      <ng-content></ng-content>
    </div>
    <div class="flex-center">
      <img
        *ngIf="!name.hasChildNodes()"
        src="assets/svgs/mobile-top-logo.svg"
        alt=""
      />
    </div>
  </div>
</div>
