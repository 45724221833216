<div class="job-element">
  <img
    class="project-full-badge"
    src="assets/img/vollstaendig-badge.svg"
    *ngIf="showProjectFullBadge"
  />
  <h4 *ngIf="nextJob || currentJob" class="secondary">
    {{ nextJob ? "Nächster Job" : "Laufender Job" }}
  </h4>
  <h2>{{job?.['titel']}}</h2>
  <div class="status" *ngIf="application?.status">
    <div
      class="badge flex-center"
      [ngStyle]="{
        backgroundColor:
          ApplicationStatusStringArbeitskraft[application!.status!].iconBg
      }"
    >
      <app-svgs
        [type]="ApplicationStatusStringArbeitskraft[application!.status!].icon"
        color="white"
      ></app-svgs>
    </div>
    {{ ApplicationStatusStringArbeitskraft[application!.status!].label }}
  </div>
  <div class="p-wrap">
    <app-svgs type="calendar16x16" color="primary40"></app-svgs>
    <p class="p-light">
      {{ job?.zeitraum_von | formatTwoDates : job?.zeitraum_bis }}
    </p>
  </div>
  <div class="p-wrap">
    <app-svgs type="location16x16" color="primary40"></app-svgs>
    <p class="p-light" [innerHtml]="job?.aktions_standorte"></p>
  </div>

  <div class="p-wrap">
    <app-svgs type="eur16x16" color="primary40"></app-svgs>
    <p class="p-light">
      {{
        job?.honorar?.honorar_auf_anfrage
          ? "auf Anfrage"
          : job?.honorar?.honorar_eur + " € / Stunde"
      }}
    </p>
  </div>
  <app-button
    [iconRight]="'chevron-right'"
    [lessHeight]="true"
    [color]="auth.currentUser ? 'secondary-gradient' : 'primary80'"
    [background]="buttonBg"
    *ngIf="!application"
    (click)="detailButtonClicked.emit(job)"
    >MEHR INFOS</app-button
  >
  <app-button
    *ngIf="application"
    [background]="buttonBg"
    color="secondary"
    [routerLink]="'/meine-jobs/detail/' + application.id"
  >
    Details
  </app-button>
  <app-button
    *ngIf="application && application.status == 'warten_auf_daten'"
    [background]="buttonBg"
    color="secondary-gradient"
    [routerLink]="['/jobs/vervollstaendigen/', application.project_id]"
  >
    Daten nachtragen
  </app-button>
  <app-button
    *ngIf="application && zeiterfassungsButton"
    color="secondary-gradient"
    [background]="buttonBg"
    [routerLink]="['/meine-jobs/detail/', application.id, 'zeiterfassung']"
  >
    Zeiterfassung
  </app-button>
</div>
