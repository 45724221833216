<div class="pagination">
  <div>
    <div class="chevrons">
      <app-svgs
        class="rotate"
        type="chevron-right"
        color="secondary"
        *ngIf="pagination.page > 1"
        (click)="incrementPage(false)"
      ></app-svgs>
      <app-svgs
        type="chevron-right"
        color="secondary"
        *ngIf="pagination.page < pagination.pagesTotal"
        (click)="incrementPage(true)"
      ></app-svgs>
    </div>
    <span> Seite </span>
    <select (change)="pageChanged.emit()" [(ngModel)]="pagination.page">
      <option *ngFor="let i of pagesTotalNumeration" [value]="i + 1">
        {{ i + 1 }}
      </option>
    </select>
  </div>
  <div>
    <span> Einträge pro Seite: </span>
    <select (change)="pageChanged.emit()" [(ngModel)]="pagination.perPage">
      <option *ngFor="let i of perPageOptions" [value]="i">
        {{ i }}
      </option>
    </select>
  </div>
</div>
