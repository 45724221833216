<div class="daten">
  <div class="main">
    <app-header-w-backbtn-logo></app-header-w-backbtn-logo>

    <div class="card">
      <h4 class="secondary margin-bottom-10">SEDCARD</h4>
      <div class="margin-bottom-20">
        <app-photo-gallery [media]="sedCard?.fotos"> </app-photo-gallery>
      </div>
      <h2 class="margin-bottom-20">
        {{ profile?.vorname }} {{ profile?.nachname }}
      </h2>
      <table class="primary margin-bottom-20">
        <tr>
          <td><h5>Haarfarbe</h5></td>
          <td>
            <p>{{ sedCard?.haarfarbe }}</p>
          </td>
        </tr>
        <tr>
          <td><h5>Größe</h5></td>
          <td>
            <p>{{ sedCard?.koerpergroesse }} cm</p>
          </td>
        </tr>
        <tr>
          <td><h5>Gewicht</h5></td>
          <td>
            <p>{{ sedCard?.gewicht }} kg</p>
          </td>
        </tr>
      </table>
      <app-button
        color="secondary"
        background="primary10"
        (click)="router.navigateByUrl('daten/sed')"
        >ZUR SEDCARD</app-button
      >
    </div>

    <div class="card">
      <h4 class="secondary margin-bottom-10">PERSÖNLICHE DATEN</h4>
      <h5 class="margin-bottom-6">ADRESSE</h5>
      <p class="margin-bottom-20">
        <span *ngIf="profile?.strasse"
          >{{ profile?.strasse }} {{ profile?.hausnr }}<br
        /></span>
        <span *ngIf="profile?.addresszusatz"
          >{{ profile?.addresszusatz }} <br
        /></span>
        <span *ngIf="profile?.plz || profile?.ort"
          >{{ profile?.plz }} {{ profile?.ort }}<br
        /></span>
      </p>
      <h5 class="margin-bottom-6">KONTO</h5>
      <p class="margin-bottom-20">
        <span *ngIf="profile?.iban">{{ profile?.iban }} <br /></span>
        <span *ngIf="profile?.bic">{{ profile?.bic }} <br /></span>
      </p>
      <app-button
        color="secondary"
        background="primary10"
        (click)="router.navigateByUrl('daten/persoenlich')"
        >ALLE DATEN</app-button
      >
    </div>
    <div class="show-logout-btn">
      <app-button color="secondary" (click)="auth.doLogout()" hoverColor="white"
        >Ausloggen</app-button
      >
    </div>
  </div>

  <div class="detail">
    <div class="logo-container">
      <img src="assets/svgs/logo_big_claim.svg" alt="Logo Claim" class="logo" />
    </div>
  </div>
</div>
