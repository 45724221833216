<div class="time-block-container" [ngClass]="extraClasses">
  <h5 *ngIf="title && blockType === 'add'">{{ title }}</h5>
  <div class="time-block-data-columns">
    <div class="time-block-left-column">
      <h5 *ngIf="!title && blockType === 'single'">{{ formattedDate }}</h5>
      <h5 *ngIf="title && blockType === 'single'">{{ title }}</h5>
      <div *ngIf="blockType === 'add'" class="text-with-icon icon-left">
        <app-svgs type="clock" color="primary40"></app-svgs>
        <p>{{ formattedOverallTime }}</p>
      </div>
      <div *ngIf="blockType === 'single'" class="text-with-icon icon-left">
        <app-svgs type="clock" color="primary40"></app-svgs>
        <p>{{ formattedTimeInterval }}</p>
      </div>
      <div
        *ngIf="blockType === 'single' && !hideBreak"
        class="text-with-icon icon-left"
      >
        <app-svgs type="yoga" color="primary40"></app-svgs>
        <p>{{ formattedBreak }}</p>
      </div>
    </div>
    <div class="time-block-right-column">
      <div *ngIf="blockType === 'single'" class="text-with-icon icon-right">
        <p [ngClass]="selectable ? 'color-secondary' : 'color-primary'">
          {{ formattedDuration }}
        </p>
        <app-svgs
          type="chevron-right"
          [color]="selectable ? 'secondary' : 'primary'"
        ></app-svgs>
      </div>
      <div *ngIf="moneyValue" class="text-with-icon icon-left">
        <app-svgs type="coin" color="white"></app-svgs>
        <p>{{ moneyValue }}</p>
      </div>
    </div>
  </div>
  <p *ngIf="subText" class="sub-text">{{ subText }}</p>
  <app-button
    *ngIf="buttonText"
    (click)="buttonClicked($event)"
    color="secondary-gradient"
    [iconRight]="buttonIcon"
    background="primary"
    >{{ buttonText }}</app-button
  >
</div>
