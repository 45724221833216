import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  ApplicationStatus,
  ApplicationStatusStringArbeitskraft,
  Projekt,
} from "@ttc_types/types";
import { DataService } from "../../../data.service";
import { AuthService } from "../../../shared/auth.service";

@Component({
  selector: "app-mehr-infos",
  templateUrl: "./mehr-infos.component.html",
  styleUrls: ["./mehr-infos.component.scss"],
})
export class MehrInfosComponent implements OnInit {
  constructor(public auth: AuthService, public data: DataService) {}

  ApplicationStatusStringArbeitskraft = ApplicationStatusStringArbeitskraft;

  ngOnInit(): void {}

  @Input()
  project: Projekt;

  @Input()
  closeBtn: boolean = true;

  @Input()
  whiteBg: boolean = false;

  @Input()
  showTitleAndAnforderung: boolean = true;

  @Input()
  status?: ApplicationStatus;

  @Input()
  showBewerbenButton: boolean = true;

  @Input()
  paddingTop: string = "36px";

  @Input()
  showTitle: boolean = true;

  @Output()
  click = new EventEmitter<boolean>();

  @Output()
  close = new EventEmitter<boolean>();
}
