import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("modalInner") scrollTarget?: ElementRef;

  @Input()
  showNormal: boolean = false;

  @Input()
  maxWidth1200: boolean = false;

  @Input()
  customMaxWidth: string | null = null;

  @Input()
  customWidth: string | null = null;

  @Output()
  close = new EventEmitter<boolean>();

  innerModalExtraStyles: string = "";

  constructor() {}

  ngOnInit(): void {
    if (this.customWidth) {
      this.innerModalExtraStyles += `width: ${this.customWidth};`;
    }

    if (this.customMaxWidth) {
      this.innerModalExtraStyles += `max-width: ${this.customMaxWidth}`;
    }

    this.scrollTarget?.nativeElement.openModal();

    // listen for escape key
    document.addEventListener("keydown", (e) => this.escapeListener(e));
  }

  ngAfterViewInit() {
    disableBodyScroll(this.scrollTarget?.nativeElement);
  }

  ngOnDestroy(): void {
    enableBodyScroll(this.scrollTarget?.nativeElement);
    clearAllBodyScrollLocks();

    // remove escape key listener
    document.removeEventListener("keydown", (e) => this.escapeListener(e));
  }

  escapeListener(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.close.emit(true);
    }
  }
}
