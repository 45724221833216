/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Media } from "@ttc_types/types";
import { Notify } from "notiflix";
import { AuthService } from "src/app/shared/auth.service";
import { environment } from "src/environments/environment.prod";
import { DataService } from "../../data.service";

@Component({
  selector: "app-fileupload",
  templateUrl: "./fileupload.component.html",
  styleUrls: ["./fileupload.component.scss"],
})
export class FileuploadComponent implements OnInit {
  fileUploadId: string = (Math.random() * 1000).toFixed(0);

  resourceUrl: string = `${environment.resourceUrl}/`;

  constructor(
    private data: DataService,
    private auth: AuthService,
    private sanitizer: DomSanitizer
  ) {}

  @Input()
  uploadFiles: Media[] = [];

  @Input()
  @HostBinding("class.onlyPreview")
  onlyPreview: boolean = false;

  @Input()
  listView: boolean = false;

  @Input()
  allowPdf: boolean = false;

  @Input()
  hideAddButtonAfterLimit: boolean = false;

  acceptTypes: string = "image/*";

  @Input()
  maxFiles?: number = undefined;

  @Output()
  uploadFilesChange = new EventEmitter<Media[]>();

  @Output()
  isUploading = new EventEmitter<boolean>(false);

  public showDeleted: boolean = false;

  get uploadFilesFiltered() {
    if (!this.uploadFiles) {
      return [];
    }

    return this.uploadFiles.filter((file) => !file.deleted || this.showDeleted);
  }

  ngOnInit(): void {
    if (this.allowPdf) {
      this.acceptTypes += ",application/pdf";
    }
    this.showDeleted = ["pm", "superadmin"].includes(
      this.auth.currentUser?.role || ""
    );
  }

  addMediaClicked() {
    if (this.maxFiles) {
      const remaining =
        this.maxFiles -
        (this.uploadFiles || []).filter((el) => !el.deleted).length;
      if (remaining < 1) {
        Notify.info(
          `Es können leider keine weiteren Dateien hochgeladen werden.`
        );
        return;
      }
    }
    document?.getElementById(`hidden_file_input${this.fileUploadId}`)?.click();
  }

  deleteImage(uploadFilesIndex: number) {
    if (this.uploadFiles[uploadFilesIndex].isUploading) return;
    this.uploadFiles[uploadFilesIndex].deleted = true;
    this.sortUploadFilesArray();
  }

  sortUploadFilesArray() {
    this.uploadFiles.sort((a, b) => {
      if (a.deleted) return 1;
      if (b.deleted) return -1;
      return 0;
    });
  }

  async onFileChange($event: Event) {
    const target = $event.target as HTMLInputElement;
    const { files } = target;
    const filesLength = files?.length || 0;
    if (!files || filesLength === 0) {
      return;
    }

    const remaining =
      (this.maxFiles || 999) -
      (this.uploadFiles || []).filter((el) => !el.deleted).length;

    if (remaining < 1) {
      Notify.info(
        `Es können leider keine weiteren Dateien hochgeladen werden. Bitte lösche erst welche.`
      );
      return;
    }

    if (filesLength > remaining) {
      Notify.info(`Es werden nur ${remaining} Dateien hochgeladen.`);
    }

    const thumbnails = await this.getThumbnails(
      files,
      Math.min(filesLength, remaining)
    );

    for (let i = 0; i < Math.min(filesLength, remaining); i++) {
      const file = files[i];
      // check for mime type
      if (
        !file.type.includes("image/") &&
        !(this.allowPdf && file.type.includes("/pdf"))
      ) {
        const info = this.allowPdf ? "Bilder und PDFs" : "Bilder";
        Notify.info(`Es sind nur ${info} erlaubt.`);
        continue;
      }

      this.uploadFiles = this.uploadFiles || [];
      this.uploadFiles.push({
        filename: file.name,
        thumbnail: thumbnails[i],
        mimeType: file.type,
        fileBaseBlob: file.type.includes("image/")
          ? await this.generateThumbnail(file)
          : file,

        uploaded: false,
        isUploading: false,
      });
      this.sortUploadFilesArray();
    }

    this.isUploading.emit(true);

    const uploadPromises: Promise<
      | {
          ufId: number;
          result: any;
        }
      | {
          ufId: number;
          result: {
            errors: Error[];
          };
        }
    >[] = [];

    const keys = Object.keys(this.uploadFiles);
    for (let i = 0; i < keys.length; i++) {
      const file = this.uploadFiles[i];
      if (file.uploaded || file.isUploading || file.id) {
        continue;
      }

      file.isUploading = true;

      const promise = this.data
        .uploadFile(file)
        .then((result) => ({ ufId: i, result }))
        .catch((e) => ({
          ufId: i,
          result: { errors: [e] },
        }));

      uploadPromises.push(promise);
    }

    Promise.allSettled(uploadPromises).then((results) => {
      const idsToSplice: number[] = []; // ids of faulty uploads

      for (const result of results) {
        if (result.status !== "fulfilled") {
          continue;
        }
        const { value } = result;

        if (value?.result?.errors.length) {
          Notify.failure("Einer oder mehrere Uploads waren fehlerhaft.");
          idsToSplice.push(value.ufId);
          console.error(value.result.errors);
          continue;
        }

        const updatedFile = this.uploadFiles[value.ufId];

        updatedFile.nameOnDisk = value.result.fileName;
        updatedFile.uploaded = true;
        updatedFile.url = this.resourceUrl + updatedFile.nameOnDisk;
        updatedFile.isUploading = false;

        delete updatedFile.thumbnail;
        delete updatedFile.fileBaseBlob;
      }

      // remove all files that were not uploaded
      idsToSplice
        .reverse()
        .forEach((splice) => this.uploadFiles.splice(splice, 1));

      target.value = ""; // reset input
      this.isUploading.emit(false);
      this.uploadFilesChange.emit(this.uploadFiles);
    });
  }

  openNewTab(image: Media) {
    if (image.nameOnDisk) {
      window.open(this.resourceUrl + image.nameOnDisk, "_blank");
    }
  }

  async getThumbnails(files: FileList, onlyTrailing?: number) {
    if (!files) {
      return [];
    }

    const thumbnails: string[] = [];
    for (let i = 0; i < (onlyTrailing || files.length); i++) {
      const file = files[i];
      if (!file.type.includes("image/")) {
        continue;
      }

      const dataUrl = URL.createObjectURL(file);
      thumbnails[i] = dataUrl;
    }

    return thumbnails;
  }

  async generateThumbnail(inputEl: File, maxHeightWidth = 2048) {
    try {
      console.time("generateThumbnail");
      const bitmap = await createImageBitmap(inputEl, {
        resizeWidth: maxHeightWidth,
        resizeQuality: "pixelated",
      });

      // @ts-ignore - OffscreenCanvas is not in the current lib
      const canvas = new OffscreenCanvas(bitmap.width, bitmap.height);
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        throw new Error("Could not create canvas context");
      }

      ctx.drawImage(bitmap, 0, 0, canvas.width, canvas.height);

      const data = await canvas.convertToBlob({
        type: "image/jpeg",
        quality: 0.8,
      });

      console.timeEnd("generateThumbnail");

      return data;
    } catch (e) {
      console.error(e);

      // in case of error, return the original file
      return new Blob([inputEl], { type: inputEl.type });
    }
  }

  dataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  get showUploadButton() {
    if (this.onlyPreview) {
      return false;
    }

    const limitReached =
      this.maxFiles && this.uploadFilesFiltered.length >= this.maxFiles;

    if (this.hideAddButtonAfterLimit && limitReached) {
      return false;
    }

    return true;
  }

  public getUrl(file: Media) {
    if (!file.isUploading) {
      return this.resourceUrl + file.nameOnDisk;
    }

    let source = file.thumbnail;

    if (!source) {
      // fallback to blob url or 1x1px gif
      source =
        file.fileBaseBlob instanceof Blob
          ? URL.createObjectURL(file.fileBaseBlob)
          : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
    }

    return this.sanitizer.bypassSecurityTrustUrl(source);
  }
}
