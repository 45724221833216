<div class="two-cols">
  <div class="left">
    <table>
      <tr *ngIf="profile?.plz || profile?.ort">
        <td>PlZ, Ort</td>
        <td>
          <strong>{{ profile.plz }} {{ profile.ort }}</strong>
        </td>
      </tr>
      <tr>
        <h4 class="flex flex-center-vertical gap-7">
          Status
          <span
            class="badge success"
            *ngIf="showStatus"
            [ngClass]="{ rejected: hasValidStatusErrors.length }"
            [title]="hasValidStatusErrors.join('\n')"
            ><app-svgs
              [type]="!hasValidStatusErrors.length ? 'checkmark' : 'close'"
              color="white"
            ></app-svgs
          ></span>
        </h4>
      </tr>
      <tr *ngIf="beschaeftigung.length">
        <td>Beschäftigung</td>
        <td>
          <div *ngFor="let besch of beschaeftigung">
            <strong *ngIf="besch.name">{{ besch.name }} </strong>
            <span *ngIf="besch.fach">{{ besch.fach }}</span>
            <span *ngIf="besch.gueltig_bis"
              ><br />eingeschrieben bis: <b>{{ besch.gueltig_bis }}</b></span
            >
          </div>
        </td>
      </tr>
      <tr *ngIf="profile">
        <td>Hauptarbeitgeber</td>
        <td>
          <strong>{{
            profile.existiert_hauptarbeitgeber ? "Ja" : "Nein"
          }}</strong>
        </td>
      </tr>
      <tr>
        <h4>Kontakt</h4>
      </tr>
      <tr *ngIf="user?.email">
        <td>E-Mail</td>
        <td>
          <a href="mailto:{{ user.email }}"
            ><strong>{{ user.email }}</strong></a
          >
        </td>
      </tr>
      <tr *ngIf="profile?.tel">
        <td>Telefon</td>
        <td>
          <a href="tel:{{ profile.tel }}"
            ><strong>{{ profile.tel }}</strong></a
          >
        </td>
      </tr>
      <tr *ngIf="sed && sed.berufserfahrung && sed.berufserfahrung.length">
        <h4>Erfahrung</h4>
      </tr>
      <tr *ngFor="let be of sed?.berufserfahrung || []">
        <td>{{ be.veranstaltung }}</td>
        <td>{{ be.taetigkeit }}, {{ be.markenname }}</td>
      </tr>
    </table>
  </div>
  <div class="right" *ngIf="sed">
    <h4 class="no-margin-top">Sedcard</h4>
    <table>
      <tr *ngIf="sed.haarfarbe">
        <td>Haarfarbe</td>
        <td>
          <strong>{{ sed.haarfarbe }}</strong>
        </td>
      </tr>
      <tr *ngIf="sed.koerpergroesse">
        <td>Körpergröße</td>
        <td>
          <strong>{{ sed.koerpergroesse }} cm</strong>
        </td>
      </tr>
      <tr *ngIf="sed.shirtgroesse">
        <td>Shirtgröße</td>
        <td>
          <strong>{{ shirtArr[sed.shirtgroesse] }}</strong>
        </td>
      </tr>
    </table>
    <div *ngIf="sed?.fotos && sed.fotos?.length">
      <h4>Fotos</h4>
      <app-photo-gallery [media]="sed.fotos"> </app-photo-gallery>
      <!-- <app-fileupload
        *ngIf="sed.fotos"
        [uploadFiles]="sed.fotos"
        [onlyPreview]="true"
      >
      </app-fileupload> -->
    </div>
  </div>
</div>
