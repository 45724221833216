<!-- projekt settings start -->
<app-projekteinstellungen-modal
  *ngIf="projektEinstellungsModalOpen"
  (close)="projektEinstellungsModalOpen = false"
></app-projekteinstellungen-modal>
<!-- projekt settings end -->

<div class="main">
  <div class="content">
    <div class="main-head">
      <div class="left">
        <div class="top with-back-btn">
          <app-svgs
            type="chevron-left"
            color="white"
            routerLink="/meine-projekte"
          ></app-svgs>
          <div *ngIf="!editProjectTitle" class="flex-center-title">
            <h2 class="white">{{ data.currentProjectTitle }}</h2>
            <h3 class="white" *ngIf="data.currentProjectInternalTitle">
              Interne Bezeichnung:
              {{ data.currentProjectInternalTitle }}
            </h3>
            <p class="white">
              <span class="p-light">Ansprechperson</span>
              <span *ngIf="ansprechpersonVorname || ansprechpersonNachname">
                {{ ansprechpersonVorname }}
                {{ ansprechpersonNachname }}
              </span>
              <span *ngIf="!ansprechpersonVorname && !ansprechpersonNachname">
                noch keine
              </span>
            </p>
          </div>
          <div *ngIf="editProjectTitle" class="width-100">
            <input
              type="text"
              name="currentProjectTitle"
              class="width-100"
              id="currentProjectTitle"
              [(ngModel)]="data.currentProjectTitle"
              placeholder="Externer Projektname"
            />
            <input
              type="text"
              name="currentProjectInternalTitle"
              class="width-100"
              id="currentProjectInternalTitle"
              [(ngModel)]="data.currentProjectInternalTitle"
              placeholder="Interner Projektname"
            />
            <div
              *ngIf="
                data.currentProjectTitleSubmitAttempt &&
                (!data.currentProjectTitle || !data.currentProjectTitle.length)
              "
              class="alert"
            >
              Bitte gib einen Titel an.
            </div>
            <p class="white margin-top-8 flex-center-title">
              <span class="p-light">Ansprechperson</span>
              <span *ngIf="ansprechpersonVorname || ansprechpersonNachname">
                {{ ansprechpersonVorname }}
                {{ ansprechpersonNachname }}
              </span>
              <span *ngIf="!ansprechpersonVorname && !ansprechpersonNachname">
                noch keine
              </span>
            </p>
          </div>
        </div>
        <div class="bottom">
          <app-toggle
            [toggles]="toggles"
            (indexSelectedChange)="toggleSwitched($event)"
            color="darkblue-blue"
          ></app-toggle>
        </div>
      </div>
      <div class="right">
        <div class="top">
          <div
            class="already-sent-link"
            *ngIf="!editProjectTitle"
            [ngClass]="{ 'with-link': baseUrl }"
          >
            <p
              class="subscription"
              *ngIf="gesendetString"
              [innerHTML]="gesendetString"
            ></p>
            <div [class]="baseUrl ? 'settings-btn' : ''">
              <app-svgs
                (click)="openLinkInNewTab(baseUrl)"
                *ngIf="baseUrl"
                [type]="baseUrl ? 'link' : 'remove-link'"
                color="white"
              ></app-svgs>
            </div>
          </div>
          <div
            class="settings-btn svg-fix"
            *ngIf="data.currentProject"
            (click)="projektEinstellungsModalOpen = true"
          >
            <app-svgs type="settings" color="white"></app-svgs>
          </div>
          <div class="dropdown-wrap" *ngIf="activeToggleIndex == 2">
            <div
              class="settings-btn"
              (click)="aktionenDropdown = !aktionenDropdown"
            >
              <app-svgs type="chevron-down" color="white"></app-svgs>
            </div>
            <div class="dropdown personal-dropdown" *ngIf="aktionenDropdown">
              <div
                (click)="
                  data.eventBus.next('open_auswahl_kontaktieren_personal');
                  aktionenDropdown = !aktionenDropdown
                "
              >
                Auswahl kontaktieren
              </div>
              <div
                (click)="
                  duplicateProject(); aktionenDropdown = !aktionenDropdown
                "
              >
                Projekt duplizieren
              </div>
              <div
                (click)="
                  data.eventBus.next('move_auswahl_personal_to_other_project');
                  aktionenDropdown = !aktionenDropdown
                "
              >
                Auswahl in anderes Projekt kopieren
              </div>
              <div
                (click)="
                  data.eventBus.next('open_master_data_export_configurator');
                  aktionenDropdown = !aktionenDropdown
                "
              >
                Stammdaten exportieren
              </div>
            </div>
          </div>
        </div>
        <div class="bottom" *ngIf="activeToggleIndex === 0">
          <app-button
            format="normal"
            color="white"
            [fullWidthOnMobile]="true"
            (click)="
              !data.currentProjectPublished
                ? emitEvent('publishClicked')
                : emitEvent('hideClicked')
            "
          >
            {{
              !data.currentProjectPublished ? "Veröffentlichen" : "Verstecken"
            }}
          </app-button>
          <app-button
            format="normal"
            [fullWidthOnMobile]="true"
            (click)="emitEvent('saveClicked')"
            >Speichern</app-button
          >
        </div>
      </div>
    </div>
    <router-outlet *ngIf="finishedLoading"></router-outlet>
  </div>
</div>
