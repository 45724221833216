<div class="projektdaten">
  <app-vertrag
    *ngIf="this.showVertrag"
    [user]="user"
    (gegengezeichnet)="this.gegengezeichnet.emit()"
    (refreshList)="refreshList.emit()"
    (kuendigen)="kuendigen.emit()"
  ></app-vertrag>

  <div *ngIf="user?.applications?.[0]?.job_role">
    <h4>Rolle</h4>
    <p>
      <b>{{ user?.applications?.[0]?.job_role }}</b>
    </p>
  </div>

  <app-zulagen
    [user]="user"
    *ngIf="user?.applications?.[0]?.status == 'erledigt_verbindlich' || user?.applications?.[0]?.status == 'gekuendigt'"
  ></app-zulagen>
</div>
