import { ArbeitsbeginnStatusProfileView, Media } from "@ttc_types/types";
import { environment } from "src/environments/environment.prod";
import { DatePipe } from "@angular/common";
import { ab_nv } from "./pages/apply/sedPersForm";

export const arbeitsBeginnStatusToArbeitsbeginnStatusProfileView = (
  arbeitsBeginnStatus?: {
    [key: string]: any;
  },
  arbeitsbeginn_status_additional?: { [key: string]: any }
): ArbeitsbeginnStatusProfileView[] => {
  const result: ArbeitsbeginnStatusProfileView[] = [];
  if (!arbeitsBeginnStatus) return result;
  for (const key of Object.keys(arbeitsBeginnStatus)) {
    if (arbeitsBeginnStatus[key] === true) {
      const tmp: ArbeitsbeginnStatusProfileView = {
        name: "",
        fach: "",
        nachweise: [],
        gueltig_bis: null,
      };
      tmp.name = ab_nv[key].label;
      if (arbeitsbeginn_status_additional) {
        if (arbeitsbeginn_status_additional[`${key}_fach`]) {
          tmp.fach = arbeitsbeginn_status_additional[`${key}_fach`];
        }
        if (arbeitsbeginn_status_additional[`${key}_nachweis`]) {
          tmp.nachweise = arbeitsbeginn_status_additional[
            `${key}_nachweis`
          ].map((el: Media) => {
            // eslint-disable-next-line dot-notation, no-param-reassign
            el["url"] = `${environment.resourceUrl}/${el.nameOnDisk}`;
            return el;
          });
        }
        if (
          arbeitsbeginn_status_additional[`${key}_gueltig_bis`] &&
          arbeitsbeginn_status_additional[`${key}_gueltig_bis`] != ""
        ) {
          const datePipe = new DatePipe("de-DE");
          try {
            tmp.gueltig_bis = datePipe.transform(
              arbeitsbeginn_status_additional[`${key}_gueltig_bis`],
              "dd.MM.yyyy"
            );
          } catch (error) {
            console.info(error);
            tmp.gueltig_bis =
              arbeitsbeginn_status_additional[`${key}_gueltig_bis`];
          }
        }
      }
      result.push(tmp);
    }
  }
  return result;
};
