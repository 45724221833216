import { Notify, Confirm, IConfirmOptions } from "notiflix";
import { Projekt, Review, User } from "@ttc_types/types";
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { DataService } from "src/app/data.service";

@Component({
  selector: "app-interne-infos",
  templateUrl: "./interne-infos.component.html",
  styleUrls: ["./interne-infos.component.scss"],
})
export class InterneInfosComponent implements OnInit {
  @Input() public user!: User;

  @Input() public reviews: Review[] | undefined;

  public projects: Projekt[] = [];

  public review_text: string = "";

  public review_date: Date | null = null;

  public project_id: number | null = null;

  public isUploading: boolean = false;

  review_id: number | null = null;

  // @Output()
  // nextAfterDelete = new EventEmitter();

  @Output()
  deleteUser = new EventEmitter();

  constructor(private data: DataService) {}

  async uploadReview() {
    if (this.review_text == "") {
      Notify.warning("Bitte gib erst einen Text ein");
      return;
    }
    try {
      this.isUploading = true;
      await this.data.saveReview({
        id: this.review_id ?? null,
        topteamer_id: this.user.id!,
        project_id: this.project_id,
        review_text: this.review_text,
      });
      this.review_text = "";
      this.project_id = null;
      await this.refreshReviews();
      this.isUploading = false;
    } catch (error) {
      this.isUploading = false;
      Notify.failure("Etwas ist schief gelaufen.");
      console.error("error", error);
    }
  }

  /**
   * editReview sets the given review as the current review to edit
   * @param review
   */
  async editReview(review: Review) {
    console.log("review: ", review);
    this.review_id = review.id ?? null;
    this.review_text = review.review_text ?? "";
    this.project_id = review.project?.id ?? null;
    this.review_date = review.review_date ?? null;
  }

  /**
   * deleteReview prompts the user with a popup to confirm the deletion of the review,
   * on confirmation it will delete the review from the database and refresh the reviews
   * @param review
   */
  async deleteReview(review: Review) {
    Confirm.show(
      "Bist du sicher?",
      "Bist du sicher, dass du die Erfahrung löschen möchten?",
      "Nein",
      "Ja",
      () => {
        // nein
      },
      async () => {
        try {
          this.isUploading = true;
          await this.data.deleteReview(review.id!);
          await this.refreshReviews();
          this.isUploading = false;
          Notify.success("Erfahrung wurde erfolgreich gelöscht.");
        } catch (error) {
          this.isUploading = false;
          Notify.failure("Etwas ist schief gelaufen.");
          console.error("error", error);
        }
      }
    );
  }

  // async delete_profile() {
  // if (this.user.id) {
  //   Confirm.show(
  //     "Bist du sicher?",
  //     "Bist du sicher, dass du das Profil löschen möchten?",
  //     "Nein",
  //     "Ja",
  //     () => {
  //       // nein
  //     },
  //     async () => {
  //       try {
  //         this.isUploading = true;
  //         await this.data.deleteUser(this.user.id!);
  //         this.user.deleted = true;
  //         this.isUploading = false;
  //         this.nextAfterDelete.emit();
  //         Notify.success("Profil wurde erfolgreich gelöscht.");
  //       } catch (error) {
  //         console.error(`deleteUser error`, error);
  //         Notify.failure(
  //           "Etwas ist schief gelaufen. Das Profil konnte nicht gelöscht werden."
  //         );
  //       }
  //       this.isUploading = false;
  //     }
  //   );
  // }
  // }

  async deaktivieren() {
    this.isUploading = true;
    const errors: string[] = [];
    try {
      errors.push(
        ...(await this.data.checkIfUserHasFutureBookings(this.user.id!)).errors
      );
    } catch (error) {
      Notify.failure("Etwas ist schief gelaufen.");
      this.isUploading = false;
      return;
    }
    let errors_string = "";
    if (errors.length > 0) {
      errors_string =
        "<br><br><span style='color:red; font-size:bold'>ACHTUNG:</span> Bitte beachte folgende Fehler:<br><br>";
      errors_string += errors.join("<br><br>");
      errors_string +=
        "<br><br> <b>Bitte kläre erst diese Fehler bevor du das Profil deaktivierst.</b>";
    }
    Confirm.show(
      "Profil deaktivieren?",
      `Ein deaktiviertes Profil wird nicht mehr für neue Jobs berücksichtigt. Vergangene Verträge und Unterlagen bleiben aber erhalten.${errors_string}`,
      "ABBRECHEN",
      "DEAKTIVIEREN",
      () => {
        // nein
        this.isUploading = false;
      },
      async () => {
        try {
          this.isUploading = true;
          if (this.user.id) {
            await this.data.setUserActivated(this.user.id, false);
          }
          this.user.activated = false;
          this.isUploading = false;
          Notify.success("Profil wurde erfolgreich deaktiviert.");
        } catch (error) {
          this.isUploading = false;
          Notify.failure("Etwas ist schief gelaufen.");
          console.error("error", error);
        }
      },
      {
        messageMaxLength: 1000000,
        plainText: false,
      } as IConfirmOptions
    );
  }

  async aktivieren() {
    Confirm.show(
      "Profil aktivieren?",
      "Das Profil wird wieder aktiviert und kann für zukünftige Jobs gebucht werden.",
      "ABBRECHEN",
      "AKTIVIEREN",
      () => {
        // nein
      },
      async () => {
        try {
          this.isUploading = true;
          if (this.user.id) {
            await this.data.setUserActivated(this.user.id, true);
          }
          this.user.activated = true;
          this.isUploading = false;
          Notify.success("Profil wurde erfolgreich aktiviert.");
        } catch (error) {
          this.isUploading = false;
          Notify.failure("Etwas ist schief gelaufen.");
          console.error("error", error);
        }
      }
    );
  }

  async ngOnInit() {
    if (this.user.id) {
      this.refreshReviews();
      this.refreshProjects();
    }
  }

  async refreshReviews() {
    this.review_id = null;
    this.reviews = await this.data.getReviewsForUser(this.user?.id!);
    console.log("reviews", this.reviews);
  }

  async refreshProjects() {
    this.projects = await this.data.getAllProjects();
  }
}
