<div class="login">
  <div class="main">
    <h1 class="white">Passwort zurücksetzen</h1>
    <p class="white">
      Noch kein Profil? Bewerbe dich hier auf einen unserer Jobs.
    </p>
    <div class="gold-link" routerLink="/jobs">Jobsuche</div>
    <!--  -->
    <form [formGroup]="pwResetForm" (ngSubmit)="resetPassword()">
      <div
        class="input-container"
        *ngIf="pwResetForm.controls['email'].enabled"
      >
        <label class="white" for="username">E-Mail Adresse</label>
        <input
          type="email"
          autocomplete="username"
          id="username"
          formControlName="email"
        />
      </div>
      <div
        class="input-container"
        *ngIf="pwResetForm.controls['new_password'].enabled"
      >
        <label class="white" for="password">Neues Passwort</label>
        <input
          type="password"
          autocomplete="password"
          id="password"
          formControlName="new_password"
        />
      </div>
      <div
        class="input-container margin-top-10"
        *ngIf="pwResetForm.controls['new_password_repeat'].enabled"
      >
        <label class="white" for="new-password"
          >Neues Passwort wiederholen</label
        >
        <input
          type="password"
          autocomplete="new-password"
          id="new-password"
          formControlName="new_password_repeat"
        />
      </div>
      <div
        *ngIf="pwResetForm.get('new_password')?.invalid && submitAttempt"
        class="alert margin-top-24"
      >
        Das Passwort muss mindestens 5 Zeichen lang sein.
      </div>
      <div
        *ngIf="pwResetForm?.errors?.['notmatched'] && submitAttempt"
        class="alert margin-top-24"
      >
        Die Passwörter stimmen nicht überein.
      </div>
      <div
        *ngIf="pwResetForm.get('email')?.invalid && submitAttempt"
        class="alert margin-top-24"
      >
        Bitte gib eine gültige E-Mail-Adresse an.
      </div>
      <div
        *ngIf="!(token && !tokenInvalidated) && sent && !mailSuccess"
        class="alert margin-top-24"
      >
        Die Mail existiert leider nicht.
      </div>
      <div *ngIf="sent && mailSuccess" class="white margin-top-24">
        Wir haben dir eine E-Mail zum Zurücksetzen deines Passworts gesendet.
      </div>

      <div *ngIf="sent && passwordResetSuccess" class="white margin-top-24">
        Das Passwort wurde erfolgreich gesetzt.
      </div>
      <div
        *ngIf="token && !tokenInvalidated && sent && mailSuccess"
        class="alert margin-top-24"
      >
        Das hat leider nicht geklappt.
      </div>

      <div *ngIf="tokenInvalidated" class="alert margin-top-24">
        Leider ist der Link bereits abgelaufen oder existiert nicht. Bitte
        versuche es erneut.
      </div>
      <div class="input-container margin-top-24">
        <app-button
          *ngIf="!passwordResetSuccess"
          type="submit"
          chevron="right"
          (click)="resetPassword()"
          [disabled]="isSubmitting"
          [isLoading]="isSubmitting"
          >Passwort zurücksetzen</app-button
        >
      </div>
    </form>
  </div>
  <div class="detail">
    <div class="logo-container">
      <img src="assets/svgs/logo_big_claim.svg" alt="Logo Claim" class="logo" />
    </div>
  </div>
</div>
