import { trigger, transition, style, animate } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-collapsable",
  templateUrl: "./collapsable.component.html",
  styleUrls: ["./collapsable.component.scss"],
  animations: [
    trigger("fade", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class CollapsableComponent implements OnInit {
  @Input() valid: boolean = false;

  @Input() collapsed: boolean = false;

  @Input() dontShowCollapsableHeader: boolean = false;

  @Output() collapsedChange: EventEmitter<boolean> = new EventEmitter<boolean>(
    false
  );

  constructor() {}

  ngOnInit(): void {}
}
