<app-svgs
  class="close-btn"
  type="close"
  *ngIf="closeButton"
  (click)="
    datenFehlerhaftOpen ? (datenFehlerhaftOpen = false) : closeEditForm()
  "
></app-svgs>
<header>
  <div class="info-header" *ngIf="user && !datenFehlerhaftOpen">
    <!-- logo on public info modal -->
    <img
      src="assets/svgs/logo_claim_right.svg"
      id="logo"
      alt="logo"
      *ngIf="customerPreview"
    />
    <h5 *ngIf="user && user.personal_category" class="margin-bottom-16">
      {{ user.personal_category }}
    </h5>
    <h4 *ngIf="anrede">{{ anrede }}</h4>
    <h2 *ngIf="name">
      {{ name }}
      <span
        class="in-auswahl-badge aenderungen-vorhanden"
        *ngIf="!user.activated"
      >
        <app-svgs type="warning-circle-outlined" color="fail"></app-svgs>
        Deaktiviert
      </span>
      <span *ngIf="!customerPreview && !arbeitskreafteEdit">
        <span
          class="in-auswahl-badge aenderungen-vorhanden"
          *ngIf="!user.activated"
        >
          <app-svgs type="warning-circle-outlined" color="fail"></app-svgs>
          Deaktiviert
        </span>
        <span *ngIf="!customerPreview && !arbeitskreafteEdit">
          <span
            class="in-auswahl-badge aenderungen-vorhanden"
            *ngIf="!user.activated"
          >
            <app-svgs type="warning-circle-outlined" color="fail"></app-svgs>
            Deaktiviert
          </span>
          <span *ngIf="!customerPreview && !arbeitskreafteEdit">
            <span
              class="in-auswahl-badge"
              *ngIf="['auswahl_auftraggeber_invisible', 'auswahl_auftraggeber_visible'].includes(user?.applications?.[0]?.status || '')"
            >
              <app-svgs type="star-outline" color="secondary"></app-svgs>
              Person ist in Auswahl
            </span>
            <span
              class="in-auswahl-badge kunde-bestaetigt"
              *ngIf="['auftraggeber_akzeptiert'].includes(user?.applications?.[0]?.status || '')"
            >
              <app-svgs type="star-outline" color="success"></app-svgs>
              Von Kunde zugesagt
            </span>
            <span
              class="in-auswahl-badge aenderungen-vorhanden"
              *ngIf="sedHasChanges"
            >
              <app-svgs type="warning-circle-outlined" color="fail"></app-svgs>
              Änderungen vorhanden
            </span>
          </span>
        </span>
      </span>
    </h2>
    <h4
      class="perso-nr-wrapper"
      *ngIf="!customerPreview && !arbeitskreafteEdit && personalnummer"
    >
      {{ personalnummer }}
    </h4>
    <div class="personalnummer" *ngIf="!customerPreview && arbeitskreafteEdit">
      <input type="number" [(ngModel)]="personalnummer" />
      <app-svgs
        class="cursor-pointer"
        type="save-floppy-disk"
        *ngIf="personalnummer != (incomingUser.personalnummer || '')"
        (click)="updatePersonalnummer()"
      ></app-svgs>
    </div>
  </div>
  <app-bewerber-notiz
    *ngIf="user && showInterneInfosNav && !customerPreview"
    [user]="user"
  ></app-bewerber-notiz>
</header>
<div
  class="navbar"
  *ngIf="
    user &&
    (user.is_topteamer || arbeitskreafteEdit) &&
    currentPage &&
    !customerPreview &&
    !datenFehlerhaftOpen &&
    !vertrag &&
    !current_dismissal_template
  "
>
  <div
    *ngIf="showProfileNav"
    [ngClass]="{ selected: currentPage == 'profile' }"
    (click)="currentPage = 'profile'"
  >
    Persönliche Daten
  </div>
  <div
    *ngIf="showSedCardNav"
    [ngClass]="{ selected: currentPage == 'sed' }"
    (click)="currentPage = 'sed'"
  >
    Sedcard
  </div>
  <div
    *ngIf="showProjektdatenNav"
    [ngClass]="{ selected: currentPage == 'projektdaten' }"
    (click)="currentPage = 'projektdaten'"
  >
    Projektdaten
  </div>
  <div
    *ngIf="showInterneInfosNav"
    [ngClass]="{ selected: currentPage == 'interne_infos' }"
    (click)="currentPage = 'interne_infos'"
  >
    Interne Infos
  </div>
  <div
    *ngIf="showProtokollNav"
    [ngClass]="{ selected: currentPage == 'protokoll' }"
    (click)="currentPage = 'protokoll'"
  >
    Protokoll
  </div>
</div>

<div class="scroll-container">
  <div
    class="daten-fehlerhaft"
    *ngIf="datenFehlerhaftOpen"
    [formGroup]="datenFehlerhaftForm"
  >
    <h5>Daten fehlerhaft</h5>
    <h2 *ngIf="name">{{ name }}</h2>
    <p class="p-light margin-top-10 margin-bottom-6">Nachricht an {{ name }}</p>
    <app-editor
      [html]="datenFehlerhaftForm.get('nachricht')?.value"
      (htmlChange)="datenFehlerhaftForm.get('nachricht')?.setValue($event)"
    ></app-editor>
    <div
      class="alert"
      *ngIf="submitAttempt && datenFehlerhaftForm.controls['nachricht'].invalid"
    >
      Bitte füge noch eine Nachricht ein.
    </div>
  </div>

  <app-bewerbercheck
    *ngIf="
      user &&
      !user.is_topteamer &&
      user.main_sed_card &&
      user.profile &&
      !arbeitskreafteEdit &&
      !datenFehlerhaftOpen
    "
    [user]="user"
    [showStatus]="true"
    [hasValidStatusErrors]="hasValidStatusErrors"
  >
  </app-bewerbercheck>

  <app-sed
    *ngIf="
      currentPage == 'sed' &&
      user &&
      (user.is_topteamer || arbeitskreafteEdit) &&
      user.main_sed_card &&
      user.profile &&
      !datenFehlerhaftOpen &&
      !vertrag &&
      !current_dismissal_template
    "
    [user]="user"
    [arbeitskreafteEdit]="arbeitskreafteEdit"
    (close)="close.emit(true)"
    [sedForm]="sedForm"
    [originalSedForm]="originalSedForm"
    [submitAttempt]="submitAttempt"
    [basicsForPersonal]="customerPreview ? user.profile : undefined"
    (isUploading)="isUploading = $event"
    [customerPreview]="customerPreview"
  ></app-sed>
  <app-profile
    *ngIf="
      currentPage == 'profile' &&
      user &&
      (user.is_topteamer || arbeitskreafteEdit) &&
      user.main_sed_card &&
      user.profile &&
      !datenFehlerhaftOpen &&
      !vertrag &&
      !current_dismissal_template
    "
    [user]="user"
    [arbeitskreafteEdit]="arbeitskreafteEdit"
    (close)="close.emit(true)"
    [profileForm]="profileForm"
    [originalProfileForm]="originalProfileForm"
    [submitAttempt]="submitAttempt"
    [showStatus]="!customerPreview && !arbeitskreafteEdit"
    [hasValidStatusErrors]="hasValidStatusErrors"
    (isUploading)="isUploading = $event"
  ></app-profile>

  <app-interne-infos
    (deleteUser)="deleteUser(true)"
    *ngIf="
      currentPage == 'interne_infos' &&
      user &&
      (user.is_topteamer || arbeitskreafteEdit) &&
      user.main_sed_card &&
      user.profile &&
      !datenFehlerhaftOpen &&
      !vertrag &&
      !current_dismissal_template
    "
    [user]="user"
  ></app-interne-infos>

  <!-- arbeitskreafteEdit && -->
  <app-protokoll
    *ngIf="
      currentPage == 'protokoll' &&
      user &&
      !datenFehlerhaftOpen &&
      !vertrag &&
      !current_dismissal_template
    "
    [user]="user"
  ></app-protokoll>

  <app-projektdaten
    *ngIf="
      currentPage == 'projektdaten' &&
      user &&
      (user.is_topteamer || arbeitskreafteEdit) &&
      user.main_sed_card &&
      user.profile &&
      !datenFehlerhaftOpen &&
      !vertrag &&
      !current_dismissal_template
    "
    [user]="user"
    [showVertrag]="showVertrag"
    (kuendigen)="handleDismissal('kuendigen')"
    (gegengezeichnet)="
      buttonsToShow.splice(buttonsToShow.indexOf('vertrag_erneut_erstellen'), 1)
    "
  ></app-projektdaten>

  <div class="vertrag-erstellen" *ngIf="vertrag">
    <label>Vorlage</label>
    <select [(ngModel)]="arbeitsvertrag_typ" class="margin-bottom-10">
      <option value="Arbeitsvertrag">Arbeitsvertrag</option>
      <option value="ANUE">ANUE</option>
    </select>
    <div class="vertrag_unterzeichnen_bis">
      <label class="label" for="vertrag_unterzeichnen_bis"
        >Vertrag unterzeichnen bis (wird automatisch mit
        var_zu_unterschreiben_bis ersetzt bei "Vertrag senden")</label
      >
      <input
        type="date"
        id="vertrag_unterzeichnen_bis"
        name="vertrag_unterzeichnen_bis"
        [(ngModel)]="vertrag_unterzeichnen_bis"
        [value]="vertrag_unterzeichnen_bis | date : 'y-MM-dd'"
      />
    </div>
    <app-editor [(html)]="vertrag[arbeitsvertrag_typ]"></app-editor>
  </div>

  <div class="vertrag-erstellen" *ngIf="current_dismissal_template">
    <app-editor [(html)]="current_dismissal_template!.template"></app-editor>
  </div>
</div>

<!-- All Buttons accumulated start -->
<!-- VERTRAG SENDEN BUTTONS -->
<div
  class="buttons"
  *ngIf="
    currentPage != 'interne_infos' &&
    currentPage != 'protokoll' &&
    datenFehlerhaftOpen
  "
>
  <div></div>
  <div class="right-buttons">
    <app-button
      color="primary80"
      [lessHeight]="true"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      (click)="datenFehlerhaftOpen = false"
      >Abbrechen</app-button
    >
    <app-button
      color="secondary-gradient"
      [lessHeight]="true"
      background="primary10"
      iconRight="check"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      (click)="handleApplicationStatus('daten_fehlerhaft')"
      >Senden</app-button
    >
  </div>
</div>

<!-- NORMAL BUTTONS -->
<div
  class="buttons"
  *ngIf="
    !arbeitskreafteEdit &&
    !customerPreview &&
    !datenFehlerhaftOpen &&
    buttonsToShow.length &&
    currentPage != 'interne_infos' &&
    currentPage != 'protokoll'
  "
>
  <div class="left-buttons">
    <app-button
      [lessHeight]="true"
      color="primary80"
      background="primary10"
      (click)="vertrag = undefined; buttonsToShow = ['vertrag_erstellen']"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      *ngIf="buttonsToShow.includes('vertrag_senden')"
    >
      Abbrechen
    </app-button>
    <app-button
      [lessHeight]="true"
      color="primary80"
      background="primary10"
      (click)="this.datenFehlerhaftOpen = true"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      iconRight="warning-circle-outlined"
      *ngIf="buttonsToShow.includes('daten_fehlerhaft')"
    >
      Daten fehlerhaft
    </app-button>
    <app-button
      [lessHeight]="true"
      color="primary80"
      background="primary10"
      (click)="handleApplicationStatus('sed_card_bearbeiten')"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      iconRight="edit-pencil"
      *ngIf="buttonsToShow.includes('sed_card_bearbeiten') && sedForm.disabled"
    >
      Sedcard bearbeiten
    </app-button>
    <div class="alert flex flex-center-vertical gap-16" *ngIf="sedForm.enabled">
      <app-svgs type="warning-circle-outlined" color="fail"></app-svgs>
      <p>
        Bearbeitete Daten werden nur dem Auftraggeber angezeigt. <br />
        Bewerber sehen weiterhin ihr erstelltes Profil.
      </p>
    </div>
  </div>
  <div class="right-buttons">
    <!-- bewerbercheck buttons -->
    <app-button
      iconRight="close"
      color="primary80"
      background="primary10"
      [lessHeight]="true"
      (click)="handleApplicationStatus('ablehnen')"
      *ngIf="buttonsToShow.includes('ablehnen')"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      >Ablehnen</app-button
    >
    <app-button
      iconRight="save-floppy-disk"
      color="primary80"
      background="primary10"
      [lessHeight]="true"
      (click)="handleApplicationStatus('als_topteamer_aufnehmen')"
      *ngIf="buttonsToShow.includes('als_topteamer_aufnehmen')"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      >Als Topteamer aufnehmen</app-button
    >
    <app-button
      iconRight="check"
      background="primary10"
      [lessHeight]="true"
      (click)="handleApplicationStatus('daten_anfordern')"
      *ngIf="buttonsToShow.includes('daten_anfordern')"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      >Daten anfordern</app-button
    >
    <!-- bewerbercheck buttons end -->
    <app-button
      [lessHeight]="true"
      color="primary80"
      background="primary10"
      (click)="handleApplicationStatus('unentschlossen')"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      *ngIf="buttonsToShow.includes('unentschlossen')"
    >
      Unentschlossen
    </app-button>
    <app-button
      [lessHeight]="true"
      color="primary80"
      background="primary10"
      (click)="handleApplicationStatus('daten_ok')"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      *ngIf="buttonsToShow.includes('daten_ok')"
    >
      Daten ok
    </app-button>

    <app-button
      [lessHeight]="true"
      color="secondary"
      background="primary10"
      (click)="handleApplicationStatus('daten_ok_und_in_auswahl')"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      iconRight="check"
      *ngIf="buttonsToShow.includes('daten_ok_und_in_auswahl')"
    >
      Daten OK & in Auswahl
    </app-button>
    <app-button
      [lessHeight]="true"
      color="primary80"
      background="primary10"
      (click)="handleApplicationStatus('aus_auswahl_entfernen')"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      *ngIf="
        buttonsToShow.includes('aus_auswahl_entfernen') && sedForm.disabled
      "
    >
      Aus Auswahl entfernen
    </app-button>

    <app-button
      [lessHeight]="true"
      color="secondary-gradient"
      (click)="handleApplicationStatus('vertrag_erstellen')"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      *ngIf="buttonsToShow.includes('vertrag_erstellen')"
    >
      Vertrag erstellen
    </app-button>

    <app-button
      [lessHeight]="true"
      color="secondary-gradient"
      (click)="handleApplicationStatus('vertrag_erneut_erstellen')"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      *ngIf="buttonsToShow.includes('vertrag_erneut_erstellen')"
    >
      Vertrag erneut erstellen
    </app-button>

    <!-- Kündigen start -->
    <app-button
      [lessHeight]="true"
      color="primary80"
      (click)="handleDismissal('kuendigung_ueberspringen')"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      *ngIf="applications_to_dismiss.length > 0"
    >
      Kündigung überspringen
    </app-button>

    <app-button
      [lessHeight]="true"
      color="secondary-gradient"
      background="primary10"
      (click)="handleDismissal('kuendigung_abschicken')"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      *ngIf="applications_to_dismiss.length"
    >
      Kündigung abschicken
    </app-button>
    <!-- Kündigen end -->

    <app-button
      [lessHeight]="true"
      color="secondary-gradient"
      background="primary10"
      (click)="handleApplicationStatus('vertrag_senden')"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      *ngIf="buttonsToShow.includes('vertrag_senden')"
    >
      Vertrag senden
    </app-button>

    <app-button
      [lessHeight]="true"
      color="secondary-gradient"
      background="primary10"
      (click)="handleApplicationStatus('vertrag_senden')"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      *ngIf="buttonsToShow.includes('vertrag_erneut_senden')"
    >
      Vertrag erneut senden
    </app-button>

    <!-- alternative sed form bearbeiten start -->

    <app-button
      [lessHeight]="true"
      color="primary80"
      background="primary10"
      (click)="cancelSedEdit()"
      *ngIf="sedForm.enabled"
      [disabled]="isUploading"
      [isLoading]="isUploading"
    >
      Abbrechen
    </app-button>
    <app-button
      *ngIf="sedForm.enabled"
      [lessHeight]="true"
      color="secondary-gradient"
      background="primary10"
      (click)="handleApplicationStatus('sed_card_speichern')"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      iconRight="save-floppy-disk"
    >
      Speichern
    </app-button>
    <!-- alternative sed form bearbeiten end -->
  </div>
</div>

<!-- ARBEITSKRÄFTE EDIT BUTTONS -->
<div
  class="buttons"
  *ngIf="
    arbeitskreafteEdit &&
    currentPage != 'interne_infos' &&
    currentPage != 'protokoll'
  "
>
  <div class="left-buttons">
    <app-button
      [lessHeight]="true"
      color="primary80"
      (click)="close.emit(true)"
      [disabled]="isUploading"
      [isLoading]="isUploading"
    >
      Abbrechen
    </app-button>
    <app-button
      [lessHeight]="true"
      color="primary80"
      (click)="deleteUser()"
      [disabled]="isUploading"
      [isLoading]="isUploading"
    >
      Profil löschen
    </app-button>
    <!-- neue buttons registrierung -->
    <app-button
      color="primary80"
      [lessHeight]="true"
      (click)="handleRegistrationStatus('ablehnen')"
      *ngIf="user?.registration_status != 'erledigt'"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      >Ablehnen</app-button
    >
    <app-button
      color="primary80"
      [lessHeight]="true"
      (click)="handleRegistrationStatus('daten_anfordern')"
      *ngIf="['neu', 'gesehen'].includes(user?.registration_status || '')"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      >Daten anfordern</app-button
    >
    <!-- neue buttons registrierung ende -->
  </div>
  <app-button
    [lessHeight]="true"
    color="secondary-gradient"
    background="primary10"
    (click)="saveForm()"
    [disabled]="isUploading"
    [isLoading]="isUploading"
    iconRight="save-floppy-disk"
  >
    Speichern
  </app-button>
</div>

<!-- CUSTOMER PREVIEW BUTTONS -->
<div
  class="buttons"
  *ngIf="
    customerPreview &&
    showCustomerPreviewButtons &&
    currentPage != 'interne_infos' &&
    currentPage != 'protokoll'
  "
>
  <div></div>
  <div class="right-buttons">
    <app-button
      [lessHeight]="true"
      color="fail-full"
      (click)="absage.emit(true)"
      iconRight="close"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      *ngIf="
        ['auswahl_auftraggeber_visible', 'auftraggeber_akzeptiert'].includes(
          incomingUser!.applications![0].status!
        )
      "
    >
      Absage
    </app-button>

    <app-button
      [lessHeight]="true"
      color="success-full"
      (click)="zusage.emit(true)"
      iconRight="check"
      [disabled]="isUploading"
      [isLoading]="isUploading"
      *ngIf="
        ['auswahl_auftraggeber_visible', 'auftraggeber_abgesagt'].includes(
          incomingUser!.applications![0].status!
        )
      "
    >
      Zusage
    </app-button>
  </div>
</div>

<!-- All Buttons accumulated end -->
