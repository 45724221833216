import { DataService } from "src/app/data.service";
import { AuthService } from "src/app/shared/auth.service";
import { Router, NavigationEnd } from "@angular/router";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Confirm } from "notiflix";

import { Toggle } from "@ttc_types/types";
import { filter } from "rxjs";

@Component({
  selector: "app-sidebar-nav",
  // templateUrl: './sidebar-nav.component.html',
  styleUrls: ["./sidebar-nav.component.scss"],
  templateUrl: "./sidebar-nav.component.html",
})
export class SidebarNavComponent implements OnInit {
  currentRoute: string = "";

  currentActivedPageIndex: number = -1;

  constructor(
    public router: Router,
    public auth: AuthService,
    public data: DataService
  ) {
    router.events
      .pipe(filter((el) => el instanceof NavigationEnd))
      .subscribe((event) => {
        this.currentRoute = (event as NavigationEnd).url;
        if (["/", "/meine-projekte", "/jobs"].includes(this.currentRoute)) {
          this.currentActivedPageIndex = 0;
        } else if (
          ["/login", "/meine-jobs", "/arbeitskraefte"].includes(
            this.currentRoute
          )
        ) {
          this.currentActivedPageIndex = 1;
        } else if (["/projektleitung"].includes(this.currentRoute)) {
          this.currentActivedPageIndex = 2;
        } else if (["/vorlagen"].includes(this.currentRoute)) {
          this.currentActivedPageIndex = 3;
        } else if (["/exporte"].includes(this.currentRoute)) {
          this.currentActivedPageIndex = 4;
        }
      });
    // console.log(`pages`, this.pages);
  }

  ngOnInit(): void {}

  loginLogout() {
    if (this.loggedIn) {
      // confirm?
      Confirm.show(
        "Abmelden",
        "Bist du sicher?",
        "Nein",
        "Abmelden",
        () => {
          // no
        },
        () => {
          // yes
          this.auth.doLogout();
        },
        {}
      );
    } else {
      this.router.navigateByUrl("/login");
    }
  }

  /**
   * Pages
   *
   */
  @Input()
  pages: Toggle[] = [];

  /**
   * LoggedIn
   *
   */
  @Input()
  loggedIn: boolean = false;

  @Output()
  LoginClicked = new EventEmitter<boolean>();

  /**
   * currentpage index ngModel
   */
  @Input()
  currentPage: number = 0;

  @Output()
  indexSelectedChange = new EventEmitter<number>();
}
