<!-- search -->
<svg
  *ngIf="type == 'search'"
  width="36"
  height="36"
  viewBox="0 0 36 36"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M23.25 23.25L28.5 28.5"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M7.5 16.5C7.5 17.6819 7.73279 18.8522 8.18508 19.9442C8.63738 21.0361 9.30031 22.0282 10.136 22.864C10.9718 23.6997 11.9639 24.3626 13.0558 24.8149C14.1478 25.2672 15.3181 25.5 16.5 25.5C17.6819 25.5 18.8522 25.2672 19.9442 24.8149C21.0361 24.3626 22.0282 23.6997 22.864 22.864C23.6997 22.0282 24.3626 21.0361 24.8149 19.9442C25.2672 18.8522 25.5 17.6819 25.5 16.5C25.5 14.1131 24.5518 11.8239 22.864 10.136C21.1761 8.44821 18.8869 7.5 16.5 7.5C14.1131 7.5 11.8239 8.44821 10.136 10.136C8.44821 11.8239 7.5 14.1131 7.5 16.5V16.5Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
<!-- suitcase -->
<svg
  *ngIf="type == 'suitcase'"
  width="36"
  height="36"
  viewBox="0 0 36 36"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12 10.5H24M12 10.5H6C5.20435 10.5 4.44129 10.8161 3.87868 11.3787C3.31607 11.9413 3 12.7044 3 13.5V28.5C3 29.2956 3.31607 30.0587 3.87868 30.6213C4.44129 31.1839 5.20435 31.5 6 31.5H30C30.7956 31.5 31.5587 31.1839 32.1213 30.6213C32.6839 30.0587 33 29.2956 33 28.5V13.5C33 12.7044 32.6839 11.9413 32.1213 11.3787C31.5587 10.8161 30.7956 10.5 30 10.5H24H12ZM12 10.5V5.4C12 5.16131 12.0948 4.93239 12.2636 4.7636C12.4324 4.59482 12.6613 4.5 12.9 4.5H23.1C23.3387 4.5 23.5676 4.59482 23.7364 4.7636C23.9052 4.93239 24 5.16131 24 5.4V10.5H12Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
  />
</svg>
<!-- avatar -->
<svg
  *ngIf="type == 'avatar'"
  width="36"
  height="36"
  viewBox="0 0 36 36"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M7.5 30V28.5C7.5 25.7152 8.60625 23.0445 10.5754 21.0754C12.5445 19.1062 15.2152 18 18 18V18C20.7848 18 23.4555 19.1062 25.4246 21.0754C27.3938 23.0445 28.5 25.7152 28.5 28.5V30"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M18 18C19.5913 18 21.1174 17.3679 22.2426 16.2426C23.3679 15.1174 24 13.5913 24 12C24 10.4087 23.3679 8.88258 22.2426 7.75736C21.1174 6.63214 19.5913 6 18 6C16.4087 6 14.8826 6.63214 13.7574 7.75736C12.6321 8.88258 12 10.4087 12 12C12 13.5913 12.6321 15.1174 13.7574 16.2426C14.8826 17.3679 16.4087 18 18 18V18Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
<!-- login -->
<svg
  *ngIf="type == 'login'"
  width="36"
  height="36"
  viewBox="0 0 36 36"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M18 18L13.5 13.5M7.5 18H18H7.5ZM18 18L13.5 22.5L18 18Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M7.5 9V7.5C7.5 6.70435 7.81607 5.94129 8.37868 5.37868C8.94129 4.81607 9.70435 4.5 10.5 4.5H25.5C26.2956 4.5 27.0587 4.81607 27.6213 5.37868C28.1839 5.94129 28.5 6.70435 28.5 7.5V28.5C28.5 29.2956 28.1839 30.0587 27.6213 30.6213C27.0587 31.1839 26.2956 31.5 25.5 31.5H10.5C9.70435 31.5 8.94129 31.1839 8.37868 30.6213C7.81607 30.0587 7.5 29.2956 7.5 28.5V27"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
<!-- logout -->
<svg
  *ngIf="type == 'logout'"
  width="36"
  height="36"
  viewBox="0 0 36 36"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M28.5 18L24 13.5M18 18H28.5H18ZM28.5 18L24 22.5L28.5 18Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M28.5 9V7.5C28.5 6.70435 28.1839 5.94129 27.6213 5.37868C27.0587 4.81607 26.2956 4.5 25.5 4.5H10.5C9.70435 4.5 8.94129 4.81607 8.37868 5.37868C7.81607 5.94129 7.5 6.70435 7.5 7.5V28.5C7.5 29.2956 7.81607 30.0587 8.37868 30.6213C8.94129 31.1839 9.70435 31.5 10.5 31.5H25.5C26.2956 31.5 27.0587 31.1839 27.6213 30.6213C28.1839 30.0587 28.5 29.2956 28.5 28.5V27"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
<!-- chevron left -->
<svg
  *ngIf="type == 'chevron-left'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M15 6L9 12L15 18"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- chevron-down -->
<svg
  *ngIf="type == 'chevron-down'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M6 9L12 15L18 9"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
<!-- chevron-down-small -->
<svg
  xmlns="http://www.w3.org/2000/svg"
  width="14"
  height="8"
  fill="none"
  *ngIf="type == 'chevron-down-small'"
>
  <path
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
    d="m1 1 6 6 6-6"
  />
</svg>

<!-- chevron-right -->
<svg
  *ngIf="type == 'chevron-right'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M9 6L15 12L9 18"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- settings -->
<svg
  *ngIf="type == 'settings'"
  width="36"
  height="36"
  viewBox="0 0 36 36"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M18 22.5C19.1935 22.5 20.3381 22.0259 21.182 21.182C22.0259 20.3381 22.5 19.1935 22.5 18C22.5 16.8065 22.0259 15.6619 21.182 14.818C20.3381 13.9741 19.1935 13.5 18 13.5C16.8065 13.5 15.6619 13.9741 14.818 14.818C13.9741 15.6619 13.5 16.8065 13.5 18C13.5 19.1935 13.9741 20.3381 14.818 21.182C15.6619 22.0259 16.8065 22.5 18 22.5V22.5Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M29.433 15.5925L27.7875 11.6175L30 9L27 6L24.3975 8.2245L20.337 6.555L19.4025 3H16.4715L15.5235 6.6015L11.556 8.274L9 6L6 9L8.1795 11.6835L6.5595 15.669L3 16.5V19.5L6.6015 20.4825L8.274 24.45L6 27L9 30L11.6865 27.81L15.5955 29.418L16.5 33H19.5L20.406 29.4195L24.3825 27.7725C25.0455 28.2465 27 30 27 30L30 27L27.774 24.375L29.421 20.397L33 19.467V16.5L29.433 15.5925V15.5925Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- infos -->
<svg
  *ngIf="type == 'infos'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12 11.5V16.5"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12 7.51002L12.01 7.49902"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
<!-- tasks -->
<svg
  *ngIf="type == 'tasks'"
  width="25"
  height="24"
  viewBox="0 0 25 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M9.66675 6H20.6667"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M4.4668 5.79961L5.2668 6.59961L7.2668 4.59961"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M4.4668 11.7996L5.2668 12.5996L7.2668 10.5996"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M4.4668 17.7996L5.2668 18.5996L7.2668 16.5996"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M9.66675 12H20.6667"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M9.66675 18H20.6667"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
<!-- user -->
<svg
  *ngIf="type == 'user'"
  width="25"
  height="24"
  viewBox="0 0 25 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M5.33325 20V19C5.33325 17.1435 6.07075 15.363 7.3835 14.0503C8.69626 12.7375 10.4767 12 12.3333 12V12C14.1898 12 15.9702 12.7375 17.283 14.0503C18.5958 15.363 19.3333 17.1435 19.3333 19V20"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12.3333 12C13.3941 12 14.4115 11.5786 15.1617 10.8284C15.9118 10.0783 16.3333 9.06087 16.3333 8C16.3333 6.93913 15.9118 5.92172 15.1617 5.17157C14.4115 4.42143 13.3941 4 12.3333 4C11.2724 4 10.255 4.42143 9.50482 5.17157C8.75468 5.92172 8.33325 6.93913 8.33325 8C8.33325 9.06087 8.75468 10.0783 9.50482 10.8284C10.255 11.5786 11.2724 12 12.3333 12V12Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
<!-- clock -->
<svg
  *ngIf="type == 'clock'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12 6V12H18"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
<!-- remove -->
<svg
  *ngIf="type == 'remove'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8 12H16"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
<!-- eye -->
<svg
  *ngIf="type == 'eye'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12C10 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M21 12C19.111 14.991 15.718 18 12 18C8.282 18 4.889 14.991 3 12C5.299 9.158 7.992 6 12 6C16.008 6 18.701 9.158 21 12Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
<!-- eye-crossed -->
<svg
  *ngIf="type == 'eye-crossed'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M3 3L21 21"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M10.5 10.6768C10.163 11.0583 9.98422 11.5539 9.99998 12.0626C10.0157 12.5714 10.2249 13.055 10.5848 13.4149C10.9447 13.7748 11.4283 13.984 11.9371 13.9997C12.4458 14.0155 12.9414 13.8367 13.323 13.4998"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M7.362 7.56055C5.68 8.73955 4.279 10.4195 3 11.9995C4.889 14.9905 8.282 17.9995 12 17.9995C13.55 17.9995 15.043 17.4765 16.395 16.6495"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12 6C16.008 6 18.701 9.158 21 12C20.6705 12.5208 20.3105 13.0216 19.922 13.5"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- calendar16x16 -->
<svg
  *ngIf="type == 'calendar16x16'"
  width="17"
  height="16"
  viewBox="0 0 17 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10.5 2.66683V1.3335V2.66683ZM10.5 2.66683V4.00016V2.66683ZM10.5 2.66683H7.5H10.5ZM2.5 6.66683V12.6668C2.5 13.0205 2.64048 13.3596 2.89052 13.6096C3.14057 13.8597 3.47971 14.0002 3.83333 14.0002H13.1667C13.5203 14.0002 13.8594 13.8597 14.1095 13.6096C14.3595 13.3596 14.5 13.0205 14.5 12.6668V6.66683H2.5Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M2.5 6.66699V4.00033C2.5 3.6467 2.64048 3.30756 2.89052 3.05752C3.14057 2.80747 3.47971 2.66699 3.83333 2.66699H5.16667"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M5.16669 1.3335V4.00016"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M14.5 6.66699V4.00033C14.5 3.6467 14.3595 3.30756 14.1095 3.05752C13.8594 2.80747 13.5203 2.66699 13.1666 2.66699H12.8333"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- location16x16 -->
<svg
  *ngIf="type == 'location16x16'"
  width="17"
  height="16"
  viewBox="0 0 17 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M13.8334 6.66683C13.8334 9.61216 8.50002 14.6668 8.50002 14.6668C8.50002 14.6668 3.16669 9.61216 3.16669 6.66683C3.16669 5.25234 3.72859 3.89579 4.72878 2.89559C5.72898 1.8954 7.08553 1.3335 8.50002 1.3335C9.91451 1.3335 11.2711 1.8954 12.2713 2.89559C13.2715 3.89579 13.8334 5.25234 13.8334 6.66683V6.66683Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
  />
  <path
    d="M8.50004 7.33333C8.67685 7.33333 8.84642 7.2631 8.97145 7.13807C9.09647 7.01305 9.16671 6.84348 9.16671 6.66667C9.16671 6.48986 9.09647 6.32029 8.97145 6.19526C8.84642 6.07024 8.67685 6 8.50004 6C8.32323 6 8.15366 6.07024 8.02864 6.19526C7.90361 6.32029 7.83337 6.48986 7.83337 6.66667C7.83337 6.84348 7.90361 7.01305 8.02864 7.13807C8.15366 7.2631 8.32323 7.33333 8.50004 7.33333Z"
    [attr.fill]="strokeColor"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- eur16x16 -->
<svg
  *ngIf="type == 'eur16x16'"
  width="17"
  height="16"
  viewBox="0 0 17 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12.8333 3.2025C12.0202 2.80756 11.1201 2.62576 10.2175 2.67416C9.31488 2.72256 8.43937 2.99957 7.67319 3.4792C6.90701 3.95882 6.2753 4.62531 5.83739 5.41607C5.39948 6.20683 5.16974 7.09592 5.16974 7.99984C5.16974 8.90376 5.39948 9.79285 5.83739 10.5836C6.2753 11.3744 6.90701 12.0409 7.67319 12.5205C8.43937 13.0001 9.31488 13.2771 10.2175 13.3255C11.1201 13.3739 12.0202 13.1921 12.8333 12.7972"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M3.83331 6.6665H11.1666"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M3.83331 9.33301H11.1666"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- checkmark-circle -->
<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  *ngIf="type == 'checkmark-circle'"
>
  <path
    d="M7 12.5L10 15.5L17 8.5"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- check -->
<svg
  *ngIf="type == 'check'"
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M3.33325 8.66675L5.99992 11.3334L12.6666 4.66675"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- telephone -->
<svg
  *ngIf="type == 'telephone'"
  width="31"
  height="24"
  viewBox="0 0 31 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M17.5577 15.5L22.7222 14.702L27.5907 16.181V19.765C27.5907 20.958 26.2952 21.866 24.8341 21.613C21.0517 20.956 14.1778 19.305 9.4059 15.5C4.86221 11.877 3.30709 6.83 2.78036 4.047C2.56841 2.932 3.68208 2 5.09673 2H9.80095L11.6257 5.87L10.66 10C11.9141 12.5 14.0687 14.104 17.5577 15.5Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- smartphone -->
<svg
  *ngIf="type == 'smartphone'"
  width="31"
  height="24"
  viewBox="0 0 31 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M15.0493 16.01L15.0619 15.999"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8.77881 4.6V19.4C8.77881 19.5591 8.85809 19.7117 8.9992 19.8243C9.14032 19.9368 9.33171 20 9.53128 20H20.5676C20.6664 20 20.7642 19.9845 20.8555 19.9543C20.9468 19.9242 21.0298 19.88 21.0997 19.8243C21.1695 19.7685 21.225 19.7024 21.2628 19.6296C21.3006 19.5568 21.3201 19.4788 21.3201 19.4V4.6C21.3201 4.52121 21.3006 4.44319 21.2628 4.37039C21.225 4.29759 21.1695 4.23145 21.0997 4.17574C21.0298 4.12002 20.9468 4.07583 20.8555 4.04567C20.7642 4.01552 20.6664 4 20.5676 4H9.53128C9.33171 4 9.14032 4.06321 8.9992 4.17574C8.85809 4.28826 8.77881 4.44087 8.77881 4.6Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
  />
</svg>

<!-- email -->
<svg
  *ngIf="type == 'email'"
  width="31"
  height="24"
  viewBox="0 0 31 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8.77881 9L15.0494 12.5L21.3201 9"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M2.5083 7V17C2.5083 17.5304 2.77256 18.0391 3.24295 18.4142C3.71334 18.7893 4.35132 19 5.01655 19H25.0825C25.7478 19 26.3858 18.7893 26.8561 18.4142C27.3265 18.0391 27.5908 17.5304 27.5908 17V7C27.5908 6.46957 27.3265 5.96086 26.8561 5.58579C26.3858 5.21071 25.7478 5 25.0825 5H5.01655C4.35132 5 3.71334 5.21071 3.24295 5.58579C2.77256 5.96086 2.5083 6.46957 2.5083 7Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
  />
</svg>

<!-- close -->
<svg
  *ngIf="type == 'close'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12.0011 11.9998L17.2441 17.2428M6.75806 17.2428L12.0011 11.9998L6.75806 17.2428ZM17.2441 6.75684L12.0001 11.9998L17.2441 6.75684ZM12.0001 11.9998L6.75806 6.75684L12.0001 11.9998Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- minus -->
<svg
  *ngIf="type == 'minus'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M6 12H18"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- plus -->
<svg
  *ngIf="type == 'plus'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M12 12V18M6 12H12H6ZM18 12H12H18ZM12 12V6V12Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- trash -->
<svg
  *ngIf="type == 'trash'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M19 11V20.4C19 20.5591 18.9368 20.7117 18.8243 20.8243C18.7117 20.9368 18.5591 21 18.4 21H5.6C5.44087 21 5.28826 20.9368 5.17574 20.8243C5.06321 20.7117 5 20.5591 5 20.4V11"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M10 17V11"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M14 17V11"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8 7H16M21 7H16H21ZM3 7H8H3ZM8 7V3.6C8 3.44087 8.06321 3.28826 8.17574 3.17574C8.28826 3.06321 8.44087 3 8.6 3H15.4C15.5591 3 15.7117 3.06321 15.8243 3.17574C15.9368 3.28826 16 3.44087 16 3.6V7H8Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- page-search -->

<svg
  *ngIf="type == 'page-search'"
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M13.3334 7.99992V3.83259C13.3335 3.77996 13.3231 3.72785 13.303 3.67923C13.2828 3.63061 13.2533 3.58644 13.2161 3.54925L11.1174 1.45059C11.0425 1.37555 10.9408 1.33335 10.8347 1.33325H3.06675C2.96066 1.33325 2.85892 1.37539 2.78391 1.45041C2.70889 1.52542 2.66675 1.62717 2.66675 1.73325V14.2666C2.66675 14.3727 2.70889 14.4744 2.78391 14.5494C2.85892 14.6244 2.96066 14.6666 3.06675 14.6666H7.33341"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M5.3335 9.33333H7.3335M5.3335 6.66667H10.6668H5.3335ZM5.3335 4H8.00016H5.3335Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M13.6667 13.6665L14.6667 14.6665"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M10 12C10 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M10.6667 3.59999V1.56932C10.6668 1.5227 10.6807 1.47716 10.7067 1.43843C10.7326 1.39969 10.7694 1.36951 10.8125 1.35168C10.8556 1.33385 10.9029 1.32918 10.9487 1.33825C10.9944 1.34732 11.0364 1.36973 11.0694 1.40265L13.2641 3.59732C13.2973 3.6302 13.32 3.67223 13.3293 3.71807C13.3385 3.7639 13.3339 3.81145 13.3161 3.85466C13.2982 3.89786 13.2678 3.93476 13.2289 3.96064C13.1899 3.98653 13.1442 4.00022 13.0974 3.99999H11.0667C10.9607 3.99999 10.8589 3.95784 10.7839 3.88283C10.7089 3.80781 10.6667 3.70607 10.6667 3.59999Z"
    [attr.fill]="strokeColor"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- yoga -->
<svg
  *ngIf="type == 'yoga'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M14.571 15.0039L15.429 16.8489C15.429 16.8489 19.286 17.6679 19.286 19.6159C19.286 20.9999 17.57 20.9999 17.57 20.9999H13L10.75 19.7499"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M9.42887 15.0039L8.57187 16.8489C8.57187 16.8489 4.71387 17.6679 4.71387 19.6159C4.71387 20.9999 6.42987 20.9999 6.42987 20.9999H8.49987L10.7499 19.7499L13.4999 17.9999"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M3 15.926C3 15.926 5.143 15.465 6.429 15.004C7.714 8.54595 11.57 9.00695 12 9.00695C12.429 9.00695 16.286 8.54595 17.571 15.004C18.857 15.464 21 15.926 21 15.926"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12 7C12.5304 7 13.0391 6.78929 13.4142 6.41421C13.7893 6.03914 14 5.53043 14 5C14 4.46957 13.7893 3.96086 13.4142 3.58579C13.0391 3.21071 12.5304 3 12 3C11.4696 3 10.9609 3.21071 10.5858 3.58579C10.2107 3.96086 10 4.46957 10 5C10 5.53043 10.2107 6.03914 10.5858 6.41421C10.9609 6.78929 11.4696 7 12 7Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- coin -->
<svg
  *ngIf="type == 'coin'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g opacity="0.4">
    <path
      d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
      [attr.stroke]="strokeColor"
      [attr.stroke-width]="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 16.391V18.5M15 8.5C14.315 7.815 13.109 7.339 12 7.309L15 8.5ZM9 15C9.644 15.86 10.843 16.35 12 16.391L9 15ZM12 7.309C10.68 7.273 9.5 7.87 9.5 9.5C9.5 12.5 15 11 15 14C15 15.711 13.536 16.446 12 16.391V7.309ZM12 7.309V5.5V7.309Z"
      [attr.stroke]="strokeColor"
      [attr.stroke-width]="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</svg>

<!-- undo-circle -->
<svg
  *ngIf="type == 'undo-circle'"
  width="22"
  height="22"
  viewBox="0 0 22 22"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M6 9.625H13.2C13.2 9.625 16 9.625 16 12.625C16 16 13.2 16 13.2 16H12.4"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M9.5 13L6 9.625L9.5 6"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M11 21C16.523 21 21 16.523 21 11C21 5.477 16.523 1 11 1C5.477 1 1 5.477 1 11C1 16.523 5.477 21 11 21Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- filter -->
<svg
  *ngIf="type == 'filter'"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  style="margin-left: 10px; bottom: -1px"
>
  <path
    d="M2 1H18C18.2652 1 18.5196 1.10536 18.7071 1.29289C18.8946 1.48043 19 1.73478 19 2V3.586C18.9999 3.85119 18.8946 4.10551 18.707 4.293L12.292 10.707C12.1048 10.8947 11.9998 11.1489 12 11.414V17.719C12 17.871 11.9653 18.021 11.8987 18.1576C11.832 18.2942 11.735 18.4138 11.6152 18.5073C11.4954 18.6008 11.3558 18.6658 11.2071 18.6973C11.0584 18.7288 10.9044 18.7259 10.757 18.689L8.757 18.189C8.54075 18.1348 8.34881 18.01 8.21166 17.8342C8.0745 17.6585 8.00001 17.4419 8 17.219V11.414C7.99994 11.1488 7.89455 10.8945 7.707 10.707L1.292 4.293C1.10482 4.10534 0.999792 3.85105 1 3.586V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1V1Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- page-edit -->
<svg
  *ngIf="type == 'page-edit'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M20 12V5.74853C20 5.5894 19.9368 5.43679 19.8243 5.32426L16.6757 2.17574C16.5632 2.06321 16.4106 2 16.2515 2H4.6C4.26863 2 4 2.26863 4 2.6V21.4C4 21.7314 4.26863 22 4.6 22H11"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8 14H11M8 10H16H8ZM8 6H12H8Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M16 5.4V2.35355C16 2.15829 16.1583 2 16.3536 2C16.4473 2 16.5372 2.03725 16.6036 2.10355L19.8964 5.39645C19.9628 5.46275 20 5.55268 20 5.64645C20 5.84171 19.8417 6 19.6464 6H16.6C16.2686 6 16 5.73137 16 5.4Z"
    [attr.fill]="strokeColor"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M17.9542 16.9394L19.54 18.5252M17.9542 16.9394L18.9542 15.9394C19.3921 15.5015 20.1021 15.5015 20.54 15.9394C20.9779 16.3773 20.9779 17.0873 20.54 17.5252L19.54 18.5252L17.9542 16.9394ZM17.9542 16.9394L14.9631 19.9305C14.8132 20.0804 14.7148 20.2741 14.6822 20.4835L14.4395 22.0399L15.9958 21.7973C16.2053 21.7646 16.3989 21.6662 16.5488 21.5163L19.54 18.5252L17.9542 16.9394Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- calendar -->
<svg
  *ngIf="type == 'calendar'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M15 4V2V4ZM15 4V6V4ZM15 4H10.5H15ZM3 10V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V10H3Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M3 10V6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4H7"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M7 2V6"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M21 10V6C21 5.46957 20.7893 4.96086 20.4142 4.58579C20.0391 4.21071 19.5304 4 19 4H18.5"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- checkmark -->
<svg
  *ngIf="type == 'checkmark'"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M4.25684 10.8202L7.53889 14.1022L15.744 5.89709"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- refresh -->
<svg
  *ngIf="type == 'refresh'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M21.888 13.5C21.164 18.311 17.013 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C16.1 2 19.625 4.468 21.168 8"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M17 8H21.4C21.4788 8 21.5568 7.98448 21.6296 7.95433C21.7024 7.92417 21.7685 7.87998 21.8243 7.82426C21.88 7.76855 21.9242 7.70241 21.9543 7.62961C21.9845 7.55681 22 7.47879 22 7.4V3"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- timer -->
<svg
  *ngIf="type == 'timer'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M9 2H15"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12 10V14"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M12 22C14.1217 22 16.1566 21.1571 17.6569 19.6569C19.1571 18.1566 20 16.1217 20 14C20 11.8783 19.1571 9.84344 17.6569 8.34315C16.1566 6.84285 14.1217 6 12 6C9.87827 6 7.84344 6.84285 6.34315 8.34315C4.84285 9.84344 4 11.8783 4 14C4 16.1217 4.84285 18.1566 6.34315 19.6569C7.84344 21.1571 9.87827 22 12 22V22Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<svg
  *ngIf="type == 'page'"
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M2.66699 14.2663V1.73301C2.66699 1.62692 2.70913 1.52518 2.78415 1.45017C2.85916 1.37515 2.96091 1.33301 3.06699 1.33301H10.835C10.941 1.3331 11.0427 1.37531 11.1177 1.45034L13.2163 3.54901C13.2536 3.58627 13.2832 3.63055 13.3034 3.67929C13.3235 3.72803 13.3338 3.78027 13.3337 3.83301V14.2663C13.3337 14.3189 13.3233 14.3709 13.3032 14.4194C13.2831 14.4679 13.2536 14.512 13.2165 14.5492C13.1794 14.5863 13.1353 14.6158 13.0867 14.6359C13.0382 14.656 12.9862 14.6663 12.9337 14.6663H3.06699C3.01446 14.6663 2.96245 14.656 2.91392 14.6359C2.86539 14.6158 2.82129 14.5863 2.78415 14.5492C2.74701 14.512 2.71754 14.4679 2.69744 14.4194C2.67734 14.3709 2.66699 14.3189 2.66699 14.2663V14.2663Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M10.667 3.59925V1.56859C10.6671 1.52197 10.681 1.47643 10.7069 1.43769C10.7328 1.39896 10.7697 1.36878 10.8127 1.35095C10.8558 1.33312 10.9032 1.32845 10.9489 1.33752C10.9946 1.34659 11.0367 1.369 11.0697 1.40192L13.2643 3.59659C13.2976 3.62947 13.3203 3.6715 13.3295 3.71734C13.3388 3.76317 13.3342 3.81072 13.3163 3.85393C13.2984 3.89713 13.2681 3.93403 13.2291 3.95991C13.1902 3.9858 13.1444 3.99949 13.0977 3.99925H11.067C10.9609 3.99925 10.8592 3.95711 10.7841 3.8821C10.7091 3.80708 10.667 3.70534 10.667 3.59925Z"
    [attr.fill]="strokeColor"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M5.33398 6.66602H10.6673"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M5.33398 12H10.6673"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M5.33398 9.33301H8.00065"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<svg
  *ngIf="type == 'save-floppy-disk'"
  width="24"
  height="24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M3 19V5a2 2 0 0 1 2-2h11.172a2 2 0 0 1 1.414.586l2.828 2.828A2 2 0 0 1 21 7.828V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
  />
  <path
    d="M8.6 9h6.8a.6.6 0 0 0 .6-.6V3.6a.6.6 0 0 0-.6-.6H8.6a.6.6 0 0 0-.6.6v4.8a.6.6 0 0 0 .6.6ZM6 13.6V21h12v-7.4a.6.6 0 0 0-.6-.6H6.6a.6.6 0 0 0-.6.6Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
  />
</svg>

<svg
  width="24"
  height="24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  *ngIf="type == 'warning-circle-outlined'"
>
  <path
    d="M12 7v6M12 17.01l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<svg
  *ngIf="type == 'star-outline'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8.58724 8.23594L11.1852 3.00394C11.2609 2.85253 11.3772 2.72517 11.5211 2.63616C11.6651 2.54715 11.831 2.5 12.0002 2.5C12.1695 2.5 12.3354 2.54715 12.4793 2.63616C12.6233 2.72517 12.7396 2.85253 12.8152 3.00394L15.4132 8.23594L21.2212 9.07994C21.3888 9.10317 21.5464 9.17303 21.6761 9.28155C21.8058 9.39007 21.9024 9.53288 21.9549 9.69367C22.0073 9.85446 22.0135 10.0268 21.9727 10.1909C21.932 10.355 21.8458 10.5044 21.7242 10.6219L17.5222 14.6919L18.5142 20.4419C18.6412 21.1799 17.8612 21.7419 17.1942 21.3939L12.0002 18.6779L6.80524 21.3939C6.13924 21.7429 5.35924 21.1799 5.48624 20.4409L6.47824 14.6909L2.27624 10.6209C2.15522 10.5033 2.06964 10.3541 2.0292 10.1903C1.98877 10.0264 1.99511 9.85451 2.04751 9.69409C2.0999 9.53367 2.19625 9.39116 2.32561 9.28277C2.45496 9.17439 2.61212 9.10446 2.77924 9.08094L8.58724 8.23594V8.23594Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<svg
  width="24"
  height="24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  *ngIf="type == 'edit-pencil'"
>
  <path
    d="m13.02 5.828 4.95 4.95m-4.95-4.95L15.85 3l4.95 4.95-2.83 2.828-4.95-4.95Zm0 0-9.606 9.607a1 1 0 0 0-.293.707v4.536h4.536a1 1 0 0 0 .707-.293l9.606-9.607-4.95-4.95Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<!-- table-2-columns -->
<svg
  *ngIf="type === 'table-2-columns'"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M3 20.4V3.6C3 3.44087 3.06321 3.28826 3.17574 3.17574C3.28826 3.06321 3.44087 3 3.6 3H20.4C20.5591 3 20.7117 3.06321 20.8243 3.17574C20.9368 3.28826 21 3.44087 21 3.6V20.4C21 20.5591 20.9368 20.7117 20.8243 20.8243C20.7117 20.9368 20.5591 21 20.4 21H3.6C3.44087 21 3.28826 20.9368 3.17574 20.8243C3.06321 20.7117 3 20.5591 3 20.4V20.4Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
  />
  <path d="M3 16.5H21" stroke="white" stroke-width="1.5" />
  <path d="M3 12H21" stroke="white" stroke-width="1.5" />
  <path d="M21 7.5H3" stroke="white" stroke-width="1.5" />
  <path d="M12 21V3" stroke="white" stroke-width="1.5" />
</svg>

<svg
  *ngIf="type === 'add-user'"
  width="24"
  height="24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M17 10h3-3Zm6 0h-3 3Zm-3 0V7v3Zm0 0v3-3ZM1 20v-1a7 7 0 0 1 7-7v0a7 7 0 0 1 7 7v1H1Zm7-8a4 4 0 1 0 0-8 4 4 0 0 0 0 8v0Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<svg
  width="24"
  height="24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  *ngIf="type === 'link'"
>
  <path
    d="M14 11.998C14 9.506 11.683 7 8.857 7H7.143C4.303 7 2 9.238 2 11.998c0 2.378 1.71 4.368 4 4.873a5.3 5.3 0 0 0 1.143.124"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M10 11.998c0 2.491 2.317 4.997 5.143 4.997h1.714c2.84 0 5.143-2.237 5.143-4.997 0-2.379-1.71-4.37-4-4.874A5.304 5.304 0 0 0 16.857 7"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<svg
  *ngIf="type === 'remove-user'"
  width="24"
  height="25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="m18.621 12.621 2.122-2.121-2.122 2.121Zm4.243-4.242L20.743 10.5l2.121-2.121ZM20.743 10.5 18.62 8.379l2.123 2.121Zm0 0 2.121 2.121-2.121-2.121ZM1 20.5v-1a7 7 0 0 1 7-7v0a7 7 0 0 1 7 7v1H1Zm7-8a4 4 0 1 0 0-8 4 4 0 0 0 0 8v0Z"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="36"
  height="36"
  fill="none"
  *ngIf="type === 'verified-user'"
>
  <path
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
    d="M3 30v-1.5A10.5 10.5 0 0 1 13.5 18v0"
  />
  <path
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    d="M23.706 18.47a2.428 2.428 0 0 1 3.588 0 2.421 2.421 0 0 0 1.908.79 2.426 2.426 0 0 1 2.538 2.538c-.034.722.255 1.42.789 1.908a2.426 2.426 0 0 1 0 3.588 2.425 2.425 0 0 0-.789 1.908 2.427 2.427 0 0 1-2.538 2.538 2.428 2.428 0 0 0-1.908.789 2.426 2.426 0 0 1-3.588 0 2.428 2.428 0 0 0-1.908-.789 2.427 2.427 0 0 1-2.538-2.538 2.428 2.428 0 0 0-.79-1.908 2.425 2.425 0 0 1 0-3.588 2.421 2.421 0 0 0 .79-1.908 2.426 2.426 0 0 1 2.538-2.538 2.423 2.423 0 0 0 1.908-.79Z"
  />
  <path
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
    d="m23.046 25.5 1.635 1.635 3.273-3.27M13.5 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12v0Z"
  />
</svg>

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="36"
  height="36"
  fill="none"
  *ngIf="type === 'page-2'"
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    d="M6 32.1V3.9a.9.9 0 0 1 .9-.9h17.478a.9.9 0 0 1 .636.264l4.722 4.722a.9.9 0 0 1 .264.639V32.1a.9.9 0 0 1-.9.9H6.9a.9.9 0 0 1-.9-.9v0Z"
  />
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    d="M24 8.1V3.531a.53.53 0 0 1 .906-.375l4.938 4.938a.53.53 0 0 1-.375.906H24.9a.9.9 0 0 1-.9-.9v0ZM12 15h12M12 27h12M12 21h6"
  />
</svg>

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  *ngIf="type === 'cloud-download'"
>
  <path
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
    d="M12 13v9m0 0 3.5-3.5M12 22l-3.5-3.5m11.5-.893c1.494-.585 3-1.918 3-4.607 0-4-3.333-5-5-5 0-2 0-6-6-6S6 6 6 8c-1.667 0-5 1-5 5 0 2.689 1.506 4.022 3 4.607"
  />
</svg>

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="46"
  height="46"
  fill="none"
  *ngIf="type === 'enlarge-round-arrow'"
>
  <path
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
    d="M16.292 18.209 11.5 23l4.792 4.792m13.416-9.584L34.5 23l-4.792 4.792"
  />
  <path
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    d="M3.833 28.75v-11.5A7.667 7.667 0 0 1 11.5 9.584h23a7.667 7.667 0 0 1 7.667 7.666v11.5a7.667 7.667 0 0 1-7.667 7.667h-23a7.667 7.667 0 0 1-7.667-7.667Z"
  />
</svg>

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  fill="none"
  *ngIf="type === 'enlarge-round-arrow-small'"
>
  <path
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
    d="M8.5 9.5 6 12l2.5 2.5m7-5L18 12l-2.5 2.5"
  />
  <path
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    d="M2 15V9a4 4 0 0 1 4-4h12a4 4 0 0 1 4 4v6a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4Z"
  />
</svg>

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  fill="none"
  *ngIf="type === 'add-circled-outline'"
>
  <path
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
    d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
  />
  <path
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
    d="M12 12v4m-4-4h4-4Zm8 0h-4 4Zm-4 0V8v4Z"
  />
</svg>

<!-- remove-link -->
<svg
  xmlns="http://www.w3.org/2000/svg"
  width="36"
  height="36"
  fill="none"
  *ngIf="type === 'remove-link'"
>
  <path
    stroke="#B1B7BE"
    stroke-linecap="round"
    stroke-linejoin="round"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    d="M10.714 25.492A8.06 8.06 0 0 1 9 25.308c-3.435-.76-6-3.744-6-7.311 0-4.071 3.339-7.385 7.5-7.494m9.477 3.948A8.276 8.276 0 0 0 16.5 11.25"
  />
  <path
    stroke="#B1B7BE"
    stroke-linecap="round"
    stroke-linejoin="round"
    [attr.stroke]="strokeColor"
    [attr.stroke-width]="strokeWidth"
    d="M25.285 10.5c.59 0 1.163.065 1.715.186 3.435.758 6 3.742 6 7.311 0 4.14-3.453 7.495-7.715 7.495h-2.57c-4.24 0-7.715-3.759-7.715-7.495 0 0 0-1.497.75-2.247M4.5 4.5l27 27"
  />
</svg>
