import { Component, Input, OnInit } from "@angular/core";
import { User } from "@ttc_types/types";

@Component({
  selector: "app-zusage-absage-toggle",
  templateUrl: "./zusage-absage-toggle.component.html",
  styleUrls: ["./zusage-absage-toggle.component.scss"],
})
export class ZusageAbsageToggleComponent implements OnInit {
  @Input()
  a!: User;

  constructor() {}

  ngOnInit(): void {}
}
