import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { animate, style, transition, trigger } from "@angular/animations";

@Component({
  selector: "app-data-collection-wrapper",
  templateUrl: "./data-collection-wrapper.component.html",
  styleUrls: ["./data-collection-wrapper.component.scss"],
  animations: [
    trigger("fade", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
        // style({ 'flex-grow': 0.1 }), animate(500, style({ 'flex-grow': 1 }))
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(500, style({ opacity: 0 })),
        // style({ 'flex-grow': 1 }), animate(500, style({ 'flex-grow': 0.1 }))
      ]),
    ]),
  ],
})
export class DataCollectionWrapperComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @Input()
  collapsedBg: string = "white";

  // collapsedBg: {[key: number]: string}= {
  //   0: `linear-gradient(0deg, rgba(60, 75, 93, 0.1), rgba(60, 75, 93, 0.1)), #FFFFFF`,
  //   1: `linear-gradient(0deg, rgba(60, 75, 93, 0.2), rgba(60, 75, 93, 0.2)), #FFFFFF`,
  //   2: `linear-gradient(0deg, rgba(60, 75, 93, 0.3), rgba(60, 75, 93, 0.1)), #FFFFFF`
  // }

  @Input()
  title: string = "";

  @Input()
  collapsed: boolean = false;

  @Input()
  index: number = 0;

  // @Input()
  // continueButtonTxt: string = ""

  // @Input()
  // continueButtonShow: boolean = false;

  @Input()
  titleNoPaddingBottom: boolean = false;

  @Output()
  titleClicked = new EventEmitter<boolean>();

  // buttonClicked(){
  //   console.log('buttonClicked')
  //   this.continueButtonClicked.emit(true)
  // }
}
