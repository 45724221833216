<div
  class="data-collection-wrapper"
  [ngStyle]="{ background: collapsed ? collapsedBg : 'white' }"
>
  <h4
    class="primary main-title"
    [ngClass]="{ 'no-padding-bottom': titleNoPaddingBottom }"
    (click)="titleClicked.emit(true)"
  >
    {{ title }}
  </h4>
  <div class="collapsable-content" @fade *ngIf="!collapsed">
    <ng-content></ng-content>
  </div>
</div>
