import { filter, Subscription } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";
import { DataService } from "src/app/data.service";
import { Component, OnInit } from "@angular/core";
// import * as dayjs from "dayjs";
import { dayjs } from "src/app/shared/date-util";
import { Application } from "@ttc_types/types";

export type Category = {
  count: number;
  name: string;
  routerLink: string;
  applications: Application[];
};

@Component({
  selector: "page-meine-jobs",
  templateUrl: "./meine-jobs.component.html",
  styleUrls: ["./meine-jobs.component.scss"],
  host: { class: "host-full-width" },
})
export class MeineJobsComponent implements OnInit {
  routerSubscription: Subscription | undefined;

  constructor(private data: DataService, public router: Router) {}

  current_jobs: Application[] = [];

  // next_job:Application | undefined = undefined
  next_jobs: Application[] = [];

  past_jobs: Application[] = [];

  applications: Application[] = [];

  navigateTo(cat: Category) {
    this.router.navigateByUrl(cat.routerLink);
  }

  public categories: Category[] = [];

  public current_detail_cat: Category | undefined;

  jobs: Application[] = [];

  async ngOnInit() {
    const jobs = await this.data.getMyJobs();

    // eslint-disable-next-line no-constant-condition
    if (false && this.data.debug) {
      // laufender job
      // jobs.push({
      //   id: 1,
      //   // "created_at": "2022-07-11T08:43:35.895+00:00",
      //   // "updated_at": "2022-07-11T08:43:35.895+00:00",
      //   role: "arbeitskraft",
      //   status: "erledigt_verbindlich",
      //   // "user_id": 3,
      //   // "project_id": 1,
      //   project: {
      //     id: 1,
      //     titel: "Mercedes-Benz GTE Köln",
      //     aktionsinhalt: "aktionsinhalt",
      //     anforderungsprofil: "anforderungsprofil",
      //     branche: "Auto/Motoräder",
      //     zeitraum_von: dayjs().subtract(1, "day").toDate(),
      //     zeitraum_bis: dayjs().add(1, "day").toDate(),
      //     zeiten: [
      //       {
      //         zeit_von: "13:13",
      //         zeit_bis: "15:15",
      //       } as Zeit,
      //     ],
      //     honorar: {
      //       honorar_auf_anfrage: true,
      //       honorar_eur: 13,
      //     },
      //     aktions_standorte: "aktions_standorte",
      //     published: true,
      //     // "created_at": dayjs().subtract(1, 'day').toDate(),
      //     // "updated_at": dayjs().subtract(1, 'day').toDate()
      //   },
      // });
      // nächster job
    }

    this.jobs = jobs;
    for (const job of jobs) {
      if (job.project) {
        if (dayjs(job.project.zeitraum_bis).isBefore(dayjs())) {
          this.past_jobs.push(job);
        } else if (job.status == "erledigt_verbindlich") {
          if (
            dayjs().isAfter(dayjs(job.project.zeitraum_von)) &&
            dayjs().isBefore(dayjs(job.project.zeitraum_bis))
          ) {
            this.current_jobs.push(job);
          } else if (dayjs().isBefore(job.project.zeitraum_von)) {
            this.next_jobs.push(job);
          }
        } else {
          this.applications.push(job);
        }
      }
    }

    this.next_jobs.sort((a, b) => {
      const result = dayjs(a.project?.zeitraum_von || new Date(0)).isBefore(
        b.project?.zeitraum_von || new Date(0)
      );
      return result ? -1 : 1;
    });

    const sortApplications = (
      applications: Application[],
      direction: "asc" | "desc" = "asc"
    ) => {
      applications.sort((a, b) => {
        const result = dayjs(a.project?.zeitraum_von || new Date(0)).isBefore(
          b.project?.zeitraum_von || new Date(0)
        );
        if (direction === "asc") {
          return result ? -1 : 1;
        }
        return result ? 1 : -1;
      });
    };
    sortApplications(this.applications, "asc");
    sortApplications(this.next_jobs, "asc");
    sortApplications(this.past_jobs, "desc");

    this.categories = [
      {
        count: this.applications.length,
        name: "Bewerbungen",
        routerLink: "/meine-jobs/bewerbungen",
        applications: this.applications,
      },
      {
        count: this.next_jobs.length,
        name: "Bevorstehende Jobs",
        routerLink: "/meine-jobs/bevorstehende-jobs",
        applications: this.next_jobs,
      },
      {
        count: this.past_jobs.length,
        name: "Vergangene Jobs",
        routerLink: "/meine-jobs/vergangene-jobs",
        applications: this.past_jobs,
      },
    ];

    console.log(`this.categories`, this.categories);

    console.log(`current_jobs`, this.current_jobs);
    console.log(`next_jobs`, this.next_jobs);
    console.log(`past_jobs`, this.past_jobs);
    console.log(`applications`, this.applications);

    console.log(`jobs`, this.jobs);
    switch (this.router.url) {
      case "/meine-jobs/bewerbungen":
        // eslint-disable-next-line prefer-destructuring
        this.current_detail_cat = this.categories[0];
        break;
      case "/meine-jobs/bevorstehende-jobs":
        // eslint-disable-next-line prefer-destructuring
        this.current_detail_cat = this.categories[1];
        break;
      case "/meine-jobs/vergangene-jobs":
        // eslint-disable-next-line prefer-destructuring
        this.current_detail_cat = this.categories[2];
        break;
      default:
        break;
    }

    this.routerSubscription = this.router.events
      .pipe(filter((el) => el instanceof NavigationEnd))
      .subscribe((event) => {
        // {path: 'bewerbungen', component: MeineJobsComponent},
        // {path: 'bevorstehende-jobs', component: MeineJobsComponent},
        // {path: 'vergangene-jobs', component: MeineJobsComponent},
        const { url } = event as NavigationEnd;
        console.log("routerSubscription url: ", url);
      });
  }

  ngOnDestroy() {
    if (this.routerSubscription) this.routerSubscription.unsubscribe();
  }
}
