<div class="infos">
  <form class="two-cols" [formGroup]="infoForm">
    <div class="left">
      <div>
        <h5>Aktionsinhalt</h5>
        <!-- <textarea
          name="aktionsinhalt"
          id="aktionsinhalt"
          rows="20"
          formControlName="aktionsinhalt"
        ></textarea> -->
        <!-- <quill-editor formControlName="aktionsinhalt"></quill-editor> -->
        <app-editor
          [html]="infoForm.controls['aktionsinhalt'].value"
          (htmlChange)="infoForm.controls['aktionsinhalt'].setValue($event)"
        ></app-editor>
        <div
          *ngIf="infoForm.get('aktionsinhalt')?.invalid && submitAttempt"
          class="alert"
        >
          Bitte gib einen Aktionsinhalt an.
        </div>
      </div>
      <div>
        <h5>Anforderungsprofil</h5>
        <!-- <textarea
          name="anforderungsprofil"
          id="anforderungsprofil"
          rows="20"
          formControlName="anforderungsprofil"
        ></textarea> -->
        <!-- <quill-editor formControlName="anforderungsprofil"></quill-editor> -->
        <app-editor
          [html]="infoForm.controls['anforderungsprofil'].value"
          (htmlChange)="
            infoForm.controls['anforderungsprofil'].setValue($event)
          "
        ></app-editor>
        <div
          *ngIf="infoForm.get('anforderungsprofil')?.invalid && submitAttempt"
          class="alert"
        >
          Bitte gib ein Anforderungsprofil an.
        </div>
      </div>
    </div>
    <div class="right">
      <div>
        <h5>Branche</h5>
        <select name="branche" id="branche" formControlName="branche">
          <option value=""></option>
          <option *ngFor="let branche of branchen" [ngValue]="branche">
            {{ branche }}
          </option>
        </select>
        <div
          *ngIf="infoForm.get('branche')?.invalid && submitAttempt"
          class="alert"
        >
          Bitte gib eine Branche an.
        </div>
      </div>
      <div>
        <h5>Zeitraum</h5>
        <input
          type="text"
          ngxDaterangepickerMd
          [(ngModel)]="zeitraum_von_bis"
          [ngModelOptions]="{ standalone: true }"
          [linkedCalendars]="true"
          [autoApply]="true"
          (change)="updateForm()"
        />

        <input
          hidden
          type="date"
          name="zeitraum_von"
          id="zeitraum_von"
          formControlName="zeitraum_von"
        />
        <input
          hidden
          type="date"
          name="zeitraum_bis"
          id="zeitraum_bis"
          formControlName="zeitraum_bis"
        />
        <div
          *ngIf="
            (infoForm.get('zeitraum_von')?.invalid ||
              infoForm.get('zeitraum_bis')?.invalid) &&
            submitAttempt
          "
          class="alert"
        >
          Bitte gib einen Zeitraum an.
        </div>
      </div>
      <div class="zeiten-wrapper" *ngIf="zeiten && zeiten.length">
        <div
          formArrayName="zeiten"
          *ngFor="let zeit of zeiten; let i = index"
          class="zeiten"
        >
          <div class="zeit">
            <input
              type="time"
              [(ngModel)]="zeit.zeit_von"
              name="zeit_von[]"
              [ngModelOptions]="{ standalone: true }"
              (change)="updateForm()"
            />
            <p class="p-light flex-center white">bis</p>
            <input
              type="time"
              [(ngModel)]="zeit.zeit_bis"
              name="zeit_bis[]"
              [ngModelOptions]="{ standalone: true }"
              (change)="updateForm()"
            />
          </div>
          <div
            class="removeButton flex-center"
            (click)="removeZeit(i)"
            *ngIf="zeiten.length > 1"
          >
            <app-svgs
              type="remove"
              color="white"
              class="removeButton"
            ></app-svgs>
          </div>
        </div>
        <app-button
          (click)="addZeit()"
          [lessHeight]="true"
          color="white"
          iconRight="plus"
          >Weiteres Zeitfenster</app-button
        >
        <div
          *ngIf="infoForm.get('zeiten')?.invalid && submitAttempt"
          class="alert"
        >
          Bitte gib mindestens einen Zeitraum an.
        </div>
      </div>
      <div>
        <h5>Honorar</h5>
        <div class="honorar" formGroupName="honorar">
          <div class="eur-std">
            <input
              type="number"
              oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
              name="honorar_eur"
              id="honorar_eur"
              min="0"
              formControlName="honorar_eur"
            />
          </div>
          <label for="auf_anfrage" class="checkbox white">
            <input
              type="checkbox"
              name="honorar_auf_anfrage"
              id="honorar_auf_anfrage"
              formControlName="honorar_auf_anfrage"
            />
            Auf Anfrage
          </label>
        </div>
        <div
          *ngIf="infoForm.get('honorar')?.invalid && submitAttempt"
          class="alert"
        >
          Bitte gib ein Honorar an.
        </div>
      </div>
      <div>
        <h5>Aktions-Standorte</h5>
        <!-- <quill-editor formControlName="aktions_standorte"></quill-editor> -->
        <app-editor
          [html]="infoForm.controls['aktions_standorte'].value"
          (htmlChange)="infoForm.controls['aktions_standorte'].setValue($event)"
        ></app-editor>
        <!-- <textarea
          name="aktions_standorte"
          id="aktions_standorte"
          rows="10"
          formControlName="aktions_standorte"
        ></textarea> -->
        <div
          *ngIf="infoForm.get('aktions_standorte')?.invalid && submitAttempt"
          class="alert"
        >
          Bitte gib einen Aktionsstandort an.
        </div>
      </div>
    </div>
  </form>
</div>
