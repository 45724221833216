<div class="zulagen">
  <h4>Zulagen</h4>
  <div class="cols">
    <div class="col">
      <div class="separator">
        <div>
          <p class="subscription">Briefing 30</p>
          <div class="cm-wrap eur">
            <input
              type="number"
              step="0.01"
              min="0"
              (ngModelChange)="saveZulagenClickedSubject.next(true)"
              [(ngModel)]="zulagen.Briefing_eur"
            />
          </div>
        </div>
        <div>
          <p class="subscription">Bonus 32</p>
          <div class="cm-wrap eur">
            <input
              type="number"
              step="0.01"
              min="0"
              (ngModelChange)="saveZulagenClickedSubject.next(true)"
              [(ngModel)]="zulagen.Bonus_eur"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="separator">
        <div>
          <p class="subscription">Freunde Bonus 300</p>
          <div class="cm-wrap eur">
            <input
              type="number"
              step="0.01"
              min="0"
              (ngModelChange)="saveZulagenClickedSubject.next(true)"
              [(ngModel)]="zulagen.Freunde_Bonus_eur"
            />
          </div>
        </div>
        <div>
          <p class="subscription">Durchhalte Bonus 31</p>
          <div class="cm-wrap eur">
            <input
              type="number"
              step="0.01"
              min="0"
              (ngModelChange)="saveZulagenClickedSubject.next(true)"
              [(ngModel)]="zulagen.Durchhaltebonus_eur"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="separator">
        <div>
          <p class="subscription">Fahrtkosten 11</p>
          <div class="cm-wrap eur">
            <input
              type="number"
              step="0.01"
              min="0"
              (ngModelChange)="saveZulagenClickedSubject.next(true)"
              [(ngModel)]="zulagen.Fahrkosten_eur"
            />
          </div>
        </div>
        <div>
          <p class="subscription">Vorschuss 9079</p>
          <div class="cm-wrap eur">
            <input
              type="number"
              step="0.01"
              min="0"
              (ngModelChange)="saveZulagenClickedSubject.next(true)"
              [(ngModel)]="zulagen.Vorschuss_eur"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="separator">
        <div>
          <p class="subscription">Auslagen für</p>
          <div class="">
            <input
              type="text"
              (ngModelChange)="saveZulagenClickedSubject.next(true)"
              [(ngModel)]="zulagen.Auslage_fuer_description"
            />
          </div>
        </div>
        <div>
          <p class="subscription">Auslagen 9029</p>
          <div class="cm-wrap eur">
            <input
              type="number"
              step="0.01"
              min="0"
              (ngModelChange)="saveZulagenClickedSubject.next(true)"
              [(ngModel)]="zulagen.Auslage_fuer_eur"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <p class="subscription">Kilometergeld 9978</p>
      <div class="cm-wrap eur">
        <input
          type="number"
          step="0.01"
          min="0"
          (ngModelChange)="saveZulagenClickedSubject.next(true)"
          (ngModelChange)="saveZulagenClickedSubject.next(true)"
          [(ngModel)]="zulagen.Kilometergeld_eur"
        />
      </div>
    </div>
    <div class="col">
      <div class="separator">
        <div>
          <p class="subscription">Nachtzuschlag 503</p>
          <div class="cm-wrap h">
            <!-- Important Nachtzuschlag is now in hours! -->
            <input
              type="number"
              step="0.01"
              min="0"
              (ngModelChange)="saveZulagenClickedSubject.next(true)"
              [(ngModel)]="zulagen.Nachtzuschlag_eur"
            />
          </div>
        </div>
        <div>
          <p class="subscription">Spesen 9979</p>
          <div class="cm-wrap eur">
            <input
              type="number"
              step="0.01"
              min="0"
              (ngModelChange)="saveZulagenClickedSubject.next(true)"
              [(ngModel)]="zulagen.Spesen_eur"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="summe">
    <div>Summe</div>
    <div class="flex flex-center-vertical gap-5">
      <span>{{ getSumme }}</span> <span class="eur">€</span>
    </div>
  </div>
  <div class="cols margin-top-20">
    <div class="col">
      <p class="subscription">Aufschlag Gehalt</p>
      <div class="separator">
        <div class="cm-wrap eur">
          <input
            type="number"
            name=""
            id=""
            placeholder="Aufschlag"
            (ngModelChange)="saveZulagenClickedSubject.next(true)"
            [(ngModel)]="zulagen.Aufschlag_h_eur"
          />
        </div>
        <p class="subscription">Aufschlag auf den regulären Lohn pro Stunde.</p>
      </div>
    </div>
    <div class="col">
      <p class="subscription">Tagessatz</p>
      <div class="separator">
        <div class="cm-wrap eur">
          <input
            type="number"
            name=""
            id=""
            placeholder="Tagessatz"
            (ngModelChange)="saveZulagenClickedSubject.next(true)"
            [(ngModel)]="zulagen.Tagessatz_eur"
          />
        </div>
        <p class="subscription">
          Der Stundenlohn wird durch den Tagessatz ersetzt, wenn > 0.
        </p>
      </div>
    </div>
  </div>
</div>
