import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import * as Sentry from "@sentry/angular";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  Sentry.init({
    dsn: "https://34ec5e1f68184176a5da46fb22da43ce@o939555.ingest.sentry.io/6770469",
    integrations: [
      Sentry.browserApiErrorsIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ["error"],
      }),
    ],
    tracesSampleRate: 0.3,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
