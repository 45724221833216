<div class="plus-minus-input">
  <div class="minus" (click)="increment(false)">
    <app-svgs type="minus" color="secondary"></app-svgs>
  </div>
  <div class="input-wrapper" [ngClass]="{ disabled: disabled }">
    <input
      *ngIf="initialized && internalFormControl"
      class="flex-center-vertical"
      [ngStyle]="{ 'text-align': unit ? 'right' : 'center' }"
      type="number"
      [step]="step"
      [disabled]="disabled"
      [min]="min"
      [max]="max"
      [formControl]="internalFormControl"
    />
    <div class="subscription flex-center-vertical" *ngIf="unit">
      {{ unit }}
    </div>
  </div>
  <div class="plus" (click)="increment(true)">
    <app-svgs type="plus" color="secondary"></app-svgs>
  </div>
</div>
