import { Injectable, OnInit } from "@angular/core";
import { BehaviorSubject, firstValueFrom, Subject, throwError } from "rxjs";
import { Notify } from "notiflix";

import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { User } from "@ttc_types/types";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnInit {
  endpoint: string = environment.apiUrl;

  headers = new HttpHeaders().set("Content-Type", "application/json");

  public currentUser: User | null = null;

  public currentUserObs: Subject<null | User> =
    new BehaviorSubject<null | User>(null);

  constructor(private http: HttpClient, public router: Router) {}

  async ngOnInit() {
    console.log(`ngOnInit`);

    if (this.isLoggedIn && !this.currentUser) {
      await this.setUserProfile();
    }
  }

  // Sign-up
  async signUp(user: User): Promise<any> {
    console.log(`user`, user);

    let res;
    try {
      res = await firstValueFrom(
        this.http.post<any>(`${this.endpoint}/signup`, user)
      );
      console.log(`res`, res);

      if (res.errors && res.errors.length) {
        throw new Error("invalid credentials");
      }
      localStorage.setItem("access_token", res.token);
      await this.setUserProfile();
      return true;
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        Notify.failure("Der User existiert bereits.");
      } else {
        Notify.failure("Etwas ist schief gelaufen.");
      }
      console.error(error);
      return false;
    }
  }

  // Sign-in
  async signIn(user: User, noRoute = false) {
    let res;
    try {
      res = await firstValueFrom(
        this.http.post<any>(`${this.endpoint}/signin`, user)
      );
      if (res.errors && res.errors.length) {
        throw new Error("invalid credentials");
      }
    } catch (error) {
      Notify.failure("Bitte überprüfe deine Eingabe.");
      console.error(error);
      return false;
    }
    console.log(`signIn res`, res);
    localStorage.setItem("access_token", res.token);

    try {
      await this.setUserProfile();
    } catch (error) {
      console.error(`error`, error);
      return false;
    }

    if (noRoute) {
      return true;
    }

    // check if auth.guard saved url
    const can_activate_route = localStorage.getItem("can_activate_route");
    console.log(`can_activate_route`, can_activate_route);
    if (can_activate_route) {
      if (user.role == "pm" && can_activate_route.includes("meine-jobs")) {
        this.router.navigateByUrl("meine-projekte");
      }
      this.router.navigateByUrl(can_activate_route);
      return true;
    }

    // else route by role
    const currUsr = this.currentUserFnc;
    if (currUsr) {
      switch (currUsr.role) {
        case "regular":
          this.router.navigateByUrl("meine-jobs");
          break;
        case "pm":
          this.router.navigateByUrl("meine-projekte");
          break;
        case "superadmin":
          this.router.navigateByUrl("meine-projekte");
          break;
        default:
          break;
      }
    }
    return true;
  }

  getToken() {
    return localStorage.getItem("access_token");
  }

  get isLoggedIn(): boolean {
    const authToken = localStorage.getItem("access_token");
    return authToken !== null;
  }

  async doLogout() {
    try {
      const api = `${this.endpoint}/logout`;
      const res = await firstValueFrom(
        this.http.post(api, { headers: this.headers })
      );
      console.log(`doLogout res`, res);
    } catch (error) {
      console.log(`doLogout api error`, error);
    }
    const removeToken = localStorage.removeItem("access_token");
    this.currentUser = null;
    this.currentUserObs.next(null);
    localStorage.removeItem("current_user");

    if (removeToken == null) {
      this.router.navigate(["/"]);
    }
  }

  // User profile
  async setUserProfile(): Promise<User | any> {
    if (!localStorage.getItem("access_token")) {
      return;
    }
    const api = `${this.endpoint}/user`;
    const user_response = await firstValueFrom(
      this.http.get(api, { headers: this.headers })
    );
    console.log(`user_response`, user_response);
    this.currentUser = user_response as User;

    console.log(`this.currentUser`, this.currentUser);
    this.currentUserObs.next(this.currentUser);
    localStorage.setItem("current_user", JSON.stringify(this.currentUser));
  }

  get currentUserFnc(): User | null {
    if (this.currentUser) {
      return this.currentUser;
    }
    if (
      !this.currentUser &&
      localStorage.getItem("current_user") &&
      this.isLoggedIn
    ) {
      const user = JSON.parse(
        <string>localStorage.getItem("current_user")
      ) as User;
      console.log(`currentUserFnc user`, user);
      this.currentUser = user;
      this.currentUserObs.next(user);
      return this.currentUser;
    }
    return null;
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let msg = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }
}
