<div class="two-cols" [ngClass]="{ deactivated: !user.activated }">
  <div class="left">
    <label class="subscription"
      ><span *ngIf="!review_id">Neue Erfahrung schreiben</span
      ><span *ngIf="review_id && review_date"
        >Eintrag vom {{ review_date | date : "dd.MM.YYYY" }} bearbeiten</span
      ></label
    >
    <div>
      <textarea cols="30" rows="10" [(ngModel)]="review_text"></textarea>
    </div>
    <h4>Projekt</h4>
    <ng-select [(ngModel)]="project_id" placeholder="kein Projekt">
      <ng-option *ngFor="let project of projects" [value]="project?.id">
        {{ project?.zeitraum_von | date : "YYYY" || "" }},
        {{ project?.titel || "" }}
      </ng-option>
    </ng-select>
    <div class="buttons margin-top-24">
      <div class="left-buttons"></div>
      <div class="right-buttons">
        <app-button
          [lessHeight]="true"
          color="primary80"
          background="primary10"
          (click)="uploadReview()"
          [disabled]="isUploading"
          [isLoading]="isUploading"
          ><span *ngIf="!review_id">Neuen Eintrag speichern</span
          ><span *ngIf="review_id">Änderung speichern</span></app-button
        >
      </div>
    </div>
  </div>
  <div class="right">
    <div class="erfahrungen-liste" *ngIf="reviews">
      <div class="erfahrungen-element" *ngFor="let review of reviews">
        <div class="veranstaltung subscription">
          <span *ngIf="!review.project">kein Projekt</span>
          <span *ngIf="review.project">
            {{ review.project.zeitraum_von | date : "YYYY" || "" }},
            {{ review.project.titel || "" }}
          </span>
          <div class="action-buttons-wrapper">
            <app-button
              (click)="editReview(review)"
              class="edit"
              [onlyIcon]="true"
              [smallerIcon]="true"
              color="secondary"
              iconRight="edit-pencil"
            ></app-button>
            <app-button
              (click)="deleteReview(review)"
              class="delete"
              iconRight="trash"
              color="fail"
              [onlyIcon]="true"
              [lessHeight]="true"
              [smallerIcon]="true"
            ></app-button>
          </div>
        </div>
        <div class="verfasser subscription">
          <span
            >{{ review.review_date | date : "dd.MM.YYYY" || "" }} erstellt</span
          >
          <span *ngIf="review.reviewer">
            von
            {{
              review.reviewer?.profile?.vorname +
                " " +
                review.reviewer?.profile?.nachname
            }}</span
          >
          <div class="last-edit" *ngIf="review.last_edit_date">
            <span
              >zuletzt bearbeitet
              {{ review.last_edit_date | date : "dd.MM.YYYY" }}
            </span>
            <span *ngIf="review.last_editor">
              von
              {{
                review.last_editor?.profile?.vorname +
                  " " +
                  review.last_editor?.profile?.nachname
              }}
            </span>
          </div>
        </div>
        <p style="white-space: pre-wrap">
          {{ review.review_text }}
        </p>
      </div>
    </div>
  </div>
</div>
<div class="buttons">
  <div class="left-buttons">
    <app-button
      [lessHeight]="true"
      color="primary80"
      background="primary10"
      (click)="deleteUser.emit()"
      [disabled]="isUploading"
      [isLoading]="isUploading"
    >
      Profil löschen
    </app-button>
    <app-button
      *ngIf="user.activated"
      [lessHeight]="true"
      color="primary80"
      background="primary10"
      (click)="deaktivieren()"
      [disabled]="isUploading"
      [isLoading]="isUploading"
    >
      deaktivieren
    </app-button>
  </div>
  <div class="right-buttons">
    <app-button
      *ngIf="!user.activated"
      [lessHeight]="true"
      color="primary80"
      background="primary10"
      (click)="aktivieren()"
      [disabled]="isUploading"
      [isLoading]="isUploading"
    >
      aktivieren
    </app-button>
  </div>
</div>
