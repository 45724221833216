<div class="time-to">
  <div class="time-to-outer">
    <div
      class="time-to-inner"
      [ngStyle]="{
        'flex-basis': 'calc(' + percent + '% - 4px)'
      }"
    ></div>
  </div>
</div>
