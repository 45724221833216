import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Toggle } from "@ttc_types/types";
import { filter, Subscription } from "rxjs";
// import * as dayjs from "dayjs";
import { dayjs } from "src/app/shared/date-util";
import { Confirm, Notify } from "notiflix";
import { DataService } from "../../../data.service";

@Component({
  selector: "page-edit",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
  host: { class: "host-full-width" },
})
export class EditComponent implements OnInit {
  editProjectTitle: boolean = false;

  projektEinstellungsModalOpen: boolean = (this.data.debug && false) || false;

  projekt_id: number | null = null;

  activeToggleIndex: number = 0;

  finishedLoading: boolean = false;

  ansprechpersonVorname: string = "";

  ansprechpersonNachname: string = "";

  aktionenDropdown: boolean = false;

  baseUrl: string = "";

  gesendetString: string = "";

  constructor(
    private route: ActivatedRoute,
    public data: DataService,
    private router: Router
  ) {}

  routerSubscription: Subscription | undefined;

  async openLinkInNewTab(url: string) {
    window.open(url, "_blank");
  }

  public handleChildRoutes() {
    if (
      this.route.children &&
      this.route.children[0].snapshot &&
      this.route.children[0].snapshot.routeConfig &&
      this.route.children[0].snapshot.routeConfig.path
    ) {
      const childPath = this.route.children[0].snapshot.routeConfig.path;
      console.log(`childPath`, childPath);
      if (childPath === "infos") {
        this.activeToggleIndex = 0;
        this.editProjectTitle = true;
      } else if (childPath === "ablauf") {
        this.activeToggleIndex = 1;
        this.editProjectTitle = false;
      } else if (childPath === "personal") {
        this.activeToggleIndex = 2;
        this.editProjectTitle = false;
      } else if (childPath === "zeiterfassung") {
        this.activeToggleIndex = 3;
        this.editProjectTitle = false;
      }
    }
  }

  param$?: Subscription;

  async ngOnInit() {
    this.param$ = this.route.paramMap.subscribe(async (params) => {
      this.projekt_id = params.get("id") ? Number(params.get("id")) : null;
      console.log("this.projekt_id: ", this.projekt_id);
      this.handleChildRoutes();
      if (!this.routerSubscription) {
        this.routerSubscription = this.router.events
          .pipe(filter((el) => el instanceof NavigationEnd))
          .subscribe((event) => {
            console.log(`event`, event);
            this.handleChildRoutes();
          });
      }
      console.log(`projekt_id`, this.projekt_id);
      if (this.projekt_id) {
        this.data.currentProject = await this.data.getProjectById(
          this.projekt_id,
          true
        );
        console.log(`this.data.currentProject`, this.data.currentProject);
        this.data.currentProjectTitle = this.data.currentProject?.titel || "";
        this.data.currentProjectInternalTitle =
          this.data.currentProject?.internal_title || "";
        this.data.currentProjectPublished =
          this.data.currentProject?.published || false;

        this.ansprechpersonVorname =
          this.data.currentProject?.ansprechperson?.user?.profile?.vorname ||
          "";
        this.ansprechpersonNachname =
          this.data.currentProject?.ansprechperson?.user?.profile?.nachname ||
          "";

        this.emitEvent("updateInfoForm");

        // this.editProjectTitle = true;
      } else {
        this.data.currentProjectTitle = "";
        this.data.currentProjectInternalTitle = "";
        this.data.currentProjectPublished = false;
        // this.editProjectTitle = true;
      }

      this.finishedLoading = true;
      this.toggles = [
        {
          label: "Infos",
          icon: "infos",
          routerLink: "infos",
        },
        {
          label: "Ablauf",
          icon: "tasks",
          routerLink: "ablauf",
          disabled: this.projekt_id == null,
        },
        {
          label: "Personal",
          icon: "tasks",
          routerLink: "personal",
          disabled: this.projekt_id == null,
        },
        {
          label: "Zeiterfassung",
          icon: "clock",
          routerLink: "zeiterfassung",
          disabled: this.projekt_id == null,
        },
      ];

      if (this.data.currentProject?.published) {
        if (!this.data.debug && true) {
          this.router.navigate(["personal"], { relativeTo: this.route });
        }
      }
      if (this.data.currentProject?.project_uuid) {
        this.baseUrl = `${window.location.protocol}//${window.location.host}/auswahl/${this.data.currentProject?.project_uuid}`;
      }

      if (
        this.data.currentProject?.link_already_sent_once &&
        this.data.currentProject?.link_already_sent_once_date
      ) {
        this.gesendetString = `<b>Kundenlink</b><br> ${dayjs(
          this.data.currentProject?.link_already_sent_once_date
        ).format("DD.MM.YYYY HH:mm")}`;
      } else if (this.data.currentProject?.link_already_sent_once) {
        this.gesendetString = "<b>Kundenlink</b><br>bereits gesendet";
      } else {
        this.gesendetString = "<b>Kundenlink</b><br>Vorschau";
      }
    });
  }

  ngOnDestroy(): void {
    this.data.currentProject = undefined;
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.param$) {
      this.param$.unsubscribe();
    }
  }

  toggles: Toggle[] = [];

  toggleSwitched(toggleIndex: number) {
    console.log("toggleSwitched toggleIndex: ", toggleIndex);
    this.activeToggleIndex = toggleIndex;
  }

  emitEvent(type: string) {
    this.data.eventBus.next(type);
  }

  async duplicateProject() {
    try {
      const conf_res = await new Promise((res) => {
        Confirm.show(
          "Projekt duplizieren",
          "Möchtest du das Projekt wirklich duplizieren?",
          "Nein",
          "Ja",
          () => {
            res(false);
          },
          () => {
            res(true);
          }
        );
      });
      if (!conf_res) return;
      this.data.spinner(true);
      const newProject = await this.data.postRequest(
        "/project/duplicate_project",
        {
          project_id: this.data.currentProject?.id,
        }
      );

      if (newProject) {
        // setTimeout IS NECESSARY -> super strange behaviour
        setTimeout(() => {
          this.data.spinner(false);
          Confirm.show(
            "Projekt dupliziert",
            "Das Projekt wurde erfolgreich dupliziert. Möchtest du es jetzt bearbeiten? Nicht gespeicherte Änderungen gehen verloren.",
            "Nein",
            "Ja",
            () => {},
            () => {
              this.router.navigate([`/meine-projekte/edit/${newProject.id}`]);
            },
            {
              messageMaxLength: 20000,
            }
          );
        }, 1000);
      }
    } catch (error) {
      Notify.failure("Es ist etwas schief gelaufen");
      console.error(error);
      this.data.spinner(false);
    }
  }
}
