import { Component, OnInit } from "@angular/core";
import { Projekt } from "@ttc_types/types";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { DataService } from "../../data.service";

@Component({
  selector: "page-jobsuche",
  styleUrls: ["./jobsuche.component.scss"],
  host: { class: "host-full-width" },
  templateUrl: "./jobsuche.component.html",
})
export class JobsucheComponent implements OnInit {
  public publishedProjects: Projekt[] = [];

  public filteredProjects: Projekt[] = [];

  public currentProjectDetail: Projekt | null = null;

  constructor(
    public data: DataService,
    public route: ActivatedRoute,
    public router: Router
  ) {}

  param$?: Subscription;

  async ngOnInit() {
    this.publishedProjects = await this.data.getAllPublishedProjects();
    this.filteredProjects = this.publishedProjects;
    console.log(`this.publishedProjects`, this.publishedProjects);
    // this.currentProjectDetail = this.filteredProjects[0]
    this.param$ = this.route.paramMap.subscribe(async (params) => {
      const id = params.get("id?");
      if (id) {
        const project = this.publishedProjects.find(
          (pro) => pro!.id! === parseInt(id, 10)
        );
        if (project) {
          this.setCurrentProjectDetail(project);
        }
      } else {
        this.unsetCurrentProjectDetail();
      }
    });
  }

  setCurrentProjectDetail(project: Projekt) {
    this.data.hideJetztBewerbenButton = true;
    this.currentProjectDetail = project;
  }

  unsetCurrentProjectDetail() {
    this.data.hideJetztBewerbenButton = false;
    this.currentProjectDetail = null;
  }

  ngOnDestroy(): void {
    this.data.hideJetztBewerbenButton = false;
    if (this.param$) this.param$?.unsubscribe();
  }

  seachStringEventFired($event: string) {
    this.filteredProjects = this.publishedProjects.filter(
      (project) =>
        (project?.titel || "").toLowerCase().includes($event.toLowerCase()) ||
        (project?.aktions_standorte || "")
          .toLowerCase()
          .includes($event.toLowerCase()) ||
        (project?.aktionsinhalt || "")
          .toLowerCase()
          .includes($event.toLowerCase()) ||
        (project?.anforderungsprofil || "")
          .toLowerCase()
          .includes($event.toLowerCase())
    );
    console.log(`seachStringEventFired $event`, $event);
  }
}
