<div class="login">
  <div class="main">
    <h1 class="white">Login</h1>
    <p class="white">
      Noch kein Profil? Bewerbe dich hier auf einen unserer Jobs.
    </p>
    <div class="gold-link" routerLink="/jobs">Jobsuche</div>
    <form [formGroup]="loginForm" (ngSubmit)="login()">
      <div class="input-container">
        <label class="white" for="username">E-Mail Adresse</label>
        <input
          type="email"
          autocomplete="username"
          id="username"
          formControlName="email"
        />
      </div>
      <div class="input-container">
        <label class="white" for="current-password">Passwort</label>
        <input
          type="password"
          autocomplete="current-password"
          id="current-password"
          formControlName="password"
        />
      </div>
      <!-- <button class="btn ">Einloggen</button> -->
      <div class="input-container margin-top-28">
        <!-- [btnText]="'Einloggen'" -->
        <app-button
          type="submit"
          chevron="right"
          (click)="login()"
          [disabled]="loginButtonDisabled"
          [isLoading]="loginButtonLoading"
          >Einloggen</app-button
        >
      </div>
    </form>
    <div class="gold-link" routerLink="/password-reset">
      Passwort vergessen?
    </div>
  </div>
  <div class="detail">
    <div class="logo-container">
      <img src="assets/svgs/logo_big_claim.svg" alt="Logo Claim" class="logo" />
    </div>
  </div>
</div>
