<button
  [type]="type"
  class="btn"
  [class]="classes"
  [disabled]="disabled || disabledByPointerEvents"
>
  <span *ngIf="!onlyIcon">
    <ng-content></ng-content>
  </span>
  <app-svgs *ngIf="iconRight && !isLoading" [type]="iconRight"></app-svgs>
  <div class="lds-dual-ring" *ngIf="isLoading"></div>
</button>
