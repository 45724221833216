import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/shared/auth.service";

@Component({
  selector: "page-login",
  // templateUrl: './login.component.html',
  styleUrls: ["./login.component.scss"],
  host: { class: "host-full-width" },
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  public loginButtonDisabled: boolean = false;

  public loginButtonLoading: boolean = false;

  public username: string = "";

  public password: string = "";

  loginForm: UntypedFormGroup;

  constructor(
    public fb: UntypedFormBuilder,
    public auth: AuthService,
    public route: ActivatedRoute
  ) {
    this.loginForm = this.fb.group({
      email: [""],
      password: [""],
    });
  }

  ngOnInit(): void {}

  async login() {
    console.log(`this.loginForm.value`, this.loginForm.value);
    this.loginButtonDisabled = true;
    this.loginButtonLoading = true;
    try {
      await this.auth.signIn(this.loginForm.value);
      this.loginButtonDisabled = false;
      this.loginButtonLoading = false;
      console.log("this.route: ", this.route);
    } catch (error) {
      this.loginButtonDisabled = false;
      this.loginButtonLoading = false;
      console.error(error);
    }
  }
}
