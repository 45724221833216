import { Notify } from "notiflix";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "src/app/data.service";

@Component({
  selector: "app-confirm-email",
  templateUrl: "./confirm-email.component.html",
  styleUrls: ["./confirm-email.component.scss"],
  host: { class: "host-full-width" },
})
export class ConfirmEmailComponent implements OnInit {
  constructor(private data: DataService, private route: ActivatedRoute) {}

  public success = false;

  public failure = false;

  async ngOnInit() {
    const { token } = this.route.snapshot.queryParams;
    if (token) {
      this.data.spinner(true);
      try {
        await this.data.confirmEmail(token);
      } catch (error) {
        console.error(`error`, error);
        Notify.failure("Leider ist der Link nicht mehr gültig.");
        this.failure = true;
        this.data.spinner(false);
        return;
      }
      this.success = true;
      this.data.spinner(false);
    }
  }
}
