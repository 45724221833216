import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { animate, style, transition, trigger } from "@angular/animations";
// import * as dayjs from "dayjs";
import { dayjs } from "src/app/shared/date-util";
import { Projekt } from "@ttc_types/types";
import { AuthService } from "src/app/shared/auth.service";
import { DataService } from "src/app/data.service";

export type ArchivedProjectsBulk = {
  year: string;
  projects: Projekt[];
  collapsed: boolean;
};

@Component({
  selector: "page-meine-projekte",
  templateUrl: "./meine-projekte.component.html",
  styleUrls: ["./meine-projekte.component.scss"],
  host: { class: "host-full-width" },
  animations: [
    trigger("fade", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class MeineProjekteComponent implements OnInit {
  public veroeffentlichtCollapsed = false;

  public unveroeffentlichtCollapsed = true;

  public oldProjectsCollapsed = true;

  public projects: Projekt[] = [];

  public publishedProjects: Projekt[] = [];

  public unpublishedProjects: Projekt[] = [];

  public oldProjects: Projekt[] = [];

  public archivedProjects: ArchivedProjectsBulk[] = [];

  public publishedProjectsFiltered: Projekt[] = [];

  public unpublishedProjectsFiltered: Projekt[] = [];

  public archivedProjectsFiltered: ArchivedProjectsBulk[] = [];

  public oldProjectsFiltered: Projekt[] = [];

  public zeitBis: { [key: string]: number } = {};
  // public earliestProjectStart = dayjs();

  public latestProjectStart = dayjs();

  public hoursToLatestProjectStart = 0;

  constructor(
    public data: DataService,
    public auth: AuthService,
    public router: Router
  ) {}

  async ngOnInit() {
    this.projects = await this.data.getAllProjects();
    //  this.publishedProjects = this.projects.filter(project => project?.published)
    //  this.unpublishedProjects = this.projects.filter(project => !project?.published)
    for (const project of this.projects) {
      // if(dayjs(project?.zeitraum_von).isBefore(this.earliestProjectStart)){
      //   this.earliestProjectStart = dayjs(project?.zeitraum_von);
      // }
      if (project?.archived) {
        const archived_year: string = `${dayjs(project.zeitraum_von).get(
          "year"
        )}`;
        const archived_index = this.archivedProjects.findIndex(
          (el) => el.year == archived_year
        );
        if (archived_index < 0) {
          this.archivedProjects.push({
            year: archived_year,
            projects: [project],
            collapsed: true,
          });
        } else {
          this.archivedProjects[archived_index].projects.push(project);
        }
      } else if (dayjs(project?.zeitraum_bis).isBefore(dayjs())) {
        // this.latestProjectStart = dayjs(project?.zeitraum_von);
        this.oldProjects.push(project);
      } else {
        if (project?.published) {
          this.publishedProjects.push(project);
        } else {
          this.unpublishedProjects.push(project);
        }
        if (dayjs(project?.zeitraum_von).isAfter(this.latestProjectStart)) {
          this.latestProjectStart = dayjs(project?.zeitraum_von);
        }
      }
    }

    this.hoursToLatestProjectStart = this.latestProjectStart.diff(
      dayjs(),
      "minutes"
    );

    for (const project of this.publishedProjects.concat(
      this.unpublishedProjects
    )) {
      if (typeof project !== "undefined" && typeof project.id !== "undefined") {
        this.zeitBis[project.id] =
          dayjs(project.zeitraum_von).diff(dayjs(), "minutes") /
          this.hoursToLatestProjectStart;
        if (this.zeitBis[project.id] < 0) this.zeitBis[project.id] = 0;
        this.zeitBis[project.id] = Math.round(this.zeitBis[project.id] * 100);
      }
    }

    this.publishedProjectsFiltered = this.publishedProjects;
    this.unpublishedProjectsFiltered = this.unpublishedProjects;
    this.oldProjectsFiltered = this.oldProjects;
    this.archivedProjectsFiltered = this.archivedProjects.sort(
      this.archivedProjectsSortFn
    );

    console.log(`this.zeitBis`, this.zeitBis);

    // console.log(`this.earliestProjectStart`, this.earliestProjectStart);
    console.log(`this.latestProjectStart`, this.latestProjectStart);

    console.log(`publishedProjects`, this.publishedProjects);
    console.log(`unpublishedProjects`, this.unpublishedProjects);

    console.log(`this.projects`, this.projects);
  }

  openProjekt(project: Projekt | undefined) {
    if (!project) return;
    if (project?.published) {
      this.router.navigateByUrl(`meine-projekte/edit/${project.id}/personal`);
    } else {
      this.router.navigateByUrl(`meine-projekte/edit/${project.id}/infos`);
    }
  }

  projectSearchFnc(projects: Projekt[], $event: string) {
    return projects.filter(
      (project) =>
        (project?.titel ?? "").toLowerCase().includes($event.toLowerCase()) ||
        (project?.internal_title ?? "")
          .toLowerCase()
          .includes($event.toLowerCase()) ||
        (project?.aktions_standorte ?? "")
          .toLowerCase()
          .includes($event.toLowerCase()) ||
        (project?.aktionsinhalt ?? "")
          .toLowerCase()
          .includes($event.toLowerCase()) ||
        (project?.anforderungsprofil ?? "")
          .toLowerCase()
          .includes($event.toLowerCase()) ||
        (project?.branche ?? "").toLowerCase().includes($event.toLowerCase())
    );
  }

  archivedProjectsSortFn(a: ArchivedProjectsBulk, b: ArchivedProjectsBulk) {
    return b.year.localeCompare(a.year);
  }

  seachStringEventFired($event: string) {
    this.publishedProjectsFiltered = this.projectSearchFnc(
      this.publishedProjects,
      $event
    );
    this.unpublishedProjectsFiltered = this.projectSearchFnc(
      this.unpublishedProjects,
      $event
    );
    this.oldProjectsFiltered = this.projectSearchFnc(this.oldProjects, $event);
    this.archivedProjectsFiltered = this.archivedProjects
      .map((bulk) => {
        return {
          ...bulk,
          projects: this.projectSearchFnc(bulk.projects, $event),
        };
      })
      .filter((bulk) => bulk.projects.length)
      .sort(this.archivedProjectsSortFn);
  }
}
