<div class="protokoll">
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th>Bereich</th>
          <th>Änderung</th>
          <th>durchgeführt</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let tl of show_transaction_logs">
          <td>
            <div class="subscription">
              {{ tl.bereich_top }}
            </div>
            <div>
              {{ tl.bereich_bottom }}
            </div>
          </td>
          <td>
            <div class="red">
              <pre>{{ tl.aenderung_top }} </pre>
            </div>
            <div>
              <pre>{{ tl.aenderung_bottom }}</pre>
            </div>
          </td>
          <td>
            <div class="subscription">
              {{ tl.durchgefuehrt_top }}
            </div>
            <div class="subscription">
              {{ tl.durchgefuehrt_bottom }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="button-bar">
    <div class="filters">
      <span class="subscription"> Änderungen </span>
      <div class="tags modal-tags">
        <span *ngFor="let model of transactionModelsArr">
          <span>
            <input
              type="checkbox"
              [(ngModel)]="filter_models[model]"
              [id]="model + '_lj'"
              (change)="refreshDataSubj.next(true)"
            />
            <label [for]="model + '_lj'">{{
              protokollModelTranslations[model]
            }}</label>
          </span>
        </span>
      </div>
    </div>
    <div class="time-range">
      <span class="subscription">Zeitraum</span>
      <input
        type="date"
        [(ngModel)]="filter_dates.from"
        [value]="filter_dates.from | date : 'y-MM-dd'"
        (change)="refreshDataSubj.next(true)"
      />
      <input
        type="date"
        [(ngModel)]="filter_dates.to"
        [value]="filter_dates.to | date : 'y-MM-dd'"
        (change)="refreshDataSubj.next(true)"
      />
    </div>
    <div class="export-button">
      <app-button
        (click)="exportWithXLSX()"
        [lessHeight]="true"
        color="primary80"
        iconRight="cloud-download"
        [disabled]="isUploading"
        [isLoading]="isUploading"
      >
        Export
      </app-button>
    </div>
  </div>
</div>
