<app-modal
  *ngIf="downloadAllContractsModal"
  (close)="downloadAllContractsModal = false"
>
  <h5>Alle Verträge herunterladen</h5>
  <ng-select [multiple]="false" [(ngModel)]="selectedYear">
    <ng-option *ngFor="let year of yearsWithContracts" [value]="year">
      {{ year }}
    </ng-option>
  </ng-select>
  <div class="buttons">
    <app-button
      (click)="downloadAllContracts()"
      [isLoading]="isLoading"
      [lessHeight]="true"
      [disabled]="isLoading"
      >Verträge herunterladen</app-button
    >
  </div>
</app-modal>
<div class="vorlagen">
  <div class="main">
    <div class="flex space-between">
      <h1 class="white">Dokument<span class="secondary">vorlagen</span></h1>
      <app-svgs
        type="cloud-download"
        color="white"
        class="cursor-pointer"
        (click)="openDownloadAllContractsModal()"
      ></app-svgs>
    </div>
    <div>
      <select [(ngModel)]="currentVorlageName">
        <option
          *ngFor="let vorlage of vorlagen | keyvalue"
          [value]="vorlage.value.name"
        >
          {{ vorlage.value.name }}
        </option>
      </select>
    </div>
    <!-- <quill-editor [(ngModel)]="vorlage.template"> </quill-editor> -->
    <app-editor
      [(html)]="vorlagen[currentVorlageName].template"
      *ngIf="currentVorlageName && vorlagen[currentVorlageName]"
    ></app-editor>

    <div class="button-right">
      <app-button
        (click)="vorlagenSpeichern()"
        [isLoading]="isLoading"
        [lessHeight]="true"
        [disabled]="isLoading"
        >Vorlagen speichern</app-button
      >
    </div>
    <div class="white">
      <h4>Platzhalter</h4>
      Folgende Variablen können verwendet werden:<br /><br />

      var_arbeitnehmer_adresse<br />
      var_zu_unterschreiben_bis<br />
      var_projekt_zeitraum_von<br />
      var_projekt_zeitraum_bis<br />
      var_eur_stunde<br />
      var_briefing<br />
      var_projektleitung<br />
      var_einsatzzeiten<br />
      var_projekt_name<br />
      var_zusaetzliche_vertragsklauseln<br />

      <br />
      <u>zusätzlich fuer Anue:</u><br />
      var_mitarbeiter_uebertarifliche_zulagen_in_hoehe_von<br />

      <br />
      <u>zusätzlich fuer Kündigung:</u><br />
      var_mitarbeiter_vorname<br />
      var_mitarbeiter_nachname<br />
      var_datum_heute<br />
      var_mitarbeiter-anrede<br />
    </div>
  </div>
</div>
