import { Component, OnInit } from "@angular/core";
import {
  Application,
  DatevExport,
  DatevExportResponse,
  Projekt,
  User,
} from "@ttc_types/types";
// import * as dayjs from "dayjs";
import { dayjs } from "src/app/shared/date-util";
import { Confirm, Notify, Report } from "notiflix";
import { DataService } from "src/app/data.service";
import { environment } from "src/environments/environment";
import * as XLSX from "xlsx";

export type ExportDate = {
  mm_yyyy: string;
  iteration: number;
  export?: DatevExport;
};
export type AvailableStammdatenMonth = {
  mm_yyyy: string;
  export_dates: ExportDate[];
  is_current_tmp_month?: boolean;
};
@Component({
  selector: "app-exporte",
  templateUrl: "./exporte.component.html",
  styleUrls: ["./exporte.component.scss"],
  host: { class: "host-full-width" },
})
export class ExporteComponent implements OnInit {
  // stammdaten_export_start: string = dayjs()
  //   .subtract(1, "month")
  //   .format("YYYY-MM-DD");

  stammdaten_export_start: string = "";
  // stammdaten_export_end: string = dayjs().format("YYYY-MM-DD");

  stammdaten_export_end: string = "";

  arbeitszeiten_export_start: string = "";

  arbeitszeiten_export_end: string = "";

  exportLoading: boolean = true;

  months: { value: Date; label: string }[] = [];

  selectedMonth?: Date;

  users: {
    value: number;
    label: string;
    applications: Partial<Application>[];
  }[] = [];

  selectedUsers: number[] = [];

  projects: { value: number; label: string }[] = [];

  selectedProjects: number[] = [];

  stammdatenExistingExports: DatevExport[] = [];

  // afterFirstExport: boolean = false;

  stammdatenAvailableMonths: AvailableStammdatenMonth[] = [];

  stammdatenInitialDatum: string = "";

  bewegungsdatenAvailableMonths: any[] = [];

  selectedMonthForDatevStammdatenExport?: AvailableStammdatenMonth;

  selectedMonthForDatevBewegungsdatenExport?: string;

  leftTabForDownloading: boolean = false;

  showMasterDataExportModal: boolean = false;

  updateStammdatenExistingExportsAfterReturnToTab = () =>
    new Promise((resolve) => {
      if (this.exportLoading) return;
      if (!this.leftTabForDownloading) return;
      this.updateStammdatenExistingExports();
      resolve(true);
    });

  constructor(public data: DataService) {}

  async ngOnInit() {
    this.exportLoading = true;
    try {
      const now = dayjs();
      let start = dayjs("2023-01-01");
      const months = [];

      while (dayjs(start).isBefore(now)) {
        months.push({
          value: dayjs(start).toDate(),
          label: dayjs(start).format("MMMM YYYY"),
        });
        start = dayjs(start).add(1, "month");
      }

      this.months = months.reverse();

      await this.updateUsersAndProjects();

      await this.updateStammdatenExistingExports();

      this.bewegungsdatenAvailableMonths = await this.data.getRequest(
        "/datev-export/getAvailableMonthsBewegungsdaten"
      );
      this.bewegungsdatenAvailableMonths =
        this.bewegungsdatenAvailableMonths.reverse();
      // eslint-disable-next-line prefer-destructuring
      this.selectedMonthForDatevBewegungsdatenExport =
        this.bewegungsdatenAvailableMonths?.[1].mm_yyyy ||
        this.bewegungsdatenAvailableMonths?.[0].mm_yyyy ||
        "";
    } catch (error) {
      console.error(error);
    }
    this.exportLoading = false;

    // Check browser support for the Page Visibility API
    if (document.hidden === undefined) {
      console.log("Page Visibility API not supported");
    } else {
      // Add an event listener for visibility change
      this.updateStammdatenExistingExportsAfterReturnToTab.bind(this);
      document.addEventListener(
        "visibilitychange",
        this.updateStammdatenExistingExportsAfterReturnToTab
      );
    }
  }

  ngOnDestroy(): void {
    document.removeEventListener(
      "visibilitychange",
      this.updateStammdatenExistingExportsAfterReturnToTab
    );
  }

  /*
   * Get Data for Stammdaten Export Datev
   */
  async updateStammdatenExistingExports() {
    this.stammdatenExistingExports = await this.data.getRequest(
      "/datev-export/getExistingDatevStammdatenExports"
    );
    this.stammdatenAvailableMonths = [];
    for (const see of this.stammdatenExistingExports) {
      const existing_element_index = this.stammdatenAvailableMonths.findIndex(
        (el) => el.mm_yyyy == dayjs(see.month).format("MM.YYYY")
      );
      if (existing_element_index > -1) {
        this.stammdatenAvailableMonths[
          existing_element_index
        ].export_dates.push({
          mm_yyyy: dayjs(see.created_at).format("DD.MM.YYYY"),
          iteration: see.iteration,
          export: see,
        });
      } else {
        this.stammdatenAvailableMonths.push({
          mm_yyyy: dayjs(see.month).format("MM.YYYY"),
          export_dates: [
            {
              mm_yyyy: dayjs(see.created_at).format("DD.MM.YYYY"),
              iteration: see.iteration,
              export: see,
            },
          ],
        });
      }
    }

    this.stammdatenAvailableMonths.sort((a, b) => {
      const aDate = dayjs(a.mm_yyyy, "MM.YYYY");
      const bDate = dayjs(b.mm_yyyy, "MM.YYYY");
      if (aDate.isBefore(bDate)) return 1;
      if (aDate.isAfter(bDate)) return -1;
      return 0;
    });

    if (
      this.stammdatenAvailableMonths.length &&
      !this.stammdatenAvailableMonths[0].is_current_tmp_month
    ) {
      const hightestDate = this.stammdatenAvailableMonths[0].mm_yyyy;
      // add one month to the highest date
      const nextMonth = dayjs(hightestDate, "MM.YYYY").add(1, "month");
      if (nextMonth.isSame(dayjs(), "month")) {
        this.stammdatenAvailableMonths.unshift({
          mm_yyyy: nextMonth.format("MM.YYYY"),
          export_dates: [],
          is_current_tmp_month: true,
        });
      }
    }
    // choose the first one
    if (this.stammdatenAvailableMonths.length) {
      if (this.stammdatenAvailableMonths[0].export_dates.length) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedMonthForDatevStammdatenExport =
          this.stammdatenAvailableMonths[0];
      } else if (this.stammdatenAvailableMonths.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedMonthForDatevStammdatenExport =
          this.stammdatenAvailableMonths[1];
      }
    }
  }

  /**
   * Get Data for Arbeitszeiten Export XLS
   */
  async updateUsersAndProjects() {
    try {
      const body = {
        project_ids: this.selectedProjects,
        time_from: this.arbeitszeiten_export_start,
        time_to: this.arbeitszeiten_export_end,
      };

      const { users, projects } = await this.data.postRequest(
        "/users/getDataForExportsArbeitszeiten",
        body
      );
      this.users = users.map((user: User) => ({
        applications: user.applications,
        value: user.id,
        label: `${user.profile?.vorname} ${user.profile?.nachname} <${user.email}>`,
      }));
      this.projects = projects.map((project: Projekt) => ({
        value: project!.id,
        label: `${project!.titel} (${dayjs(project!.zeitraum_von).format(
          "YYYY"
        )})`,
      }));
      this.selectedUsers = this.selectedUsers.filter((id) =>
        this.users.find((u) => u.value === id)
      );
      this.selectedProjects = this.selectedProjects.filter((id) =>
        this.projects.find((p) => p.value === id)
      );
    } catch (error) {
      Notify.failure("Etwas ist schief gelaufen");
      console.error(`error`, error);
    }
  }

  async generateDatevStammdaten(temporary_export_from_initial_date = false) {
    if (this.exportLoading) return;
    if (
      !this.stammdatenExistingExports.length &&
      !this.stammdatenInitialDatum
    ) {
      Notify.failure("Bitte wähle ein initiales Datum für den Export aus.");
      return;
    }

    this.exportLoading = true;
    try {
      // when export_dates.length is 0 -> it is the actual month selected
      let month =
        this.selectedMonthForDatevStammdatenExport &&
        !this.selectedMonthForDatevStammdatenExport.export_dates.length
          ? this.selectedMonthForDatevStammdatenExport?.mm_yyyy
          : "";

      if (temporary_export_from_initial_date) {
        month = dayjs().format("MM.YYYY");
      }
      const url = `/datev-export/generateStammdatenDatevTxt?&tmp_month=${month}&stammdatenInitialDatum=${this.stammdatenInitialDatum}`;

      const result: DatevExportResponse = await this.data.getRequest(url);
      if (result?.errors.length) {
        // Notify.failure(result?.error);
        Report.failure(
          "Fehler beim Stammdaten generieren",
          `${result?.errors.join("<br><br>")}`,
          "Okay",
          {
            svgSize: "20px",
            messageMaxLength: 9999,
            plainText: false,
          }
        );
        this.exportLoading = false;
        return;
      }
      // let result2: DatevExportResponse | null = null;
      if (result?.warnings?.length) {
        const ignoreWarnings = await new Promise((resolve) => {
          Confirm.show(
            "Warnungen",
            ` ${result?.warnings.join("<br><br>")}`,
            "Abbrechen",
            "Trotzdem generieren",
            () => {
              resolve(false);
            },
            async () => {
              resolve(true);
            },
            {
              messageMaxLength: 9999,
              plainText: false,
            }
          );
        });
        if (!ignoreWarnings) {
          this.exportLoading = false;
          return;
        }
        const result2 = await this.data.getRequest(`${url}&ignoreWarnings=1`);

        if (result2?.errors.length) {
          Report.failure(
            "Fehler beim Stammdaten generieren",
            `${result2?.errors.join("<br><br>")}`,
            "Okay",
            {
              svgSize: "20px",
              messageMaxLength: 9999,
              plainText: false,
            }
          );
          this.exportLoading = false;
          return;
        }

        if (result2.success) {
          Notify.success(result2.success);
        }
      }

      if (result?.success) {
        Notify.success(result?.success);
      }
      if (this.stammdatenInitialDatum) {
        this.stammdatenInitialDatum = "";
      }
      await this.updateStammdatenExistingExports();
    } catch (error) {
      console.error(`error`, error);
      Notify.failure("Fehler beim Stammdaten generieren");
    }
    this.exportLoading = false;
  }

  async downloadDatevStammdaten(date: ExportDate, type: string) {
    this.leftTabForDownloading = true;
    this.exportLoading = true;
    try {
      const { access_token } = await this.data.getRequest(
        "/datev-export/getDownloadToken"
      );
      const url1 = `${environment.apiUrl}/datev-export/downloadStammdatenDatevTxt?export_id=${date.export?.id}&access_token=${access_token}&type=${type}`;
      window.open(url1, "_blank");
    } catch (error) {
      console.error(`error`, error);
      Notify.failure("Fehler beim Download");
    }
    this.exportLoading = false;
  }

  async downloadDatevBewegungsdaten() {
    if (!this.selectedMonthForDatevBewegungsdatenExport) {
      Notify.failure("Bitte wähle einen Monat aus");
      return;
    }
    this.exportLoading = true;
    try {
      // extract the access_token from localStorage
      const { access_token } = await this.data.getRequest(
        "/datev-export/getDownloadToken"
      );
      // open new tab with download link
      const url = `${environment.apiUrl}/datev-export/downloadBewegungsdatenDatevTxt?month=${this.selectedMonthForDatevBewegungsdatenExport}&access_token=${access_token}`;
      window.open(url, "_blank");
    } catch (error) {
      console.error(`error`, error);
      Notify.failure("Fehler beim Download");
    }
    this.exportLoading = false;
  }

  async downloadAllStammdaten(
    type: "month" | "range",
    selectedMasterDataFields?: { key: string; displayName: string }[]
  ) {
    if (this.exportLoading) return;
    this.exportLoading = true;
    try {
      console.log(`this.selectedMonth`, this.selectedMonth);
      let start = this.stammdaten_export_start;
      let end = this.stammdaten_export_end;
      if (type === "month") {
        if (!this.selectedMonth) {
          Notify.failure("Bitte wähle einen Monat aus");
          this.exportLoading = false;
          return;
        }
        start = dayjs(this.selectedMonth).startOf("month").format("YYYY-MM-DD");
        end = dayjs(this.selectedMonth).endOf("month").format("YYYY-MM-DD");
      }
      if (!start || !end) {
        Notify.failure("Bitte geben Sie start und end Datum ein.");
        this.exportLoading = false;
        return;
      }
      await this.data.downloadAllStammdaten(
        {
          time_from: start,
          time_to: end,
          document_id_not_null: true,
        },
        true,
        selectedMasterDataFields
      );
    } catch (error) {
      console.log(`error`, error);
      Notify.failure("Etwas ist schief gelaufen");
    }
    this.exportLoading = false;
  }

  /**
   * exportAllUsersProjectsWithUsers - Export a csv of all projects as columns and all users as rows and all fields a booleans if the user worked on that project or not
   */
  async exportAllUsersProjectsWithUsers() {
    if (this.exportLoading) {
      return;
    }
    this.exportLoading = true;
    try {
      const year = 2024;
      const response = await this.data.postRequest(
        "/datev-export/exportAllUsersProjectsWithUsers",
        { year }
      );
      console.log("response: ", response);
      const { csvString } = response;
      // generate xlsx file from response csvString
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(
        csvString.split("\n").map((row: string) => row.split(";"))
      );
      XLSX.utils.book_append_sheet(wb, ws, "users_projects_export");
      XLSX.writeFile(wb, "users_projects_export.xlsx");
      Notify.success("Die Projekte wurden erfolgreich exportiert.");

      this.exportLoading = false;
    } catch (exportAllUsersProjectsWithUsersError) {
      console.error(
        `exportAllUsersProjectsWithUsersError`,
        exportAllUsersProjectsWithUsersError
      );
      Notify.failure("Etwas ist schief gelaufen");
      this.exportLoading = false;
    }
  }

  /**
   * exportAllUsersWithWorkdays - Export a csv of all users with their workdays overall and for the current year (2024)
   */
  async exportAllUsersWithWorkdays() {
    if (this.exportLoading) {
      return;
    }
    this.exportLoading = true;
    try {
      const year = 2024;
      const response = await this.data.postRequest(
        "/datev-export/exportAllUsersWithWorkdays",
        { year }
      );
      console.log("response: ", response);
      const { csvString } = response;
      // generate xlsx file from response csvString
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(
        csvString.split("\n").map((row: string) => row.split(";"))
      );
      XLSX.utils.book_append_sheet(wb, ws, "users_workdays_export");
      XLSX.writeFile(wb, `users_workdays_export_${year}.xlsx`);
      Notify.success("Die Arbeitstage wurden erfolgreich exportiert.");

      this.exportLoading = false;
    } catch (exportAllUsersWithWorkdaysError) {
      console.error(
        `exportAllUsersWithWorkdaysError`,
        exportAllUsersWithWorkdaysError
      );
      Notify.failure("Etwas ist schief gelaufen");
      this.exportLoading = false;
    }
  }

  async exportArbeitszeiten() {
    if (this.exportLoading) {
      return;
    }
    this.exportLoading = true;
    try {
      let application_ids: number[] = this.selectedUsers.reduce((acc, id) => {
        const user = this.users.find((u) => u.value === id);
        return [
          ...acc,
          ...user!
            .applications!.filter((a) =>
              this.selectedProjects.length
                ? this.selectedProjects.includes(a!.project_id!)
                : true
            )
            .map((a) => a!.id!),
        ];
      }, [] as number[]);

      if (!application_ids.length && this.selectedProjects.length) {
        application_ids = this.users.reduce((acc, user) => {
          return [
            ...acc,
            ...user
              .applications!.filter((a) =>
                this.selectedProjects.includes(a!.project_id!)
              )
              .map((a) => a!.id!),
          ];
        }, [] as number[]);
      }

      console.log(`application_ids`, application_ids);

      await this.data.exportTimeslots({
        application_ids,
        // projectids?
        time_from: this.arbeitszeiten_export_start,
        time_to: this.arbeitszeiten_export_end,
        document_id_not_null: true,
      });
      // Notify.success("Die Arbeitszeiten wurden erfolgreich exportiert.");
    } catch (error) {
      console.error(`error`, error);
      Notify.failure(
        "Etwas ist schief gelaufen. Die Arbeitszeiten konnten nicht exportiert werden."
      );
    }
    this.exportLoading = false;
  }

  async iterationFestschreiben(datevExport?: DatevExport) {
    console.log(`datevExport`, datevExport);

    if (!datevExport) return;
    if (this.exportLoading) return;
    const iterationFestschreibenTrue = await new Promise((resolve) => {
      Confirm.show(
        "Iteration festschreiben",
        "Möchtest du die aktuelle Iteration wirklich festschreiben?",
        "Nein",
        "Ja",
        () => {
          resolve(false);
        },
        async () => {
          resolve(true);
        }
      );
    });
    if (!iterationFestschreibenTrue) return;
    this.exportLoading = true;
    try {
      const result: DatevExportResponse = await this.data.postRequest(
        "/datev-export/iterationFestschreiben",
        datevExport
      );
      if (result?.errors.length) {
        // Notify.failure(result?.error);
        Report.failure(
          "Fehler beim Monat Festschreiben",
          ` ${result?.errors.join("<br><br>")}`,
          "Okay",
          {
            svgSize: "20px",
            messageMaxLength: 9999,
            plainText: false,
          }
        );
        this.exportLoading = false;
        return;
      }

      if (result?.warnings.length) {
        const ignoreWarnings = await new Promise((resolve) => {
          Confirm.show(
            "Warnungen",
            ` ${result?.warnings.join("<br><br>")}`,
            "Abbrechen",
            "Trotzdem festschreiben",
            () => {
              resolve(false);
            },
            async () => {
              resolve(true);
            },

            {
              messageMaxLength: 9999,
              plainText: false,
            }
          );
        });

        if (!ignoreWarnings) {
          this.exportLoading = false;
          return;
        }

        const result2: DatevExportResponse | null = await this.data.postRequest(
          "/datev-export/iterationFestschreiben?ignoreWarnings=1",
          datevExport
        );

        if (result2?.errors.length) {
          Report.failure(
            "Fehler beim Monat Festschreiben",
            ` ${result2?.errors.join("<br><br>")}`,
            "Okay",
            {
              svgSize: "20px",
              messageMaxLength: 9999,
              plainText: false,
            }
          );
          this.exportLoading = false;
          return;
        }
        if (result2?.success) {
          Notify.success(result2?.success);
        }
      }

      if (result?.success) {
        Notify.success(result?.success);
      }

      await this.updateStammdatenExistingExports();
    } catch (error) {
      console.error(`error`, error);
      Notify.failure("Fehler beim Iteration festschreiben");
    }
    this.exportLoading = false;
  }

  async iterationLoeschen(datevExport?: DatevExport) {
    console.log(`datevExport`, datevExport);
    if (!datevExport) return;
    Confirm.show(
      "Iteration löschen",
      "Möchtest du die aktuelle Iteration wirklich löschen?",
      "Nein",
      "Ja",
      () => {},
      async () => {
        this.exportLoading = true;
        try {
          const result = await this.data.postRequest(
            "/datev-export/iterationLoeschen",
            datevExport
          );
          if (result?.errors?.length) {
            Report.failure(
              "Fehler beim Monat Löschen",
              ` ${result?.errors.join("<br><br>")}`,
              "Okay",
              {
                svgSize: "20px",
                messageMaxLength: 9999,
                plainText: false,
              }
            );
          }
          if (result?.success) {
            Notify.success(result?.success);
          }
          await this.updateStammdatenExistingExports();
        } catch (error) {
          console.error(`error`, error);
          Notify.failure("Fehler beim Iteration löschen");
        }
        this.exportLoading = false;
      }
    );
  }

  closeMasterDataExportModal() {
    this.showMasterDataExportModal = false;
  }

  onMasterDataDownload(payLoad: any) {
    console.log("onMasterDataDownload payLoad: ", payLoad);
    if (
      !this.selectedMonth &&
      !this.stammdaten_export_start &&
      !this.stammdaten_export_end
    ) {
      // show toast error that a month or a date range has to be set before
      Notify.failure("Ein Zeitraum wurde nicht ausgewählt");
    }
    if (this.selectedMonth) {
      this.downloadAllStammdaten("month", payLoad.selectedMasterDataFields);
    } else if (this.stammdaten_export_start && this.stammdaten_export_end) {
      this.downloadAllStammdaten("range", payLoad.selectedMasterDataFields);
    }
    this.closeMasterDataExportModal();
  }

  openMasterDataExportModal() {
    this.showMasterDataExportModal = true;
  }
}
