/* eslint-disable no-plusplus */
import { Component, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent {
  @Input()
  pagination: {
    page: number;
    perPage: number;
    pagesTotal: number;
    total: number;
  } = {
    page: 1,
    perPage: 25,
    pagesTotal: 1,
    total: 0,
  };

  @Output()
  pageChanged: EventEmitter<number> = new EventEmitter();

  get pagesTotalNumeration() {
    return Array.from(Array(this.pagination.pagesTotal).keys());
  }

  perPageOptions = [25, 50, 100, 500];

  incrementPage(inc: boolean = true) {
    if (inc) {
      this.pagination.page++;
    } else {
      this.pagination.page--;
    }
    this.pageChanged.emit();
  }
}
