import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { SvgsTypes } from "@ttc_types/types";

@Component({
  selector: "app-svgs",
  styleUrls: ["./svgs.component.scss"],
  host: { class: "flex-center" },
  templateUrl: "./svgs.component.html",
})
export class SvgsComponent implements OnInit {
  @Input()
  iconSizePxWidth?: number;

  @Input()
  iconSizePxHeight?: number;

  @Input()
  strokeWidth: "1.5" | "3" = "1.5";

  @Input()
  color:
    | "primary"
    | "primary120"
    | "primary90"
    | "primary80"
    | "primary60"
    | "primary40"
    | "primary20"
    | "primary10"
    | "white"
    | "success"
    | "fail"
    | "secondary" = "primary";

  get strokeColor(): string {
    return `var(--${this.color})`;
  }

  /**
   * What svg should be shown?
   */
  @Input()
  type: SvgsTypes = "search";

  constructor(public elementRef: ElementRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.iconSizePxHeight || this.iconSizePxWidth) {
      const targetElement = this.elementRef.nativeElement.querySelector("svg");
      if (targetElement && this.iconSizePxWidth) {
        targetElement.style.width = `${this.iconSizePxWidth}px`;
      }
      if (targetElement && this.iconSizePxHeight) {
        targetElement.style.height = `${this.iconSizePxHeight}px`;
      }
    }
  }
}
