import { Pipe, PipeTransform } from "@angular/core";
// import * as dayjs from "dayjs";
import { dayjs } from "src/app/shared/date-util";

@Pipe({
  name: "formatTwoDates",
})
export class FormatTwoDatesPipe implements PipeTransform {
  transform(date: Date | undefined, ...args: (Date | undefined)[]): string {
    if (!date) return "";
    const dayjs1 = dayjs(date);
    const dayjs2 = args.length ? dayjs(args[0]) : null;
    if (!dayjs1 || !dayjs1.isValid()) return "Invalid Date";
    if (!dayjs2 || !dayjs2.isValid() || dayjs1.isSame(dayjs2, "day")) {
      return `${dayjs1.format("dd. D.M.YYYY")}`;
    }
    let year1 = dayjs1.year();
    let year2 = dayjs2.year();
    year1 = year2 == year1 ? 0 : year1;
    year2 = year2 == year1 ? year1 : year2;
    const date1 = dayjs1.format("dd. D.M.");
    const date2 = dayjs2.format("dd. D.M.");
    return `${date1}${year1 || ""} - ${date2}${year2}`;

    // if()
    // console.log(`dayjs1`, dayjs1);
    // console.log(`date`, date);
    // console.log(`args`, args);

    // dayjs 1          dayjs 1
  }
}
