import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-time-block",
  templateUrl: "./time-block.component.html",
  styleUrls: ["./time-block.component.scss"],
})
export class TimeBlockComponent implements OnInit {
  @Input() timeFrom: number = 0;

  @Input() timeTo: number | null = null;

  @Input() overallDuration: number = 0;

  @Input() timeBreak: number | null = null;

  @Input() blockType: "single" | "add" = "single";

  @Input() title: string = "";

  @Input() buttonText: string = "";

  @Input() buttonIcon: any = undefined;

  @Input() moneyValue: string = "";

  @Input() subText: string = "";

  @Input() selectable: boolean = true;

  @Input() hideBreak: boolean = false;

  @Output() onButtonClick = new EventEmitter<any>();

  timeDuration: number = 0;

  formattedDate: string = "";

  formattedTimeInterval: string = "";

  formattedBreak: string = "";

  formattedDuration: string = "";

  formattedOverallTime: string = "";

  extraClasses: string[] = [];

  constructor() {}

  ngOnInit(): void {
    if (this.blockType === "single") {
      this.extraClasses.push("type-single");
    } else if (this.blockType === "add") {
      this.extraClasses.push("type-add");
    }
    if (!this.timeTo && this.blockType === "single") {
      this.extraClasses.push("type-no-checkout");
    }
    this.updateValues();
  }

  ngOnChanges() {
    if (this.blockType === "single") {
      this.extraClasses.push("type-single");
    } else if (this.blockType === "add") {
      this.extraClasses.push("type-add");
    }
    if (!this.timeTo && this.blockType === "single") {
      this.extraClasses.push("type-no-checkout");
    }
    this.updateValues();
  }

  buttonClicked(event: any) {
    event.preventDefault();
    this.onButtonClick.emit();
  }

  getNextDayStringIfOverMidnight() {
    if (!this.timeTo) return "";
    const dayFrom = new Date(this.timeFrom).toLocaleDateString("de", {
      weekday: "short",
    });
    const dayTo = new Date(this.timeTo).toLocaleDateString("de", {
      weekday: "short",
    });
    if (dayFrom !== dayTo) {
      return `${dayTo}. `;
    }
    return "";
  }

  updateValues() {
    this.formattedBreak = "";
    // update timeDuration:
    // format times
    const timeOptions: any = { hour: "numeric", minute: "2-digit" };
    // format from - to time string:
    const timeIntervalFromString = new Date(this.timeFrom).toLocaleTimeString(
      "de",
      timeOptions
    );
    // format day date string
    const dateStringFrom = new Date(this.timeFrom).toLocaleDateString("de", {
      weekday: "long",
    });
    const clockStringFrom = new Date(this.timeFrom).toLocaleDateString("de", {
      month: "2-digit",
      day: "2-digit",
    });
    this.formattedDate = `${dateStringFrom} ${clockStringFrom}`;
    if (this.timeFrom && this.timeTo) {
      this.timeDuration = this.timeTo - this.timeFrom;
      if (this.timeBreak && !this.hideBreak) {
        this.timeDuration -= this.timeBreak;
        // format break string
        const breakHours = Math.floor(this.timeBreak / 1000 / 60 / 60);
        const breakMinutes = Math.floor(
          (this.timeBreak - breakHours * 60 * 60 * 1000) / 1000 / 60
        );
        this.formattedBreak = "";
        if (breakHours > 0) {
          this.formattedBreak += `${breakHours} Std.`;
        }
        if (breakMinutes > 0) {
          if (this.formattedBreak.length > 0) {
            this.formattedBreak += " ";
          }
          this.formattedBreak += `${breakMinutes} Min.`;
        }
      } else {
        this.formattedBreak += "Keine";
      }
      this.formattedBreak += " Pause";

      // format duration time:
      const midnight = new Date(this.timeFrom);
      midnight.setHours(0, 0, 0, 0);
      const durationHourString = new Date(
        midnight.getTime() + this.timeDuration
      ).toLocaleTimeString("de", timeOptions);
      this.formattedDuration = `${durationHourString} h`;

      // format to time string:
      const timeIntervalToString = new Date(this.timeTo).toLocaleTimeString(
        "de",
        timeOptions
      );
      const optionalNextDay = this.getNextDayStringIfOverMidnight();
      this.formattedTimeInterval = `${timeIntervalFromString} - ${optionalNextDay}${timeIntervalToString} Uhr`;
    } else if (this.timeFrom && !this.timeTo) {
      this.formattedTimeInterval = `${timeIntervalFromString} Uhr - Checkout ausstehend`;
    }
    let hours: any = Math.floor(this.overallDuration / 1000 / 60 / 60);
    let minutes: any = Math.floor(
      (this.overallDuration - hours * 60 * 60 * 1000) / 1000 / 60
    );
    if (hours.toString().length === 0) {
      hours = `0`;
    }
    if (minutes.toString().length === 1) {
      minutes = `0${minutes}`;
    } else if (minutes.toString().length === 0) {
      minutes = "00";
    }
    this.formattedOverallTime = `${hours}:${minutes} h`; // TODO
  }
}
