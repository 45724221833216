import { Subscription } from "rxjs";
import { AbstractControl, UntypedFormControl } from "@angular/forms";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-plus-minus-input",
  templateUrl: "./plus-minus-input.component.html",
  styleUrls: ["./plus-minus-input.component.scss"],
})
export class PlusMinusInputComponent implements OnInit {
  @Input()
  step: number = 1;

  @Input()
  disabled: boolean = false;

  @Input()
  min: number | null = null;

  @Input()
  max: number | null = null;

  @Input()
  unit: string = "";

  @Input()
  formCntrl: UntypedFormControl | AbstractControl | undefined;

  internalFormControl: UntypedFormControl = new UntypedFormControl("", []);

  @Input()
  value: number = 0;

  @Output()
  valueChange: EventEmitter<number> = new EventEmitter();

  initialized: boolean = false;

  formCntrlSubscription: Subscription | undefined;

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    if (this.formCntrl) {
      this.internalFormControl = this.formCntrl as UntypedFormControl;
    } else {
      this.internalFormControl.setValue(this.value, { emitEvent: false });
    }
    this.initialized = true;
    if (!this.formCntrlSubscription) {
      this.formCntrlSubscription =
        this.internalFormControl.valueChanges.subscribe((value) => {
          let val = value;
          if (this.min !== null && value < this.min) {
            this.internalFormControl.setValue(this.min);
            val = this.min;
          }
          if (this.max !== null && value > this.max) {
            this.internalFormControl.setValue(this.max);
            val = this.max;
          }
          this.valueChange.emit(val);
        });
    }
  }

  ngOnChanges(): void {
    if (
      !this.initialized ||
      !this.internalFormControl ||
      !this.formCntrlSubscription
    ) {
      return;
    }

    this.internalFormControl.setValue(this.value, { emitEvent: false });
  }

  ngOnDestroy(): void {
    this.formCntrlSubscription?.unsubscribe();
  }

  increment(inc: boolean = true) {
    if (inc) {
      this.internalFormControl.setValue(
        this.internalFormControl.value + this.step
      );
    } else {
      this.internalFormControl.setValue(
        this.internalFormControl.value - this.step
      );
    }
  }
}
