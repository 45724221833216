import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Projekt, User } from "@ttc_types/types";
import { Confirm, Notify } from "notiflix";
import { DataService } from "src/app/data.service";

@Component({
  selector: "app-copy-personal-to-other-project-modal",
  templateUrl: "./copy-personal-to-other-project-modal.component.html",
  styleUrls: ["./copy-personal-to-other-project-modal.component.scss"],
})
export class CopyPersonalToOtherProjectModalComponent implements OnInit {
  @Input() incomingSelectedUserIds: number[] = [];

  selectedUserIds: number[] = [];

  userItems: { id: number; name: string }[] = [];

  @Output() close = new EventEmitter();

  projects: Projekt[] | undefined;

  selectedProjectId: number | undefined;

  isLoading: boolean = true;

  constructor(private data: DataService, private datePipe: DatePipe) {}

  async ngOnInit() {
    this.projects = await this.data.getRequest(
      "/project/getAllProjectsNotInPast"
    );

    this.projects?.sort((a, b) => {
      if (!a || !b) {
        return 0;
      }

      return a.titel.localeCompare(b.titel);
    });

    const copyableUsers: User[] = await this.data.getRequest(
      "/project/getCopyableUsers"
    );

    this.userItems = copyableUsers.map((u: User) => {
      return {
        name: `${u.profile?.vorname} ${u.profile?.nachname}`,
        id: u.id ?? -1,
      };
    });

    const userIds = new Set<number>();
    for (const user of copyableUsers) {
      userIds.add(user.id!);
    }

    this.selectedUserIds = this.incomingSelectedUserIds.filter((id) => {
      return userIds.has(id);
    });

    if (this.selectedUserIds.length !== this.incomingSelectedUserIds.length) {
      Notify.warning(
        "Einige ausgewählte Personen können nicht kopiert werden, da deren Profile nicht vollständig sind.",
        {
          timeout: 10_000,
        }
      );
    }

    this.isLoading = false;
  }

  async copyAuswahlToProjekt() {
    if (!this.selectedProjectId) {
      Notify.warning("Bitte wähle erst ein Projekt aus.");
      return;
    }
    if (!this.selectedUserIds.length) {
      Notify.warning("Es sind keine Topteamer ausgewählt");
      return;
    }
    // eslint-disable-next-line no-shadow
    const res = await new Promise((res) => {
      const selectedProject = this.projects?.find(
        (p) => p!.id === this.selectedProjectId
      );
      Confirm.show(
        "Bist du dir sicher?",
        `Möchtest du die ausgewählten Personen wirklich dem Projekt
        ${selectedProject!.titel} (${this.datePipe.transform(
          selectedProject!.zeitraum_von,
          "y"
        )})  hinzufügen?`,
        "Nein",
        "Ja",
        () => {
          res(false);
        },
        () => {
          res(true);
        }
      );
    });
    if (res) {
      this.isLoading = true;
      try {
        const body = {
          project_id: this.selectedProjectId,
          user_ids: this.selectedUserIds,
        };
        await this.data.postRequest(
          `/project/copyPersonalToOtherProject`,
          body
        );
        Notify.success("Personen wurden dem Projekt hinzugefügt.");
        this.close.emit();
      } catch (error) {
        console.error(`error`, error);
        Notify.failure(
          "Personen konnten nicht dem Projekt hinzugefügt werden."
        );
      }
      this.isLoading = false;
    }
  }
}
