import { Component, OnInit, Input } from "@angular/core";
import { ab_nv } from "src/app/pages/apply/sedPersForm";
import {
  SED,
  Profile,
  User,
  ArbeitsbeginnStatusProfileView,
} from "@ttc_types/types";
import { shirtArr } from "@ttc_types/datasets";

import { DatePipe } from "@angular/common";

@Component({
  selector: "app-bewerbercheck",
  templateUrl: "./bewerbercheck.component.html",
  styleUrls: ["./bewerbercheck.component.scss"],
})
export class BewerbercheckComponent implements OnInit {
  sed!: SED;

  profile!: Profile;

  beschaeftigung: ArbeitsbeginnStatusProfileView[] = [];

  erfahrung = [];

  @Input()
  user!: User;

  @Input()
  showStatus: boolean = false;

  @Input()
  hasValidStatusErrors: string[] = [];

  shirtArr = shirtArr;

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {
    console.log(`user`, this.user);
    console.log(`shirtArr`, shirtArr);
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    console.log(`BewerbercheckModalBewerbercheckComponent user`, this.user);
    // setTimeout(() => {
    if (this.user?.main_sed_card) {
      this.sed = this.user.main_sed_card;
      console.log(`this.sed`, this.sed);
    }
    if (this.user?.profile) {
      this.profile = this.user.profile;
      console.log(`this.profile`, this.profile);

      if (this.user.profile.arbeitsbeginn_status) {
        const abs = this.user.profile.arbeitsbeginn_status;
        const absa = this.user.profile.arbeitsbeginn_status_additional;
        for (const key of Object.keys(abs)) {
          console.log(`abs[key]`, abs[key]);
          if (abs[key]) {
            console.log(`ab_nv[key]`, ab_nv[key]);
            const abs_to_push: ArbeitsbeginnStatusProfileView = {
              name: ab_nv[key].label,
              fach: undefined,
              nachweise: [],
              gueltig_bis: undefined,
            };
            try {
              if (absa && typeof absa[`${key}_fach`] !== "undefined") {
                abs_to_push.fach = absa[`${key}_fach`];
              }
              if (absa && typeof absa[`${key}_gueltig_bis`] !== "undefined") {
                abs_to_push.gueltig_bis = `${this.datePipe.transform(
                  absa[`${key}_gueltig_bis`],
                  "dd.MM.yyyy"
                )}`;
              }
            } catch (error) {
              console.error("catched error: ", error);
            }
            this.beschaeftigung.push(abs_to_push);
          }
          // ab_nv;
        }
      }
    }
    console.log(`beschaeftigung`, this.beschaeftigung);
    // }, 500);
  }
}
