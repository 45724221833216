import { Notify, Confirm, IReportOptions, Report } from "notiflix";
import { Validators, UntypedFormControl, UntypedFormGroup, UntypedFormArray } from "@angular/forms";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  User,
  ApplicationPermission,
  Projekt,
  Application,
} from "@ttc_types/types";
import { AuthService } from "src/app/shared/auth.service";
import { DataService } from "src/app/data.service";
import { Router } from "@angular/router";
import { kostenstellen } from "@ttc_types/datasets";

export interface PM {
  id: number | null;
  admin: boolean;
}
@Component({
  selector: "app-projekteinstellungen-modal",
  templateUrl: "./projekteinstellungen-modal.component.html",
  styleUrls: ["./projekteinstellungen-modal.component.scss"],
})
export class ProjekteinstellungenModalComponent implements OnInit {
  // @Input()
  // projekt: Projekt;

  @Output()
  close = new EventEmitter();

  submitAttempt: boolean = false;

  weitere_projektmanager: UntypedFormArray = new UntypedFormArray([] as UntypedFormGroup[]);

  additional_teamleads: UntypedFormArray = new UntypedFormArray([] as UntypedFormGroup[]);

  kostenstellen = kostenstellen;

  canChangeLiveCheckinMode: boolean = false;

  settingsForm: UntypedFormGroup = new UntypedFormGroup(
    {
      internal_title: new UntypedFormControl("", []),
      bewerbung_moeglich: new UntypedFormControl(false, []),
      jeder_mitarbeiter_darf_unterschrift_von_kunden_einholen: new UntypedFormControl(
        false,
        []
      ),
      ansprechperson: new UntypedFormControl(null, [Validators.required]),
      ansprechperson_admin: new UntypedFormControl(false, []),

      weitere_projektmanager: this.weitere_projektmanager,

      additional_teamleads: this.additional_teamleads,

      teamleiter_vor_ort: new UntypedFormControl(null, []),

      personalbedarf: new UntypedFormControl(0, []),
      zusaetzliche_vertragsklauseln: new UntypedFormControl("", []),
      abrechnung_kostenstelle: new UntypedFormControl(null, []),
      abrechnung_topteam_taetigkeit: new UntypedFormControl(null, []),

      kunde_ansprechpartner_name: new UntypedFormControl("", []),
      kunde_ansprechpartner_email: new UntypedFormControl("", []),
      kunde_ansprechpartner_tel: new UntypedFormControl("", []),
      live_checkin: new UntypedFormControl(false, []),
    }
    // (control: AbstractControl): ValidationErrors | null => {
    //   const { value } = control;
    //   console.log(`value`, value);
    //   const index = (value.weitere_projektmanager || []).indexOf(
    //     value.ansprechperson
    //   );
    //   if (index > -1) {
    //     this.settingsForm.controls.weitere_projektmanager.setValue(
    //       value.weitere_projektmanager.splice(index, 1),
    //       { emitEvent: false }
    //     );
    //   }
    //   return null;
    //   // return { invalid: true };
    // }
  );

  constructor(
    public data: DataService,
    public auth: AuthService,
    public router: Router
  ) {}

  projectmanagers: User[] = [];

  get getProjectManagers() {
    return this.projectmanagers;
    // return this.projectmanagers.map((el) => {
    //   return {
    //     label:
    //       (el.profile?.vorname || "") + (el.profile?.nachname || "") ||
    //       el.email,
    //     value: el.id,
    //   };
    // });
    // .filter(
    //   (el) => !this.usedUserIds.includes(el.id!)
    // );
  }

  applicants: User[] = [];

  admin_ids: number[] = [];

  applicants_projectmanagers: User[] = [];

  get teamleadList() {
    return this.applicants.filter(
      (el) =>
        el.applications?.[0]?.role == "teamleiter_vor_ort" ||
        el.applications?.[0]?.role == "additional_teamleads" ||
        el.applications?.[0]?.role === "arbeitskraft"
    );
    // .filter((el) => !this.usedUserIds.includes(el.id!));
  }

  addPM(id: number | null = null, admin: boolean = false) {
    for (let i = this.weitere_projektmanager.controls.length - 1; i >= 0; i--) {
      if (!this.weitere_projektmanager.controls[i].value.id) {
        this.weitere_projektmanager.removeAt(i);
      }
    }
    this.weitere_projektmanager.push(
      new UntypedFormGroup({
        id: new UntypedFormControl(id, []),
        admin: new UntypedFormControl(admin, []),
      })
    );
    console.log(`this.weitere_projektmanager`, this.weitere_projektmanager);
  }

  addTL(id: number | null = null, admin: boolean = false) {
    console.log(`addTL called id: ${id} admin: ${admin}`);
    for (let i = this.additional_teamleads.controls.length - 1; i >= 0; i--) {
      if (!this.additional_teamleads.controls[i].value.id) {
        this.additional_teamleads.removeAt(i);
      }
    }
    this.additional_teamleads.push(
      new UntypedFormGroup({
        id: new UntypedFormControl(id, []),
        admin: new UntypedFormControl(admin, []),
      })
    );
    console.log(`this.additional_teamleads`, this.additional_teamleads);
  }

  /**
   * removes projectmanager from formarray
   * @param index
   */
  removePM(index: number) {
    this.weitere_projektmanager.removeAt(index);
  }

  /**
   * removes teamleader from formarray
   * @param index
   */
  removeTL(index: number) {
    this.additional_teamleads.removeAt(index);
  }

  public usedUserIds: number[] = [];

  async ngOnInit() {
    console.log("this.auth.currentUser", this.auth.currentUser);
    console.log(`this.data.currentProject`, this.data.currentProject);

    this.settingsForm.patchValue({
      internal_title: this.data.currentProject?.internal_title ?? null,
      bewerbung_moeglich: this.data.currentProject?.bewerbung_moeglich ?? false,
      jeder_mitarbeiter_darf_unterschrift_von_kunden_einholen:
        this.data.currentProject
          ?.jeder_mitarbeiter_darf_unterschrift_von_kunden_einholen ?? false,
      ansprechperson: this.data.currentProject?.ansprechperson?.id ?? null,
      personalbedarf: this.data.currentProject?.personalbedarf ?? null,
      zusaetzliche_vertragsklauseln:
        this.data.currentProject?.zusaetzliche_vertragsklauseln ?? null,
      abrechnung_kostenstelle:
        this.data.currentProject?.abrechnung_kostenstelle ?? null,
      abrechnung_topteam_taetigkeit:
        this.data.currentProject?.abrechnung_topteam_taetigkeit ?? null,
      kunde_ansprechpartner_name:
        this.data.currentProject?.kunde_ansprechpartner_name ?? null,
      kunde_ansprechpartner_email:
        this.data.currentProject?.kunde_ansprechpartner_email ?? null,
      kunde_ansprechpartner_tel:
        this.data.currentProject?.kunde_ansprechpartner_tel ?? null,
      live_checkin: this.data.currentProject?.live_checkin ?? false,
    });
    try {
      this.projectmanagers = await this.data.getAllPMs();
      console.log(`this.projectmanagers`, this.projectmanagers);

      if (this.data.currentProject?.id) {
        this.applicants = await this.data.getApplicants(
          this.data.currentProject?.id
          // false
        );

        this.admin_ids = this.applicants
          .filter((appl) => appl.applications![0].is_admin)
          .map((appl) => appl.id!);

        console.log(`this.applicants`, this.applicants);
        console.log(`this.admin_ids`, this.admin_ids);
      }
    } catch (error) {
      console.error(`error`, error);
      Notify.failure("Etwas ist schief gelaufen. Bitte versuche es erneut.");
    }

    if (this.applicants && this.applicants.length) {
      for (const applicant of this.applicants) {
        if (applicant.applications && applicant.applications.length) {
          const { role } = applicant.applications[0];
          if (role == "ansprechperson") {
            this.settingsForm.controls.ansprechperson.setValue(applicant.id);
            this.settingsForm.controls.ansprechperson_admin.setValue(
              applicant.applications[0].is_admin || false
            );
          }
          if (role === "weiterer_projektmanager") {
            this.addPM(
              applicant.id,
              applicant.applications[0].is_admin || false
            );
          } else if (role === "additional_teamleads") {
            this.addTL(
              applicant.id,
              applicant.applications[0].is_admin || false
            );
          }
          if (role == "teamleiter_vor_ort") {
            this.settingsForm.controls.teamleiter_vor_ort.setValue(
              applicant.id
            );
          }
        }
      }

      console.log(
        `this.data.currentProject?.personalbedarf`,
        this.data.currentProject?.personalbedarf
      );

      this.settingsForm.controls.personalbedarf.setValue(
        this.data.currentProject?.personalbedarf || null
      );
      this.settingsForm.controls.zusaetzliche_vertragsklauseln.setValue(
        this.data.currentProject?.zusaetzliche_vertragsklauseln || ""
      );
      this.settingsForm.controls.abrechnung_kostenstelle.setValue(
        this.data.currentProject?.abrechnung_kostenstelle || null
      );
      this.settingsForm.controls.abrechnung_topteam_taetigkeit.setValue(
        this.data.currentProject?.abrechnung_topteam_taetigkeit || null
      );
      this.settingsForm.controls.live_checkin?.disable();
      await this.checkIfCanChangeLiveCheckinMode();
      if (this.canChangeLiveCheckinMode) {
        this.settingsForm.controls.live_checkin?.enable();
      }
    }

    if (this.data.debug && true) {
      this.settingsForm.valueChanges.subscribe((val) => {
        console.log(`settingsForm val`, val);
      });
    }
    console.log(`settingsForm`, this.settingsForm);
  }

  isLoading: boolean = false;

  /**
   * Checks if working times have already been tracked for this projects,
   * if so, the live checkin mode cannot be changed anymore. The functions
   * saves the result in the variable canChangeLiveCheckinMode and returns it.
   * @returns boolean canChangeLiveCheckinMode
   */
  async checkIfCanChangeLiveCheckinMode() {
    try {
      const applicationsWithTimes: Application[] = await this.data.postRequest(
        `/project/applications/${this.data.currentProject?.id}`,
        {
          includeTimeslots: true,
        }
      );
      this.canChangeLiveCheckinMode = true;
      if (applicationsWithTimes) {
        applicationsWithTimes.forEach((application) => {
          if (application.timeslots && application.timeslots.length > 0) {
            this.canChangeLiveCheckinMode = false;
          }
        });
      }
    } catch (error) {
      console.error(`error`, error);
    }
    return this.canChangeLiveCheckinMode;
  }

  async saveProjectSettings() {
    for (let i = this.weitere_projektmanager.controls.length - 1; i >= 0; i--) {
      if (!this.weitere_projektmanager.controls[i].value.id) {
        this.weitere_projektmanager.removeAt(i);
      }
    }
    if (this.settingsForm.invalid) {
      this.submitAttempt = true;
      Notify.info("Es fehlen noch Angaben.");
    } else {
      this.isLoading = true;
      // update application permissions
      // admins can only be set by other admins?

      const updatePermissionUsers = [];
      try {
        if (this.data.currentProject?.id) {
          if (this.settingsForm.value.teamleiter_vor_ort) {
            updatePermissionUsers.push({
              user_id: this.settingsForm.value.teamleiter_vor_ort as number,
              project_id: this.data.currentProject!.id,
              role: "teamleiter_vor_ort",
              is_admin: null,
            } as ApplicationPermission);
          }

          if (this.settingsForm.value.additional_teamleads.length) {
            for (const tl of this.settingsForm.value.additional_teamleads) {
              if (tl.id) {
                updatePermissionUsers.push({
                  user_id: tl.id as number,
                  project_id: this.data.currentProject!.id,
                  role: "additional_teamleads",
                  is_admin:
                    this.auth.currentUser?.role == "superadmin"
                      ? !!tl.admin
                      : null,
                } as ApplicationPermission);
              }
            }
          }

          if (this.settingsForm.value.weitere_projektmanager.length) {
            // const promises = [];
            for (const pm of this.settingsForm.value.weitere_projektmanager) {
              updatePermissionUsers.push({
                user_id: pm.id as number,
                project_id: this.data.currentProject!.id,
                role: "weiterer_projektmanager",
                is_admin:
                  this.auth.currentUser?.role == "superadmin"
                    ? !!pm.admin
                    : null,
              } as ApplicationPermission);
            }
          }

          if (this.settingsForm.value.ansprechperson) {
            updatePermissionUsers.push({
              user_id: this.settingsForm.value.ansprechperson,
              project_id: this.data.currentProject!.id,
              role: "ansprechperson",
              is_admin:
                this.auth.currentUser?.role == "superadmin"
                  ? !!this.settingsForm.value.ansprechperson_admin
                  : null,
            } as ApplicationPermission);
          }

          try {
            this.isLoading = true;
            await this.data.updateApplicationPermissions(updatePermissionUsers);
            this.isLoading = false;
          } catch (error) {
            this.isLoading = false;
            console.error(`error`, error);
            Notify.failure(
              "Etwas ist schief gelaufen. Bitte versuche es nochmals."
            );
            this.close.emit(true);
            console.error(`updateApplicationPermissions error`, error);
            Notify.failure("Fehler beim Speichern der Projekt-Einstellungen");
            return;
          }
        }
        // update application permissions end

        this.data.currentProject!.personalbedarf =
          this.settingsForm.value.personalbedarf;
        this.data.currentProject!.zusaetzliche_vertragsklauseln =
          this.settingsForm.value.zusaetzliche_vertragsklauseln;
        this.data.currentProject!.abrechnung_kostenstelle =
          this.settingsForm.value.abrechnung_kostenstelle;
        this.data.currentProject!.abrechnung_topteam_taetigkeit =
          this.settingsForm.value.abrechnung_topteam_taetigkeit;
        this.data.currentProject!.kunde_ansprechpartner_name =
          this.settingsForm.value.kunde_ansprechpartner_name;
        this.data.currentProject!.kunde_ansprechpartner_email =
          this.settingsForm.value.kunde_ansprechpartner_email;
        this.data.currentProject!.kunde_ansprechpartner_tel =
          this.settingsForm.value.kunde_ansprechpartner_tel;
        this.data.currentProject!.bewerbung_moeglich =
          this.settingsForm.value.bewerbung_moeglich;
        this.data.currentProject!.jeder_mitarbeiter_darf_unterschrift_von_kunden_einholen =
          this.settingsForm.value.jeder_mitarbeiter_darf_unterschrift_von_kunden_einholen;
        this.data.currentProject!.internal_title =
          this.settingsForm.value.internal_title ?? null;
        this.data.currentProjectInternalTitle =
          this.settingsForm.value.internal_title ?? null;
        this.data.currentProject!.live_checkin =
          this.settingsForm.value.live_checkin ?? false;

        this.data.currentProject = await this.data.uploadCurrentProject();
        console.log(`this.data.currentProject`, this.data.currentProject);
        this.data.eventBus.next("updateInfoForm");
        this.isLoading = false;
        Notify.success(
          "Die Projekteinstellungen wurden erfolgreich aktualisiert."
        );
        this.close.emit(true);
      } catch (error) {
        this.isLoading = false;
        console.error(`error`, error);
        Notify.failure(
          "Etwas ist schief gelaufen. Bitte versuche es nochmals."
        );
        this.close.emit(true);
      }
    }
  }

  async archiveProject(archive: boolean = true) {
    try {
      this.isLoading = true;
      if (!this.data.currentProject) return;
      await this.data.postRequest("/project/archiveProject", {
        project_id: this.data.currentProject?.id,
        archive: true,
      });
      this.data.currentProject!.archived = archive;
      if (archive) {
        Notify.success("Das Projekt wurde erfolgreich archiviert.");
      } else {
        Notify.success("Archivierung wurde erfolgreich rückgängig gemacht.");
      }
    } catch (error) {
      console.error(error);
      Notify.failure("Etwas ist schief gegangen.");
    }
    this.isLoading = false;
  }

  async deleteProject() {
    try {
      this.isLoading = true;
      const isDeleteableResult: Projekt = await this.data.postRequest(
        "/project/isDeleteable",
        {
          project_id: this.data.currentProject?.id,
        }
      );
      this.isLoading = false;

      if (isDeleteableResult?.applications?.length) {
        const application_left_string = isDeleteableResult.applications
          .map(
            (a) => `${a.user?.profile?.vorname} ${a.user?.profile?.nachname}`
          )
          .join("<br> ");
        Report.failure(
          "Das Projekt kann nicht gelöscht werden, da es noch Bewerbungen enthält.",
          `Folgende Bewerbungen sind noch vorhanden: <br> ${application_left_string}`,
          "OK",
          {
            messageMaxLength: 100000,
            titleMaxLength: 100000,
            plainText: false,
          } as IReportOptions
        );
      } else {
        const deleteDecision = await new Promise((resolve) => {
          Confirm.show(
            "Projekt löschen",
            "Soll das Projekt wirklich gelöscht werden?",
            "Nein",
            "Ja, löschen",
            () => {
              resolve(false);
            },
            () => {
              resolve(true);
            }
          );
        });
        if (deleteDecision) {
          this.data.postRequest("/project/delete", {
            project_id: this.data.currentProject?.id,
          });
          Notify.success("Das Projekt wurde erfolgreich gelöscht.");
          this.router.navigate(["/meine-projekte"]);
          // this.close.emit();
        }
      }
    } catch (error) {
      console.error(error);
      Notify.failure("Etwas ist schief gelaufen.");
    }
    this.isLoading = false;
  }
}
