<div class="toggle-component" [class]="classes" *ngIf="show">
  <!-- && !toggle.routerLink -->
  <div
    class="toggle"
    *ngFor="let toggle of toggles; let i = index"
    (click)="emitIndex(i, toggle)"
    [ngClass]="{
      selected: indexSelected === i,
      disabled: toggle.disabled
    }"
    [routerLink]="toggle.routerLink"
    [routerLinkActive]="'selected'"
  >
    <div class="icon" *ngIf="toggle.icon">
      <app-svgs
        [type]="toggle.icon"
        [iconSizePxWidth]="toggle.iconSizePxWidth"
        [iconSizePxHeight]="toggle.iconSizePxHeight"
      ></app-svgs>
    </div>
    <p class="text">{{ toggle.label }}</p>
  </div>
</div>
