<div class="meine-projekte">
  <div class="main no-detail">
    <div class="main-head">
      <div class="left">
        <h1 class="white">
          Projekte von<br /><span class="secondary"
            >{{ auth.currentUser?.profile?.vorname }}
            {{ auth.currentUser?.profile?.nachname }}</span
          >
        </h1>
      </div>
      <div class="right">
        <app-searchbar
          [color]="'primary20'"
          (searchStringChange)="seachStringEventFired($event)"
        ></app-searchbar>

        <app-button iconRight="plus" format="normal" routerLink="add">
          Neues Projekt
        </app-button>
      </div>
    </div>

    <div class="content">
      <div class="table-wrapper">
        <h4
          class="white"
          (click)="veroeffentlichtCollapsed = !veroeffentlichtCollapsed"
        >
          <div class="flex-title">
            Veröffentlichte Projekte<span
              *ngIf="
                publishedProjectsFiltered && publishedProjectsFiltered.length
              "
              class="primary-badge"
              >{{ publishedProjectsFiltered.length }}</span
            >
          </div>
          <app-svgs
            [type]="veroeffentlichtCollapsed ? 'eye' : 'eye-crossed'"
            color="secondary"
          ></app-svgs>
        </h4>
        <table @fade *ngIf="!veroeffentlichtCollapsed">
          <thead>
            <tr>
              <th>Name</th>
              <th>Start</th>
              <th>Bewerbungen</th>
              <th>Zusagen</th>
              <th>In Auswahl</th>
              <th>Zeit bis Start</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let project of publishedProjectsFiltered"
              (click)="openProjekt(project)"
            >
              <td class="flex-center-vertical gap-7">
                <div
                  class="required-dot"
                  *ngIf="
                    project?.meta?.action_required &&
                    project?.meta?.action_required != 0
                  "
                ></div>
                <h3>
                  {{ project?.titel
                  }}<span
                    style="font-size: 18px"
                    *ngIf="project && project.internal_title"
                    ><br />{{ project.internal_title }}</span
                  >
                </h3>
              </td>
              <!-- format date like März 2022 -->
              <td>{{ project?.zeitraum_von | date : "MMMM y" : "de-DE" }}</td>
              <td>
                {{ project?.meta?.bewerbungen_neu || 0 }} neue /
                {{ project?.meta?.bewerbungen_gesamt || 0 }} Gesamt
              </td>
              <td>{{ project?.meta?.zusagen || 0 }}</td>
              <td>{{ project?.meta?.in_auswahl || 0 }}</td>
              <td>
                <app-progress-bar
                  [percent]="zeitBis[project?.id || 0]"
                  *ngIf="project?.id"
                ></app-progress-bar>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="table-wrapper">
        <h4
          class="white"
          (click)="unveroeffentlichtCollapsed = !unveroeffentlichtCollapsed"
        >
          <div class="flex-title">
            Unveröffentlichte Projekte<span
              *ngIf="
                unpublishedProjectsFiltered &&
                unpublishedProjectsFiltered.length
              "
              class="primary-badge"
              >{{ unpublishedProjectsFiltered.length }}</span
            >
          </div>
          <app-svgs
            [type]="unveroeffentlichtCollapsed ? 'eye' : 'eye-crossed'"
            color="secondary"
          ></app-svgs>
        </h4>
        <div class="inner-scroll"></div>
        <table @fade *ngIf="!unveroeffentlichtCollapsed">
          <thead>
            <tr>
              <th>Name</th>
              <th>Bewerbungen</th>
              <th>Zusagen</th>
              <th>In Auswahl</th>
              <th>Zeit bis Start</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let project of unpublishedProjectsFiltered"
              (click)="openProjekt(project)"
            >
              <td>
                <h3>
                  {{ project?.titel
                  }}<span
                    style="font-size: 18px"
                    *ngIf="project && project.internal_title"
                    ><br />{{ project.internal_title }}</span
                  >
                </h3>
              </td>
              <td>
                {{ project?.meta?.bewerbungen_neu || 0 }} neue /
                {{ project?.meta?.bewerbungen_gesamt || 0 }} Gesamt
              </td>
              <td>{{ project?.meta?.zusagen || 0 }}</td>
              <td>{{ project?.meta?.in_auswahl || 0 }}</td>
              <td>
                <app-progress-bar
                  [percent]="zeitBis[project?.id || 0]"
                  *ngIf="project?.id"
                ></app-progress-bar>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="table-wrapper">
        <h4
          class="white"
          (click)="oldProjectsCollapsed = !oldProjectsCollapsed"
        >
          <div class="flex-title">
            Vergangene Projekte<span
              *ngIf="oldProjectsFiltered && oldProjectsFiltered.length"
              class="primary-badge"
              >{{ oldProjectsFiltered.length }}</span
            >
          </div>
          <app-svgs
            [type]="oldProjectsCollapsed ? 'eye' : 'eye-crossed'"
            color="secondary"
          ></app-svgs>
        </h4>
        <table @fade *ngIf="!oldProjectsCollapsed">
          <thead>
            <tr>
              <th>Name</th>
              <th>Bewerbungen</th>
              <th>Zusagen</th>
              <th>In Auswahl</th>
              <th>Zeit bis Start</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let project of oldProjectsFiltered"
              (click)="openProjekt(project)"
            >
              <td>
                <h3>
                  {{ project?.titel
                  }}<span
                    style="font-size: 18px"
                    *ngIf="project && project.internal_title"
                    ><br />{{ project.internal_title }}</span
                  >
                </h3>
              </td>
              <td>
                {{ project?.meta?.bewerbungen_neu || 0 }} neue /
                {{ project?.meta?.bewerbungen_gesamt || 0 }} Gesamt
              </td>
              <td>{{ project?.meta?.zusagen || 0 }}</td>
              <td>{{ project?.meta?.in_auswahl || 0 }}</td>
              <td>
                <app-progress-bar
                  [percent]="zeitBis[project?.id || 0]"
                  *ngIf="project?.id"
                ></app-progress-bar>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2
        class="white margin-bottom-36"
        *ngIf="archivedProjectsFiltered.length"
      >
        Archiv
      </h2>

      <div class="table-wrapper" *ngFor="let arch of archivedProjectsFiltered">
        <h4 class="white" (click)="arch.collapsed = !arch.collapsed">
          <div class="flex-title">
            {{ arch.year
            }}<span *ngIf="arch.projects.length" class="primary-badge">{{
              arch.projects.length
            }}</span>
          </div>
          <app-svgs
            [type]="arch.collapsed ? 'eye' : 'eye-crossed'"
            color="secondary"
          ></app-svgs>
        </h4>
        <table @fade *ngIf="!arch.collapsed">
          <thead>
            <tr>
              <th>Name</th>
              <th>Bewerbungen</th>
              <th>Zusagen</th>
              <th>In Auswahl</th>
              <th>Zeit bis Start</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let project of arch.projects"
              (click)="openProjekt(project)"
            >
              <td>
                <h3>
                  {{ project?.titel
                  }}<span
                    style="font-size: 18px"
                    *ngIf="project && project.internal_title"
                    ><br />{{ project.internal_title }}</span
                  >
                </h3>
              </td>
              <td>
                {{ project?.meta?.bewerbungen_neu || 0 }} neue /
                {{ project?.meta?.bewerbungen_gesamt || 0 }} Gesamt
              </td>
              <td>{{ project?.meta?.zusagen || 0 }}</td>
              <td>{{ project?.meta?.in_auswahl || 0 }}</td>
              <td>
                <app-progress-bar
                  [percent]="zeitBis[project?.id || 0]"
                  *ngIf="project?.id"
                ></app-progress-bar>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="show-logout-btn">
        <app-button
          color="secondary"
          (click)="auth.doLogout()"
          hoverColor="white"
          >Ausloggen</app-button
        >
      </div>
    </div>
  </div>
</div>
