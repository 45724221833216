<app-modal (close)="close.emit(true)">
  <div>
    <h5>Rollen Topteamer</h5>
    <div class="three-cols">
      <div class="left">
        <h2>Topteamer in Auswahl</h2>
        <!-- *ngIf="left_side_applications && left_side_applications.length > 0" -->
        <div
          class="users user-wrap"
          [dragula]="'tags'"
          [(dragulaModel)]="left_side_applications"
        >
          <div
            class="user handle"
            *ngFor="let application of left_side_applications || []"
          >
            <div class="inner">
              <span>
                {{ application?.user?.profile?.vorname }}
                {{ application?.user?.profile?.nachname }}
              </span>
              <app-svgs
                type="enlarge-round-arrow-small"
                color="primary40"
              ></app-svgs>
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <div>
          <span>
            <p>Ziehe die Personen in die entsprechenden Aufgabenbereiche</p>
            <app-svgs type="enlarge-round-arrow"></app-svgs>
          </span>
        </div>
      </div>
      <div class="right">
        <h2>Rollen</h2>
        <div class="add-job-role">
          <input
            type="text"
            [(ngModel)]="currentJobRoleString"
            [ngModelOptions]="{ standalone: true }"
            (keyup.enter)="addJobRole()"
            placeholder="Rolle hinzufügen"
          />
          <app-svgs
            type="plus"
            color="primary"
            (click)="addJobRole()"
          ></app-svgs>
        </div>

        <div class="job-roles" *ngIf="job_roles">
          <div
            *ngFor="let job_role of job_roles || []; let i = index"
            class="job-role"
          >
            <label>
              <input type="text" [(ngModel)]="job_role.name" />
              <div class="up-down-container">
                <app-svgs
                  color="primary"
                  type="chevron-down-small"
                  class="transform-rotate-180"
                  (click)="moveIndexInJobRolesArray(i, true)"
                ></app-svgs>
                <app-svgs
                  type="chevron-down-small"
                  color="primary"
                  (click)="moveIndexInJobRolesArray(i, false)"
                ></app-svgs>
                <app-svgs
                  type="trash"
                  color="primary"
                  (click)="removeJobRole(job_role)"
                ></app-svgs>
              </div>
            </label>
            <div
              class="category user-wrap"
              [dragula]="'tags'"
              [(dragulaModel)]="job_role.applications"
            >
              <div
                class="user handle"
                *ngFor="let application of job_role.applications || []"
              >
                <div class="inner">
                  <span>
                    {{ application?.user?.profile?.vorname }}
                    {{ application?.user?.profile?.nachname }}
                  </span>
                  <app-svgs
                    type="enlarge-round-arrow-small"
                    color="primary40"
                  ></app-svgs>
                </div>
              </div>
              <!-- <label>{{ job_role }}</label> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <app-button (click)="saveJobRoles()" [lessHeight]="true">
        Speichern
      </app-button>
    </div>
  </div>
</app-modal>
