import { Application, Kontakt } from "@ttc_types/types";
import { DataService } from "src/app/data.service";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-kontakt-liste",
  templateUrl: "./kontakt-liste.component.html",
  styleUrls: ["./kontakt-liste.component.scss"],
})
export class KontaktListeComponent implements OnInit {
  @Input()
  application!: Application;

  @Input()
  show_ansprechperson: boolean = false;

  @Input()
  show_weitere_projektmanager: boolean = false;

  @Input()
  show_teamleiter_vor_ort: boolean = false;

  @Input()
  show_arbeitskraefte: boolean = false;

  kontaktliste: Kontakt[] = [];

  constructor(public data: DataService) {}

  async ngOnInit() {
    this.kontaktliste = (
      await this.data.getContactListForApplication(this.application.id!)
    ).map((el: any) => el as Kontakt);
  }
}
