<div class="timepicker-wrapper">
  <div class="select-wrap">
    <select [(ngModel)]="selectedHour" (ngModelChange)="onHourChange()">
      <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
    </select>
  </div>
  <span class="trenner"> : </span>
  <div class="cm-wrap h select-wrap">
    <select [(ngModel)]="selectedMinute" (ngModelChange)="onMinuteChange()">
      <option *ngFor="let minute of minutes" [value]="minute">
        {{ minute }}
      </option>
    </select>
  </div>
</div>
