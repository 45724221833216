import { DataService } from "src/app/data.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ab_nv } from "src/app/pages/apply/sedPersForm";
import { geschlecht, steuerKlassen } from "@ttc_types/datasets";
import { staatsangehoearigkeit } from "@ttc_types/staatsangehoerigkeit";
import {
  User,
  Media,
  BeschaeftigungLaufendesJahr,
  ArbeitsbeginnStatusProfileView,
} from "@ttc_types/types";
import { environment } from "src/environments/environment.prod";

import { privateKK } from "@ttc_types/privateKK";
import { gesetzlicheKK } from "@ttc_types/gesetzlicheKK";
import { konfessionen } from "@ttc_types/konfessionen";
import { staaten } from "@ttc_types/staaten";

import { DatePipe } from "@angular/common";
// import * as dayjs from "dayjs";
import { dayjs } from "src/app/shared/date-util";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  constructor(private data: DataService, private datePipe: DatePipe) {}

  currentYear: string = dayjs().year().toString();

  @Input()
  user!: User;

  @Input()
  arbeitskreafteEdit: boolean = false;

  @Output()
  close: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Input()
  profileForm!: UntypedFormGroup;

  @Input()
  originalProfileForm!: UntypedFormGroup;

  @Input()
  submitAttempt!: boolean;

  @Input()
  showStatus: boolean = false;

  @Input()
  hasValidStatusErrors: string[] = [];

  geschlecht = geschlecht;

  steuerKlassen = steuerKlassen;

  ab_nv = ab_nv;

  laufendesJahrKfB: number = 0;

  gesetzlicheKK = gesetzlicheKK;

  privateKK = privateKK;

  staaten = staaten;

  enableDisabled: boolean = false;

  @Output()
  isUploading = new EventEmitter<boolean>();

  getStaat(value: string) {
    return this.staaten.filter((el) => el.id === value)[0]?.label;
  }

  get getStaaten() {
    return staaten;
  }

  get getKK() {
    const typ = this.profileForm.value.krankenkasse_typ;
    // eslint-disable-next-line no-nested-ternary
    if (typ == "gesetzlich") {
      return this.gesetzlicheKK;
    }
    if (typ == "privat") {
      return this.privateKK;
    }
    return [];
  }

  get getKonfessionen() {
    return konfessionen;
  }

  get getStaatsstaatsangehoearigkeit() {
    return staatsangehoearigkeit;
  }

  arbeitsbeginnStatus: ArbeitsbeginnStatusProfileView[] = [];

  resourceUrl: string = `${environment.resourceUrl}/`;

  async ngOnInit() {
    this.privateKK.sort((a, b) => a.localeCompare(b));
    this.gesetzlicheKK.sort((a, b) => a.localeCompare(b));

    setTimeout(() => {
      this.setArbeitsbeginnStatus();
      this.setLaufendesJahrKfB();
    }, 200);
  }

  ngAfterContentChecked(): void {
    this.enableDisabled = this.profileForm.disabled;
  }

  setLaufendesJahrKfB() {
    if (this.user?.profile?.laufendes_jahr?.KurzfristigeBeschaeftigung) {
      this.laufendesJahrKfB =
        this.user?.profile?.laufendes_jahr.KurzfristigeBeschaeftigung.reduce(
          (prev: number, el: BeschaeftigungLaufendesJahr) =>
            (el.tage_beschaeftigt || 0) + prev,
          0
        );
    }
  }

  isChanged(key: string, subFormGroup: string = ""): boolean {
    if (subFormGroup) {
      return (
        (<UntypedFormGroup>this.originalProfileForm.controls[subFormGroup]).value[
          key
        ] != (<UntypedFormGroup>this.profileForm.controls[subFormGroup]).value[key]
      );
    }
    return (
      this.originalProfileForm.controls[key].value !=
      this.profileForm.controls[key].value
    );
  }

  // getIsChangedFunction() {
  //   return this.isChanged.bind(this);
  // }

  reset(key: string, subFormGroup: string = "") {
    if (subFormGroup) {
      (<UntypedFormGroup>this.profileForm.controls[subFormGroup]).controls[
        key
      ].setValue(this.originalProfileForm.controls[subFormGroup].value[key]);
    } else {
      this.profileForm.controls[key].setValue(
        this.originalProfileForm.value[key]
      );
    }
  }

  setArbeitsbeginnStatus() {
    if (this.profileForm?.value?.arbeitsbeginn_status) {
      for (const key of Object.keys(
        this.profileForm.value.arbeitsbeginn_status
      )) {
        if (this.profileForm.value.arbeitsbeginn_status[key] === true) {
          const tmp: ArbeitsbeginnStatusProfileView = {
            name: "",
            fach: "",
            nachweise: [],
            gueltig_bis: null,
          };
          tmp.name = this.ab_nv[key].label;
          console.log(
            `(
            this.profileForm.controls.arbeitsbeginn_status_additional as FormGroup
          ).controls`,
            (
              this.profileForm.controls
                .arbeitsbeginn_status_additional as UntypedFormGroup
            ).controls[`${key}_fach`]
          );
          if (this.profileForm?.controls?.arbeitsbeginn_status_additional) {
            const fg = this.profileForm.controls
              .arbeitsbeginn_status_additional as UntypedFormGroup;

            if (fg.controls[`${key}_fach`]?.value) {
              tmp.fach = fg.controls[`${key}_fach`].value;
            }
            if (fg.controls[`${key}_nachweis`]?.value) {
              tmp.nachweise = fg.controls[`${key}_nachweis`].value.map(
                (el: Media) => {
                  // eslint-disable-next-line dot-notation, no-param-reassign
                  el["url"] = this.resourceUrl + el.nameOnDisk;
                  return el;
                }
              );
            }
            if (
              fg.controls[`${key}_gueltig_bis`]?.value &&
              fg.controls[`${key}_gueltig_bis`]?.value != ""
            ) {
              tmp.gueltig_bis = this.datePipe.transform(
                fg.controls[`${key}_gueltig_bis`].value,
                "dd.MM.yyyy"
              );
            }
          }
          this.arbeitsbeginnStatus.push(tmp);
        }
      }
      console.log(`this.arbeitsbeginnStatus`, this.arbeitsbeginnStatus);
    }
  }
}
