import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { User } from "@ttc_types/types";
import { DataService } from "src/app/data.service";

@Component({
  selector: "master-data-export-configurator",
  templateUrl: "./master-data-export-configurator.component.html",
  styleUrls: ["./master-data-export-configurator.component.scss"],
})
export class MasterDataExportConfiguratorComponent implements OnInit {
  @Input() applicantsForExport: User[] = [];

  @Output()
  onDownloadClick = new EventEmitter<any>(false);

  selectableMasterDataFields: { key: string; displayName: string }[] = [
    { key: "plz", displayName: "PLZ" },
    { key: "ort", displayName: "Ort" },
    { key: "geschlecht", displayName: "Geschlecht" },
    { key: "weitere_vornamen", displayName: "Weitere Vornamen" },
    { key: "geburtsdatum", displayName: "Geboren am" },
    { key: "staatsangehoerigkeit", displayName: "Staatsangehörigkeit" },
    { key: "geburtsstadt", displayName: "Geburtsort" },
    { key: "geburtsland", displayName: "Geburtsland" },
    { key: "ausweisnummer", displayName: "Ausweisnummer" },
    { key: "tel", displayName: "Telefon" },
    { key: "strasse", displayName: "Straße" },
    { key: "hausnr", displayName: "Nr." },
    { key: "wohnland", displayName: "Wohnland" },
    { key: "iban", displayName: "IBAN" },
    { key: "bic", displayName: "BIC" },
    { key: "existiert_hauptarbeitgeber", displayName: "Hauptarbeitgeber" },
    { key: "aufenthaltstitel", displayName: "Aufenthaltstitel" },
    { key: "gewerbeschein", displayName: "Gewerbeschein" },
    { key: "gesundheitszeugnis", displayName: "Gesundheitszeugnis" },
    {
      key: "sozialversicherungsnummer",
      displayName: "Sozialversicherungsnummer",
    },
    {
      key: "steueridentifikationsnummer",
      displayName: "Steueridentifikationsnummer",
    },
    { key: "steuerklasse", displayName: "Steuerklasse" },
    { key: "krankenkasse_typ", displayName: "Krankenkasse" },
    { key: "krankenkasse_name", displayName: "Krankenkassenname" },
    { key: "familienstand", displayName: "Familienstand" },
    { key: "kinderfreibetrag", displayName: "Kinderfreibetrag" },
    { key: "arbeitsbeginn_status", displayName: "Beschäftigung" },
    { key: "religionszugehoerigkeit", displayName: "Religion" },
  ];

  selectedMasterDataFields: { key: string; displayName: string }[] = [
    { key: "personalnummer", displayName: "Personalnummer" },
    { key: "status", displayName: "Bewerbungstatus" },
    { key: "vorname", displayName: "Vorname" },
    { key: "nachname", displayName: "Nachname" },
    { key: "email", displayName: "E-Mail" },
  ];

  constructor(private data: DataService) {}

  ngOnInit(): void {
    console.log("this.applicantsForExport", this.applicantsForExport);
  }

  ngOnDestroy(): void {}

  /**
   * Construct excel file and trigger download
   */
  exportAndDownload() {
    console.log("exportAndDownload");
    this.onDownloadClick.emit({
      applicants: this.applicantsForExport,
      selectedMasterDataFields: this.selectedMasterDataFields,
    });
  }
}
