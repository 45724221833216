<div class="jobsuche">
  <div
    class="main display-none-jobsuche jobdetail"
    [ngClass]="{ display: currentProjectDetail }"
  >
    <app-header-w-backbtn-logo
      [backBtn]="true"
      (backBtnClicked)="router.navigate(['/jobs'])"
    >
    </app-header-w-backbtn-logo>
    <h1 class="white">
      {{ currentProjectDetail?.titel }}
    </h1>
    <app-mehr-infos
      *ngIf="currentProjectDetail"
      [project]="currentProjectDetail"
      (close)="router.navigate(['/jobs'])"
      [whiteBg]="true"
      [closeBtn]="false"
      [showTitle]="false"
    ></app-mehr-infos>
  </div>
  <div
    class="main joblist"
    [ngClass]="{
      'overflow-hidden': currentProjectDetail,
      'display-none-jobsuche': currentProjectDetail,
      'register-button-active':
        !data.hideJetztBewerbenButton && !data.auth.currentUser
    }"
  >
    <h1 class="white">
      Finde deinen <br /><span class="secondary">passenden Job</span>
    </h1>
    <app-searchbar
      (searchStringChange)="seachStringEventFired($event)"
    ></app-searchbar>
    <div class="results" *ngIf="filteredProjects && filteredProjects.length">
      <app-job-card
        *ngFor="let job of filteredProjects"
        [job]="job"
        [showProjectFullBadge]="!job!.bewerbung_moeglich"
        routerLink="/jobs/{{ job!.id! }}"
      >
      </app-job-card>
    </div>
    <div class="results" *ngIf="!filteredProjects || !filteredProjects.length">
      <p>Leider konnten wir keine Jobs finden.</p>
    </div>
  </div>
  <div class="detail">
    <div class="logo-container">
      <img src="assets/svgs/logo_big_claim.svg" alt="Logo Claim" class="logo" />
    </div>
    <app-mehr-infos
      *ngIf="currentProjectDetail"
      (close)="router.navigate(['/jobs'])"
      [project]="currentProjectDetail"
      [whiteBg]="true"
    ></app-mehr-infos>
  </div>
</div>
