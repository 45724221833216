<app-modal
  class="project-settings-modal"
  (close)="close.emit()"
  [formGroup]="settingsForm"
>
  <h4 class="">Projekteinstellungen</h4>
  <div class="two-cols">
    <div style="min-width: 50%">
      <h5 for="internal_title">Interne Projektbezeichnung:</h5>
      <input
        type="text"
        name="internal_title"
        formControlName="internal_title"
        placeholder="Interne Projektbezeichnung"
        [value]="data.currentProjectInternalTitle ?? ''"
      />
    </div>
  </div>
  <div class="two-cols">
    <label for="bewerbung_moeglich" class="checkbox justify-start">
      <input
        type="checkbox"
        id="bewerbung_moeglich"
        formControlName="bewerbung_moeglich"
      />
      Bewerbung möglich
    </label>
    <label
      for="live_checkin"
      class="checkbox justify-start"
      [ngClass]="{ disabled: !canChangeLiveCheckinMode }"
    >
      <input type="checkbox" id="live_checkin" formControlName="live_checkin" />
      Mit Live-Check-In / -Out
    </label>
  </div>
  <app-collapsable>
    <h4>Verantwortliche</h4>
    <div>
      <div class="two-cols">
        <div class="left">
          <div class="checkbox-left-right">
            <div class="left">
              <h5 class="subscription">Ansprechperson</h5>
              <ng-select
                *ngIf="getProjectManagers"
                formControlName="ansprechperson"
                class="hide-delete-button"
              >
                <ng-option
                  *ngFor="let pm of getProjectManagers || []"
                  [value]="pm.id"
                  >{{ pm.profile?.vorname || pm.email }}
                  {{ pm.profile?.nachname }}</ng-option
                >
              </ng-select>
              <div
                *ngIf="
                  settingsForm.get('ansprechperson')?.invalid && submitAttempt
                "
                class="alert"
              >
                Dieses Feld ist erforderlich.
              </div>
            </div>
            <div class="right">
              <h5 class="subscription">Admin</h5>
              <label for="ansprechperson_admin" class="checkbox">
                <input
                  type="checkbox"
                  [attr.disabled]="
                    !auth.currentUser?.role ||
                    auth.currentUser?.role != 'superadmin'
                      ? true
                      : null
                  "
                  name="ansprechperson_admin"
                  id="ansprechperson_admin"
                  formControlName="ansprechperson_admin"
                />
              </label>
            </div>
          </div>

          <!-- Weitere Projektleitungen -->
          <h5 class="subscription margin-top-16">Weitere Projektleitungen</h5>

          <div formArrayName="weitere_projektmanager">
            <div
              class="checkbox-left-right margin-bottom-16"
              *ngFor="let fg of weitere_projektmanager.controls; index as i"
              [formGroupName]="i"
            >
              <div class="left">
                <ng-select
                  formControlName="id"
                  (clear)="removePM(i)"
                  *ngIf="getProjectManagers"
                >
                  <ng-option
                    *ngFor="let pm of getProjectManagers"
                    [value]="pm.id"
                    >{{ pm.profile?.vorname || pm.email }}
                    {{ pm.profile?.nachname }}</ng-option
                  >
                </ng-select>
              </div>
              <div class="right">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    formControlName="admin"
                    [attr.disabled]="
                      !auth.currentUser?.role ||
                      auth.currentUser?.role != 'superadmin'
                        ? true
                        : null
                    "
                  />
                </label>
              </div>
            </div>

            <app-button
              color="primary80"
              [lessHeight]="true"
              background="primary10"
              iconRight="user"
              (click)="addPM()"
              >PM hinzufügen</app-button
            >
          </div>
          <!-- Weitere Projektmanager end -->

          <p class="subscription margin-top-16">
            Als Admin darf man für dieses Projekt Verträge unterschreiben.
          </p>
        </div>
        <div class="right">
          <h5 class="subscription">Teamleiter vor Ort</h5>
          <ng-select
            placeholder="Ohne Teamleitung"
            formControlName="teamleiter_vor_ort"
            *ngIf="teamleadList"
          >
            <ng-option *ngFor="let appl of teamleadList" [value]="appl.id"
              >{{ appl.profile?.vorname || appl.email }}
              {{ appl.profile?.nachname }}</ng-option
            >
          </ng-select>
          <p class="subscription margin-top-24">
            Der Teamleiter hat Zugriff auf die Zeiterfassung und kann diese vom
            Kunden unterzeichnen lassen. Außerdem stehen die Kontaktdaten der
            anderen Topteamer zur Verfügung.
          </p>
          <!-- Weitere Teamleiter -->
          <h5 class="subscription margin-top-16">Weitere Teamleiter</h5>

          <div formArrayName="additional_teamleads">
            <div
              class="checkbox-left-right margin-bottom-16"
              *ngFor="let fg of additional_teamleads.controls; index as i"
              [formGroupName]="i"
            >
              <div class="left">
                <ng-select
                  formControlName="id"
                  (clear)="removeTL(i)"
                  *ngIf="teamleadList"
                >
                  <ng-option *ngFor="let pm of teamleadList" [value]="pm.id"
                    >{{ pm.profile?.vorname || pm.email }}
                    {{ pm.profile?.nachname }}</ng-option
                  >
                </ng-select>
              </div>
            </div>

            <app-button
              color="primary80"
              [lessHeight]="true"
              background="primary10"
              iconRight="user"
              (click)="addTL()"
              >Teamleiter hinzufügen</app-button
            >
          </div>
          <!-- Weitere Teamleiter end -->
          <label
            for="jeder_mitarbeiter_darf_unterschrift_von_kunden_einholen"
            class="checkbox justify-start margin-top-24"
          >
            <input
              type="checkbox"
              id="jeder_mitarbeiter_darf_unterschrift_von_kunden_einholen"
              formControlName="jeder_mitarbeiter_darf_unterschrift_von_kunden_einholen"
            />
            Jeder Mitarbeiter darf für eigene Zeiten Unterschrift von Kunden
            einholen.
          </label>
        </div>
      </div>
    </div>
  </app-collapsable>
  <app-collapsable>
    <h4>Kunde Ansprechpartner</h4>
    <div>
      <div class="two-cols">
        <div class="left">
          <h5 class="subscription margin-top-16">Kunde Ansprechpartner Name</h5>
          <input
            type="text"
            name="kunde_ansprechpartner_name"
            id="kunde_ansprechpartner_name"
            formControlName="kunde_ansprechpartner_name"
          />
          <p class="subscription margin-top-24">
            Der Kunde Ansprechpartner wird dem Teamleiter als Kontakt für
            Rückfragen angezeigt, wenn angegeben.
          </p>
        </div>
        <div class="right">
          <h5 class="subscription margin-top-16">
            Kunde Ansprechpartner E-Mail-Adresse
          </h5>
          <input
            type="email"
            name="kunde_ansprechpartner_email"
            id="kunde_ansprechpartner_email"
            formControlName="kunde_ansprechpartner_email"
          />
          <h5 class="subscription margin-top-16">
            Kunde Ansprechpartner Telefon
          </h5>
          <input
            type="tel"
            name="kunde_ansprechpartner_tel"
            id="kunde_ansprechpartner_tel"
            formControlName="kunde_ansprechpartner_tel"
          />
        </div>
      </div>
    </div>
  </app-collapsable>
  <app-collapsable>
    <h4>Personalbedarf</h4>
    <div>
      <div class="two-cols">
        <div class="left">
          <p class="subscription">
            Dieser Wert wird lediglich für die Statistik auf der Personalseite
            herangezogen.
          </p>
        </div>
        <div class="right">
          <app-plus-minus-input
            [min]="0"
            unit="Personen"
            [formCntrl]="settingsForm.controls['personalbedarf']"
          ></app-plus-minus-input>
        </div>
      </div>
    </div>
  </app-collapsable>
  <app-collapsable>
    <h4>Verträge</h4>
    <div>
      <div class="two-cols">
        <div class="left">
          <p class="subscription">
            Zusätzliche Vertragsklauseln, die für das gesamte Projekt gültig
            sind. Diese werden beim Erstellen der Verträge automatisch
            übernommen.
          </p>
        </div>
        <div class="right">
          <!-- <quill-editor
            formControlName="zusaetzliche_vertragsklauseln"
          ></quill-editor> -->
          <app-editor
            [html]="
              settingsForm.controls['zusaetzliche_vertragsklauseln'].value
            "
            (htmlChange)="
              settingsForm.controls['zusaetzliche_vertragsklauseln'].setValue(
                $event
              )
            "
          ></app-editor>
        </div>
      </div>
    </div>
  </app-collapsable>
  <app-collapsable>
    <h4>Abrechnung</h4>
    <div>
      <div class="two-cols">
        <div class="left">
          <h5>Kostenstelle</h5>
          <ng-select
            formControlName="abrechnung_kostenstelle"
            [items]="kostenstellen"
            bindLabel="label"
            bindValue="value"
          >
          </ng-select>
        </div>
        <div class="right">
          <h5>Topteam Tätigkeit</h5>
          <ng-select formControlName="abrechnung_topteam_taetigkeit">
            <ng-option [value]="'Host/Hostess'">Host/Hostess</ng-option>
          </ng-select>
        </div>
      </div>
    </div>
  </app-collapsable>
  <app-collapsable
    [collapsed]="true"
    class="projekt-löschen"
    *ngIf="auth.currentUser?.role == 'superadmin'"
  >
    <h4>Projekt löschen</h4>
    <div class="two-cols">
      <div class="left">
        <p class="subscription">
          Vorher müssen alle Bewerbungen abgelehnt oder gekündigt sein.
        </p>
      </div>
      <div class="right">
        <app-button
          (click)="deleteProject()"
          iconRight="trash"
          [isLoading]="isLoading"
          [disabled]="isLoading"
          color="fail"
          [lessHeight]="true"
          >Löschen</app-button
        >
      </div>
    </div>
  </app-collapsable>
  <app-collapsable
    [collapsed]="true"
    class="projekt-löschen"
    *ngIf="auth.currentUser?.role == 'superadmin'"
  >
    <h4>Projekt archivieren</h4>
    <div class="two-cols">
      <div class="left">
        <p class="subscription">
          Das Projekt wird der Übersichtlichkeit halber archiviert.
        </p>
      </div>
      <div class="right">
        <app-button
          (click)="archiveProject(!data.currentProject?.archived)"
          [iconRight]="data.currentProject?.archived ? 'eye-crossed' : 'eye'"
          [isLoading]="isLoading"
          [disabled]="isLoading"
          [lessHeight]="true"
          color="primary80"
          >{{
            !data.currentProject?.archived
              ? "Archivieren"
              : "Archivieren rückgängig machen"
          }}</app-button
        >
      </div>
    </div>
  </app-collapsable>
  <div class="button-right">
    <app-button
      color="secondary-gradient"
      background="primary10"
      (click)="saveProjectSettings()"
      [lessHeight]="true"
      [disabled]="isLoading"
      [isLoading]="isLoading"
      >SPEICHERN</app-button
    >
  </div>
</app-modal>
