<div class="confirm-email">
  <div class="main">
    <h2 class="white" *ngIf="success">
      Vielen Dank! Ihre E-Mail-Adresse wurde bestätigt.
    </h2>
    <h2 class="white" *ngIf="failure">
      Leider ist der Link nicht mehr gültig.
    </h2>
    <!-- <p class="white">
      Noch kein Profil? Bewerbe dich hier auf einen unserer Jobs.
    </p> -->
    <div class="gold-link" routerLink="/jobs">Jobsuche</div>
    <!--  -->
  </div>
  <div class="detail">
    <div class="logo-container">
      <img src="assets/svgs/logo_big_claim.svg" alt="Logo Claim" class="logo" />
    </div>
  </div>
</div>
