import * as XLSX from "xlsx";

export const setDynamicHeightsAndWidthsOfWorksheet = (
  ws: XLSX.WorkSheet,
  worksheetData: any[],
  header: string[]
) => {
  const widths = header.map((h) => h.length);
  const heights = [12];
  for (const data of worksheetData) {
    let height = 0;
    // eslint-disable-next-line guard-for-in
    const vals: string[] = Object.values(data);
    for (let i = 0; i < vals.length; i++) {
      if (typeof vals[i] == "string") {
        const splits = vals[i].split("\n");
        //width
        const h_count = splits.reduce((acc: number, val: string) => {
          if (val.length > acc) {
            return val.length;
          }
          return acc;
        }, 0);
        if (h_count > widths[i]) {
          widths[i] = h_count;
        }

        //height
        const n_count = splits.length;
        if (n_count * 12 > height) {
          height = n_count * 12;
        }
      }
    }
    heights.push(height);
  }
  // eslint-disable-next-line no-param-reassign
  ws["!cols"] = widths.map((str) => ({ width: str }));
  // eslint-disable-next-line no-param-reassign
  ws["!rows"] = heights.map((h) => ({ hpt: h }));
};
