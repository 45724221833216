<div class="ordering-icons">
  <app-svgs
    class="up"
    [color]="'secondary'"
    type="chevron-down-small"
    *ngIf="!sortDirection || sortDirection == 2"
  ></app-svgs>
  <app-svgs
    class="down"
    [color]="'secondary'"
    type="chevron-down-small"
    *ngIf="!sortDirection || sortDirection == 1"
  ></app-svgs>
</div>
