import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { User } from "@ttc_types/types";

@Component({
  selector: "app-projektdaten",
  templateUrl: "./projektdaten.component.html",
  styleUrls: ["./projektdaten.component.scss"],
})
export class ProjektdatenComponent implements OnInit {
  @Input()
  user!: User;

  @Output()
  refreshList: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Output()
  gegengezeichnet: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Output()
  kuendigen = new EventEmitter();

  @Input()
  showVertrag: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
