<div class="exporte">
  <div class="main">
    <h1 class="white">Datev Exporte</h1>
    <div class="left-right">
      <div class="table-wrapper flex gap-12 direction-column">
        <h2 class="white">Stammdaten</h2>
        <div
          *ngIf="stammdatenExistingExports.length"
          class="flex gap-12 direction-column"
        >
          <h4 class="white">Monat wählen als Eintritts- und Austrittsdatum</h4>
          <div class="months">
            <ng-select
              [(ngModel)]="selectedMonthForDatevStammdatenExport"
              placeholder="Monat wählen"
              [items]="stammdatenAvailableMonths"
              bindLabel="mm_yyyy"
            ></ng-select>
            <!-- bindValue="mm_yyyy" -->
          </div>
          <div
            class="export-dates-list"
            *ngIf="
              selectedMonthForDatevStammdatenExport &&
              selectedMonthForDatevStammdatenExport.export_dates
            "
          >
            <div
              class="white"
              *ngFor="
                let date of selectedMonthForDatevStammdatenExport.export_dates
              "
            >
              <h6 class="alert" *ngIf="date.export?.is_temporary_export">
                Dies ist nur ein Testexport für den laufenden Monat. Löschen Sie
                diesen bitte bevor Sie einen neuen erstellen.
              </h6>
              <div class="flex gap-12">
                <b class="underline flex-grow-1">
                  {{ date.mm_yyyy }} - Iteration {{ date.iteration }}
                </b>
                <span
                  *ngIf="date.export?.festgeschrieben"
                  class="primary60 cursor-pointer a"
                >
                  Festgeschrieben
                </span>
                <span
                  (click)="iterationFestschreiben(date.export)"
                  *ngIf="
                    !date.export?.festgeschrieben &&
                    !date.export?.is_temporary_export &&
                    !exportLoading
                  "
                  class="success cursor-pointer a"
                >
                  Festschreiben
                </span>
                <span
                  (click)="iterationLoeschen(date.export)"
                  *ngIf="!date.export?.festgeschrieben && !exportLoading"
                  class="alert cursor-pointer a"
                >
                  Löschen
                </span>
              </div>
              <span
                class="a cursor-pointer secondary"
                (click)="downloadDatevStammdaten(date, 'wiederanmeldungen')"
              >
                Wiederanmeldungen
              </span>
              <br />
              <span
                class="a cursor-pointer primary60"
                (click)="downloadDatevStammdaten(date, 'neuanmeldungen')"
              >
                Neuanmeldungen
              </span>
            </div>
          </div>
        </div>
        <div
          *ngIf="!stammdatenExistingExports.length"
          class="flex gap-12 direction-column"
        >
          <h4 class="white">
            Bitte wähle das initiale Datum, bis zu welchem die Datev
            Bewegungsdaten manuell gepflegt wurden.
          </h4>
          <!-- *ngIf="!afterFirstExport" -->
          <input
            [disabled]="exportLoading"
            type="date"
            id="stammdaten_initial_datum"
            name="stammdaten_initial_datum"
            [(ngModel)]="stammdatenInitialDatum"
            [value]="stammdatenInitialDatum | date : 'y-MM-dd'"
          />
          <app-button
            color="secondary-gradient"
            [lessHeight]="true"
            (click)="generateDatevStammdaten(true)"
            [disabled]="exportLoading"
            [isLoading]="exportLoading"
            >Generiere Temporäre Datev für diesen Monat</app-button
          >
        </div>
        <!-- *ngIf="!afterFirstExport" -->
        <app-button
          color="secondary-gradient"
          [lessHeight]="true"
          (click)="generateDatevStammdaten()"
          [disabled]="exportLoading"
          [isLoading]="exportLoading"
          >Generiere Datev txt</app-button
        >
      </div>
      <div class="table-wrapper flex gap-12 direction-column">
        <h2 class="white">Arbeitszeitenexport</h2>
        <h4 class="white">Monat wählen für Arbeitszeitenexport</h4>
        <div class="months">
          <ng-select
            [(ngModel)]="selectedMonthForDatevBewegungsdatenExport"
            placeholder="Monat wählen"
            [items]="bewegungsdatenAvailableMonths"
            bindLabel="mm_yyyy"
            bindValue="mm_yyyy"
          ></ng-select>
        </div>
        <app-button
          color="secondary-gradient"
          [lessHeight]="true"
          (click)="downloadDatevBewegungsdaten()"
          [disabled]="exportLoading"
          [isLoading]="exportLoading"
          >Datev txt download</app-button
        >
      </div>
    </div>
    <h1 class="white">Excel Exporte</h1>
    <div class="left-right">
      <div class="table-wrapper flex gap-12 direction-column">
        <h2 class="white">Stammdaten</h2>
        <h4 class="white">Monat wählen als Eintritts- und Austrittsdatum</h4>
        <div class="months">
          <ng-select
            [(ngModel)]="selectedMonth"
            placeholder="Monat wählen"
            [items]="months"
            bindLabel="label"
            bindValue="value"
          ></ng-select>
        </div>
        <app-button
          color="secondary-gradient"
          [lessHeight]="true"
          (click)="downloadAllStammdaten('month')"
          [disabled]="exportLoading"
          [isLoading]="exportLoading"
          >Daten download</app-button
        >
        <div class="flex flex-center">
          <p class="subscription white">oder</p>
        </div>
        <h4 class="white">Zeitraum wählen für Eintritts- und Austrittsdatum</h4>
        <div class="flex gap-12">
          <input
            type="date"
            id="vertrag_unterzeichnen_bis"
            name="vertrag_unterzeichnen_bis"
            [(ngModel)]="stammdaten_export_start"
            [value]="stammdaten_export_start | date : 'y-MM-dd'"
          />
          <input
            type="date"
            id="vertrag_unterzeichnen_bis"
            name="vertrag_unterzeichnen_bis"
            [(ngModel)]="stammdaten_export_end"
            [value]="stammdaten_export_end | date : 'y-MM-dd'"
          />
        </div>
        <app-button
          color="secondary-gradient"
          [lessHeight]="true"
          (click)="downloadAllStammdaten('range')"
          [disabled]="exportLoading"
          [isLoading]="exportLoading"
          >Daten download</app-button
        >
        <div class="flex flex-center">
          <p class="subscription white">oder</p>
        </div>
        <h4 class="white">Nur bestimmte Felder exportieren</h4>
        <p class="secondary">
          Wichtig: Vorher Monat oder Zeitraum oben auswählen
        </p>
        <app-button
          color="secondary-gradient"
          [lessHeight]="true"
          (click)="openMasterDataExportModal()"
          [disabled]="exportLoading"
          [isLoading]="exportLoading"
          >Felder auswählen</app-button
        >
      </div>

      <div class="table-wrapper flex gap-12 direction-column">
        <h2 class="white">Arbeitszeitenexport</h2>
        <ng-select
          class="ng-select-multiple"
          [(ngModel)]="selectedProjects"
          placeholder="Projekte filtern oder leer lassen für alle"
          [items]="projects"
          bindLabel="label"
          bindValue="value"
          class="flex-basis-100"
          [multiple]="true"
          (ngModelChange)="updateUsersAndProjects()"
        >
        </ng-select>
        <ng-select
          class="ng-select-multiple"
          [(ngModel)]="selectedUsers"
          [multiple]="true"
          placeholder="Mitarbeiter filtern oder leer lassen für alle"
          [items]="users"
          bindLabel="label"
          bindValue="value"
          class="flex-basis-100"
        >
        </ng-select>
        <h4 class="white">Zeitraum wählen oder leer lassen</h4>
        <div class="flex gap-12">
          <input
            type="date"
            [(ngModel)]="arbeitszeiten_export_start"
            [value]="arbeitszeiten_export_start | date : 'y-MM-dd'"
            (ngModelChange)="updateUsersAndProjects()"
          />
          <input
            type="date"
            [(ngModel)]="arbeitszeiten_export_end"
            [value]="arbeitszeiten_export_end | date : 'y-MM-dd'"
            (ngModelChange)="updateUsersAndProjects()"
          />
        </div>
        <app-button
          color="secondary-gradient"
          [lessHeight]="true"
          (click)="exportArbeitszeiten()"
          [disabled]="exportLoading"
          [isLoading]="exportLoading"
          >Daten download</app-button
        >

        <!-- <app-button
          color="secondary-gradient"
          [lessHeight]="true"
          (click)="exportAllUsersWithWorkdays()"
          [disabled]="exportLoading"
          [isLoading]="exportLoading"
          >DEBUG exportAllUsersWithWorkdays</app-button
        > -->
        <!-- <app-button
          color="secondary-gradient"
          [lessHeight]="true"
          (click)="exportAllUsersProjectsWithUsers()"
          [disabled]="exportLoading"
          [isLoading]="exportLoading"
          >DEBUG exportAllUsersProjectsWithUsers</app-button
        > -->
      </div>
    </div>
  </div>
</div>

<app-modal
  class="master-data-export-modal"
  *ngIf="showMasterDataExportModal"
  (close)="closeMasterDataExportModal()"
  ><master-data-export-configurator
    (onDownloadClick)="onMasterDataDownload($event)"
  ></master-data-export-configurator
></app-modal>
