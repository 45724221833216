<div class="collapsable">
  <div
    class="heading flex-center-vertical"
    *ngIf="!dontShowCollapsableHeader"
    (click)="collapsed = !collapsed"
  >
    <div class="title">
      <ng-content select="h4"></ng-content>
    </div>
    <div class="right-icon">
      <app-svgs
        *ngIf="collapsed && valid"
        type="checkmark-circle"
        color="success"
      ></app-svgs>
      <app-svgs
        *ngIf="collapsed && !valid"
        type="chevron-down"
        color="primary"
      ></app-svgs>
      <app-svgs *ngIf="!collapsed" type="minus" color="primary"></app-svgs>
    </div>
  </div>
  <div class="content" *ngIf="!collapsed">
    <!-- @fade -->
    <ng-content select="div"></ng-content>
  </div>
</div>
