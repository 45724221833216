<div class="ablauf" *ngIf="data.currentProject?.checkliste">
  <div class="element" *ngFor="let el of data.currentProject?.checkliste">
    <hr />
    <div class="left-right">
      <div class="left" [ngStyle]="{ 'min-width': leftMinWidth + 'px' }">
        <h5 class="white">{{ el.label }}</h5>
        <div class="secondary">
          {{ filterByTrueLength(el.items, "checked") }} /
          {{ el.items.length }}
        </div>
      </div>
      <div class="right">
        <div class="checkbox" *ngFor="let item of el.items">
          <input
            type="checkbox"
            [id]="item.id"
            [name]="item.id"
            [value]="item.id"
            [checked]="item.checked"
            (change)="item.checked = !item.checked; uploadProject()"
          />
          <label [for]="item.id">{{ item.text }}</label>
        </div>
      </div>
    </div>
  </div>
</div>
