import { Component, ElementRef, HostBinding, ViewChild } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { NgSelectConfig } from "@ng-select/ng-select";
import { Toggle } from "@ttc_types/types";
import { Subscription, filter } from "rxjs";
import { DataService } from "src/app/data.service";
import { AuthService } from "src/app/shared/auth.service";
import { UpdateVersionService } from "./update-version.service";

@Component({
  selector: "app-root",
  styleUrls: ["./app.component.scss"],
  templateUrl: "./app.component.html",
})
export class AppComponent {
  // login start
  loginButtonDisabled: boolean = false;

  loginButtonLoading: boolean = false;

  loginFormOpen: boolean = false;

  loginForm = new UntypedFormGroup({
    email: new UntypedFormControl("", [Validators.email, Validators.required]),
    password: new UntypedFormControl("", [Validators.required]),
  });
  // login end

  public activeSidebarIndex: number = -1;

  @HostBinding("class.admin-mode")
  adminMode: boolean = false;

  currentUserSubscription: Subscription | undefined;

  @ViewChild("mobileScrollTarget") mobileScrollTarget?: ElementRef;

  constructor(
    public auth: AuthService,
    public data: DataService,
    public updateVersionService: UpdateVersionService,
    private config: NgSelectConfig,
    public router: Router
  ) {
    this.updateVersionService.startVersionCheck();
    this.config.notFoundText = "Keine Elemente gefunden.";
    router.events
      .pipe(filter((el) => el instanceof NavigationEnd))
      .subscribe((event) => {
        const { url } = event as NavigationEnd;
        console.log(`url`, url);

        this.showSidebar = ![
          "/register",
          "/jobs/apply/",
          "/daten/",
          "/auswahl/",
          "/jobs/vervollstaendigen/",
        ].filter((slug) => url.includes(slug)).length;

        if (window.innerWidth < 900 || window.innerHeight < 900) {
          try {
            console.log("scrolled after page change");
            this.mobileScrollTarget?.nativeElement.scrollIntoView({
              behavior: "smooth",
            });
          } catch (error) {
            console.error(`failed to scroll error`, error);
          }
        }
      });
  }

  public setActiveSidebarIndex(index: number) {
    this.activeSidebarIndex = index;
    // TODO update pages elements
  }

  // ngAfterContentChecked() {
  //   setTimeout(() => {
  //     if (!this.currentUserSubscription) {
  //       this.currentUserSubscription = this.auth.currentUserObs.subscribe(
  //         (user) => {
  //           this.adminMode = ["superadmin", "pm"].includes(user?.role || "");
  //         }
  //       );
  //     }
  //   }, 250);
  // }

  title = "topteam-connect-frontend";

  showSidebar: boolean = true;

  // showContentHeaderMobile: boolean = true;

  regularPages: Toggle[] = [
    {
      label: "Jobsuche",
      icon: "search",
      routerLink: "jobs",
    },
    {
      label: "Meine Jobs",
      icon: "suitcase",
      routerLink: "meine-jobs",
    },
    {
      label: "Profil",
      icon: "avatar",
      routerLink: "daten",
    },
  ];

  pmPages: Toggle[] = [
    {
      label: "Meine Projekte",
      icon: "suitcase",
      routerLink: "meine-projekte",
    },
    {
      label: "Topteamer",
      icon: "avatar",
      routerLink: "arbeitskraefte",
    },
    {
      label: "Profil",
      icon: "avatar",
      routerLink: "daten",
    },
  ];

  superUserPages: Toggle[] = [
    {
      label: "Projekte",
      icon: "suitcase",
      routerLink: "meine-projekte", // eigener view?
    },
    {
      label: "Topteamer",
      icon: "avatar",
      routerLink: "arbeitskraefte", // eigener view?
    },
    {
      label: "Projektleitung",
      icon: "verified-user",
      routerLink: "projektleitung", // eigener view?
    },
    {
      label: "Vorlagen",
      icon: "page-2",
      routerLink: "vorlagen", // eigener view?
    },
    {
      label: "Exporte",
      icon: "cloud-download",
      routerLink: "exporte", // eigener view?
      iconSizePxWidth: 37,
      iconSizePxHeight: 28,
    },
    // {
    //   label: "Import",
    //   icon: "page-2",
    //   routerLink: "import", // eigener view?
    // },
  ];

  get pages(): Toggle[] {
    const currentUser = this.auth.currentUserFnc;
    // console.log(`this.auth.currentUser`, currentUser);
    if (currentUser) {
      if (currentUser.role == "regular") {
        return this.regularPages;
      }
      if (currentUser.role == "pm") {
        return this.pmPages;
      }
      if (currentUser.role == "superadmin") {
        return this.superUserPages;
      }
    }
    return this.regularPages;
  }

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    setTimeout(() => {
      if (!this.currentUserSubscription) {
        this.currentUserSubscription = this.auth.currentUserObs.subscribe(
          (user) => {
            this.adminMode = ["superadmin", "pm"].includes(user?.role || "");
          }
        );
      }
      try {
        this.auth.setUserProfile();
      } catch (error) {
        console.error(error);
      }
    }, 250);
  }

  async login() {
    console.log(`this.loginForm.value`, this.loginForm.value);
    this.loginButtonDisabled = true;
    this.loginButtonLoading = true;
    try {
      await this.auth.signIn(this.loginForm.value, true);
      this.loginButtonDisabled = false;
      this.loginButtonLoading = false;
    } catch (error) {
      this.loginButtonDisabled = false;
      this.loginButtonLoading = false;
      console.error(error);
    }
  }

  abortLogin() {
    this.router.navigateByUrl("/");
    this.loginFormOpen = false;
  }

  // pages: Toggle[] = this.regularPages;
}
