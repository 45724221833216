// https://de.wikipedia.org/wiki/Liste_deutscher_privater_Krankenversicherer
export const privateKK = [
  "Debeka",
  "DKV (Victoria)",
  "Allianz Private Krankenversicherung",
  "Allianz Care",
  "Axa (Colonia, DBV-Winterthur)",
  "Signal Iduna (mit Deutscher Ring)",
  "Central Krankenversicherung",
  "Barmenia",
  "Versicherungskammer Bayern",
  "Continentale",
  "HUK-Coburg",
  "Hallesche",
  "HanseMerkur",
  "Gothaer",
  "Landeskrankenhilfe LKH",
  "Süddeutsche Krankenversicherung",
  "Union Krankenversicherung",
  "Deutscher Ring",
  "Inter",
  "Universa",
  "Münchener Verein",
  "R+V",
  "Ergo Direkt",
  "ARAG",
  "LVM",
  "Alte Oldenburger Krankenversicherung",
  "Nürnberger",
  "Württembergische Krankenversicherung",
  "PAX-Familienfürsorge Krankenversicherung",
  "HanseMerkur Versicherungsgruppe S",
  "Mannheimer Versicherungen",
  "Envivas Krankenversicherung",
  "DEVK",
  "Provinzial",
  "Concordia",
  "Freie Arztkasse",
  "Vigo Krankenversicherung (Düsseldorfer Versicherung)",
  "Mecklenburgische",
  "LIGA Krankenversicherung katholischer Priester",
  "Augenoptiker Ausgleichskasse",
  "Praenatura",
  "St. Martinus Kranken- und Sterbekasse",
  "Krankenunterstützungskasse der Berufsfeuerwehr Hannover",
  "Alte Oldenburger",
];
