import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { CheckInQrCodeResult } from "@ttc_types/types";
import {
  NgxScannerQrcodeComponent,
  ScannerQRCodeConfig,
  ScannerQRCodeResult,
} from "ngx-scanner-qrcode";
import { DataService } from "src/app/data.service";

@Component({
  selector: "app-qr-scanner-element",
  templateUrl: "./qr-scanner-element.component.html",
  styleUrls: ["./qr-scanner-element.component.scss"],
})
export class QrScannerElementComponent implements OnInit {
  @Output() qrCodeResultEvent: EventEmitter<CheckInQrCodeResult> =
    new EventEmitter<CheckInQrCodeResult>();

  qrScannerConfig: ScannerQRCodeConfig = {
    fps: 60,
    vibrate: 400,
    isBeep: false,
    constraints: {
      audio: false,
      video: {
        width: window.innerWidth,
      },
    },
  };

  public qrCodeResult: CheckInQrCodeResult | null = null;

  @ViewChild("action") action!: NgxScannerQrcodeComponent;

  constructor(private data: DataService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    // this.data.spinner(true);
    this.action.isReady.subscribe(async (ready) => {
      console.log("isReady: ", ready);
      await this.action.start().subscribe(() => {
        // this.data.spinner(false);
      });
    });
  }

  ngOnDestroy() {
    this.action.stop();
  }

  async startQrScanner() {
    await this.action.start();
  }

  async stopQrScanner() {
    await this.action.stop();
  }

  /**
   * onQrCodeScan gets triggered when the qrScanner detects a qrCode, then it runs the validateQrCodeResult
   * function.
   * @param scanEvent
   */
  onQrCodeScan(scanEvent: ScannerQRCodeResult[]) {
    if (scanEvent[0] && scanEvent[0].value)
      this.validateQrCodeResult(scanEvent[0].value);
  }

  /**
   * isCheckInQrCodeResult is a type guard that checks if the toBeTestedObject is a CheckInQrCodeResult
   * @param toBeTestedObject
   * @returns
   */
  isCheckInQrCodeResult(
    toBeTestedObject: any
  ): toBeTestedObject is CheckInQrCodeResult {
    return (
      toBeTestedObject?.ttConnectQrCheckinQrCode?.projectId &&
      toBeTestedObject?.ttConnectQrCheckinQrCode?.userId
    );
  }

  /**
   * validateQrCodeResult checks if the qrCodeResult is valid, if it is, it will stop the qrScanner and
   * emit the qrCodeResult to the parent component
   */
  validateQrCodeResult(scanValue: string) {
    console.log("scanValue: ", scanValue);
    try {
      const parsedScanValue: CheckInQrCodeResult = JSON.parse(scanValue);
      if (this.isCheckInQrCodeResult(parsedScanValue)) {
        this.qrCodeResult = parsedScanValue;
        console.log("this.qrCodeResult: ", this.qrCodeResult);
        this.qrCodeResultEvent.emit(this.qrCodeResult);
        this.stopQrScanner();
      }
    } catch (error) {
      console.log("parsedScanValue error: ", error);
    }
  }
}
