<div
  [formGroup]="persForm"
  class="arbeitsbeginn-status collapsable-content margin-top-16"
  [ngClass]="{
    modalDesign: modalDesign,
    editModalDesign: !persForm.disabled
  }"
>
  <div class="collapsable-content">
    <div class="tags" [formGroup]="arbeitsBeginnStatus" *ngIf="!onlyPreview">
      <span *ngFor="let item of filteredArbeitsBeginnStatus">
        <span>
          <input
            type="checkbox"
            [name]="item.formControlName"
            [id]="item.formControlName"
            [value]="item.formControlName"
            [formControlName]="item.formControlName"
          />
          <label [for]="item.formControlName">{{ item.label }}</label>
        </span>
      </span>
    </div>

    <div
      *ngIf="persForm.get('arbeitsbeginn_status')?.invalid && submitAttempt"
      class="alert"
    >
      Bitte gib deinen Status bei Arbeitsbeginn im laufenden Kalenderjahr an.
    </div>

    <span [formGroup]="arbeitsBeginnAdditional">
      <div
        *ngFor="let item of filteredArbeitsBeginnStatusAdditional"
        class="additional-container"
      >
        <div
          *ngIf="
            (arbeitsBeginnAdditional.controls[item.formControlName + '_fach'] &&
              !arbeitsBeginnAdditional.controls[item.formControlName + '_fach']
                .disabled) ||
            onlyPreview
          "
          [ngClass]="{ twoColumns: modalDesign }"
        >
          <h4>{{ item.label }}</h4>
          <div class="additional">
            <div>
              <p class="subscription" *ngIf="!onlyPreview">Beruf / Fach</p>
              <input
                type="text"
                [name]="item.formControlName + '_fach'"
                [id]="item.formControlName + '_fach'"
                [formControlName]="item.formControlName + '_fach'"
              />
              <div
                *ngIf="
                  arbeitsBeginnAdditional.controls[
                    item.formControlName + '_fach'
                  ]?.invalid && submitAttempt
                "
                class="alert"
              >
                Bitte gib deinen Beruf / dein Fach an.
              </div>
            </div>

            <div>
              <h5 class="subscription" *ngIf="!onlyPreview">
                Nachweis beifügen
              </h5>
              <app-fileupload
                [listView]="onlyPreview"
                [allowPdf]="true"
                [uploadFiles]="
                  this.arbeitsBeginnAdditional.controls[
                    item.formControlName + '_nachweis'
                  ].value
                "
                (uploadFilesChange)="
                  arbeitsBeginnAdditional.controls[
                    item.formControlName + '_nachweis'
                  ].setValue($event)
                "
                (isUploading)="isUploading.emit($event)"
              >
              </app-fileupload>
              <div
                *ngIf="
                  arbeitsBeginnAdditional.controls[
                    item.formControlName + '_nachweis'
                  ]?.invalid && submitAttempt
                "
                class="alert"
              >
                Lade mindestens einen Nachweis hoch.
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            (arbeitsBeginnAdditional.controls[
              item.formControlName + '_gueltig_bis'
            ] &&
              !arbeitsBeginnAdditional.controls[
                item.formControlName + '_gueltig_bis'
              ].disabled) ||
            (onlyPreview &&
              arbeitsBeginnAdditional.value[
                item.formControlName + '_gueltig_bis'
              ])
          "
        >
          <div class="additional margin-top-20">
            <div [ngClass]="{ twoColumns: modalDesign }">
              <p class="subscription">
                <span *ngIf="!onlyPreview">
                  Gültigkeit der Immatrikulationsbescheinigung
                </span>
              </p>
              <input
                type="date"
                [name]="item.formControlName + '_gueltig_bis'"
                [id]="item.formControlName + '_gueltig_bis'"
                [formControlName]="item.formControlName + '_gueltig_bis'"
              />
              <div
                *ngIf="
                  arbeitsBeginnAdditional.controls[
                    item.formControlName + '_gueltig_bis'
                  ]?.invalid && submitAttempt
                "
                class="alert"
              >
                Bitte gib an, bis wann deine aktuelle
                Immatrikulationsbescheinigung gültig ist.
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  </div>

  <div
    *ngIf="
      !persForm.controls['existiert_hauptarbeitgeber'].disabled || onlyPreview
    "
    [ngClass]="{ twoColumns: modalDesign }"
  >
    <p class="subscription margin-bottom-20">
      <span *ngIf="modalDesign"> Hauptarbeitgeber </span>
      <span *ngIf="!modalDesign"> Existiert ein Hauptarbeitgeber? </span>
    </p>
    <div *ngIf="onlyPreview">
      <b *ngIf="persForm.value.existiert_hauptarbeitgeber === true">Ja</b>
      <b *ngIf="persForm.value.existiert_hauptarbeitgeber !== true">Nein</b>
    </div>
    <div class="sex-wrap" *ngIf="!onlyPreview">
      <label for="Ja" class="checkbox">
        <input
          id="Ja"
          type="radio"
          [value]="true"
          name="existiert_hauptarbeitgeber"
          formControlName="existiert_hauptarbeitgeber"
        />
        Ja
      </label>
      <label for="Nein" class="checkbox">
        <input
          id="Nein"
          type="radio"
          [value]="false"
          name="existiert_hauptarbeitgeber"
          formControlName="existiert_hauptarbeitgeber"
        />
        Nein
      </label>
    </div>
    <div
      *ngIf="
        persForm.get('existiert_hauptarbeitgeber')?.invalid && submitAttempt
      "
      class="alert"
    >
      Existiert ein Hauptarbeitgeber?
    </div>
  </div>

  <!-- Aufenthaltstitel -->
  <div
    *ngIf="!persForm.controls['aufenthaltstitel'].disabled || onlyPreview"
    [ngClass]="{ twoColumns: modalDesign }"
  >
    <p class="subscription margin-bottom-20">
      <span *ngIf="modalDesign"> Aufenthaltstitel </span>
      <span *ngIf="!modalDesign"> Hast du einen Aufenthaltstitel? </span>
    </p>
    <div *ngIf="onlyPreview">
      <b *ngIf="persForm.value.aufenthaltstitel === true">Ja</b>
      <b *ngIf="persForm.value.aufenthaltstitel !== true">Nein</b>
    </div>
    <div class="sex-wrap" *ngIf="!onlyPreview">
      <label for="Ja_aufenthalt" class="checkbox">
        <input
          id="Ja_aufenthalt"
          type="radio"
          [value]="true"
          name="aufenthaltstitel"
          formControlName="aufenthaltstitel"
        />
        Ja
      </label>
      <label for="Nein_aufenthalt" class="checkbox">
        <input
          id="Nein_aufenthalt"
          type="radio"
          [value]="false"
          name="aufenthaltstitel"
          formControlName="aufenthaltstitel"
        />
        Nein
      </label>
    </div>
    <div
      *ngIf="persForm.get('aufenthaltstitel')?.invalid && submitAttempt"
      class="alert"
    >
      Dieses Feld ist erforderlich.
    </div>
  </div>
  <div
    *ngIf="
      (persForm.value['aufenthaltstitel'] === true &&
        !persForm.controls['aufenthaltstitel_nachweis'].disabled) ||
      onlyPreview
    "
    [ngClass]="{ twoColumns: modalDesign }"
  >
    <h5 class="subscription">
      <span *ngIf="!onlyPreview">Aufenthaltstitel hochladen </span>
    </h5>
    <p *ngIf="!modalDesign" class="subscription margin-bottom-15">
      Falls auf der Rückseite deines Aufenthaltstitel unter "1. Anmerkungen"
      "ERWERBSTÄTIGKEIT ERLAUBT" steht, lade bitte auch noch das Zusatzblatt mit
      hoch.
    </p>
    <app-fileupload
      [listView]="onlyPreview"
      [allowPdf]="true"
      [uploadFiles]="persForm.value['aufenthaltstitel_nachweis']"
      (uploadFilesChange)="
        persForm.controls['aufenthaltstitel_nachweis'].setValue($event)
      "
      (isUploading)="isUploading.emit($event)"
    >
    </app-fileupload>
    <div
      *ngIf="
        persForm.get('aufenthaltstitel_nachweis')?.invalid && submitAttempt
      "
      class="alert"
    >
      Dieses Feld ist erforderlich.
    </div>
  </div>

  <!-- Gewerbeschein -->
  <div
    *ngIf="!persForm.controls['gewerbeschein'].disabled || onlyPreview"
    [ngClass]="{ twoColumns: modalDesign }"
  >
    <p class="subscription margin-bottom-20">
      <span *ngIf="modalDesign"> Gewerbeschein </span>
      <span *ngIf="!modalDesign"> Hast du einen Gewerbeschein? </span>
    </p>
    <div *ngIf="onlyPreview">
      <b *ngIf="persForm.value.gewerbeschein === true">Ja</b>
      <b *ngIf="persForm.value.gewerbeschein !== true">Nein</b>
    </div>
    <div class="sex-wrap" *ngIf="!onlyPreview">
      <label for="Ja_gewerbe" class="checkbox">
        <input
          id="Ja_gewerbe"
          type="radio"
          [value]="true"
          name="gewerbeschein"
          formControlName="gewerbeschein"
        />
        Ja
      </label>
      <label for="Nein_gewerbe" class="checkbox">
        <input
          id="Nein_gewerbe"
          type="radio"
          [value]="false"
          name="gewerbeschein"
          formControlName="gewerbeschein"
        />
        Nein
      </label>
    </div>
    <div
      *ngIf="persForm.get('gewerbeschein')?.invalid && submitAttempt"
      class="alert"
    >
      Dieses Feld ist erforderlich.
    </div>
  </div>
  <div
    *ngIf="
      (persForm.value['gewerbeschein'] === true &&
        !persForm.controls['gewerbeschein_nachweis'].disabled) ||
      onlyPreview
    "
    [ngClass]="{ twoColumns: modalDesign }"
  >
    <h5 class="subscription">
      <span *ngIf="!onlyPreview">Gewerbeschein hochladen </span>
    </h5>
    <app-fileupload
      [listView]="onlyPreview"
      [allowPdf]="true"
      [uploadFiles]="persForm.value['gewerbeschein_nachweis']"
      (uploadFilesChange)="
        persForm.controls['gewerbeschein_nachweis'].setValue($event)
      "
      (isUploading)="isUploading.emit($event)"
    >
    </app-fileupload>
    <div
      *ngIf="persForm.get('gewerbeschein_nachweis')?.invalid && submitAttempt"
      class="alert"
    >
      Dieses Feld ist erforderlich.
    </div>
  </div>

  <!-- Gesundheitszeugnis -->
  <div
    *ngIf="!persForm.controls['gesundheitszeugnis'].disabled || onlyPreview"
    [ngClass]="{ twoColumns: modalDesign }"
  >
    <p class="subscription margin-bottom-20">
      <span *ngIf="modalDesign"> Gesundheitszeugnis </span>
      <span *ngIf="!modalDesign"> Hast du ein Gesundheitszeugnis? </span>
    </p>
    <div *ngIf="onlyPreview">
      <b *ngIf="persForm.value.gesundheitszeugnis === true">Ja</b>
      <b *ngIf="persForm.value.gesundheitszeugnis !== true">Nein</b>
    </div>
    <div class="sex-wrap" *ngIf="!onlyPreview">
      <label for="Ja_gesundheitszeugnis" class="checkbox">
        <input
          id="Ja_gesundheitszeugnis"
          type="radio"
          [value]="true"
          name="gesundheitszeugnis"
          formControlName="gesundheitszeugnis"
        />
        Ja
      </label>
      <label for="Nein_gesundheitszeugnis" class="checkbox">
        <input
          id="Nein_gesundheitszeugnis"
          type="radio"
          [value]="false"
          name="gesundheitszeugnis"
          formControlName="gesundheitszeugnis"
        />
        Nein
      </label>
    </div>
    <div
      *ngIf="persForm.get('gesundheitszeugnis')?.invalid && submitAttempt"
      class="alert"
    >
      Dieses Feld ist erforderlich.
    </div>
  </div>
  <div
    *ngIf="
      (persForm.value['gesundheitszeugnis'] === true &&
        !persForm.controls['gesundheitszeugnis_nachweis'].disabled) ||
      onlyPreview
    "
    [ngClass]="{ twoColumns: modalDesign }"
  >
    <h5 class="subscription">
      <!-- <span *ngIf="onlyPreview">Gesundheitszeugnis</span> -->
      <span *ngIf="!onlyPreview"> Gesundheitszeugnis hochladen </span>
    </h5>
    <app-fileupload
      [listView]="onlyPreview"
      [allowPdf]="true"
      [uploadFiles]="persForm.value['gesundheitszeugnis_nachweis']"
      (uploadFilesChange)="
        persForm.controls['gesundheitszeugnis_nachweis'].setValue($event)
      "
      (isUploading)="isUploading.emit($event)"
    >
    </app-fileupload>
    <div
      *ngIf="
        persForm.get('gesundheitszeugnis_nachweis')?.invalid && submitAttempt
      "
      class="alert"
    >
      Dieses Feld ist erforderlich.
    </div>
  </div>
</div>
