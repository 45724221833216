import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import {
  Application,
  EmploymentContract,
  Profile,
  Toggle,
} from "@ttc_types/types";
import { Notify } from "notiflix";
import { Subscription, filter } from "rxjs";
import { SignaturePad } from "src/app/components/signature-pad/angular2-signaturepad.component";
import { DataService } from "../../data.service";

@Component({
  selector: "app-job-detail",
  templateUrl: "./job-detail.component.html",
  styleUrls: ["./job-detail.component.scss"],
  host: { class: "host-full-width" },
})
export class JobDetailComponent implements OnInit {
  toggles: Toggle[] = [
    {
      label: "Infos",
      icon: "infos",
      routerLink: "infos",
    },
    {
      label: "Unterlagen",
      icon: "tasks",
      routerLink: "unterlagen",
      disabled: true,
    },
    {
      label: "Zeiterfassung",
      icon: "tasks",
      routerLink: "zeiterfassung",
      disabled: true,
    },
  ];

  activeToggle = 0;

  application: Application | undefined;

  mehrInfos: boolean = false;

  timetracker: boolean = false;

  unterlagen: boolean = false;

  routerSubscription: Subscription | undefined;

  contractIsSigned: boolean = false;

  showContract: boolean = false;

  employmentContract!: EmploymentContract;

  profile!: Profile;

  @ViewChild(SignaturePad) signaturePad!: SignaturePad;

  // documents and signature
  signatureBase64: string = "";

  signaturePadOpts: Object | null = null;

  emptyCheckboxString = "▢";

  checkedCheckboxstring = "☑";

  inputElementString: string = `<input type="checkbox" class="user-checkbox-input"/>`;

  constructor(
    private elRef: ElementRef,
    private router: Router,
    public data: DataService,
    private route: ActivatedRoute
  ) {}

  setPageContent() {
    switch (this.router.url.split("/").pop()) {
      case "unterlagen":
        this.mehrInfos = false;
        this.timetracker = false;
        this.unterlagen = true;
        this.activeToggle = 1;
        break;
      case "zeiterfassung":
        this.mehrInfos = false;
        this.timetracker = true;
        this.unterlagen = false;
        this.activeToggle = 2;
        break;
      default:
        this.mehrInfos = true;
        this.timetracker = false;
        this.unterlagen = false;
        this.activeToggle = 0;
        break;
    }
  }

  async ngOnInit() {
    const routeParams = this.route.snapshot.paramMap;
    const application_id = routeParams.get("id")
      ? Number(routeParams.get("id"))
      : null;
    console.log(`application_id`, application_id);
    if (application_id) {
      this.application = await this.data.getJobById(application_id);
      this.data.getEmploymenContract(application_id).then((contract) => {
        this.employmentContract = contract;
        if (
          this.employmentContract &&
          this.employmentContract.final_contract_text
        ) {
          const regex = new RegExp(this.emptyCheckboxString, "g");
          this.employmentContract.final_contract_text =
            this.employmentContract.final_contract_text?.replace(
              regex,
              this.inputElementString
            );
        }
        // eslint-disable-next-line prefer-regex-literals
        if (this.employmentContract?.time_employee_signature) {
          this.contractIsSigned = true;
        }
        console.log("this.employmentContract: ", this.employmentContract);
        if (this.employmentContract) {
          this.toggles[1].disabled = false;
          if (
            this.employmentContract.time_employee_signature &&
            this.employmentContract.time_employer_signature
          ) {
            this.toggles[2].disabled = false;
          }
        }
      });
      if (this.application && this.application.user_id) {
        this.profile = await this.data.getProfile();
        console.log("this.profile: ", this.profile);
      }
    }
    console.log(`this.application`, this.application);

    this.setPageContent();

    // eslint-disable-next-line no-constant-condition
    if (this.data.debug && false) {
      // this.application = {
      //   id: 1,
      //   created_at: new Date("2022-07-11T08:43:35.895+00:00"),
      //   updated_at: new Date("2022-07-11T08:43:35.895+00:00"),
      //   role: "arbeitskraft",
      //   status: "erledigt_verbindlich",
      //   user_id: 3,
      //   project_id: 1,
      //   project: {
      //     id: 1,
      //     titel: "titel",
      //     aktionsinhalt: "aktionsinhalt",
      //     anforderungsprofil: "anforderungsprofil",
      //     branche: "Auto/Motoräder",
      //     zeitraum_von: new Date("2022-10-22T15:44:33.000Z"),
      //     zeitraum_bis: new Date("2022-10-22T15:44:33.000Z"),
      //     zeiten: [
      //       {
      //         zeit_von: "13:13",
      //         zeit_bis: "15:15",
      //       },
      //     ],
      //     honorar: {
      //       honorar_auf_anfrage: true,
      //       honorar_eur: 13,
      //     },
      //     aktions_standorte: "aktions_standorte",
      //     published: true,
      //     created_by_id: undefined,
      //     created_at: new Date("2022-07-11T08:43:35.883+00:00"),
      //     updated_at: new Date("2022-07-11T08:43:35.883+00:00"),
      //   },
      // };
    }

    if (!this.routerSubscription) {
      this.routerSubscription = this.router.events
        .pipe(filter((el) => el instanceof NavigationEnd))
        .subscribe(() => {
          // const url = (event as NavigationEnd).url;
          this.setPageContent();
        });
    }
    this.initSignaturePad();
  }

  ngOnDestroy() {
    if (this.routerSubscription) this.routerSubscription.unsubscribe();
  }

  goBackClicked() {
    if (this.showContract) {
      this.showContract = false;
    } else {
      this.router.navigate(["/meine-jobs"]);
    }
  }

  initSignaturePad() {
    // init signaturepad
    const minWidth = 290;
    const maxWidth = 325;
    const heightRatio = 0.4138;
    const incomingWidth = Math.min(window.screen.width - 30, maxWidth);
    const width = Math.max(minWidth, incomingWidth);
    const height = Math.round(width * heightRatio);
    this.signaturePadOpts = {
      minWidth: 1,
      canvasWidth: width,
      canvasHeight: height,
      backgroundColor: "#fff",
      penColor: "#4287f5",
    };
  }

  signatureDrawStart() {
    console.log("signatureDrawStart");
  }

  signatureDrawFinish() {
    console.log("signatureDrawFinish");
    this.signatureBase64 = this.signaturePad.toDataURL();
  }

  signatureClear() {
    this.signaturePad.clear();
    this.signatureBase64 = "";
  }

  async signContractClicked() {
    if (this.signatureBase64) {
      try {
        const checkboxes = this.elRef.nativeElement.getElementsByClassName(
          "user-checkbox-input"
        );

        const checked: boolean[] = [];
        for (const checkbox of checkboxes) {
          checked.push(checkbox.checked);
        }

        if (checkboxes.length && !checked.filter((el) => el).length) {
          Notify.failure("Bitte mindestens ein Kästchen ankreuzen.");
          setTimeout(() => {
            try {
              checkboxes[0]?.scrollIntoView({
                behavior: "smooth",
              });
            } catch (error) {
              console.error(`scrollIntoView error`, error);
            }
          }, 1000);
          return;
        }

        // eslint-disable-next-line prefer-regex-literals
        const regex = new RegExp(this.inputElementString, "g");
        this.employmentContract.final_contract_text =
          this.employmentContract.final_contract_text?.replace(regex, () =>
            checked.shift()
              ? this.checkedCheckboxstring
              : this.emptyCheckboxString
          );
      } catch (error) {
        console.error(error);
      }

      const updateEmploymentContract: Partial<EmploymentContract> = {};

      updateEmploymentContract.application_id =
        this.employmentContract.application_id;
      updateEmploymentContract.time_employee_signature = new Date();
      updateEmploymentContract.employee_signature = this.signatureBase64;
      updateEmploymentContract.final_contract_text =
        this.employmentContract.final_contract_text;

      const newUpdatedEmploymentContract =
        await this.data.updateEmploymentContract(
          this.employmentContract.id!,
          updateEmploymentContract
        );

      if (newUpdatedEmploymentContract) {
        this.employmentContract = newUpdatedEmploymentContract;
        this.contractIsSigned = true;
        this.showContract = false;
      }
    } else {
      Notify.failure(
        "Es muss eine E-Mail und eine Unterschrift angegeben werden."
      );
    }
  }
}
