<!-- <app-modal (close)="(true)" [maxWidth1200]="true"> -->
<app-send-mail-to-auswahl-modal
  (close)="closeMailModal()"
  [selectedMails]="selectedMails"
  *ngIf="mailModal"
></app-send-mail-to-auswahl-modal>

<app-modal
  class="master-data-export-modal"
  *ngIf="showMasterDataExportModal"
  (close)="closeMasterDataExportModal()"
  ><master-data-export-configurator
    (onDownloadClick)="onMasterDataDownload($event)"
    [applicantsForExport]="applicantsForExport"
  ></master-data-export-configurator
></app-modal>

<app-copy-personal-to-other-project-modal
  [incomingSelectedUserIds]="copyAbleUserIds"
  (close)="copyPersonalModal = false"
  *ngIf="copyPersonalModal"
>
</app-copy-personal-to-other-project-modal>

<div
  class="app-modal big-modal-fix"
  *ngIf="getApplicantsToCheckIndex > -1 || allApplicantsIndex > -1"
  @fade
>
  <div class="modal-inner" *ngIf="allApplicantsIndex > -1" #scrollTarget>
    <app-bewerbercheck-modal
      (close)="
        allApplicantsIndex = -1; clearAllBodyScrollLocks(); refreshData()
      "
      [incomingUser]="allApplicants[allApplicantsIndex]"
      [arbeitskreafteEdit]="false"
      (nextAfterAction)="navNext(true)"
      (refreshList)="refreshData()"
    >
    </app-bewerbercheck-modal>
  </div>
  <div class="navigation" *ngIf="getApplicantsToCheckIndex > -1">
    <div
      class="left-nav"
      (click)="navBack()"
      *ngIf="getApplicantsToCheckIndex >= 0"
    ></div>
    <div
      *ngFor="let app of applicantsToCheck; let i = index"
      class="swipe-wrap"
      [@animImageSlider]="getApplicantsToCheckIndex"
      [ngClass]="{ 'width-0': i != getApplicantsToCheckIndex }"
    >
      <div
        class="modal-inner navigation width-100"
        *ngIf="i == getApplicantsToCheckIndex"
        #scrollTargetSwipe
      >
        <app-bewerbercheck-modal
          (close)="
            getApplicantsToCheckIndex = -1;
            clearAllBodyScrollLocks();
            refreshData()
          "
          [arbeitskreafteEdit]="false"
          [incomingUser]="app"
          (nextAfterAction)="navNext(true)"
          (refreshList)="refreshData()"
        >
        </app-bewerbercheck-modal>
      </div>
    </div>
    <div
      class="right-nav"
      (click)="navNext()"
      *ngIf="getApplicantsToCheckIndex < applicantsToCheck.length - 1"
    ></div>
  </div>
</div>
<!-- </app-modal> -->

<app-job-roles-modal
  *ngIf="jobRolesModalOpen"
  (close)="jobRolesModalOpen = false"
>
</app-job-roles-modal>

<app-modal
  *ngIf="auswahlAnKundenForm.enabled"
  (close)="auswahlAnKundenForm.disable({ emitEvent: false })"
>
  <h4 class="">
    Auswahl an Kunden<span *ngIf="linkAlreadySentOnce"> erneut</span> senden
  </h4>
  <div class="two-cols" [formGroup]="auswahlAnKundenForm">
    <div class="left">
      <div>
        <div>
          <label class="subscription" for="kunden_email">E-Mail Adresse</label>
          <input
            type="email"
            id="kunden_email"
            formControlName="kunden_email"
          />
          <span
            class="alert"
            *ngIf="
              auswahlAnKundenForm.controls['kunden_email'].invalid &&
              submitAttempt
            "
            >Bitte gib eine gültige E-Mail-Adresse an.</span
          >
        </div>
        <label for="auswahl_durch_kunden_zulassen" class="checkbox">
          <input
            type="checkbox"
            id="auswahl_durch_kunden_zulassen"
            formControlName="auswahl_durch_kunden_zulassen"
          />
          Auswahl durch Kunden zulassen
        </label>
      </div>

      <div>
        <div>
          <p class="subscription">Link Kundenseite</p>
          <a [href]="baseUrl" target="_blank">{{ baseUrl }}</a>
        </div>
        <div class="two-cols">
          <a
            [href]="
              baseUrl + (linkAlreadySentOnce ? '?preview=1' : '?preview=1')
            "
            target="_blank"
            class="left gold-link"
            *ngIf="!linkAlreadySentOnce"
            >Vorschau</a
          >
          <div (click)="copyToClipboard()" class="right gold-link">
            Kopieren
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div>
        <label class="subscription" for="mitteilung">Mitteilung</label>
        <!-- <quill-editor
          id="mitteilung"
          formControlName="mitteilung"
        ></quill-editor> -->
        <app-editor
          [html]="auswahlAnKundenForm.controls['mitteilung'].value"
          (htmlChange)="
            auswahlAnKundenForm.controls['mitteilung'].setValue($event)
          "
        ></app-editor>
      </div>
      <app-button
        [isLoading]="isLoading"
        [disabled]="isLoading"
        color="secondary-gradient"
        background="primary10"
        (click)="auswahlAnKundenSenden()"
        >Auswahl<span *ngIf="linkAlreadySentOnce"> erneut</span>
        senden</app-button
      >
    </div>
  </div>
</app-modal>

<div class="personal white">
  <div class="top-cards">
    <div class="card white left">
      <div class="aktion-erforderlich flex-center-vertical">
        <div class="required-dot" *ngIf="applicantsToCheck.length"></div>
        Aktion erforderlich
      </div>
      <div class="flex-gap-30">
        <div class="gesamt-aktionen flex-center-vertical">
          <h1 class="gold-bg">
            {{ neue_bewerbungen.length + dateneingabe.length }}
          </h1>
        </div>

        <div class="bewerbungen-pruefen">
          <div class="top primary60">
            <span class="white">{{ neue_bewerbungen.length }}</span> neue
            Bewerbungen
          </div>
          <div class="bottom primary60">
            <span class="white">{{ dateneingabe.length }}</span> Daten prüfen
          </div>
        </div>
      </div>
    </div>
    <div class="card white right">
      <div class="left flex-center-vertical">Final für Projekt</div>
      <div class="right flex-center-vertical">
        <div class="primary60 width-100">
          <div class="anz-personen">
            <div>
              <span class="white">{{ erledigtVerbindlichCount }}</span>
              / {{ data?.currentProject?.personalbedarf || 1 }} Personen
            </div>
            <div>
              <h3 class="primary60">
                <span class="white">{{ percentPersonal }}</span>
                <span class="less-font-weight">%</span>
              </h3>
            </div>
          </div>
          <app-progress-bar [percent]="percentPersonal"></app-progress-bar>
        </div>
      </div>
    </div>
  </div>

  <div class="table-wrapper" *ngFor="let tab of tabs; let tabIndex = index">
    <h4 class="white">
      <div class="flex-title">
        {{ tab.label }}<span class="primary-badge">{{ tab.data.length }}</span>
      </div>

      <div class="buttons" [ngClass]="{ 'flex-no-wrap': tabIndex == 4 }">
        <!-- 3 -->
        <div
          class="dropdown-wrap right"
          *ngIf="tabIndex != 5"
          [ngClass]="{ right: ![3, 4].includes(tabIndex) }"
        >
          <app-button
            color="white"
            iconRight="chevron-down"
            (click)="
              aktionenDropdown = aktionenDropdown == tabIndex ? -1 : tabIndex
            "
            [onlyIcon]="true"
            [lessHeight]="true"
            [isLoading]="isLoading"
            [disabled]="isLoading"
          ></app-button>
          <div class="dropdown" *ngIf="aktionenDropdown == tabIndex">
            <div
              (click)="
                cancelApplicationsBecauseProjectIsFull(tabIndex);
                aktionenDropdown = -1
              "
            >
              Absagen für dieses Event
            </div>
            <div (click)="downloadAllZipFiles(tabIndex)">Bundle-Download</div>
            <div
              *ngIf="tabIndex == 3"
              (click)="jobRolesModalOpen = true; aktionenDropdown = -1"
            >
              Rollen vergeben
            </div>
          </div>
        </div>

        <!-- 4 -->
        <app-searchbar
          *ngIf="tabIndex == 4"
          [color]="'primary20'"
          (searchStringChange)="filterTab(4, final, (searchString = $event))"
        ></app-searchbar>

        <app-button
          *ngIf="tabIndex == 3"
          (click)="auswahlAnKundenForm.enable({ emitEvent: true })"
          [color]="linkAlreadySentOnce ? 'white' : 'secondary-gradient'"
          [lessHeight]="true"
          iconRight="link"
          background="primary90"
          [isLoading]="isLoading"
          [disabled]="isLoading"
        >
          An Kunde senden
        </app-button>

        <app-button
          *ngIf="tabIndex == 3 && tab.data.length"
          [isLoading]="isLoading"
          [disabled]="isLoading"
          iconRight="add-user"
          color="secondary-gradient"
          background="primary90"
          [lessHeight]="true"
          (click)="auswahlHinzufuegen()"
          >Auswahl hinzufügen</app-button
        >

        <!-- <app-button
          *ngIf="![3, 5].includes(tabIndex)"
          [isLoading]="isLoading"
          [disabled]="isLoading"
          [lessHeight]="true"
          color="white"
          (click)="cancelApplicationsBecauseProjectIsFull(tabIndex)"
          >Absagen für dieses Event</app-button
        > -->

        <app-svgs
          (click)="tab.collapsed = !tab.collapsed"
          [type]="tab.collapsed ? 'eye' : 'eye-crossed'"
          color="secondary"
        ></app-svgs>
      </div>
    </h4>

    <table @fade *ngIf="!tab.collapsed">
      <thead>
        <tr>
          <th>
            <div class="flex-center">
              <!-- [value]="tabIndex == tabAllSelected" -->
              <input
                class="list-checkbox"
                type="checkbox"
                [(ngModel)]="tab.allSelected"
                (change)="selectAll(tab)"
              />
            </div>
          </th>
          <th
            *ngFor="let header of tableHeaders"
            (click)="toggleSortDirection(header.sortDirectionKey, tab)"
          >
            <div class="flex flex-center-vertical gap-7">
              {{ header.label }}
              <app-ordering-icons
                class="flex-center-vertical"
                [sortDirection]="
                  tab.sortDirection.field === header.sortDirectionKey
                    ? tab.sortDirection.order
                    : 0
                "
              ></app-ordering-icons>
            </div>
          </th>
        </tr>
      </thead>
      <tbody *ngFor="let user of tab.data; let userIndex = index">
        <!-- data.currentProject?.auswahl_durch_kunden_zulassen && -->
        <tr
          *ngIf="
            tabIndex == 3 &&
            userIndex == 0 &&
            user.applications![0].status == 'auswahl_auftraggeber_invisible'
          "
        >
          <th colspan="6">Noch nicht sichtbar für den Kunden</th>
        </tr>
        <!-- data.currentProject?.auswahl_durch_kunden_zulassen && -->
        <tr
          *ngIf="
            tabIndex == 3 &&
            [
              'auswahl_auftraggeber_visible',
              'auftraggeber_akzeptiert',
              'auftraggeber_abgesagt'
            ].includes(user.applications![0].status!) &&
            (userIndex == 0 ||
              (userIndex > 0 &&
                tab.data[userIndex - 1].applications![0].status ==
                  'auswahl_auftraggeber_invisible'))
          "
        >
          <th
            colspan="6"
            [ngClass]="{
              vorgestellt:
                [
                  'auswahl_auftraggeber_visible',
                  'auftraggeber_akzeptiert',
                  'auftraggeber_abgesagt'
                ].includes(user.applications![0].status!) &&
                userIndex > 0 &&
                tab.data[userIndex - 1].applications![0].status ==
                  'auswahl_auftraggeber_invisible'
            }"
          >
            Vorgestellt
          </th>
        </tr>
        <tr
          class="cursor-pointer"
          [ngClass]="{ 'user-deactivated': !user.activated }"
        >
          <td>
            <div class="flex-center-vertical">
              <input
                class="list-checkbox"
                type="checkbox"
                [(ngModel)]="user.selected"
                (change)="checkSelectAll(tab)"
              />
            </div>
          </td>
          <td
            (click)="openEditForm(user, tab)"
            class="flex-center-vertical gap-7"
          >
            <div
              class="required-dot"
              *ngIf="
                status[user.applications![0].status || 'neu'].action_required ||
                ([
                  'auswahl_auftraggeber_visible',
                  'auftraggeber_akzeptiert',
                  'auftraggeber_abgesagt'
                ].includes(user.applications![0].status!) &&
                  !data.currentProject?.auswahl_durch_kunden_zulassen)
              "
            ></div>
            <h3>
              {{ toFirstNameOnly(user?.profile?.vorname) }}
              {{ user?.profile?.nachname }}
              <span class="user-deactivated-badge" *ngIf="!user.activated">
                Deaktiviert
              </span>
            </h3>
          </td>

          <td (click)="openEditForm(user, tab)">
            {{
              [
                "auswahl_auftraggeber_invisible",
                "auswahl_auftraggeber_visible",
                "auftraggeber_akzeptiert",
                "auftraggeber_abgesagt"
              ].includes(user.applications![0].status!) &&
              !data.currentProject?.auswahl_durch_kunden_zulassen
                ? "Auswahl deaktiviert"
                : status[user.applications![0].status || "neu"].label
            }}
          </td>

          <td (click)="openEditForm(user, tab)">
            {{ user.erfahrungen_count }}
          </td>

          <td (click)="openEditForm(user, tab)">
            {{ user.profile?.plz || "" }} {{ user.profile?.ort || "" }}
          </td>

          <td (click)="openEditForm(user, tab)">
            <div class="flex-right">
              <div
                class="badge flex-center"
                [ngClass]="{
                  success: user.is_topteamer,
                  primary120: !user.is_topteamer
                }"
              >
                <app-svgs
                  [type]="user.is_topteamer ? 'check' : 'close'"
                  color="white"
                ></app-svgs>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
