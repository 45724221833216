import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "src/app/shared/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard  {
  constructor(public authService: AuthService, public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const nextRoute = next.url[0]?.path;
    console.log(`canActivate next`, next);
    const loggedIn = this.authService.isLoggedIn;
    console.log(`canActivate nextRoute`, nextRoute);
    if (!nextRoute) return false;
    if (loggedIn) {
      const role = this.authService.currentUserFnc?.role;
      const isTopteamer = this.authService.currentUserFnc?.is_topteamer;
      if (
        (next.routeConfig?.path || "").includes("jobs/apply") &&
        this.authService.currentUserFnc?.activated === false
      ) {
        this.router.navigate(["jobs"]);
        return false;
      }
      if (role == "regular") {
        if (["jobs", "meine-jobs", "daten", "job-detail"].includes(nextRoute)) {
          return true;
        }
        this.router.navigate(["jobs"]);
      }
      if (role == "pm") {
        if (
          isTopteamer &&
          ["meine-projekte", "arbeitskraefte", "daten"].includes(nextRoute)
        ) {
          return true;
        }
        if (!isTopteamer) {
          if (next.routeConfig?.path == "daten/sed") {
            return true;
          }
          this.router.navigateByUrl("daten/sed");
          return false;
        }

        this.router.navigateByUrl("/meine-projekte");
        return false;
      }
      if (role == "superadmin") {
        if (
          [
            "meine-projekte",
            "arbeitskraefte",
            "projektleitung",
            "vorlagen",
          ].includes(nextRoute)
        ) {
          return true;
        }
        this.router.navigateByUrl("/meine-projekte");
      }
    } else {
      if (nextRoute.indexOf("jobs") < 1 && nextRoute.indexOf("jobs") > -1) {
        return true;
      }
      localStorage.setItem("can_activate_route", state.url);
      this.router.navigateByUrl("/login");
    }

    return true;
  }
}
