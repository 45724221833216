import { Toggle, ApplicationStatus } from "./types";

export const haarfarben: string[] = [
  "Blond",
  "Braun",
  "Schwarz",
  "Rot",
  "Grau",
  "Sonstiges",
];

export const shirtArr = ["XS", "S", "M", "L", "XL", "XXL"];
export const shirtToggles: Toggle[] = shirtArr.map((el, index) => {
  return { label: el, value: index };
});

export const jacke_sacko_groessen: number[] = [...Array(15).keys()].map(
  (val) => 32 + val * 2
);
export const jeansweite: number[] = [...Array(17).keys()]
  .map((val) => 24 + val)
  .concat([42, 44, 46, 48]);

export const jeanslaenge: number[] = [28, 30, 32, 34, 36];

export const hose_rock_groessen: number[] = jacke_sacko_groessen.slice(0, -1);

export const schuhgroesse: number[] = [...Array(29).keys()].map(
  (val) => 34 + (!(val % 2) ? Math.ceil(val / 2) : Math.ceil(val / 2) - 0.5)
);

export const guertellaenge: number[] = [
  70, 75, 80, 85, 90, 95, 100, 105, 110, 115,
];

export const kragenweite: number[] = [...Array(8).keys()].map(
  (val) => 38 + val
);

export const sprachenKeysLabelArray = [
  { key: "deutsch", label: "Deutsch" },
  { key: "englisch", label: "Englisch" },
  { key: "spanisch", label: "Spanisch" },
  { key: "franzoesisch", label: "Französisch" },
  { key: "italienisch", label: "Italienisch" },
];

export const sprachNiveau = ["Nein", "3", "2", "1", "1+"];

export const geschlecht = [
  {
    value: "w",
    label: "Weiblich",
  },
  {
    value: "m",
    label: "Männlich",
  },
  {
    value: "d",
    label: "Divers",
  },
];

export const steuerKlassen = [1, 2, 3, 4, 5, 6];

export const hoechster_allgemeiner_schulabschluss: string[] = [
  "ohne Abschluss",
  "Fachhochschulreife",
  "Hauptschulabschluss",
  "Allgemeine Hochschulreife",
  "Mittlerer Abschluss",
];

export const hoechster_beruflicher_abschluss: string[] = [
  "ohne Abschluss",
  "Bachelor",
  "Master",
  "Diplom",
  "Geselle",
  "Meister",
];

export const fotosDebug = [
  {
    filename: "Untitled.png",
    mimeType: "image/png",
    uploaded: true,
    isUploading: false,
    nameOnDisk: "cl6yz7768000jt4v1333lb1is.png",
  },
  {
    filename: "Untitled-1.png",
    mimeType: "image/png",
    uploaded: true,
    isUploading: false,
    nameOnDisk: "cl6yz79qt000lt4v16m4f8enq.png",
  },
  {
    filename: "preview_angelo_und_gerd.jpg",
    mimeType: "image/jpeg",
    uploaded: true,
    isUploading: false,
    nameOnDisk: "cl6yz86pc000pt4v1bicihb9q.png",
  },
];

export const branchen: string[] = [
  "Messe",
  "Event",
  "Roadshow",
  "Promotion",
  "Service",
  "Festival",
  "Konferenz",
  "Sonstiges",
];

export const statusLabelRequired: {
  [key in ApplicationStatus]: { label: string; action_required: boolean };
} = {
  neu: { label: "Neu", action_required: true },
  daten_pruefen: { label: "Daten prüfen", action_required: true },
  warten_auf_daten: { label: "Warten auf Daten", action_required: false },
  projekt_pool: { label: "Unentschlossen", action_required: false },
  auswahl_auftraggeber_invisible: {
    label: "in Auswahl",
    action_required: true,
  },
  auswahl_auftraggeber_visible: {
    label: "Liegt bei Kunde",
    action_required: false,
  },
  auftraggeber_akzeptiert: {
    label: "Zusage vom Kunden",
    action_required: true,
  },
  auftraggeber_abgesagt: {
    label: "Absage vom Kunden",
    action_required: false,
  },
  // auftraggeber abgesagt? oder direkt in absage`?
  warten_auf_vertrag: { label: "Warten auf Vertrag", action_required: false },
  vertrag_gegenzeichnen: {
    label: "Vertrag gegenzeichnen",
    action_required: true,
  },
  vertrag_geloescht: {
    label: "Vertrag gelöscht",
    action_required: false,
  },
  erledigt_verbindlich: { label: "Erledigt", action_required: false },
  abgesagt: { label: "Abgesagt", action_required: false },
  gekuendigt: { label: "Gekündigt", action_required: false },
};

export const protokollWordingsFn: (
  key: string,
  value: any
) => {
  label: string;
  translation: string;
} = (key, value) => {
  const key_as_string = key as string;

  const key_translation = key_as_string
    .replace(/_/g, " ")
    .replace(/arr$/, "")
    .replace(/oe/g, "ö")
    .replace(/ae/g, "ä")
    // .replace(/(?<!e)ue/g, "ü")
    .replace(/plz/g, "PLZ")
    .replace(/bic/g, "BIC")
    .replace(/iban/g, "IBAN")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const translate_json_recursive_like_yaml = (json: any): string => {
    const keys = Object.keys(json);
    if (keys.length === 0) {
      return "";
    }
    return keys
      .map((k) => {
        if (typeof json[k] === "object") {
          return `${k}:\n${translate_json_recursive_like_yaml(json[k]).replace(
            /^/gm,
            "\t"
          )}`;
        }
        if (typeof json[k] === "boolean") {
          return `${k}: ${json[k] ? "Ja" : "Nein"}`;
        }
        return `${k}: ${json[k]}`;
      })
      .join("\n");
  };
  const exclude_media_keys = [
    "mimeType",
    "fileBaseBlob",
    "uploaded",
    "isUploading",
    "nameOnDisk",
    "formControlName",
  ];
  if (key_as_string != "sprachen") {
    exclude_media_keys.push("label");
  }
  let value_translation = "";

  if (!(value || value === false)) {
    return { label: key_translation, translation: value_translation };
  }

  const keys_for_extra_value_translation = [
    "staatsangehoerigkeit_arr",
    "geschlecht",
    "religionszugehoerigkeit",
    "shirtgroesse",
  ];

  if (keys_for_extra_value_translation.includes(key_as_string)) {
    if (key_as_string == "staatsangehoerigkeit_arr") {
      value_translation = value ? value.join(", ") : "";
    }
    if (key_as_string == "geschlecht") {
      value_translation =
        // eslint-disable-next-line no-nested-ternary
        value == "w" ? "Weiblich" : value == "m" ? "Männlich" : "Divers";
    }
    if (key_as_string == "religionszugehoerigkeit") {
      value_translation = value?.label || "Religionszugehörigkeit geändert";
    }
    if (key_as_string == "shirtgroesse") {
      value_translation = shirtArr[value || 0];
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (typeof value === "object") {
      // TODO no label if key is sprachen
      value = JSON.stringify(value, (k, v) => {
        if (exclude_media_keys.includes(k) || (!v && v !== false)) {
          return undefined;
        }
        return v;
      });
      value = JSON.parse(value);
      value = translate_json_recursive_like_yaml(value);
      value_translation = value;
    } else if (typeof value === "string") {
      value_translation = value.charAt(0).toUpperCase() + value.slice(1);
    } else if (typeof value === "boolean") {
      value_translation = value ? "Ja" : "Nein";
    } else {
      value_translation = value;
    }
  }

  if (key_as_string == "koerpergroesse") {
    value_translation += " cm";
  }
  if (key_as_string == "gewicht") {
    value_translation += " kg";
  }

  return { label: key_translation, translation: value_translation };
};

export const kostenstellen = [
  { value: 30, label: "30 Event / Messe" },
  { value: 40, label: "40 Melitta" },
  { value: 50, label: "50 Forum" },
  { value: 60, label: "60 Sonderprojekte" },
];
