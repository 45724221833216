import { Subscription } from "rxjs";
import { DataService } from "src/app/data.service";
import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { getSedForm, getPersForm } from "src/app/pages/apply/sedPersForm";
import { Notify, Confirm, IConfirmOptions } from "notiflix";
import {
  Profile,
  User,
  ApplicationStatus,
  ApplicationAction,
  RegistrationAction,
  Application,
  DokumentVorlage,
} from "@ttc_types/types";
import { getLaufendeTage, getProjectYears } from "src/app/shared/functions";
import { AuthService } from "src/app/shared/auth.service";
import { formatDate } from "@angular/common";
import { dayjs } from "src/app/shared/date-util";

@Component({
  selector: "app-bewerbercheck-modal",
  templateUrl: "./bewerbercheck-modal.component.html",
  styleUrls: ["./bewerbercheck-modal.component.scss"],
})
export class BewerbercheckModalComponent implements OnInit {
  // this.variable is for simple editing at arbeitskraefte
  @Input()
  arbeitskreafteEdit: boolean = false;

  @Input()
  customerPreview: boolean = false;

  isRegister: boolean = false;

  editApplicationSedNotUserProfile = false;

  showVertrag: boolean = false;

  showProfileNav: boolean = false;

  showSedCardNav: boolean = false;

  showInterneInfosNav: boolean = true;

  showProjektdatenNav: boolean = true;

  showProtokollNav: boolean = true;

  currentPage:
    | "sed"
    | "profile"
    | "projektdaten"
    | "interne_infos"
    | "projektdaten"
    | "protokoll"
    | undefined = undefined;

  sedForm: UntypedFormGroup = getSedForm(true, true);

  originalSedForm: UntypedFormGroup = getSedForm(true, true);

  profileForm: UntypedFormGroup = getPersForm(
    true,
    undefined,
    false,
    false,
    true
  );

  originalProfileForm: UntypedFormGroup = getPersForm(
    true,
    undefined,
    false,
    false,
    true
  );

  anrede: string = "";

  name: string = "";

  isUploading: boolean = false;

  submitAttempt: boolean = false;

  arbeitsvertrag_typ: string = "Arbeitsvertrag";

  // submitAttempt: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input()
  incomingUser!: User; // incoming

  user!: User | undefined;

  @Input()
  closeButton: boolean = true;

  @Output()
  close = new EventEmitter<boolean>();

  @Output()
  nextAfterAction = new EventEmitter();

  @Output()
  refreshList = new EventEmitter();

  buttonsToShow: ApplicationAction[] = [];

  buttonsToShowBeforeEdit: ApplicationAction[] = [];

  application_status: ApplicationStatus | undefined;

  @Output()
  absage: EventEmitter<any> = new EventEmitter();

  @Output()
  zusage: EventEmitter<any> = new EventEmitter();

  @Input()
  showCustomerPreviewButtons: boolean = false;

  vertrag: { [key: string]: string } | undefined;

  vertrag_unterzeichnen_bis: string | undefined;

  personalnummer: number | undefined;

  @Input()
  project_uuid?: string | null;

  constructor(private data: DataService, private auth: AuthService) {}

  async ngOnInit() {
    await this.initBewerbercheckModal();
    this.personalnummer = this.incomingUser.personalnummer;
    console.log("this.data.currentProject", this.data.currentProject);
  }

  ngOnDestroy(): void {}

  datenFehlerhaftOpen: boolean = false;

  datenFehlerhaftForm: UntypedFormGroup = new UntypedFormGroup({
    nachricht: new UntypedFormControl("", [Validators.required]),
  });

  async updatePersonalnummer() {
    Confirm.show(
      "Bist du dir sicher?",
      "Bitte bestätige, dass du die Personalnummer aktualisieren möchtest. Sie muss zwingend mit der Personalnummer in DATEV übereinstimmen.",
      "Abbrechen",
      "Aktualisieren",
      async () => {},
      async () => {
        try {
          this.incomingUser.personalnummer = this.personalnummer;
          if (this.user) {
            this.user.personalnummer = this.personalnummer;
          }
          await this.data.updatePersonalnummer({
            user_id: this.incomingUser.id!,
            personalnummer: this.incomingUser?.personalnummer,
          });
          Notify.success("Die Personalnummer wurde erfolgreich aktualisiert.");
        } catch (error) {
          console.error(`updatePersonalnummer error`, error);
          Notify.failure(
            "Die Personalnummer konnte nicht aktualisiert werden."
          );
        }
      },
      {
        messageMaxLength: 1000000,
        plainText: false,
      } as IConfirmOptions
    );
  }

  get hasValidStatusErrors(): string[] {
    if (this.arbeitskreafteEdit || this.customerPreview) return [];
    // TODO evt noch schöner machen -> details ausgeben
    const errors: string[] = [];
    try {
      if (!this.user) return errors;
      // if (this.user.profile?.arbeitsbeginn_status?.Arbeitslos) {
      //   errors.push("Der Bewerber ist Arbeitslos.");
      // }
      if (this.user.profile?.arbeitsbeginn_status?.Arbeitnehmerin) {
        errors.push("Der Bewerber ist Arbeitnehmer.");
      }

      if (this.user.profile && this.user.profile.laufendes_jahr) {
        getLaufendeTage(
          this.user.profile.laufendes_jahr,
          getProjectYears(this.data.currentProject)
        ).forEach((val) => {
          errors.push(...val.errors);
        });

        // return !!getLaufendeTage().reduce((prev, curr) => {
        //   return curr.valid && prev ? 1 : 0;
        // }, 1);
      }
    } catch (error) {
      console.error(`hasValidStatusIndicator error`, error);
    }
    return errors;
  }

  sortKeysOfObjectRecursively(obj: any) {
    if (obj && typeof obj === "object") {
      return Object.keys(obj)
        .sort()
        .reduce((result, key) => {
          // eslint-disable-next-line no-param-reassign
          result[key] = this.sortKeysOfObjectRecursively(obj[key]);
          return result;
        }, {} as any);
    }
    return obj;
  }

  public sedHasChanges: boolean = false;

  checkHasChanges() {
    const origSed = this.sortKeysOfObjectRecursively(
      this.originalSedForm.value
    );
    // console.log(`origSed`, origSed);
    const sed = this.sortKeysOfObjectRecursively(this.sedForm.value);
    // console.log(`sed`, sed);
    this.sedHasChanges = JSON.stringify(origSed) != JSON.stringify(sed);
  }

  async initBewerbercheckModal() {
    let is_admin_for_project: boolean = false;

    if (!this.arbeitskreafteEdit) {
      if (this.auth.currentUser?.role == "superadmin") {
        is_admin_for_project = true;
      } else if (!this.customerPreview && this.auth.isLoggedIn) {
        const my_jobs: Application[] = await this.data.getRequest(
          "/get-my-jobs"
        );
        is_admin_for_project = my_jobs.some(
          (job) => job.user_id == this.auth.currentUser?.id && job.is_admin
        );
      }

      this.showProfileNav = true;
      this.showSedCardNav = true;
      this.showVertrag = false;
      this.isUploading = true;

      const applicant = await this.data.postRequest(
        "/project/getUserWithFullApplicationForProject",
        {
          project_id: this.incomingUser.applications![0].project_id,
          user_id: this.incomingUser.id,
          project_uuid:
            this.data.currentProject?.project_uuid || this.project_uuid,
        }
      );
      this.isUploading = false;
      this.user = applicant;
      this.application_status = this.incomingUser.applications![0].status;

      this.setupForms(this.user!);
      this.profileForm.disable();
      this.sedForm.disable();
      this.arbeitskreafteEdit = false;
      if (this.buttonsToShowBeforeEdit)
        this.buttonsToShow = this.buttonsToShowBeforeEdit;

      applicant.personal_category = this.incomingUser.personal_category;
      this.user = applicant;
      switch (applicant.applications![0].status) {
        case "neu":
          if (!applicant.is_topteamer) {
            this.currentPage = undefined;
            this.buttonsToShow = [
              "ablehnen",
              "als_topteamer_aufnehmen",
              "daten_anfordern",
            ];
          } else {
            this.currentPage = "profile";
            this.buttonsToShow = [
              "daten_fehlerhaft",
              "ablehnen",
              "unentschlossen",
              "daten_ok_und_in_auswahl",
            ];
          }
          break;
        case "warten_auf_daten":
          this.currentPage = "profile";
          this.buttonsToShow = [
            "daten_fehlerhaft",
            "daten_ok",
            "unentschlossen",
            "daten_ok_und_in_auswahl",
          ];
          break;
        case "daten_pruefen":
          this.currentPage = "profile";
          this.buttonsToShow = [
            "daten_fehlerhaft",
            "daten_ok",
            "unentschlossen",
            "daten_ok_und_in_auswahl",
          ];
          break;
        case "projekt_pool":
          this.currentPage = "profile";
          if (
            applicant.applications![0].all_data_approved_by_pm_ts ||
            applicant.applications![0].sed_card_confirmed_by_user_serialized
          ) {
            this.buttonsToShow = [
              "daten_fehlerhaft",
              "daten_ok_und_in_auswahl",
              "ablehnen",
            ];
          } else {
            this.buttonsToShow = ["daten_anfordern", "ablehnen"];
          }
          break;
        case "auswahl_auftraggeber_invisible":
          this.currentPage = "profile";
          this.buttonsToShow = [
            "aus_auswahl_entfernen",
            "sed_card_bearbeiten",
            "ablehnen",
          ];
          if (!this.data.currentProject?.auswahl_durch_kunden_zulassen) {
            this.buttonsToShow.push("vertrag_erstellen");
          }
          break;
        case "auswahl_auftraggeber_visible":
          this.currentPage = "profile";
          this.buttonsToShow = ["aus_auswahl_entfernen", "ablehnen"];
          if (!this.data.currentProject?.auswahl_durch_kunden_zulassen) {
            this.buttonsToShow.push("vertrag_erstellen");
          }
          break;
        case "auftraggeber_akzeptiert":
          this.currentPage = "profile";
          this.buttonsToShow = ["vertrag_erstellen"];
          if (!this.data.currentProject?.auswahl_durch_kunden_zulassen) {
            this.buttonsToShow.push("ablehnen", "aus_auswahl_entfernen");
          }
          break;
        case "auftraggeber_abgesagt":
          this.currentPage = "profile";
          if (!this.data.currentProject?.auswahl_durch_kunden_zulassen) {
            this.buttonsToShow.push(
              "ablehnen",
              "aus_auswahl_entfernen",
              "vertrag_erstellen"
            );
          } else {
            this.buttonsToShow.push("aus_auswahl_entfernen");
          }
          break;
        case "warten_auf_vertrag":
          this.showVertrag = true;
          this.currentPage = "projektdaten";
          this.buttonsToShow = ["vertrag_erneut_erstellen"];
          break;

        case "vertrag_gegenzeichnen":
          this.showVertrag = true;
          this.currentPage = "projektdaten";
          this.buttonsToShow = ["vertrag_erneut_erstellen"];
          // determine this.canSignContract
          // console.log("this.canSignContract: ", this.canSignContract);
          break;
        case "erledigt_verbindlich":
          this.showVertrag = true;
          this.currentPage = "projektdaten";
          if (is_admin_for_project) {
            this.buttonsToShow = ["kuendigen"];
          }
          break;
        case "abgesagt":
          // this.showVertrag = true;
          this.currentPage = "profile";
          if (applicant.applications![0].all_data_approved_by_pm_ts) {
            this.buttonsToShow = [
              "daten_fehlerhaft",
              "daten_ok_und_in_auswahl",
            ];
          } else {
            this.buttonsToShow = ["daten_anfordern"];
          }
          break;
        case "gekuendigt":
          this.showVertrag = true;
          this.currentPage = "projektdaten";
          break;
        default:
          this.showSedCardNav = true;
          this.showProfileNav = true;
          this.currentPage = "profile";
          break;
      }
    } else {
      this.showProfileNav = true;
      this.showSedCardNav = true;
      this.showProjektdatenNav = false;
      this.showVertrag = false;
      this.currentPage = "profile";
      this.isUploading = true;
      const userToEdit = await this.data.getUser(this.incomingUser.id!);

      this.isUploading = false;
      this.user = userToEdit;

      if (
        this.user.registration_status &&
        this.user.registration_status !== "erledigt" &&
        !this.user.is_topteamer &&
        this.user.meta?.has_applications === "0"
      ) {
        this.isRegister = true;
      }

      // change to gesehen
      this.setupForms(this.user);
    }

    if (!this.customerPreview && this.user!.registration_status === "neu") {
      try {
        await this.handleRegistrationStatus("gesehen");
      } catch (error) {
        console.error(`error`, error);
      }
    }

    if (this.customerPreview) {
      this.showProfileNav = false;
      this.showSedCardNav = false;
      this.showVertrag = false;
      this.currentPage = "sed";
      this.sedForm.disable();
      this.arbeitskreafteEdit = false;
      if (this.buttonsToShowBeforeEdit)
        this.buttonsToShow = this.buttonsToShowBeforeEdit;
      this.profileForm.disable();
      this.buttonsToShow.splice(0);
    }

    if (this.data.debug && true) {
      if (this.arbeitskreafteEdit) {
        this.currentPage = "sed";
      }
    }
  }

  closeEditForm() {
    console.log("closeEditForm");
    // TODO if sed is in edit mode
    this.close.emit();
  }

  private setupForms(user: User) {
    if (user?.main_sed_card) {
      if (this.customerPreview) {
        console.log("setupForms: customerPreview");
        if (user?.applications?.[0].sed_card) {
          this.originalSedForm?.patchValue(user.applications[0].sed_card);
          this.sedForm?.patchValue(user?.applications[0].sed_card);
        } else {
          this.originalSedForm?.patchValue(user.main_sed_card);
          this.sedForm?.patchValue(user.main_sed_card);
        }
      } else if (this.arbeitskreafteEdit) {
        console.log("setupForms: arbeitskreafteEdit");
        this.originalSedForm?.patchValue(user.main_sed_card);
        this.sedForm?.patchValue(user.main_sed_card);
      } else {
        console.log("setupForms: personal auswahl");
        // edit sed card for project
        this.originalSedForm?.patchValue(user.main_sed_card);
        if (user?.applications?.[0].sed_card) {
          console.log(
            `user.applications[0].sed_card`,
            user.applications[0].sed_card
          );
          this.sedForm?.patchValue(user.applications[0].sed_card);
        } else {
          this.sedForm?.patchValue(user.main_sed_card);
        }
      }
      this.checkHasChanges();
      if (this.sedFormSubscription) {
        this.sedFormSubscription.unsubscribe();
      }
      this.sedFormSubscription = this.sedForm.valueChanges.subscribe(() => {
        this.checkHasChanges();
      });
    }
    // console.log(`this.originalSedForm.value`, this.originalSedForm.value);
    // console.log(`this.sedForm.value`, this.sedForm.value);

    if (user?.profile) {
      // do this before patchValue to get triggered by actual values
      if (this.wohnlandSubscription) {
        this.wohnlandSubscription.unsubscribe();
      }
      this.wohnlandSubscription =
        this.profileForm.controls.wohnland.valueChanges.subscribe((val) => {
          if (val == "CH") {
            this.profileForm.controls.sozialversicherungsnummer.setValue(
              this.profileForm.value.steueridentifikationsnummer,
              { emitEvent: false }
            );
            this.profileForm.controls.sozialversicherungsnummer.disable({
              emitEvent: false,
            });
          } else {
            this.profileForm.controls.sozialversicherungsnummer.enable({
              emitEvent: false,
            });
          }
          this.profileForm.controls.steueridentifikationsnummer.updateValueAndValidity();
          // this.profileForm.controls.sozialversicherungsnummer.updateValueAndValidity();
        });
      this.profileForm?.patchValue(user.profile);
      this.originalProfileForm?.patchValue(user.profile);
      const { geschlecht, vorname, nachname } = user.profile;
      if (geschlecht) {
        if (geschlecht == "m") this.anrede = "Herr";
        if (geschlecht == "w") this.anrede = "Frau";
      }
      if (nachname) this.name = nachname;
      if (vorname) this.name = `${vorname} ${this.name}`;
    }

    if (this.auth.currentUser?.role == "superadmin") {
      this.profileForm.controls.email.patchValue(user.email, {
        emitEvent: false,
      });
      this.originalProfileForm.controls.email.patchValue(user.email, {
        emitEvent: false,
      });
      this.profileForm.controls.email.enable({ emitEvent: false });
    } else {
      this.profileForm.controls.email.disable({ emitEvent: false });
    }
  }

  wohnlandSubscription: Subscription | undefined;

  sedFormSubscription: Subscription | undefined;

  cancelSedEdit() {
    Confirm.show(
      "Bist du dir sicher?",
      "Die gemachten Änderungen werden auf den letzten gespeicherten Stand zurückgesetzt.",
      "Nein",
      "Ja",
      async () => {},
      async () => {
        if (this.user?.applications?.[0].sed_card) {
          this.sedForm.patchValue(this.user.applications[0].sed_card);
        }
        this.buttonsToShow = ["sed_card_bearbeiten", "aus_auswahl_entfernen"];
        this.sedForm.disable();
        this.arbeitskreafteEdit = false;
        if (this.buttonsToShowBeforeEdit)
          this.buttonsToShow = this.buttonsToShowBeforeEdit;
      }
    );
  }

  async handleRegistrationStatus(action: RegistrationAction) {
    // Nachbesprechung Kathrin-Ivo 25.01.2023:
    // Vorerst wird bei Bewerbungen nur der "Daten anfordern" Button angezeigt in der Topteamer Ansicht. Dabei wird eine Mail getriggert, dass der Registrant doch sein Profil vervollständigen möge. Danach wird aber keine Überprüfung der Daten seitens der PMs eingeleitet - dies geschieht bei der ersten Bewerbung für ein Event.

    if (!this.user) return;
    if (action == "ablehnen") {
      const confirm_result = await new Promise((res) => {
        let text = "";
        switch (action) {
          case "ablehnen":
            if (!this.user?.is_topteamer) {
              text =
                "Möchtest du den Bewerber wirklich ablehnen? Da der Bewerber noch kein Topteamer ist, wird er gelöscht. ";
            } else {
              res(true);
              return;
            }
            break;

          default:
            break;
        }

        Confirm.show(
          "Bist du sicher?",
          text,
          "Nein",
          "Ja",
          () => {
            return res(false);
          },
          () => {
            return res(true);
          }
        );
      });
      if (!confirm_result) {
        return;
      }
    }
    // als_topteamer_aufnehmen
    // 'daten_anfordern'
    // daten_fehlerhaft
    this.isUploading = true;
    try {
      await this.data.handleRegistrationStatus({
        user_id: this.user.id!,
        action,
        nachricht: this.datenFehlerhaftForm?.value?.nachricht,
      });
      if (this.datenFehlerhaftForm) {
        this.datenFehlerhaftForm.reset();
      }
    } catch (error) {
      console.error(error);
      Notify.failure("Etwas ist schief gegangen.");
    }
    this.isUploading = false;
    switch (action) {
      case "ablehnen":
        Notify.success("Bewerber wurde erfolgreich abgelehnt.");
        break;
      case "daten_ok":
        Notify.success("Bewerber wurde erfolgreich als Topteamer aufgenommen.");
        break;
      case "daten_anfordern":
        Notify.success("Bewerber wurde erfolgreich benachrichtigt.");
        break;
      case "daten_fehlerhaft":
        Notify.success("Bewerber wurde erfolgreich benachrichtigt.");
        break;
      default:
        break;
    }
    if (action != "gesehen") {
      this.close.emit();
    }
  }

  applications_to_dismiss: Application[] = [];

  current_dismissal_template?: DokumentVorlage;

  async handleDismissal(action: ApplicationAction) {
    if (!this.user) return;

    // This variable is only here, because of an typescript error when returing inside this function

    if (action == "kuendigung_abschicken") {
      this.isUploading = true;
      try {
        await this.data.postRequest("/application/kuendigen", {
          application_id: this.applications_to_dismiss[0].id,
          final_dismissal_text: this.current_dismissal_template?.template,
          user_id: this.user.id!,
        });
        Notify.success("Kündigung erfolgreich abgeschickt.");
      } catch (error) {
        console.error(error);
        Notify.failure("Etwas ist schief gegangen.");
      }
      this.isUploading = false;
    }

    if (
      ["kuendigung_abschicken", "kuendigung_ueberspringen"].includes(action)
    ) {
      this.applications_to_dismiss.shift();
      if (this.applications_to_dismiss.length == 0) {
        this.close.emit();
        return;
      }
    }

    if (action == "kuendigen") {
      const name = `${this.user.profile?.vorname} ${this.user.profile?.nachname}`;
      const errors: string[] = [];
      const further_applications: Application[] = [];
      try {
        const res: { errors: string[]; applications: Application[] } =
          await this.data.checkIfUserHasFutureBookings(
            this.incomingUser.id!,
            this.user.applications![0].project_id
          );
        further_applications.push(...res.applications);
        errors.push(...res.errors);
      } catch (error) {
        Notify.failure("Etwas ist schief gelaufen.");
        this.isUploading = false;
        return;
      }
      // only_projects_for_requesting_pm

      let text =
        "Bist du sicher, dass du den Topteamer für diesen Job kündigen möchtest?";

      console.log(`errors`, errors);
      if (errors.length) {
        text = `${name} ist aktuell bei mehreren Jobs aktiv. sollen die weitern Jobs auch gekündigt werden?<br><br> <hr> <br> Aktive Jobs: <br> ${errors.join(
          "<br><br>"
        )}`;
      }

      const btnTxt = errors.length ? "Nur diesen Job kündigen" : "Job kündigen";
      const result = await new Promise((res) => {
        Confirm.show(
          "Bist du sicher?",
          text,
          "Abbrechen",
          btnTxt,
          () => {
            res("abbrechen");
          },
          async () => {
            res("diesen_job_kuendigen");
          },
          {
            messageMaxLength: 1000000,
            plainText: false,
          } as IConfirmOptions
        );
        if (errors.length) {
          const element = document.querySelector(
            "#NotiflixConfirmWrap .notiflix-confirm-buttons"
          );
          element?.classList.add("kuendigen-confirm-buttons");
          const buttonEl = document.createElement("a");
          buttonEl.className = "nx-confirm-button-cancel";
          buttonEl.innerText = "Alle Jobs kündigen";
          buttonEl.addEventListener("click", () => {
            setTimeout(() => {
              document.getElementById("NXConfirmButtonCancel")?.click();
            }, 200);
            res("alle_jobs_kuendigen");
          });
          if (element) element.appendChild(buttonEl);
        }
        // buttonEl.append(buttonEl);
      });

      console.log(`result`, result);
      // let kuendigen_result = null;
      try {
        if (result == "alle_jobs_kuendigen") {
          this.applications_to_dismiss = further_applications.concat(
            this.user.applications![0]
          );
        }
        if (result == "diesen_job_kuendigen") {
          this.applications_to_dismiss = [this.user.applications![0]];
        }
      } catch (error) {
        Notify.failure("Etwas ist schief gelaufen.");
      }
    }

    if (this.applications_to_dismiss.length) {
      this.isUploading = true;
      try {
        this.current_dismissal_template = await this.data.postRequest(
          "/getDocumentTemplate",
          {
            name: "Kündigung",
            application_id: this.applications_to_dismiss[0].id!,
          }
        );
        console.log(
          `this.current_dismissal_template`,
          this.current_dismissal_template
        );
      } catch (error) {
        console.error(`error`, error);
      }
      this.isUploading = false;
    } else {
      this.current_dismissal_template = undefined;
    }
    // This is only here, because of an typescript error when returing inside this function
  }

  // bewerbercheck functions start
  async handleApplicationStatus(action: ApplicationAction) {
    this.editApplicationSedNotUserProfile = false;
    if (!this.user) return;
    const appl_id = this.user.applications![0].id!;
    if (action == "aus_auswahl_entfernen" || action == "ablehnen") {
      const confirm_result = await new Promise((res) => {
        let text = "";
        switch (action) {
          case "aus_auswahl_entfernen":
            text =
              "Möchtest du den Bewerber wirklich aus der Auswahl entfernen? Der Bewerber wird in den Projektpool verschoben.";
            break;
          case "ablehnen":
            if (!this.user?.is_topteamer) {
              text =
                "Möchtest du den Bewerber wirklich ablehnen? Da der Bewerber noch kein Topteamer ist, wird er gelöscht. ";
            } else {
              res(true);
              return;
            }
            break;

          default:
            break;
        }

        Confirm.show(
          "Bist du sicher?",
          text,
          "Nein",
          "Ja",
          () => {
            return res(false);
          },
          () => {
            return res(true);
          }
        );
      });
      if (!confirm_result) {
        return;
      }
    }

    if (action == "sed_card_bearbeiten") {
      this.currentPage = "sed";
      this.sedForm.enable();
      this.arbeitskreafteEdit = true;
      this.buttonsToShowBeforeEdit = this.buttonsToShow;
      this.editApplicationSedNotUserProfile = true;
      console.log(`handleApplicationStatus this.sedForm`, this.sedForm);
      this.buttonsToShow = ["sed_card_speichern"];
      return;
    }
    if (action == "sed_card_speichern") {
      this.submitAttempt = false;
      if (this.sedForm.invalid) {
        this.submitAttempt = true;
        Notify.info("Es gibt noch fehlerhafte Felder");
        return;
      }
    }
    if (action == "daten_fehlerhaft") {
      this.submitAttempt = false;
      if (this.datenFehlerhaftForm.invalid) {
        this.submitAttempt = true;
        Notify.info("Bitte füge noch eine Nachricht ein.");
        return;
      }
    }
    if (action == "vertrag_erstellen" || action == "vertrag_erneut_erstellen") {
      this.submitAttempt = false;
      if (!this.data.currentProject?.honorar.honorar_eur) {
        Notify.info(
          "Da in den Projekteinstellungen noch kein Honorar angegeben wurde, kann kein Vertrag erstellt werden."
        );
        return;
      }

      if (!this.user?.applications![0].all_data_approved_by_pm_ts) {
        Notify.info(
          "Da die Daten des Bewerbers noch nicht von einem Topteamer geprüft wurden, kann kein Vertrag erstellt werden."
        );
        return;
      }

      if (action == "vertrag_erneut_erstellen") {
        const erneut_res = await new Promise((res) => {
          Confirm.show(
            "Bist du sicher?",
            "Möchtest du den Vertrag wirklich erneut erstellen? Der alte Vertrag wird dabei gelöscht, sobald im nächsten Schritt 'Vertrag senden' gedrückt wurde.",
            "Nein",
            "Ja",
            () => {
              return res(false);
            },
            () => {
              return res(true);
            },
            {
              messageMaxLength: 1000000,
              plainText: false,
            } as IConfirmOptions
          );
        });

        if (!erneut_res) {
          return;
        }
      }
      try {
        this.isUploading = true;
        this.vertrag_unterzeichnen_bis = formatDate(
          dayjs().add(3, "day").toDate(),
          "yyyy-MM-dd",
          "en"
        );
        this.vertrag = {
          Arbeitsvertrag: (
            await this.data.getDocumentTemplate("Arbeitsvertrag", appl_id)
          ).template,
          ANUE: (await this.data.getDocumentTemplate("ANUE", appl_id)).template,
        };
        if (action == "vertrag_erneut_erstellen") {
          this.buttonsToShow = ["vertrag_erneut_senden"];
        } else {
          this.buttonsToShow = ["vertrag_senden"];
        }
        this.isUploading = false;
      } catch (error) {
        this.isUploading = false;
        Notify.failure("Fehler beim Laden des Vertrags");
        console.error(`error`, error);
      }
      return;
    }

    this.isUploading = true;
    try {
      await this.data.handleApplicationStatus(
        appl_id,
        action,
        action == "sed_card_speichern" ? this.sedForm.value : undefined,
        this.vertrag
          ? this.vertrag[this.arbeitsvertrag_typ]
          : this.datenFehlerhaftForm.value.nachricht,
        this.vertrag ? this.vertrag_unterzeichnen_bis : undefined
      );
    } catch (error) {
      console.error(`error`, error);
      this.vertrag = undefined;
      this.isUploading = false;
      this.submitAttempt = false;
      this.datenFehlerhaftForm.reset();
      if (action == "vertrag_senden") {
        this.buttonsToShow = ["vertrag_erstellen"];
      }
      Notify.failure("Etwas ist schief gelaufen. Bitte versuche es erneut.");
      return;
    }
    this.vertrag = undefined;
    this.isUploading = false;
    this.submitAttempt = false;
    this.datenFehlerhaftForm.reset();

    if (action == "kuendigen") {
      Notify.success("Der Bewerber wurde erfolgreich gekündigt.");
      this.buttonsToShow = [];
    }

    if (action == "vertrag_senden") {
      Notify.success("Vertrag wurde gesendet.");
    }

    if (action == "daten_fehlerhaft") {
      this.datenFehlerhaftForm.reset();
      Notify.success("Die Nachricht wurde erfolgreich versendet.");
      this.datenFehlerhaftOpen = false;
    }

    if (action == "sed_card_speichern") {
      this.buttonsToShow = ["sed_card_bearbeiten", "aus_auswahl_entfernen"];
      this.sedForm.disable();
      this.arbeitskreafteEdit = false;
      if (this.buttonsToShowBeforeEdit)
        this.buttonsToShow = this.buttonsToShowBeforeEdit;
      this.isUploading = true;
      await this.initBewerbercheckModal();
      this.currentPage = "sed";
      this.isUploading = false;
      return;
    }

    this.nextAfterAction.emit();
    // ablehnen -> löschenf
  }

  async saveForm() {
    if (!this.user) return;

    // remove sed.sprachen.weitereAsGroup before sending
    const sed = { ...this.sedForm.value };
    if (sed.sprachen && sed.sprachen.weitereAsGroup) {
      delete sed.sprachen.weitereAsGroup;
    }

    if (
      this.editApplicationSedNotUserProfile &&
      this.user?.applications &&
      this.user.applications.length > 0
    ) {
      const appl_id = this.user.applications[0].id!;
      try {
        await this.data.handleApplicationStatus(
          appl_id,
          "sed_card_speichern",
          sed,
          this.vertrag
            ? this.vertrag[this.arbeitsvertrag_typ]
            : this.datenFehlerhaftForm.value.nachricht,
          this.vertrag ? this.vertrag_unterzeichnen_bis : undefined
        );
        this.markFromAsPristine();
      } catch (error) {
        console.error(`error`, error);
        Notify.failure("Etwas ist schief gelaufen. Bitte versuche es erneut.");
      }
      return;
    }

    if (this.arbeitskreafteEdit) {
      this.submitAttempt = false;
      Confirm.show(
        "Bist du dir sicher?",
        "Bist du dir sicher? Die Änderungen sind auch für den Nutzer sichtbar.",
        "Nein",
        "Ja",
        async () => {},
        async () => {
          try {
            this.isUploading = true;

            await this.data.updateUserProfile(
              this.profileForm.value as Profile,
              sed,
              this.user!.id!
            );

            this.arbeitskreafteEdit = true;
            if (this.buttonsToShowBeforeEdit)
              this.buttonsToShow = this.buttonsToShowBeforeEdit;
            this.isUploading = false;
            this.markFromAsPristine();
            Notify.success("Das Profil wurde erfolgreich aktualisiert.");
          } catch (error) {
            Notify.failure("Etwas ist schief gelaufen.");
            this.isUploading = false;
          }
        }
      );
    }
  }

  /**
   * Set the original form values to the current form values and mark the form as pristine.
   */
  private markFromAsPristine() {
    this.originalProfileForm.patchValue(this.profileForm.value);
    this.profileForm.markAsPristine();

    this.originalSedForm.patchValue(this.sedForm.value);
    this.sedForm.markAsPristine();

    this.checkHasChanges();
  }

  async deleteUser(nextAfterDelete: boolean = false) {
    console.log("deleteUser");
    this.isUploading = true;
    const errors: string[] = [];
    try {
      errors.push(
        ...(await this.data.checkIfUserHasFutureBookings(this.incomingUser.id!))
          .errors
      );
    } catch (error) {
      Notify.failure("Etwas ist schief gelaufen.");
      this.isUploading = false;
      return;
    }
    let errors_string = "";
    if (errors.length > 0) {
      errors_string =
        "<br><br><span style='color:red; font-size:bold'>ACHTUNG:</span> Bitte beachte folgende Fehler:<br><br>";
      errors_string += errors.join("<br><br>");
      errors_string +=
        "<br><br> <b>Bitte kläre erst diese Fehler bevor du das Profil deaktivierst.</b>";
    }
    Confirm.show(
      "Profil löschen?",
      `In ein gelöschtes Profil kann sich nicht mehr eingeloggt werden. Vergangene Verträge und Unterlagen bleiben aber erhalten, jedoch muss das Profil davor erst mit der gleichen E-Mail-Adresse erstellt werden.${errors_string}`,
      "ABBRECHEN",
      "LÖSCHEN",
      () => {
        // nein
        this.isUploading = false;
      },
      async () => {
        try {
          this.isUploading = true;
          if (this.incomingUser.id) {
            // await this.data.setUserActivated(this.incomingUser.id, false);
            await this.data.deleteUser(this.incomingUser.id!);
          }
          this.incomingUser.deleted = true;
          this.isUploading = false;
          if (nextAfterDelete) {
            this.nextAfterAction.emit();
          } else {
            this.close.emit(true);
          }
          Notify.success("Profil wurde erfolgreich gelöscht.");
        } catch (error) {
          this.isUploading = false;
          Notify.failure("Etwas ist schief gelaufen.");
          console.error("error", error);
        }
      },
      {
        messageMaxLength: 1000000,
        plainText: false,
      } as IConfirmOptions
    );

    // Confirm.show(
    //   "Bist du sicher?",
    //   "Bist du sicher, dass du das Profil löschen möchten?",
    //   "Nein",
    //   "Ja",
    //   () => {
    //     // nein
    //   },
    //   async () => {
    //     try {
    //       this.isUploading = true;
    //       if (this.user) {
    //         await this.data.deleteUser(this.user.id!);
    //         Notify.success("Der User wurde erfolgreich gelöscht!");
    //         this.close.emit(true);
    //       }
    //     } catch (error) {
    //       console.error(`deleteUser error`, error);
    //       Notify.failure(
    //         "Etwas ist schief gelaufen. Der User konnte nicht gelöscht werden."
    //       );
    //     }
    //     this.isUploading = false;

    //     // ja
    //   }
    // );
  }

  // closeModalClicked(specificModal: string = "") {
  //   if (specificModal) {
  //     if (specificModal === "signature") {
  //       this.showSignatureModal = false;
  //     }
  //   } else {
  //     this.showSignatureModal = false;
  //   }
  // }
}
