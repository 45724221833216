<div class="sidebar-nav" [ngClass]="{ 'display-none': data.keyboardOpen }">
  <app-button
    class="register-button-absolute"
    color="secondary-gradient"
    [lessHeight]="true"
    background="transparent"
    routerLink="/register"
    *ngIf="
      !auth.currentUser &&
      !data.hideJetztBewerbenButton &&
      currentRoute != '/register' &&
      currentRoute != '/login'
    "
    >Jetzt registrieren</app-button
  >
  <div class="logo" routerLink="/">
    <img src="assets/svgs/logo_small.svg" alt="" />
  </div>
  <app-toggle
    (indexSelectedChange)="indexSelectedChange.emit($event)"
    [indexSelected]="currentActivedPageIndex"
    class="nav"
    [toggles]="pages"
    mode="menu"
    color="white-gold"
    [vertical]="true"
  ></app-toggle>
  <div class="login">
    <!-- <div class="bottom">

    </div> -->
    <div class="bottom">
      <span
        (click)="loginLogout()"
        [ngClass]="{ activated: currentRoute == '/login' }"
      >
        <app-svgs [type]="loggedIn ? 'logout' : 'login'"></app-svgs>
        {{ loggedIn ? "Abmelden" : "Login" }}
      </span>
      <app-button
        *ngIf="
          !auth.currentUser &&
          currentRoute != '/register' &&
          currentRoute != '/login'
        "
        color="secondary-gradient"
        [lessHeight]="true"
        routerLink="/register"
        >Jetzt registrieren</app-button
      >
    </div>
  </div>
</div>
