import { Notify } from "notiflix";
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from "@angular/common/http";
import { catchError, map } from "rxjs";
import { AuthService } from "./auth.service";
import { DataService } from "../data.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private data: DataService) {}

  justFiredNotifyLogIn: boolean = false;

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.authService.getToken();
    // console.log(`AuthInterceptor authToken`, authToken);
    const tmpReq = req.clone({
      setHeaders: {
        "x-custom-token": `Bearer ${authToken}`,
      },
    });
    return next.handle(tmpReq).pipe(
      map((res) => {
        this.data.minutesInactiveCount = 0;
        return res;
      }),

      catchError(async (err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // disable consoleError for 1 second, so that Sentry does not track 401 subsequent errors. 401 is expected here.
            // dear follow-up developer: im glad that you found peak of my code quality here - please dont judge me
            const consoleError = console.error;
            console.error = () => {};
            setTimeout(() => {
              console.error = consoleError;
            }, 1000);
            if (!this.justFiredNotifyLogIn) {
              this.justFiredNotifyLogIn = true;
              Notify.warning("Bitte logge dich erneut ein.");
              setTimeout(() => {
                this.justFiredNotifyLogIn = false;
              }, 1000);
            }
            await this.authService.doLogout();
          }
        }
        return err;
      })
    );
  }
}
