<div class="add-media-container" *ngIf="!listView">
  <div
    class="preview-container"
    *ngFor="let image of uploadFilesFiltered; let i = index"
    [ngClass]="{
      spinner: image && image.isUploading
    }"
  >
    <div
      class="deleted-overlay"
      (click)="openNewTab(image)"
      *ngIf="image && image.deleted"
    >
      <div class="deleted-text">Gelöscht</div>
    </div>
    <div
      *ngIf="!onlyPreview && !image.deleted"
      class="delete-btn"
      (click)="deleteImage(i)"
    >
      &times;
    </div>
    <img
      *ngIf="image.mimeType != 'application/pdf'"
      [ngClass]="{ 'cursor-pointer': image && image.nameOnDisk }"
      class="button-native"
      [src]="getUrl(image)"
      (click)="openNewTab(image)"
      alt=""
    />
    <img
      *ngIf="image.mimeType == 'application/pdf'"
      [ngClass]="{ 'cursor-pointer': image && image.nameOnDisk }"
      class="button-native"
      src="assets/svgs/PDF_file_icon.svg"
      (click)="openNewTab(image)"
      alt=""
    />
  </div>
  <img
    src="assets/svgs/fileupload.png"
    alt="fileupload"
    *ngIf="showUploadButton"
    (click)="addMediaClicked()"
  />

  <input
    hidden
    [multiple]="!maxFiles || maxFiles > 1"
    [id]="'hidden_file_input' + fileUploadId"
    name="hidden_file_input[]"
    type="file"
    [accept]="acceptTypes"
    (change)="onFileChange($event)"
    *ngIf="showUploadButton"
  />
</div>

<div class="" *ngIf="listView">
  <div *ngFor="let file of uploadFilesFiltered; let i = index">
    <a
      [href]="getUrl(file)"
      target="_blank"
      rel="noopener noreferrer"
      class="nachweis-link flex flex-center-vertical gap-7"
      [ngClass]="{
        'deleted-file': file && file.deleted
      }"
    >
      <app-svgs type="page" color="secondary"></app-svgs>
      <strong>{{ file.filename }}</strong></a
    >
  </div>
</div>
