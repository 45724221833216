<!-- arbeitszeiten modal -->
<app-modal
  *ngIf="arbeitszeitenModal"
  class="arbeitszeiten-export-modal"
  (close)="arbeitszeitenModal = false"
>
  <div>
    <h5>Datenexport</h5>
    <h2>Zeitraum wählen</h2>
  </div>
  <div *ngIf="!selectedUsersLength">
    Da derzeit keine Mitarbeiter ausgewählt sind, werden alle Mitarbeiter in den
    Export aufgenommen.
  </div>
  <div *ngIf="selectedUsersLength">
    Derzeit ausgewählt: {{ selectedUserNameString }}
  </div>

  <div class="from-to-wrapper margin-top-16">
    <input
      type="date"
      id="vertrag_unterzeichnen_bis"
      name="vertrag_unterzeichnen_bis"
      [(ngModel)]="export_start"
      [value]="export_start | date : 'y-MM-dd'"
    />
    <input
      type="date"
      id="vertrag_unterzeichnen_bis"
      name="vertrag_unterzeichnen_bis"
      [(ngModel)]="export_end"
      [value]="export_end | date : 'y-MM-dd'"
    />
  </div>
  <div class="flex-right margin-top-16">
    <app-button
      color="secondary-gradient"
      [lessHeight]="true"
      (click)="exportArbeitszeiten()"
      [disabled]="exportLoading"
      [isLoading]="exportLoading"
      >Daten download</app-button
    >
  </div>
</app-modal>

<!-- stammdaten modal -->
<app-modal
  *ngIf="stammdatenModal"
  class="arbeitszeiten-export-modal"
  (close)="stammdatenModal = false"
>
  <div>
    <h5>Stammdatenexport</h5>
    <h2>Zeitraum wählen für Eintritts- und Austrittsdatum</h2>
  </div>

  <div class="from-to-wrapper margin-top-16">
    <input
      type="date"
      id="vertrag_unterzeichnen_bis"
      name="vertrag_unterzeichnen_bis"
      [(ngModel)]="export_start"
      [value]="export_start | date : 'y-MM-dd'"
    />
    <input
      type="date"
      id="vertrag_unterzeichnen_bis"
      name="vertrag_unterzeichnen_bis"
      [(ngModel)]="export_end"
      [value]="export_end | date : 'y-MM-dd'"
    />
  </div>
  <div class="flex-right margin-top-16">
    <app-button
      color="secondary-gradient"
      [lessHeight]="true"
      (click)="downloadAllStammdaten()"
      [disabled]="exportLoading"
      [isLoading]="exportLoading"
      >Daten download</app-button
    >
  </div>
</app-modal>

<app-copy-personal-to-other-project-modal
  (close)="copyToProjectModal = false"
  *ngIf="copyToProjectModal"
  [incomingSelectedUserIds]="selectedUserIds"
>
</app-copy-personal-to-other-project-modal>

<div class="meine-projekte">
  <div class="main no-detail">
    <div class="main-head">
      <div class="left">
        <h1 class="white">
          Alle<br /><span class="secondary">{{
            onlyAdminsAndPMs ? "Projektleitungen" : "Topteamer"
          }}</span>
        </h1>
      </div>
      <div class="right">
        <app-searchbar
          [color]="'primary20'"
          [formContrl]="arbeitskraefteFilter.controls['freitextsuche']"
        ></app-searchbar>
      </div>
    </div>

    <div class="main-head">
      <div class="left">
        <app-button
          *ngIf="!onlyAdminsAndPMs"
          style="width: fit-content"
          iconRight="filter"
          format="normal"
          color="secondary-gradient"
          [lessHeight]="true"
          (click)="showArbeitskraefteFilterModal = true"
          >FILTER</app-button
        >
      </div>
      <div class="right">
        <div class="btn-wrap">
          <div class="dropdown-wrap">
            <app-button
              *ngIf="!onlyAdminsAndPMs"
              iconRight="chevron-down-small"
              format="normal"
              color="white"
              [lessHeight]="true"
              (click)="aktionenDropdown = !aktionenDropdown"
              [isLoading]="exportLoading"
              [disabled]="exportLoading"
            >
              Aktionen
            </app-button>
            <div class="dropdown" *ngIf="aktionenDropdown">
              <div (click)="mailtoAuswahl(); aktionenDropdown = false">
                Auswahl kontaktieren
              </div>
              <div
                (click)="copyToProjectModal = true; aktionenDropdown = false"
              >
                Topteamer zu Projekt hinzufügen
              </div>
              <div (click)="stammdatenModal = true; aktionenDropdown = false">
                Alle Stammdaten herunterladen
              </div>
              <div
                (click)="arbeitszeitenModal = true; aktionenDropdown = false"
              >
                Arbeitszeiten als Excel exportieren
              </div>
            </div>
          </div>
          <app-button
            iconRight="plus"
            format="normal"
            color="white"
            [lessHeight]="true"
            (click)="createNewUser()"
            [disabled]="exportLoading"
            [isLoading]="exportLoading"
          >
            {{ onlyAdminsAndPMs ? "Neue Projektleitung" : "Neuer Topteamer" }}
          </app-button>
        </div>
      </div>
    </div>

    <!-- TAGS -->
    <div class="main-head">
      <div class="filter-tags">
        <!-- distanz -->
        <div
          class="tag"
          *ngIf="
            arbeitskraefteFilter.value['lat'] &&
            arbeitskraefteFilter.value['distance']
          "
          (click)="resetLatLng(true); clearPlzOrtFilter()"
        >
          {{ event_location?.name }} +
          {{ arbeitskraefteFilter.value["distance"] }} km
        </div>
        <!-- geschlecht -->
        <span
          *ngFor="
            let el of arbeitskreefteFilterObject.geschlecht.value;
            index as i
          "
        >
          <div
            class="tag"
            (click)="
              arbeitskreefteFilterObject.geschlecht.value.length > 1
                ? arbeitskreefteFilterObject.geschlecht.setValue(
                    arbeitskreefteFilterObject.geschlecht.value.splice(i, 1)
                  )
                : arbeitskreefteFilterObject.geschlecht.reset()
            "
          >
            Geschlecht:
            {{ el == "m" ? "Männlich" : el == "d" ? "Divers" : "Weiblich" }}
          </div>
        </span>

        <!-- sprachen -->
        <span *ngFor="let sprache of sprachen_fa.controls; index as i">
          <div
            class="tag"
            *ngIf="sprache.value.sprache && sprache.value.sprache_level_min"
            (click)="
              sprachen_fa.controls.length > 1
                ? sprachen_fa.removeAt(i)
                : sprachen_fa.controls[i].reset()
            "
          >
            {{ sprache.value.sprache | titlecase }}:
            {{ sprache.value.sprache_level_min }}
          </div>
        </span>
        <!-- Äußeres -->
        <span
          *ngFor="
            let el of arbeitskreefteFilterObject.haarfarbe.value;
            index as i
          "
        >
          <div
            class="tag"
            (click)="
              arbeitskreefteFilterObject.haarfarbe.value.length > 1
                ? arbeitskreefteFilterObject.haarfarbe.setValue(
                    arbeitskreefteFilterObject.haarfarbe.value.splice(i, 1)
                  )
                : arbeitskreefteFilterObject.haarfarbe.reset()
            "
          >
            Haarfarbe:
            {{ el }}
          </div>
        </span>

        <span
          *ngFor="let item of other_min_max_objects"
          class="inline-flex gap-7"
        >
          <span *ngFor="let fg of fa_array[item.key].value; index as i">
            <div
              class="tag"
              *ngIf="fg[item.key + '_min'] || fg[item.key + '_max']"
              (click)="
                fa_array[item.key].length > 1
                  ? fa_array[item.key].removeAt(i)
                  : fa_array[item.key].reset()
              "
            >
              {{ item.label }}:
              <span>
                {{
                  item.key == "shirtgroesse"
                    ? shirtArr[fg[item.key + "_min"]]
                    : fg[item.key + "_min"]
                }}
                <span *ngIf="fg[item.key + '_min'] && fg[item.key + '_max']"
                  >-</span
                >
                {{
                  item.key == "shirtgroesse"
                    ? shirtArr[fg[item.key + "_max"]]
                    : fg[item.key + "_max"]
                }}
                {{ item.einheit }}
              </span>
            </div>
          </span>
        </span>

        <!-- Konfektionsgrößen -->
        <span
          *ngFor="let item of konfektionsgroessen_objects"
          class="inline-flex gap-7"
        >
          <span *ngFor="let fg of fa_array[item.key].value; index as i">
            <div
              class="tag"
              *ngIf="fg[item.key + '_min'] || fg[item.key + '_max']"
              (click)="
                fa_array[item.key].length > 1
                  ? fa_array[item.key].removeAt(i)
                  : fa_array[item.key].reset()
              "
            >
              {{ item.label }}:
              <span>
                {{
                  item.key == "shirtgroesse"
                    ? shirtArr[fg[item.key + "_min"]]
                    : fg[item.key + "_min"]
                }}
                <span>-</span>
                {{
                  item.key == "shirtgroesse"
                    ? shirtArr[fg[item.key + "_max"]]
                    : fg[item.key + "_max"]
                }}
              </span>
            </div>
          </span>
        </span>

        <!-- Mobilität -->
        <div
          class="tag"
          *ngIf="
            arbeitskraefteFilter.value['fuehrerschein'] === true ||
            arbeitskraefteFilter.value['fuehrerschein'] === false
          "
          (click)="arbeitskraefteFilter.controls['fuehrerschein'].setValue('')"
        >
          Führerschein:
          {{
            arbeitskraefteFilter.value["fuehrerschein"] === true ? "Ja" : "Nein"
          }}
        </div>

        <div
          class="tag"
          *ngIf="vorhandene_mobilitaet_fg.value['PKW']"
          (click)="vorhandene_mobilitaet_fg.controls['PKW'].reset()"
        >
          Vorhandene Mobilität: PKW
        </div>
        <div
          class="tag"
          *ngIf="vorhandene_mobilitaet_fg.value['Bahncard']"
          (click)="vorhandene_mobilitaet_fg.controls['Bahncard'].reset()"
        >
          Vorhandene Mobilität: Bahncard
        </div>

        <!-- Berufserfahrung -->
        <div
          class="tag"
          *ngIf="arbeitskraefteFilter.value['berufserfahrung']"
          (click)="arbeitskraefteFilter.controls['berufserfahrung'].reset()"
        >
          Berufserfahrung:
          {{ arbeitskraefteFilter.value["berufserfahrung"].join(", ") }}
        </div>
        <div
          class="tag"
          *ngIf="arbeitskraefteFilter.value['teilgenommene_events']"
          (click)="
            arbeitskraefteFilter.controls['teilgenommene_events'].reset()
          "
        >
          Teilgenommene Events:
          {{
            getTeilgenommeneEvents(
              arbeitskraefteFilter.value["teilgenommene_events"]
            )
          }}
        </div>
        <div
          class="tag"
          *ngIf="status_fg.value['deaktiviert']"
          (click)="status_fg.controls['deaktiviert'].reset()"
        >
          Status: Deaktiviert
        </div>
        <div
          class="tag"
          *ngIf="status_fg.value['unvollstaendiges_profil']"
          (click)="status_fg.controls['unvollstaendiges_profil'].reset()"
        >
          Status: Unvollständiges Profil
        </div>
        <div
          class="tag"
          *ngIf="status_fg.value['active_profile']"
          (click)="status_fg.controls['active_profile'].reset()"
        >
          Status: Aktive und vollständige Profile
        </div>
      </div>
    </div>

    <!-- SELECTION -->
    <div class="main-head" *ngIf="selectedUserNameString">
      <div class="selection">
        <div class="title">
          <span>Ausgewählt ({{ selectedUsersCount }}):</span>
          <div>
            <span class="cursor-pointer" (click)="deselectAll()">
              (zurückzusetzen)</span
            >

            <!-- <div class="badge rejected">
              <app-svgs type="close" color="white"></app-svgs>
            </div> -->
          </div>
        </div>
        <div class="left">
          <span *ngIf="selectedUserNameString" class="description white">{{
            selectedUserNameString
          }}</span>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="table-wrapper">
        <app-pagination
          (pageChanged)="refreshResults()"
          [pagination]="pagination"
        ></app-pagination>

        <!-- *ngIf="!veroeffentlichtCollapsed" -->
        <table>
          <thead>
            <tr>
              <th>
                <div class="flex-center">
                  <input
                    class="list-checkbox"
                    type="checkbox"
                    [(ngModel)]="allSelected"
                    (change)="selectAll($event)"
                  />
                </div>
              </th>
              <th
                (click)="toggleSortDirection(item.sortDirectionKey)"
                *ngFor="let item of tableHeaders"
              >
                <div class="flex flex-center-vertical gap-7">
                  <span>{{ item.label }}</span>
                  <app-ordering-icons
                    class="flex-center-vertical"
                    [sortDirection]="
                      sortDirection.field === item.sortDirectionKey
                        ? sortDirection.order
                        : 0
                    "
                  ></app-ordering-icons>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let user of arbeitskreafteFiltered"
              [ngClass]="{ 'user-deactivated': !user.activated }"
            >
              <td>
                <div class="flex-center">
                  <input
                    class="list-checkbox"
                    type="checkbox"
                    (change)="selectDeselect($event, user)"
                    [(ngModel)]="user.selected"
                  />
                </div>
              </td>

              <td (click)="openEditForm(user)">
                <div class="flex-center-vertical gap-7">
                  <div
                    class="required-dot"
                    *ngIf="user?.registration_status == 'neu'"
                  ></div>
                  <h3>
                    <span class="no-wrap">
                      {{ toFirstNameOnly(user?.profile?.vorname) }}
                      {{ user?.profile?.nachname }}
                      <span
                        class="user-deactivated-badge"
                        *ngIf="!user.activated"
                      >
                        Deaktiviert
                      </span>
                    </span>
                  </h3>
                </div>
              </td>
              <td (click)="openEditForm(user)" *ngIf="onlyAdminsAndPMs">
                {{ user?.role === "superadmin" ? "Admin" : "Projektleitung" }}
              </td>
              <td (click)="openEditForm(user)" *ngIf="onlyAdminsAndPMs">
                <a
                  class="a white text-decoration-none"
                  href="mailto:{{ user?.email }}"
                  >{{ user?.email }}</a
                >
              </td>
              <td (click)="openEditForm(user)" *ngIf="!onlyAdminsAndPMs">
                {{
                  user.meta?.letzter_einsatz
                    ? (user.meta?.letzter_einsatz | date : "dd.MM.YYYY")
                    : "Nie"
                }}
              </td>
              <td (click)="openEditForm(user)" *ngIf="!onlyAdminsAndPMs">
                {{ user.alter }}
              </td>
              <td (click)="openEditForm(user)" *ngIf="!onlyAdminsAndPMs">
                {{ user.profile?.plz || "" }}
              </td>
              <td (click)="openEditForm(user)" *ngIf="!onlyAdminsAndPMs">
                {{ user.meta?.application_count || 0 }}
              </td>
              <td>
                {{
                  !user.last_activity
                    ? "Nie"
                    : dayjs(user.last_activity).format("DD.MM.YYYY")
                }}
              </td>
              <td (click)="openEditForm(user)">
                <div class="flex-right">
                  <div
                    class="badge flex-center"
                    [ngClass]="{
                      success: user.is_topteamer,
                      primary120: !user.is_topteamer
                    }"
                  >
                    <app-svgs
                      [type]="user.is_topteamer ? 'check' : 'close'"
                      color="white"
                    ></app-svgs>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <app-pagination
          (pageChanged)="refreshResults()"
          [pagination]="pagination"
        ></app-pagination>
      </div>
    </div>
  </div>
</div>

<!-- auswahl kontaktieren modal -->
<app-send-mail-to-auswahl-modal
  (close)="closeMailModal()"
  [selectedMails]="selectedMails"
  *ngIf="mailModal"
></app-send-mail-to-auswahl-modal>
<!-- auswahl kontaktieren modal end -->

<!-- filter modal -->
<app-modal
  [formGroup]="arbeitskraefteFilter"
  [ngStyle]="{ display: showArbeitskraefteFilterModal ? 'flex' : 'none' }"
  (close)="showArbeitskraefteFilterModal = false"
>
  <div class="flex space-between">
    <h5 class="flex-center">Suchfilter</h5>
    <div class="p-light">{{ suchergebnisseCount }} Ergebnisse</div>
  </div>
  <!-- <form  class="such-form"> -->
  <!-- [formGroup]="arbeitskraefteFilter" -->
  <app-collapsable [dontShowCollapsableHeader]="true" [collapsed]="false">
    <h4>Distanz</h4>
    <div class="left-right">
      <div class="left">
        <h5>PLZ oder Ort</h5>
        <div>
          <div>
            <input
              class="rounded-input"
              type="text"
              (keyup)="resetLatLng()"
              [(ngModel)]="plz_filter_value"
              (blur)="clearPlzOrtFilter()"
              [ngModelOptions]="{ standalone: true }"
              (keydown.enter)="$event.preventDefault()"
              [placeholder]="
                event_location && event_location.formatted_address
                  ? event_location.formatted_address
                  : 'Bitte Ort eingeben und auswählen'
              "
              autocorrect="off"
              autocapitalize="off"
              spellcheck="off"
              type="text"
              #search
            />
          </div>
          <div
            *ngIf="arbeitskraefteFilter.value['lat']"
            class="trash flex-center"
            (click)="resetLatLng(true)"
          >
            <app-svgs type="trash"></app-svgs>
          </div>
        </div>
      </div>
      <div class="right">
        <h5>Umkreis</h5>
        <div>
          <div class="cm-wrap km">
            <input
              type="number"
              formControlName="distance"
              min="0"
              oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
            />
          </div>
          <div
            *ngIf="arbeitskraefteFilter.value['distance']"
            class="trash flex-center"
            (click)="arbeitskraefteFilter.controls['distance'].reset()"
          >
            <app-svgs type="trash"></app-svgs>
          </div>
        </div>
      </div>
    </div>
    <div class="left-right">
      <div class="left">
        <h5>Geschlecht</h5>
        <div class="gap-7">
          <div>
            <ng-select
              class="hide-delete-button"
              [multiple]="true"
              [items]="geschlecht"
              bindLabel="label"
              bindValue="value"
              formControlName="geschlecht"
            >
            </ng-select>
          </div>
          <div
            *ngIf="
              arbeitskraefteFilter.value['geschlecht'] &&
              arbeitskraefteFilter.value['geschlecht'].length
            "
            class="trash flex-center"
            (click)="arbeitskraefteFilter.controls['geschlecht'].reset()"
          >
            <app-svgs type="trash"></app-svgs>
          </div>
        </div>
        <!-- [formContrl]="sprachenFG.controls['deutsch']" -->
      </div>
      <div class="right"></div>
    </div>
  </app-collapsable>

  <app-collapsable
    [dontShowCollapsableHeader]="false"
    [collapsed]="true"
    formArrayName="sprachen"
  >
    <h4>Sprache</h4>
    <div
      class="left-right"
      *ngFor="let sprache of sprachen_fa.controls; index as i"
      [formGroupName]="i"
    >
      <div class="left">
        <h5>Sprache</h5>
        <div>
          <div class="ng-select-container-white">
            <select formControlName="sprache" class="white-bg">
              <option value="" selected>-</option>
              <option value="deutsch">Deutsch</option>
              <option value="englisch">Englisch</option>
              <option value="spanisch">Spanisch</option>
              <option value="franzoesisch">Französisch</option>
              <option value="italienisch">Italienisch</option>
            </select>
          </div>
        </div>
      </div>
      <div class="right">
        <h5>Kenntnisse min.</h5>
        <div class="gap-7">
          <div>
            <div>
              <app-toggle
                color="darkblue-primary10"
                [toggles]="sprachToggles"
                [formContrl]="
                  control_to_form_group(sprachen_fa.controls[i]).controls
                    .sprache_level_min
                "
              ></app-toggle>
            </div>
          </div>
          <!-- [formContrl]="sprachenFG.controls['deutsch']" -->
          <div
            *ngIf="sprache.value['sprache'] || i > 0"
            class="trash flex-center"
            (click)="
              sprachen_fa.length > 1
                ? sprachen_fa.removeAt(i)
                : sprachen_fa.reset()
            "
          >
            <!-- (click)="sprache.controls['sprache'].reset()" -->
            <app-svgs type="trash"></app-svgs>
          </div>
          <div class="plus" (click)="push_sprache_fg()">+</div>
        </div>
      </div>
    </div>
  </app-collapsable>

  <!-- Äusseres -->
  <app-collapsable [collapsed]="true">
    <h4>Äusseres</h4>

    <div class="left-right">
      <div class="left">
        <h5>Haarfarbe</h5>
        <div>
          <div>
            <ng-select
              class="hide-delete-button"
              [multiple]="true"
              [items]="haarfarben"
              formControlName="haarfarbe"
            >
            </ng-select>
            <!-- <select name="haarfarbe" id="haarfarbe" formControlName="haarfarbe">
              <option value=""></option>
              <option *ngFor="let farbe of haarfarben" [ngValue]="farbe">
                {{ farbe }}
              </option>
            </select> -->
          </div>
          <div
            *ngIf="
              arbeitskraefteFilter.value['haarfarbe'] &&
              arbeitskraefteFilter.value['haarfarbe'].length
            "
            class="trash flex-center"
            (click)="arbeitskraefteFilter.controls['haarfarbe'].reset()"
          >
            <app-svgs type="trash"></app-svgs>
          </div>
        </div>
      </div>
    </div>

    <div formArrayName="koerpergroesse" class="flex direction-column gap-7">
      <div
        class="left-right"
        *ngFor="let fg of fa_array.koerpergroesse.controls; index as i"
        [formGroupName]="i"
      >
        <div *ngFor="let item of leftRightMinMax" class="{{ item.class }}">
          <h5 *ngIf="i == 0">Körpergröße {{ item.type }}</h5>
          <div>
            <div class="cm-wrap">
              <input
                type="number"
                step="1"
                oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                min="0"
                max="300"
                name="koerpergroesse_{{ item.type }}"
                id="koerpergroesse_{{ item.type }}"
                formControlName="koerpergroesse_{{ item.type }}"
                class="cm"
              />
            </div>
            <div
              *ngIf="
                (fg.value['koerpergroesse_min'] ||
                  fg.value['koerpergroesse_max'] ||
                  i > 0) &&
                item.type == 'max'
              "
              class="trash flex-center"
              (click)="
                fa_array.koerpergroesse.length > 1
                  ? fa_array.koerpergroesse.removeAt(i)
                  : fa_array.koerpergroesse.reset()
              "
            >
              <app-svgs type="trash"></app-svgs>
            </div>
            <div
              class="plus"
              (click)="push_minmax_fa('koerpergroesse')"
              *ngIf="item.type == 'max'"
            >
              +
            </div>
          </div>
        </div>
      </div>
    </div>

    <div formArrayName="gewicht" class="flex direction-column gap-7">
      <div
        class="left-right"
        *ngFor="let fg of fa_array.gewicht.controls; index as i"
        [formGroupName]="i"
      >
        <div *ngFor="let item of leftRightMinMax" class="{{ item.class }}">
          <h5 *ngIf="i == 0">Gewicht {{ item.type }}</h5>
          <div>
            <div class="cm-wrap kg">
              <input
                type="number"
                step="1"
                oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                min="0"
                max="300"
                name="gewicht_{{ item.type }}"
                id="gewicht_{{ item.type }}"
                formControlName="gewicht_{{ item.type }}"
                class="kg"
              />
            </div>
            <div
              *ngIf="
                (fg.value['gewicht_min'] || fg.value['gewicht_max'] || i > 0) &&
                item.type == 'max'
              "
              class="trash flex-center"
              (click)="
                fa_array.gewicht.length > 1
                  ? fa_array.gewicht.removeAt(i)
                  : fa_array.gewicht.reset()
              "
            >
              <app-svgs type="trash"></app-svgs>
            </div>
            <div
              class="plus"
              (click)="push_minmax_fa('gewicht')"
              *ngIf="item.type == 'max'"
            >
              +
            </div>
          </div>
        </div>
      </div>
    </div>

    <div formArrayName="shirtgroesse" class="flex direction-column gap-7">
      <div
        class="left-right"
        *ngFor="let fg of fa_array.shirtgroesse.controls; index as i"
      >
        <div *ngFor="let item of leftRightMinMax" class="{{ item.class }}">
          <h5 *ngIf="i == 0">Shirtgröße {{ item.type }}</h5>
          <div>
            <div>
              <!-- [indexSelected]="shirtIndex" -->
              <app-toggle
                color="darkblue-primary10"
                [toggles]="shirtToggles"
                [formContrl]="
                  control_to_form_group(fa_array.shirtgroesse.controls[i])
                    .controls['shirtgroesse_' + item.type]
                "
              ></app-toggle>
              <!-- (indexSelectedChange)="setShirtGroesse($event)" -->
            </div>
            <div
              *ngIf="
                (fg.value['shirtgroesse_min'] ||
                  fg.value['shirtgroesse_max'] ||
                  i > 0) &&
                item.type == 'max'
              "
              class="trash flex-center"
              (click)="
                fa_array.shirtgroesse.length > 1
                  ? fa_array.shirtgroesse.removeAt(i)
                  : fa_array.shirtgroesse.reset()
              "
            >
              <app-svgs type="trash"></app-svgs>
            </div>
            <div
              class="plus"
              (click)="push_minmax_fa('shirtgroesse')"
              *ngIf="item.type == 'max'"
            >
              +
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-collapsable>

  <!-- Konfektionsgrössen -->
  <app-collapsable [collapsed]="true">
    <h4>Konfektionsgrössen</h4>

    <div *ngFor="let obj of konfektionsgroessen_objects">
      <div [formArrayName]="obj.key" class="flex direction-column gap-7">
        <div
          class="left-right"
          *ngFor="let fg of fa_array[obj.key].controls; index as i"
          [formGroupName]="i"
        >
          <div *ngFor="let item of leftRightMinMax" class="{{ item.class }}">
            <h5 *ngIf="i == 0">{{ obj.label }} {{ item.type }}</h5>
            <div>
              <select
                name="{{ obj.key }}_{{ item.type }}"
                id="{{ obj.key }}_{{ item.type }}"
                formControlName="{{ obj.key }}_{{ item.type }}"
              >
                <option value=""></option>
                <option *ngFor="let groesse of obj.dataset" [ngValue]="groesse">
                  {{ groesse }}
                </option>
              </select>
              <div
                *ngIf="
                  (fg.value[obj.key + '_min'] ||
                    fg.value[obj.key + '_max'] ||
                    i > 0) &&
                  item.type == 'max'
                "
                class="trash flex-center"
                (click)="
                  fa_array[obj.key].length > 1
                    ? fa_array[obj.key].removeAt(i)
                    : fa_array[obj.key].reset()
                "
              >
                <app-svgs type="trash"></app-svgs>
              </div>
              <div
                class="plus"
                (click)="push_minmax_fa(obj.key)"
                *ngIf="item.type == 'max'"
              >
                +
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="left-right">
      <div *ngFor="let item of leftRightMinMax" class="{{ item.class }}">
        <h5>Jacke / Sacko {{ item.type }}</h5>
        <div>
          <div>
            <select
              name="jacke_sacko_{{ item.type }}"
              id="jacke_sacko_{{ item.type }}"
              formControlName="jacke_sacko_{{ item.type }}"
            >
              <option value=""></option>
              <option
                *ngFor="let groesse of jacke_sacko_groessen"
                [ngValue]="groesse"
              >
                {{ groesse }}
              </option>
            </select>
          </div>
          <div
            *ngIf="arbeitskraefteFilter.value['jacke_sacko_' + item.type]"
            class="trash flex-center"
            (click)="
              arbeitskraefteFilter.controls['jacke_sacko_' + item.type].reset()
            "
          >
            <app-svgs type="trash"></app-svgs>
          </div>
        </div>
      </div>
    </div>

    <div class="left-right">
      <div *ngFor="let item of leftRightMinMax" class="{{ item.class }}">
        <h5>Hose / Rock {{ item.type }}</h5>
        <div>
          <div>
            <select
              name="hose_rock_{{ item.type }}"
              id="hose_rock_{{ item.type }}"
              formControlName="hose_rock_{{ item.type }}"
            >
              <option value=""></option>
              <option
                *ngFor="let groesse of hose_rock_groessen"
                [ngValue]="groesse"
              >
                {{ groesse }}
              </option>
            </select>
          </div>
          <div
            *ngIf="arbeitskraefteFilter.value['hose_rock_' + item.type]"
            class="trash flex-center"
            (click)="
              arbeitskraefteFilter.controls['hose_rock_' + item.type].reset()
            "
          >
            <app-svgs type="trash"></app-svgs>
          </div>
        </div>
      </div>
    </div>

    <div class="left-right">
      <div *ngFor="let item of leftRightMinMax" class="{{ item.class }}">
        <h5>Jeansweite {{ item.type }}</h5>
        <div>
          <div>
            <select
              name="jeansweite_{{ item.type }}"
              id="jeansweite_{{ item.type }}"
              formControlName="jeansweite_{{ item.type }}"
            >
              <option value=""></option>
              <option *ngFor="let groesse of jeansweite" [ngValue]="groesse">
                {{ groesse }}
              </option>
            </select>
          </div>
          <div
            *ngIf="arbeitskraefteFilter.value['jeansweite_' + item.type]"
            class="trash flex-center"
            (click)="
              arbeitskraefteFilter.controls['jeansweite_' + item.type].reset()
            "
          >
            <app-svgs type="trash"></app-svgs>
          </div>
        </div>
      </div>
    </div>

    <div class="left-right">
      <div *ngFor="let item of leftRightMinMax" class="{{ item.class }}">
        <h5>Jeanslänge {{ item.type }}</h5>
        <div>
          <div>
            <select
              name="jeanslaenge_{{ item.type }}"
              id="jeanslaenge_{{ item.type }}"
              formControlName="jeanslaenge_{{ item.type }}"
            >
              <option value=""></option>
              <option *ngFor="let groesse of jeanslaenge" [ngValue]="groesse">
                {{ groesse }}
              </option>
            </select>
          </div>
          <div
            *ngIf="arbeitskraefteFilter.value['jeanslaenge_' + item.type]"
            class="trash flex-center"
            (click)="
              arbeitskraefteFilter.controls['jeanslaenge_' + item.type].reset()
            "
          >
            <app-svgs type="trash"></app-svgs>
          </div>
        </div>
      </div>
    </div>

    <div class="left-right">
      <div *ngFor="let item of leftRightMinMax" class="{{ item.class }}">
        <h5>Schuhgroesse {{ item.type }}</h5>
        <div>
          <div>
            <select
              name="schuhgroesse_{{ item.type }}"
              id="schuhgroesse_{{ item.type }}"
              formControlName="schuhgroesse_{{ item.type }}"
            >
              <option value=""></option>
              <option *ngFor="let groesse of schuhgroesse" [ngValue]="groesse">
                {{ groesse }}
              </option>
            </select>
          </div>
          <div
            *ngIf="arbeitskraefteFilter.value['schuhgroesse_' + item.type]"
            class="trash flex-center"
            (click)="
              arbeitskraefteFilter.controls['schuhgroesse_' + item.type].reset()
            "
          >
            <app-svgs type="trash"></app-svgs>
          </div>
        </div>
      </div>
    </div> -->
  </app-collapsable>

  <!-- Mobilität -->
  <app-collapsable [collapsed]="true">
    <h4>Mobilität</h4>
    <div class="left-right">
      <div class="left">
        <h5>Führerschein vorhanden?</h5>
        <div>
          <div class="flex">
            <label for="Ja_fuehrer" class="checkbox">
              <input
                type="radio"
                name="fuehrerschein"
                id="Ja_fuehrer"
                formControlName="fuehrerschein"
                [value]="true"
              />
              <!-- value="Ja" -->
              Ja
            </label>
            <label for="Nein_fuehrer" class="checkbox">
              <input
                type="radio"
                name="fuehrerschein"
                id="Nein_fuehrer"
                formControlName="fuehrerschein"
                [value]="false"
              />
              <!-- value="Nein" -->
              Nein
            </label>
          </div>
          <div
            *ngIf="
              arbeitskraefteFilter.value['fuehrerschein'] ||
              arbeitskraefteFilter.value['fuehrerschein'] === false
            "
            class="trash flex-center"
            (click)="arbeitskraefteFilter.controls['fuehrerschein'].reset()"
          >
            <app-svgs type="trash"></app-svgs>
          </div>
        </div>
      </div>
      <div class="right">
        <h5>Vorhandene Mobilität</h5>
        <div>
          <div class="tags" formGroupName="vorhandene_mobilitaet">
            <span>
              <input
                type="checkbox"
                id="vorhandene_mobilitaet_PKW"
                name="vorhandene_mobilitaet_PKW"
                value="PKW"
                formControlName="PKW"
              />
              <label for="vorhandene_mobilitaet_PKW">PKW</label>
            </span>
            <span>
              <input
                type="checkbox"
                id="vorhandene_mobilitaet_Bahncard"
                name="vorhandene_mobilitaet_Bahncard"
                value="Bahncard"
                formControlName="Bahncard"
              />
              <label for="vorhandene_mobilitaet_Bahncard">Bahncard</label>
            </span>
          </div>
          <div
            *ngIf="
              vorhandene_mobilitaet_fg.value['PKW'] ||
              vorhandene_mobilitaet_fg.value['Bahncard']
            "
            class="trash flex-center"
            (click)="vorhandene_mobilitaet_fg.reset()"
          >
            <app-svgs type="trash"></app-svgs>
          </div>
        </div>
      </div>
    </div>
  </app-collapsable>

  <!-- Erfahrungen -->
  <app-collapsable [collapsed]="true">
    <h4>Erfahrungen</h4>
    <div class="left-right">
      <div class="left">
        <div class="flex hide-delete-button">
          <ng-select
            class="hide-delete-button"
            [items]="berufserfahrung"
            formControlName="berufserfahrung"
            autocomplete
            [multiple]="true"
          >
          </ng-select>
          <!-- <div
            *ngIf="arbeitskraefteFilter.value['berufserfahrung']"
            class="trash flex-center"
            (click)="arbeitskraefteFilter.controls['berufserfahrung'].reset()"
          >
            <app-svgs type="trash"></app-svgs>
          </div> -->
        </div>
      </div>
    </div>
  </app-collapsable>

  <!-- Teilgenommene Events -->
  <app-collapsable [collapsed]="true">
    <h4>Teilgenommene Events</h4>
    <div class="left-right">
      <div class="left">
        <div class="flex">
          <ng-select
            class="hide-delete-button"
            [items]="teilgenommeneEvents"
            bindLabel="label"
            bindValue="id"
            formControlName="teilgenommene_events"
            autocomplete
            [multiple]="true"
          >
          </ng-select>
          <!-- <div
            *ngIf="arbeitskraefteFilter.value['teilgenommene_events']"
            class="trash flex-center"
            (click)="
              arbeitskraefteFilter.controls['teilgenommene_events'].reset()
            "
          >
            <app-svgs type="trash"></app-svgs>
          </div> -->
        </div>
      </div>
    </div>
  </app-collapsable>

  <!-- Status -->
  <app-collapsable [collapsed]="true">
    <h4>Status</h4>
    <div class="left-right">
      <div class="left">
        <h5>Topteamer mit folgendem Status anzeigen</h5>
        <div>
          <div class="tags" formGroupName="status">
            <span>
              <input
                type="checkbox"
                id="status_deaktiviert"
                name="status_deaktiviert"
                value="deaktiviert"
                formControlName="deaktiviert"
              />
              <label for="status_deaktiviert">Deaktiviert</label>
            </span>
            <span>
              <input
                type="checkbox"
                id="status_unvollstaendiges_profil"
                name="status_unvollstaendiges_profil"
                value="unvollstaendiges_profil"
                formControlName="unvollstaendiges_profil"
              />
              <label for="status_unvollstaendiges_profil"
                >Unvollständiges Profil</label
              >
            </span>
            <span>
              <input
                type="checkbox"
                id="status_active_profile"
                name="status_active_profile"
                value="active_profile"
                formControlName="active_profile"
              />
              <label for="status_active_profile"
                >Aktive und vollständige Profile</label
              >
            </span>
          </div>
          <div
            *ngIf="
              status_fg.value['deaktiviert'] ||
              status_fg.value['unvollstaendiges_profil'] ||
              status_fg.value['active_profile']
            "
            class="trash flex-center"
            (click)="status_fg.reset()"
          >
            <app-svgs type="trash"></app-svgs>
          </div>
        </div>
      </div>
    </div>
  </app-collapsable>

  <div class="buttons">
    <app-button
      background="primary10"
      color="primary80"
      background="primary10"
      iconRight="undo-circle"
      (click)="arbeitskraefteFilter.reset(); refreshResults(1)"
    >
      ZURÜCKSETZEN
    </app-button>
    <app-button background="primary10" (click)="refreshResults(1)">
      FILTER ANWENDEN
    </app-button>
  </div>
  <!-- </form> -->
</app-modal>
<!-- filter modal end -->

<!-- neue arbeitskraft start -->
<app-modal
  class="smaller-modal"
  *ngIf="!mitarbeiterToEditForm.disabled"
  [ngClass]="justEnteredMobileScrollfix ? 'transparent' : ''"
  [formGroup]="mitarbeiterToEditForm"
  (close)="mitarbeiterToEditForm.disable()"
>
  <div>
    <h4>
      {{
        !currentUserToEdit
          ? "Neue" +
            (mitarbeiterToEditForm.value["role"] == "superadmin" ? "r" : "")
          : ""
      }}
      {{
        mitarbeiterToEditForm.value["role"] == "pm"
          ? "Projektleitung"
          : mitarbeiterToEditForm.value["role"] == "superadmin"
          ? "Admin"
          : "Topteamer"
      }}
    </h4>
    <h2 class="margin-top-10">
      {{ mitarbeiterToEditForm.value["vorname"] }}
      {{ mitarbeiterToEditForm.value["nachname"] }}
    </h2>
    <p class="p-light" *ngIf="!currentUserToEdit">
      Beim anlegen eines neuen Users, wird diesem eine Mail zugeschickt in
      welcher er aufgefordert wird, sein Passwort zurückzusetzen und sein Profil
      zu vervollständigen.
    </p>
  </div>
  <div class="left-right">
    <div class="left">
      <h5>Vorname</h5>
      <div>
        <input
          type="text"
          name="vorname_add"
          id="vorname_add"
          formControlName="vorname"
        />
        <div
          *ngIf="
            mitarbeiterToEditForm.controls['vorname'].invalid && submitAttempt
          "
          class="alert"
        >
          Dieses Feld wird benötigt.
        </div>
      </div>
    </div>
    <div class="right">
      <h5>Nachname</h5>
      <div>
        <input
          type="text"
          name="nachname_add"
          id="nachname_add"
          formControlName="nachname"
        />
        <div
          *ngIf="
            mitarbeiterToEditForm.controls['nachname'].invalid && submitAttempt
          "
          class="alert"
        >
          Dieses Feld wird benötigt.
        </div>
      </div>
    </div>
  </div>
  <div class="left-right">
    <div class="left">
      <h5>E-Mail Adresse</h5>
      <div>
        <input
          type="email"
          name="email_add"
          id="email_add"
          formControlName="email"
        />
        <div class="alert">
          <span *ngIf="user_for_email_is_deleted">
            Dieser Benutzer wurde gelöscht. Durch hinzufügen wird er
            reaktiviert.
          </span>
          <span
            *ngIf="
              mitarbeiterToEditForm.controls['email'].errors?.['user_exists']
            "
          >
            {{ mitarbeiterToEditForm.controls['email'].errors?.['user_exists']}}
          </span>
          <span
            *ngIf="
              mitarbeiterToEditForm.controls['email'].invalid && submitAttempt
            "
          >
            Bitte gib eine gültige E-Mail-Adresse an.
          </span>
        </div>
      </div>
    </div>
    <div class="right">
      <h5>Rolle</h5>
      <div>
        <select name="role_add" id="role_add" formControlName="role">
          <option value=""></option>
          <option value="pm">Projektleitung</option>
          <option value="regular">Topteamer</option>
          <option value="superadmin">Admin</option>
        </select>
      </div>
    </div>
  </div>
  <div class="left-right">
    <div class="left" *ngIf="mitarbeiterToEditProjekte.length">
      <h4 class="aktuelle-projekte-titel">
        Aktuelle Projekte
        <span class="badge">{{ mitarbeiterToEditProjekte.length }}</span>
      </h4>
      <div
        *ngFor="let item of mitarbeiterToEditProjekte"
        class="link secondary margin-top-10 bold"
      >
        {{ item?.titel }}
      </div>
    </div>
  </div>

  <div class="buttons btns-right">
    <app-button
      *ngIf="currentUserToEdit"
      color="primary80"
      (click)="deleteUser()"
      background="primary10"
      [lessHeight]="true"
      >Profil löschen</app-button
    >
    <app-button
      color="secondary-gradient"
      (click)="updateUser()"
      background="primary10"
      [lessHeight]="true"
      [disabled]="userIsUpdating"
      [isLoading]="userIsUpdating"
      >Fertig</app-button
    >
  </div>
</app-modal>
<!-- neue arbeitskraft end -->

<app-modal
  *ngIf="userToEditFull"
  (close)="
    userToEditFull = undefined; this.refreshResults(this.pagination.page)
  "
  [maxWidth1200]="true"
  class="big-modal-fix"
>
  <app-bewerbercheck-modal
    *ngIf="userToEditFull"
    [incomingUser]="userToEditFull"
    [arbeitskreafteEdit]="true"
    (close)="
      userToEditFull = undefined; this.refreshResults(this.pagination.page)
    "
    (nextAfterAction)="
      userToEditFull = undefined; this.refreshResults(this.pagination.page)
    "
    [closeButton]="false"
  ></app-bewerbercheck-modal>
</app-modal>
