export const staaten = [
  {
    sa: "deutsch",
    id: "DE",
    label: "Deutschland",
    label_lodas: "Deutschland",
    code_lodas_4214: "000",
    code_lodas_4213: null,
  },
  {
    sa: "schweizerisch",
    id: "CH",
    label: "Schweiz",
    label_lodas: "Schweiz",
    code_lodas_4214: "158",
    code_lodas_4213: "CH",
  },
  {
    sa: "österreichisch",
    id: "AT",
    label: "Österreich",
    label_lodas: "Österreich",
    code_lodas_4214: "151",
    code_lodas_4213: "A",
  },
  {
    sa: "französisch",
    id: "FR",
    label: "Frankreich",
    label_lodas: "Frankreich, inkl. Korsika",
    code_lodas_4214: "129",
    code_lodas_4213: "F",
  },
  {
    sa: "afghanisch",
    id: "AF",
    label: "Afghanistan",
    label_lodas: "Afghanistan",
    code_lodas_4214: "423",
    code_lodas_4213: "AFG",
  },
  {
    sa: "ägyptisch",
    id: "EG",
    label: "Ägypten",
    label_lodas: "Ägypten",
    code_lodas_4214: "287",
    code_lodas_4213: "ET",
  },
  {
    sa: "albanisch",
    id: "AL",
    label: "Albanien",
    label_lodas: "Albanien",
    code_lodas_4214: "121",
    code_lodas_4213: "AL",
  },
  {
    sa: "algerisch",
    id: "DZ",
    label: "Algerien",
    label_lodas: "Algerien",
    code_lodas_4214: "221",
    code_lodas_4213: "DZ",
  },
  {
    sa: "andorranisch",
    id: "AD",
    label: "Andorra",
    label_lodas: "Andorra",
    code_lodas_4214: "123",
    code_lodas_4213: "AND",
  },
  {
    sa: "angolanisch",
    id: "AO",
    label: "Angola",
    label_lodas: "Angola",
    code_lodas_4214: "223",
    code_lodas_4213: "AGO",
  },
  {
    sa: "antiguanisch",
    id: "AG",
    label: "Antigua und Barbuda",
    label_lodas: "Antigua und Barbuda",
    code_lodas_4214: "320",
    code_lodas_4213: "ANT",
  },
  {
    sa: "äquatorialguineisch",
    id: "GQ",
    label: "Äquatorialguinea",
    label_lodas: "Äquatorialguinea",
    code_lodas_4214: "274",
    code_lodas_4213: "AQU",
  },
  {
    sa: "argentinisch",
    id: "AR",
    label: "Argentinien",
    label_lodas: "Argentinien",
    code_lodas_4214: "323",
    code_lodas_4213: "RA",
  },
  {
    sa: "armenisch",
    id: "AM",
    label: "Armenien",
    label_lodas: "Armenien",
    code_lodas_4214: "422",
    code_lodas_4213: "ARM",
  },
  {
    sa: "aserbaidschanisch",
    id: "AZ",
    label: "Aserbaidschan",
    label_lodas: "Aserbaidschan",
    code_lodas_4214: "425",
    code_lodas_4213: "ASE",
  },
  {
    sa: "äthiopisch",
    id: "ET",
    label: "Äthiopien",
    label_lodas: "Äthiopien",
    code_lodas_4214: "225",
    code_lodas_4213: "ETH",
  },
  {
    sa: "australisch",
    id: "AU",
    label: "Australien",
    label_lodas: "Australien",
    code_lodas_4214: "523",
    code_lodas_4213: "AUS",
  },
  {
    sa: "bahamaisch",
    id: "BS",
    label: "Bahamas",
    label_lodas: "Bahamas",
    code_lodas_4214: "324",
    code_lodas_4213: "BS",
  },
  {
    sa: "bahrainisch",
    id: "BH",
    label: "Bahrain",
    label_lodas: "Bahrain",
    code_lodas_4214: "424",
    code_lodas_4213: "BRN",
  },
  {
    sa: "bangladeschisch",
    id: "BD",
    label: "Bangladesch",
    label_lodas: "Bangladesch",
    code_lodas_4214: "460",
    code_lodas_4213: "BD",
  },
  {
    sa: "barbadisch",
    id: "BB",
    label: "Barbados",
    label_lodas: "Barbados",
    code_lodas_4214: "322",
    code_lodas_4213: "BDS",
  },
  {
    sa: "belgisch",
    id: "BE",
    label: "Belgien",
    label_lodas: "Belgien",
    code_lodas_4214: "124",
    code_lodas_4213: "B",
  },
  {
    sa: "belizisch",
    id: "BZ",
    label: "Belize",
    label_lodas: "Belize",
    code_lodas_4214: "330",
    code_lodas_4213: "BH",
  },
  {
    sa: "beninisch",
    id: "BJ",
    label: "Benin",
    label_lodas: "Benin",
    code_lodas_4214: "229",
    code_lodas_4213: "DY",
  },
  {
    sa: "bhutanisch",
    id: "BT",
    label: "Bhutan",
    label_lodas: "Bhutan",
    code_lodas_4214: "426",
    code_lodas_4213: "BHT",
  },
  {
    sa: "bolivianisch",
    id: "BO",
    label: "Bolivien",
    label_lodas: "Bolivien",
    code_lodas_4214: "326",
    code_lodas_4213: "BOL",
  },
  {
    sa: "bosnisch-herzegowinisch",
    id: "BA",
    label: "Bosnien und Herzegowina",
    label_lodas: "Bosnien und Herzegowina",
    code_lodas_4214: "122",
    code_lodas_4213: "BIH",
  },
  {
    sa: "botsuanisch",
    id: "BW",
    label: "Botsuana",
    label_lodas: "Botswana",
    code_lodas_4214: "227",
    code_lodas_4213: "RB",
  },
  {
    sa: "brasilianisch",
    id: "BR",
    label: "Brasilien",
    label_lodas: "Brasilien",
    code_lodas_4214: "327",
    code_lodas_4213: "BR",
  },
  {
    sa: "bruneiisch",
    id: "BN",
    label: "Brunei Darussalam",
    label_lodas: "Brunei Darussalam",
    code_lodas_4214: "429",
    code_lodas_4213: "BRU",
  },
  {
    sa: "bulgarisch",
    id: "BG",
    label: "Bulgarien",
    label_lodas: "Bulgarien",
    code_lodas_4214: "125",
    code_lodas_4213: "BG",
  },
  {
    sa: "burkinisch",
    id: "BF",
    label: "Burkina Faso",
    label_lodas: "Burkina Faso",
    code_lodas_4214: "258",
    code_lodas_4213: "HV",
  },
  {
    sa: "burundisch",
    id: "BI",
    label: "Burundi",
    label_lodas: "Burundi",
    code_lodas_4214: "291",
    code_lodas_4213: "RU",
  },
  {
    sa: "cabo-verdisch",
    id: "CV",
    label: "Cabo Verde",
    label_lodas: "Kap Verde",
    code_lodas_4214: "242",
    code_lodas_4213: "CV",
  },
  {
    sa: "chilenisch",
    id: "CL",
    label: "Chile",
    label_lodas: "Chile",
    code_lodas_4214: "332",
    code_lodas_4213: "RCH",
  },
  {
    sa: "chinesisch",
    id: "CN",
    label: "China",
    label_lodas: "China, Volksrepublik inkl.",
    code_lodas_4214: "479",
    code_lodas_4213: "TJ",
  },
  {
    sa: "costa-ricanisch",
    id: "CR",
    label: "Costa Rica",
    label_lodas: "Costa Rica",
    code_lodas_4214: "334",
    code_lodas_4213: "CR",
  },
  {
    sa: "ivorisch",
    id: "CI",
    label: "Côte d'Ivoire",
    label_lodas: "Côte d'Ivoire",
    code_lodas_4214: "231",
    code_lodas_4213: "CI",
  },
  {
    sa: "dänisch",
    id: "DK",
    label: "Dänemark",
    label_lodas: "Dänemark und Färöer",
    code_lodas_4214: "126",
    code_lodas_4213: "DK",
  },
  {
    sa: "dominicanisch",
    id: "DM",
    label: "Dominica",
    label_lodas: "Dominica",
    code_lodas_4214: "333",
    code_lodas_4213: "WD",
  },
  {
    sa: "dominikanisch",
    id: "DO",
    label: "Dominikanische Republik",
    label_lodas: "Dominikanische Republik",
    code_lodas_4214: "335",
    code_lodas_4213: "DOM",
  },
  {
    sa: "dschibutisch",
    id: "DJ",
    label: "Dschibuti",
    label_lodas: "Dschibuti",
    code_lodas_4214: "230",
    code_lodas_4213: "DSC",
  },
  {
    sa: "ecuadorianisch",
    id: "EC",
    label: "Ecuador",
    label_lodas: "Ecuador, einschl. Galapagos-Inseln",
    code_lodas_4214: "336",
    code_lodas_4213: "EC",
  },
  {
    sa: "salvadorianisch",
    id: "SV",
    label: "El Salvador",
    label_lodas: "El Salvador",
    code_lodas_4214: "337",
    code_lodas_4213: "ES",
  },
  {
    sa: "eritreisch",
    id: "ER",
    label: "Eritrea",
    label_lodas: "Eritrea",
    code_lodas_4214: "224",
    code_lodas_4213: "ERI",
  },
  {
    sa: "estnisch",
    id: "EE",
    label: "Estland",
    label_lodas: "Estland",
    code_lodas_4214: "127",
    code_lodas_4213: "EST",
  },
  {
    sa: "eswatinisch",
    id: "SZ",
    label: "Eswatini",
    label_lodas: "Swasiland",
    code_lodas_4214: "281",
    code_lodas_4213: "SWZ",
  },
  {
    sa: "fidschianisch",
    id: "FJ",
    label: "Fidschi",
    label_lodas: "Fidschi",
    code_lodas_4214: "526",
    code_lodas_4213: "FJI",
  },
  {
    sa: "finnisch",
    id: "FI",
    label: "Finnland",
    label_lodas: "Finnland",
    code_lodas_4214: "128",
    code_lodas_4213: "FIN",
  },
  {
    sa: "gabunisch",
    id: "GA",
    label: "Gabun",
    label_lodas: "Gabun",
    code_lodas_4214: "236",
    code_lodas_4213: "GAB",
  },
  {
    sa: "gambisch",
    id: "GM",
    label: "Gambia",
    label_lodas: "Gambia",
    code_lodas_4214: "237",
    code_lodas_4213: "WAG",
  },
  {
    sa: "georgisch",
    id: "GE",
    label: "Georgien",
    label_lodas: "Georgien",
    code_lodas_4214: "430",
    code_lodas_4213: "GEO",
  },
  {
    sa: "ghanaisch",
    id: "GH",
    label: "Ghana",
    label_lodas: "Ghana",
    code_lodas_4214: "238",
    code_lodas_4213: "GH",
  },
  {
    sa: "grenadisch",
    id: "GD",
    label: "Grenada",
    label_lodas: "Grenada",
    code_lodas_4214: "340",
    code_lodas_4213: "WG",
  },
  {
    sa: "griechisch",
    id: "GR",
    label: "Griechenland",
    label_lodas: "Griechenland",
    code_lodas_4214: "134",
    code_lodas_4213: "GR",
  },
  {
    sa: "guatemaltekisch",
    id: "GT",
    label: "Guatemala",
    label_lodas: "Guatemala",
    code_lodas_4214: "345",
    code_lodas_4213: "GCA",
  },
  {
    sa: "guineisch",
    id: "GN",
    label: "Guinea",
    label_lodas: "Guinea",
    code_lodas_4214: "261",
    code_lodas_4213: "RG",
  },
  {
    sa: "guinea-bissauisch",
    id: "GW",
    label: "Guinea-Bissau",
    label_lodas: "Guinea-Bissau",
    code_lodas_4214: "259",
    code_lodas_4213: "GUB",
  },
  {
    sa: "guyanisch",
    id: "GY",
    label: "Guyana",
    label_lodas: "Guyana",
    code_lodas_4214: "328",
    code_lodas_4213: "GUY",
  },
  {
    sa: "haitianisch",
    id: "HT",
    label: "Haiti",
    label_lodas: "Haiti",
    code_lodas_4214: "346",
    code_lodas_4213: "RH",
  },
  {
    sa: "honduranisch",
    id: "HN",
    label: "Honduras",
    label_lodas: "Honduras",
    code_lodas_4214: "347",
    code_lodas_4213: "HCA",
  },
  {
    sa: "chinesisch (Hongkong)",
    id: "HK",
    label: "Hongkong",
    label_lodas: "Hongkong",
    code_lodas_4214: "411",
    code_lodas_4213: "HKG",
  },
  {
    sa: "indisch",
    id: "IN",
    label: "Indien",
    label_lodas: "Indien, einschl. Sikkim und Goa",
    code_lodas_4214: "436",
    code_lodas_4213: "IND",
  },
  {
    sa: "indonesisch",
    id: "ID",
    label: "Indonesien",
    label_lodas: "Indonesien, einschl. Irian Jaya",
    code_lodas_4214: "437",
    code_lodas_4213: "RI",
  },
  {
    sa: "irakisch",
    id: "IQ",
    label: "Irak",
    label_lodas: "Irak",
    code_lodas_4214: "438",
    code_lodas_4213: "IRQ",
  },
  {
    sa: "iranisch",
    id: "IR",
    label: "Iran",
    label_lodas: "Iran, Islamische Republik",
    code_lodas_4214: "439",
    code_lodas_4213: "IR",
  },
  {
    sa: "irisch",
    id: "IE",
    label: "Irland",
    label_lodas: "Irland",
    code_lodas_4214: "135",
    code_lodas_4213: "IRL",
  },
  {
    sa: "isländisch",
    id: "IS",
    label: "Island",
    label_lodas: "Island",
    code_lodas_4214: "136",
    code_lodas_4213: "IS",
  },
  {
    sa: "israelisch",
    id: "IL",
    label: "Israel",
    label_lodas: "Israel",
    code_lodas_4214: "441",
    code_lodas_4213: "IL",
  },
  {
    sa: "italienisch",
    id: "IT",
    label: "Italien",
    label_lodas: "Italien",
    code_lodas_4214: "137",
    code_lodas_4213: "I",
  },
  {
    sa: "jamaikanisch",
    id: "JM",
    label: "Jamaika",
    label_lodas: "Jamaika",
    code_lodas_4214: "355",
    code_lodas_4213: "JA",
  },
  {
    sa: "japanisch",
    id: "JP",
    label: "Japan",
    label_lodas: "Japan",
    code_lodas_4214: "442",
    code_lodas_4213: "J",
  },
  {
    sa: "jemenitisch",
    id: "YE",
    label: "Jemen",
    label_lodas: "Jemen",
    code_lodas_4214: "421",
    code_lodas_4213: "YEM",
  },
  {
    sa: "jordanisch",
    id: "JO",
    label: "Jordanien",
    label_lodas: "Jordanien",
    code_lodas_4214: "445",
    code_lodas_4213: "JOR",
  },
  {
    sa: "jugoslawisch",
    id: "YU",
    label: "Jugoslawien",
    label_lodas: "Jugoslawien",
    code_lodas_4214: "138",
    code_lodas_4213: "YU",
  },
  {
    sa: "kambodschanisch",
    id: "KH",
    label: "Kambodscha",
    label_lodas: "Kambodscha",
    code_lodas_4214: "446",
    code_lodas_4213: "K",
  },
  {
    sa: "kamerunisch",
    id: "CM",
    label: "Kamerun",
    label_lodas: "Kamerun",
    code_lodas_4214: "262",
    code_lodas_4213: "CAM",
  },
  {
    sa: "kanadisch",
    id: "CA",
    label: "Kanada",
    label_lodas: "Kanada",
    code_lodas_4214: "348",
    code_lodas_4213: "CDN",
  },
  {
    sa: "kasachisch",
    id: "KZ",
    label: "Kasachstan",
    label_lodas: "Kasachstan",
    code_lodas_4214: "444",
    code_lodas_4213: "KAS",
  },
  {
    sa: "katarisch",
    id: "QA",
    label: "Katar",
    label_lodas: "Katar / Qatar",
    code_lodas_4214: "447",
    code_lodas_4213: "QAT",
  },
  {
    sa: "kenianisch",
    id: "KE",
    label: "Kenia",
    label_lodas: "Kenia",
    code_lodas_4214: "243",
    code_lodas_4213: "EAK",
  },
  {
    sa: "kirgisisch",
    id: "KG",
    label: "Kirgisistan",
    label_lodas: "Kirgisistan",
    code_lodas_4214: "450",
    code_lodas_4213: "KIS",
  },
  {
    sa: "kiribatisch",
    id: "KI",
    label: "Kiribati",
    label_lodas: "Kiribati",
    code_lodas_4214: "530",
    code_lodas_4213: "KIB",
  },
  {
    sa: "kolumbianisch",
    id: "CO",
    label: "Kolumbien",
    label_lodas: "Kolumbien",
    code_lodas_4214: "349",
    code_lodas_4213: "CO",
  },
  {
    sa: "komorisch",
    id: "KM",
    label: "Komoren",
    label_lodas: "Komoren",
    code_lodas_4214: "244",
    code_lodas_4213: "KOM",
  },
  {
    sa: "kongolesisch",
    id: "CG",
    label: "Kongo",
    label_lodas: "Kongo",
    code_lodas_4214: "245",
    code_lodas_4213: "RCB",
  },
  {
    sa: "der Demokratischen Republik Kongo",
    id: "CD",
    label: "Kongo, Demokratische Republik",
    label_lodas: "Kongo, Demokratische Republik",
    code_lodas_4214: "246",
    code_lodas_4213: "ZRE",
  },
  {
    sa: "der Demokratischen Volksrepublik Korea",
    id: "KP",
    label: "Korea, Demokratische Volksrepublik",
    label_lodas: "Korea, Demokratische Volksrepublik",
    code_lodas_4214: "434",
    code_lodas_4213: "KOR",
  },
  {
    sa: "der Republik Korea",
    id: "KR",
    label: "Korea, Republik",
    label_lodas: "Korea, Republik",
    code_lodas_4214: "467",
    code_lodas_4213: "ROK",
  },
  {
    sa: "kosovarisch",
    id: "XK",
    label: "Kosovo",
    label_lodas: "Kosovo",
    code_lodas_4214: "150",
    code_lodas_4213: "KOS",
  },
  {
    sa: "kroatisch",
    id: "HR",
    label: "Kroatien",
    label_lodas: "Kroatien",
    code_lodas_4214: "130",
    code_lodas_4213: "HR",
  },
  {
    sa: "kubanisch",
    id: "CU",
    label: "Kuba",
    label_lodas: "Kuba",
    code_lodas_4214: "351",
    code_lodas_4213: "C",
  },
  {
    sa: "kuwaitisch",
    id: "KW",
    label: "Kuwait",
    label_lodas: "Kuwait",
    code_lodas_4214: "448",
    code_lodas_4213: "KWT",
  },
  {
    sa: "laotisch",
    id: "LA",
    label: "Laos",
    label_lodas: "Laos, Demokratische Volksrepublik",
    code_lodas_4214: "449",
    code_lodas_4213: "LAO",
  },
  {
    sa: "lesothisch",
    id: "LS",
    label: "Lesotho",
    label_lodas: "Lesotho",
    code_lodas_4214: "226",
    code_lodas_4213: "LS",
  },
  {
    sa: "lettisch",
    id: "LV",
    label: "Lettland",
    label_lodas: "Lettland",
    code_lodas_4214: "139",
    code_lodas_4213: "LV",
  },
  {
    sa: "libanesisch",
    id: "LB",
    label: "Libanon",
    label_lodas: "Libanon",
    code_lodas_4214: "451",
    code_lodas_4213: "RL",
  },
  {
    sa: "liberianisch",
    id: "LR",
    label: "Liberia",
    label_lodas: "Liberia",
    code_lodas_4214: "247",
    code_lodas_4213: "LB",
  },
  {
    sa: "libysch",
    id: "LY",
    label: "Libyen",
    label_lodas: "Libyen",
    code_lodas_4214: "248",
    code_lodas_4213: "LAR",
  },
  {
    sa: "liechtensteinisch",
    id: "LI",
    label: "Liechtenstein",
    label_lodas: "Liechtenstein",
    code_lodas_4214: "141",
    code_lodas_4213: "FL",
  },
  {
    sa: "litauisch",
    id: "LT",
    label: "Litauen",
    label_lodas: "Litauen",
    code_lodas_4214: "142",
    code_lodas_4213: "LT",
  },
  {
    sa: "luxemburgisch",
    id: "LU",
    label: "Luxemburg",
    label_lodas: "Luxemburg",
    code_lodas_4214: "143",
    code_lodas_4213: "L",
  },
  {
    sa: "chinesisch (Macau)",
    id: "MO",
    label: "Macau",
    label_lodas: "Macau",
    code_lodas_4214: "412",
    code_lodas_4213: "MAC",
  },
  {
    sa: "madagassisch",
    id: "MG",
    label: "Madagaskar",
    label_lodas: "Madagaskar",
    code_lodas_4214: "249",
    code_lodas_4213: "RM",
  },
  {
    sa: "malawisch",
    id: "MW",
    label: "Malawi",
    label_lodas: "Malawi",
    code_lodas_4214: "256",
    code_lodas_4213: "MW",
  },
  {
    sa: "malaysisch",
    id: "MY",
    label: "Malaysia",
    label_lodas: "Malaysia",
    code_lodas_4214: "482",
    code_lodas_4213: "MAL",
  },
  {
    sa: "maledivisch",
    id: "MV",
    label: "Malediven",
    label_lodas: "Malediven",
    code_lodas_4214: "454",
    code_lodas_4213: "BIO",
  },
  {
    sa: "malisch",
    id: "ML",
    label: "Mali",
    label_lodas: "Mali",
    code_lodas_4214: "251",
    code_lodas_4213: "RMM",
  },
  {
    sa: "maltesisch",
    id: "MT",
    label: "Malta",
    label_lodas: "Malta",
    code_lodas_4214: "145",
    code_lodas_4213: "M",
  },
  {
    sa: "marokkanisch",
    id: "MA",
    label: "Marokko",
    label_lodas: "Marokko",
    code_lodas_4214: "252",
    code_lodas_4213: "MA",
  },
  {
    sa: "marshallisch",
    id: "MH",
    label: "Marshallinseln",
    label_lodas: "Marshallinseln",
    code_lodas_4214: "544",
    code_lodas_4213: "MAR",
  },
  {
    sa: "mauretanisch",
    id: "MR",
    label: "Mauretanien",
    label_lodas: "Mauretanien",
    code_lodas_4214: "239",
    code_lodas_4213: "RIM",
  },
  {
    sa: "mauritisch",
    id: "MU",
    label: "Mauritius",
    label_lodas: "Mauritius",
    code_lodas_4214: "253",
    code_lodas_4213: "MS",
  },
  {
    sa: "mexikanisch",
    id: "MX",
    label: "Mexiko",
    label_lodas: "Mexiko",
    code_lodas_4214: "353",
    code_lodas_4213: "MEX",
  },
  {
    sa: "mikronesisch",
    id: "FM",
    label: "Mikronesien",
    label_lodas: "Mikronesien",
    code_lodas_4214: "545",
    code_lodas_4213: "MIK",
  },
  {
    sa: "moldauisch",
    id: "MD",
    label: "Moldau",
    label_lodas: "Moldau",
    code_lodas_4214: "146",
    code_lodas_4213: "MD",
  },
  {
    sa: "monegassisch",
    id: "MC",
    label: "Monaco",
    label_lodas: "Monaco",
    code_lodas_4214: "147",
    code_lodas_4213: "MC",
  },
  {
    sa: "mongolisch",
    id: "MN",
    label: "Mongolei",
    label_lodas: "Mongolei",
    code_lodas_4214: "457",
    code_lodas_4213: "MON",
  },
  {
    sa: "montenegrinisch",
    id: "ME",
    label: "Montenegro",
    label_lodas: "Montenegro",
    code_lodas_4214: "140",
    code_lodas_4213: "MNE",
  },
  {
    sa: "mosambikanisch",
    id: "MZ",
    label: "Mosambik",
    label_lodas: "Mosambik",
    code_lodas_4214: "254",
    code_lodas_4213: "MOZ",
  },
  {
    sa: "myanmarisch",
    id: "MM",
    label: "Myanmar",
    label_lodas: "Myanmar",
    code_lodas_4214: "427",
    code_lodas_4213: "MYA",
  },
  {
    sa: "namibisch",
    id: "NA",
    label: "Namibia",
    label_lodas: "Namibia",
    code_lodas_4214: "267",
    code_lodas_4213: "SWA",
  },
  {
    sa: "nauruisch",
    id: "NR",
    label: "Nauru",
    label_lodas: "Nauru",
    code_lodas_4214: "531",
    code_lodas_4213: "NAU",
  },
  {
    sa: "nepalesisch",
    id: "NP",
    label: "Nepal",
    label_lodas: "Nepal",
    code_lodas_4214: "458",
    code_lodas_4213: "NEP",
  },
  {
    sa: "neuseeländisch",
    id: "NZ",
    label: "Neuseeland",
    label_lodas: "Neuseeland",
    code_lodas_4214: "536",
    code_lodas_4213: "NZ",
  },
  {
    sa: "nicaraguanisch",
    id: "NI",
    label: "Nicaragua",
    label_lodas: "Nicaragua",
    code_lodas_4214: "354",
    code_lodas_4213: "NIC",
  },
  {
    sa: "niederländisch",
    id: "NL",
    label: "Niederlande",
    label_lodas: "Niederlande",
    code_lodas_4214: "148",
    code_lodas_4213: "NL",
  },
  {
    sa: "nigrisch",
    id: "NE",
    label: "Niger",
    label_lodas: "Niger",
    code_lodas_4214: "255",
    code_lodas_4213: "RN",
  },
  {
    sa: "nigerianisch",
    id: "NG",
    label: "Nigeria",
    label_lodas: "Nigeria",
    code_lodas_4214: "232",
    code_lodas_4213: "WAN",
  },
  {
    sa: "mazedonisch",
    id: "MK",
    label: "Nordmazedonien",
    label_lodas: "Makedonien / Mazedonien",
    code_lodas_4214: "144",
    code_lodas_4213: "MK",
  },
  {
    sa: "norwegisch",
    id: "NO",
    label: "Norwegen",
    label_lodas: "Norwegen, einschl. Bäreninsel und Spitzbergen, auch Svalbard",
    code_lodas_4214: "149",
    code_lodas_4213: "N",
  },
  {
    sa: "omanisch",
    id: "OM",
    label: "Oman",
    label_lodas: "Oman",
    code_lodas_4214: "456",
    code_lodas_4213: "MAO",
  },
  {
    sa: "pakistanisch",
    id: "PK",
    label: "Pakistan",
    label_lodas: "Pakistan",
    code_lodas_4214: "461",
    code_lodas_4213: "PK",
  },
  {
    sa: "palauisch",
    id: "PW",
    label: "Palau",
    label_lodas: "Palau",
    code_lodas_4214: "537",
    code_lodas_4213: "PAL",
  },
  {
    sa: "panamaisch",
    id: "PA",
    label: "Panama",
    label_lodas: "Panama",
    code_lodas_4214: "357",
    code_lodas_4213: "PA",
  },
  {
    sa: "papua-neuguineisch",
    id: "PG",
    label: "Papua-Neuguinea",
    label_lodas: "Papua-Neuguinea",
    code_lodas_4214: "538",
    code_lodas_4213: "PNG",
  },
  {
    sa: "paraguayisch",
    id: "PY",
    label: "Paraguay",
    label_lodas: "Paraguay",
    code_lodas_4214: "359",
    code_lodas_4213: "PY",
  },
  {
    sa: "peruanisch",
    id: "PE",
    label: "Peru",
    label_lodas: "Peru",
    code_lodas_4214: "361",
    code_lodas_4213: "PE",
  },
  {
    sa: "philippinisch",
    id: "PH",
    label: "Philippinen",
    label_lodas: "Philippinen",
    code_lodas_4214: "462",
    code_lodas_4213: "RP",
  },
  {
    sa: "polnisch",
    id: "PL",
    label: "Polen",
    label_lodas: "Polen",
    code_lodas_4214: "152",
    code_lodas_4213: "PL",
  },
  {
    sa: "portugiesisch",
    id: "PT",
    label: "Portugal",
    label_lodas: "Portugal",
    code_lodas_4214: "153",
    code_lodas_4213: "P",
  },
  {
    sa: "ruandisch",
    id: "RW",
    label: "Ruanda",
    label_lodas: "Ruanda",
    code_lodas_4214: "265",
    code_lodas_4213: "RWA",
  },
  {
    sa: "rumänisch",
    id: "RO",
    label: "Rumänien",
    label_lodas: "Rumänien",
    code_lodas_4214: "154",
    code_lodas_4213: "RO",
  },
  {
    sa: "russisch",
    id: "RU",
    label: "Russische Föderation",
    label_lodas: "Russische Föderation",
    code_lodas_4214: "160",
    code_lodas_4213: "RUS",
  },
  {
    sa: "salomonisch",
    id: "SB",
    label: "Salomonen",
    label_lodas: "Salomonen",
    code_lodas_4214: "524",
    code_lodas_4213: "SOL",
  },
  {
    sa: "sambisch",
    id: "ZM",
    label: "Sambia",
    label_lodas: "Sambia",
    code_lodas_4214: "257",
    code_lodas_4213: "Z",
  },
  {
    sa: "samoanisch",
    id: "WS",
    label: "Samoa",
    label_lodas: "Samoa",
    code_lodas_4214: "543",
    code_lodas_4213: "WS",
  },
  {
    sa: "san-marinesisch",
    id: "SM",
    label: "San Marino",
    label_lodas: "San Marino",
    code_lodas_4214: "156",
    code_lodas_4213: "RSM",
  },
  {
    sa: "são-toméisch",
    id: "ST",
    label: "São Tomé und Príncipe",
    label_lodas: "Sao Tomé und Príncipe",
    code_lodas_4214: "268",
    code_lodas_4213: "STP",
  },
  {
    sa: "saudi-arabisch",
    id: "SA",
    label: "Saudi-Arabien",
    label_lodas: "Saudi-Arabien",
    code_lodas_4214: "472",
    code_lodas_4213: "SAU",
  },
  {
    sa: "schwedisch",
    id: "SE",
    label: "Schweden",
    label_lodas: "Schweden",
    code_lodas_4214: "157",
    code_lodas_4213: "S",
  },
  {
    sa: "senegalesisch",
    id: "SN",
    label: "Senegal",
    label_lodas: "Senegal",
    code_lodas_4214: "269",
    code_lodas_4213: "SN",
  },
  {
    sa: "serbisch",
    id: "RS",
    label: "Serbien",
    label_lodas: "Serbien",
    code_lodas_4214: "133",
    code_lodas_4213: "SRB",
  },
  {
    sa: "von Serbien und Montenegro",
    id: "CS",
    label: "Serbien und Montenegro",
    label_lodas: "Serbien und Montenegro",
    code_lodas_4214: "132",
    code_lodas_4213: "SCG",
  },
  {
    sa: "seychellisch",
    id: "SC",
    label: "Seychellen",
    label_lodas: "Seychellen",
    code_lodas_4214: "271",
    code_lodas_4213: "SY",
  },
  {
    sa: "sierra-leonisch",
    id: "SL",
    label: "Sierra Leone",
    label_lodas: "Sierra Leone",
    code_lodas_4214: "272",
    code_lodas_4213: "WAL",
  },
  {
    sa: "simbabwisch",
    id: "ZW",
    label: "Simbabwe",
    label_lodas: "Simbabwe",
    code_lodas_4214: "233",
    code_lodas_4213: "ZW",
  },
  {
    sa: "singapurisch",
    id: "SG",
    label: "Singapur",
    label_lodas: "Singapur",
    code_lodas_4214: "474",
    code_lodas_4213: "SGP",
  },
  {
    sa: "slowakisch",
    id: "SK",
    label: "Slowakei",
    label_lodas: "Slowakei",
    code_lodas_4214: "155",
    code_lodas_4213: "SK",
  },
  {
    sa: "slowenisch",
    id: "SI",
    label: "Slowenien",
    label_lodas: "Slowenien",
    code_lodas_4214: "131",
    code_lodas_4213: "SLO",
  },
  {
    sa: "somalisch",
    id: "SO",
    label: "Somalia",
    label_lodas: "Somalia",
    code_lodas_4214: "273",
    code_lodas_4213: "SP",
  },
  {
    sa: "sowjetisch",
    id: "SU",
    label: "Sowjetunion",
    label_lodas: "Russische Föderation",
    code_lodas_4214: "160",
    code_lodas_4213: "RUS",
  },
  {
    sa: "spanisch",
    id: "ES",
    label: "Spanien",
    label_lodas: "Spanien",
    code_lodas_4214: "161",
    code_lodas_4213: "E",
  },
  {
    sa: "sri-lankisch",
    id: "LK",
    label: "Sri Lanka",
    label_lodas: "Sri Lanka",
    code_lodas_4214: "431",
    code_lodas_4213: "CL",
  },
  {
    sa: "von St. Kitts und Nevis",
    id: "KN",
    label: "St. Kitts und Nevis",
    label_lodas: "St. Kitts und Nevis",
    code_lodas_4214: "370",
    code_lodas_4213: "SCN",
  },
  {
    sa: "lucianisch",
    id: "LC",
    label: "St. Lucia",
    label_lodas: "St. Lucia",
    code_lodas_4214: "366",
    code_lodas_4213: "WL",
  },
  {
    sa: "vincentisch",
    id: "VC",
    label: "St. Vincent und die Grenadinen",
    label_lodas: "St. Vincent und die Grenadinen",
    code_lodas_4214: "369",
    code_lodas_4213: "WV",
  },
  {
    sa: "staatenlos",
    id: "XA",
    label: "staatenlos",
    label_lodas: "Staatenlos",
    code_lodas_4214: "997",
    code_lodas_4213: null,
  },
  {
    sa: "südafrikanisch",
    id: "ZA",
    label: "Südafrika",
    label_lodas: "Südafrika",
    code_lodas_4214: "263",
    code_lodas_4213: "ZA",
  },
  {
    sa: "sudanesisch",
    id: "SD",
    label: "Sudan",
    label_lodas: "Sudan",
    code_lodas_4214: "276",
    code_lodas_4213: "SDN",
  },
  {
    sa: "südsudanesisch",
    id: "SS",
    label: "Südsudan",
    label_lodas: "Südsudan (Republik Südsudan)",
    code_lodas_4214: "278",
    code_lodas_4213: "SSD",
  },
  {
    sa: "surinamisch",
    id: "SR",
    label: "Suriname",
    label_lodas: "Suriname",
    code_lodas_4214: "364",
    code_lodas_4213: "SME",
  },
  {
    sa: "syrisch",
    id: "SY",
    label: "Syrien",
    label_lodas: "Syrien, Arabische Republik",
    code_lodas_4214: "475",
    code_lodas_4213: "SYR",
  },
  {
    sa: "tadschikisch",
    id: "TJ",
    label: "Tadschikistan",
    label_lodas: "Tadschikistan",
    code_lodas_4214: "470",
    code_lodas_4213: "TAD",
  },
  {
    sa: "taiwanisch",
    id: "TW",
    label: "Taiwan",
    label_lodas: "Taiwan",
    code_lodas_4214: "465",
    code_lodas_4213: "TWN",
  },
  {
    sa: "tansanisch",
    id: "TZ",
    label: "Tansania",
    label_lodas: "Tansania, Vereinigte Republik",
    code_lodas_4214: "282",
    code_lodas_4213: "EAT",
  },
  {
    sa: "thailändisch",
    id: "TH",
    label: "Thailand",
    label_lodas: "Thailand",
    code_lodas_4214: "476",
    code_lodas_4213: "T",
  },
  {
    sa: "von Timor-Leste",
    id: "TL",
    label: "Timor-Leste",
    label_lodas: "Timor-Leste",
    code_lodas_4214: "483",
    code_lodas_4213: null,
  },
  {
    sa: "togoisch",
    id: "TG",
    label: "Togo",
    label_lodas: "Togo",
    code_lodas_4214: "283",
    code_lodas_4213: "TG",
  },
  {
    sa: "tongaisch",
    id: "TO",
    label: "Tonga",
    label_lodas: "Tonga",
    code_lodas_4214: "541",
    code_lodas_4213: "TON",
  },
  {
    sa: "von Trinidad und Tobago",
    id: "TT",
    label: "Trinidad und Tobago",
    label_lodas: "Trinidad und Tobago",
    code_lodas_4214: "371",
    code_lodas_4213: "TT",
  },
  {
    sa: "tschadisch",
    id: "TD",
    label: "Tschad",
    label_lodas: "Tschad",
    code_lodas_4214: "284",
    code_lodas_4213: "CHD",
  },
  {
    sa: "tschechisch",
    id: "CZ",
    label: "Tschechien",
    label_lodas: "Tschechische Republik",
    code_lodas_4214: "164",
    code_lodas_4213: "CZ",
  },
  {
    sa: "tunesisch",
    id: "TN",
    label: "Tunesien",
    label_lodas: "Tunesien",
    code_lodas_4214: "285",
    code_lodas_4213: "TN",
  },
  {
    sa: "türkisch",
    id: "TR",
    label: "Türkei",
    label_lodas: "Türkei",
    code_lodas_4214: "163",
    code_lodas_4213: "TR",
  },
  {
    sa: "turkmenisch",
    id: "TM",
    label: "Turkmenistan",
    label_lodas: "Turkmenistan",
    code_lodas_4214: "471",
    code_lodas_4213: "TUR",
  },
  {
    sa: "tuvaluisch",
    id: "TV",
    label: "Tuvalu",
    label_lodas: "Tuvalu",
    code_lodas_4214: "540",
    code_lodas_4213: "TUV",
  },
  {
    sa: "ugandisch",
    id: "UG",
    label: "Uganda",
    label_lodas: "Uganda",
    code_lodas_4214: "286",
    code_lodas_4213: "EAU",
  },
  {
    sa: "ukrainisch",
    id: "UA",
    label: "Ukraine",
    label_lodas: "Ukraine",
    code_lodas_4214: "166",
    code_lodas_4213: "UA",
  },
  {
    sa: "ungarisch",
    id: "HU",
    label: "Ungarn",
    label_lodas: "Ungarn",
    code_lodas_4214: "165",
    code_lodas_4213: "H",
  },
  {
    sa: "uruguayisch",
    id: "UY",
    label: "Uruguay",
    label_lodas: "Uruguay",
    code_lodas_4214: "365",
    code_lodas_4213: "ROU",
  },
  {
    sa: "usbekisch",
    id: "UZ",
    label: "Usbekistan",
    label_lodas: "Usbekistan",
    code_lodas_4214: "477",
    code_lodas_4213: "USB",
  },
  {
    sa: "vanuatuisch",
    id: "VU",
    label: "Vanuatu",
    label_lodas: "Vanuatu",
    code_lodas_4214: "532",
    code_lodas_4213: "VAN",
  },
  {
    sa: "vatikanisch",
    id: "VA",
    label: "Vatikanstadt",
    label_lodas: "Vatikanstadt",
    code_lodas_4214: "167",
    code_lodas_4213: "V",
  },
  {
    sa: "venezolanisch",
    id: "VE",
    label: "Venezuela",
    label_lodas: "Venezuela",
    code_lodas_4214: "367",
    code_lodas_4213: "YV",
  },
  {
    sa: "der Vereinigten Arabischen Emirate",
    id: "AE",
    label: "Vereinigte Arabische Emirate",
    label_lodas: "Vereinigte Arabische Emirate",
    code_lodas_4214: "469",
    code_lodas_4213: "UAE",
  },
  {
    sa: "amerikanisch",
    id: "US",
    label: "Vereinigte Staaten",
    label_lodas: "Vereinigte Staaten von Amerika",
    code_lodas_4214: "368",
    code_lodas_4213: "USA",
  },
  {
    sa: "britisch",
    id: "GB",
    label: "Vereinigtes Königreich",
    label_lodas: "Vereinigte Arabische Emirate",
    code_lodas_4214: "469",
    code_lodas_4213: "UAE",
  },
  {
    sa: "vietnamesisch",
    id: "VN",
    label: "Vietnam",
    label_lodas: "Vietnam",
    code_lodas_4214: "432",
    code_lodas_4213: "VN",
  },
  {
    sa: "weißrussisch",
    id: "BY",
    label: "Weißrussland",
    label_lodas: "Weißrussland (Belarus)",
    code_lodas_4214: "169",
    code_lodas_4213: "BY",
  },
  {
    sa: "zentralafrikanisch",
    id: "CF",
    label: "Zentralafrikanische Republik",
    label_lodas: "Zentralafrikanische Republik",
    code_lodas_4214: "289",
    code_lodas_4213: "RCA",
  },
  {
    sa: "zyprisch",
    id: "CY",
    label: "Zypern",
    label_lodas: "Zypern",
    code_lodas_4214: "181",
    code_lodas_4213: "CY",
  },

  // für die vollständigkeit, abklären ob die gebraucht werden:
  // Abklären ob man die bisherigen namen nimmt oder die Lodas bezeichnungen.
  // {
  //   "label": "Britisch abhängige Gebiete in Afrika",
  //   "label_lodas": "Britisch abhängige Gebiete in Afrika",
  //   "lodas": "295"
  // },
  // {
  //   "label": "Britisch abhängige Gebiete in Amerika",
  //   "label_lodas": "Britisch abhängige Gebiete in Amerika",
  //   "lodas": "395"
  // },
  // {
  //   "label": "Britisch abhängige Gebiete in Australien/Ozeanien",
  //   "label_lodas": "Britisch abhängige Gebiete in Australien/Ozeanien",
  //   "lodas": "595"
  // }
  // { code_lodas_4214: "999", label: "Ohne Angabe" },
  // { code_lodas_4214: "997", label: "Staatenlos" },
  // { code_lodas_4214: "998", label: "Ungeklärt" },
  // { code_lodas_4214: "996", label: "Unbekanntes Ausland" },
  //   { code_lodas_4214: "299", label: "Übriges Afrika" },
  // { code_lodas_4214: "399", label: "Übriges Amerika" },
  // { code_lodas_4214: "499", label: "Übriges Asien" },
  // { code_lodas_4214: "199", label: "Übriges Europa" },
  // { code_lodas_4214: "599", label: "Übriges Ozeanien" },
];
