import { Notify } from "notiflix";
import { Router } from "@angular/router";
import { DataService } from "src/app/data.service";
import { Component, OnInit } from "@angular/core";
import { DokumentVorlage } from "@ttc_types/types";
import { environment } from "src/environments/environment";

@Component({
  selector: "page-vorlagen",
  templateUrl: "./vorlagen.component.html",
  styleUrls: ["./vorlagen.component.scss"],
  host: { class: "host-full-width" },
})
export class VorlagenComponent implements OnInit {
  constructor(public data: DataService, public router: Router) {}

  vorlagen: { [key: string]: DokumentVorlage } = {};

  downloadAllContractsModal: boolean = false;

  currentVorlageName: string = "";

  yearsWithContracts: number[] = [];

  selectedYear?: number;

  token: string = "";

  async ngOnInit() {
    await this.loadVorlagen();
  }

  async loadVorlagen(refresh: boolean = false) {
    const vorlagen_api = await this.data.getAllDocumentTemplates();
    if (vorlagen_api.length) {
      for (const vor of vorlagen_api) {
        this.vorlagen[vor.name] = vor;
      }
      if (!refresh) {
        this.currentVorlageName = vorlagen_api[0].name;
      }
    }

    console.log(`this.vorlagen`, this.vorlagen);
  }

  isLoading = false;

  async vorlagenSpeichern() {
    try {
      this.isLoading = true;
      console.log("this.vorlagen", this.vorlagen);
      await this.data.saveDocumentTemplates(Object.values(this.vorlagen));
      this.isLoading = false;
      await this.loadVorlagen(true);
      Notify.success("Vorlagen erfolgreich gespeichert");
    } catch (error) {
      Notify.failure("Fehler beim Speichern der Vorlagen");
      console.error(`vorlagenSpeichern error`, error);
    }
    this.isLoading = false;
  }

  async downloadAllContracts() {
    try {
      if (!this.selectedYear) {
        Notify.failure("Bitte wählen Sie ein Jahr aus");
        return;
      }
      console.log(`this.selectedYear`, this.selectedYear);
      this.isLoading = true;
      // await this.data.postRequest(
      //   "/application/employmentContract/downloadAll",
      //   { selectedYears: this.selectedYears }
      // );
      let url = `/application/employmentContract/generateAllMissingContractsForYearAndSaveToDisk?selectedYear=${this.selectedYear}&token=${this.token}`;
      await this.data.getRequest(url);

      url = `${environment.apiUrl}/application/employmentContract/createZipFromFilesForYearAndDownloadIt?selectedYear=${this.selectedYear}&token=${this.token}`;
      window.open(url, "_blank");
      this.isLoading = false;
      Notify.success("Verträge erfolgreich heruntergeladen");
      this.downloadAllContractsModal = false;
    } catch (error) {
      Notify.failure("Fehler beim Herunterladen der Verträge");
      console.error(`alleVertraegeHerunterladen error`, error);
    }

    this.isLoading = false;
    // return;
    // try {
    //   if (!this.selectedYears.length) {
    //     Notify.failure("Bitte wählen Sie mindestens ein Jahr aus");
    //     return;
    //   }
    //   console.log(`this.selectedYears`, this.selectedYears);
    //   this.isLoading = true;
    //   // await this.data.postRequest(
    //   //   "/application/employmentContract/downloadAll",
    //   //   { selectedYears: this.selectedYears }
    //   // );
    //   const url = `${environment.apiUrl}/application/employmentContract/downloadAll?selectedYears=${this.selectedYear}&token=${this.token}`;
    //   window.open(url, "_blank");
    //   this.isLoading = false;
    //   Notify.success("Verträge erfolgreich heruntergeladen");
    // } catch (error) {
    //   Notify.failure("Fehler beim Herunterladen der Verträge");
    //   console.error(`alleVertraegeHerunterladen error`, error);
    // }
    // this.isLoading = false;
  }

  async openDownloadAllContractsModal() {
    //open new tab with download url

    const { years, token } = await this.data.postRequest(
      "/application/employmentContract/getAllYearsWithContracts",
      {}
    );
    this.token = token;

    this.yearsWithContracts = years;
    if (this.yearsWithContracts.length) {
      this.downloadAllContractsModal = true;
    } else {
      Notify.failure("Es gibt keine Verträge zum Herunterladen");
    }
  }
}
