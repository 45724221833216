import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Notify } from "notiflix";
import { DataService } from "src/app/data.service";

@Component({
  selector: "app-send-mail-to-auswahl-modal",
  templateUrl: "./send-mail-to-auswahl-modal.component.html",
  styleUrls: ["./send-mail-to-auswahl-modal.component.scss"],
})
export class SendMailToAuswahlModalComponent implements OnInit {
  constructor(private data: DataService) {}

  @Output()
  public close: EventEmitter<boolean> = new EventEmitter();

  @Input()
  public selectedMails: string[] = [];

  subject: string = "";

  messageToSend: string = "";

  isLoading: boolean = false;

  ngOnInit(): void {}

  async sendMail() {
    if (this.isLoading) return;
    try {
      this.isLoading = true;
      await this.data.sendMail(
        this.selectedMails,
        this.messageToSend,
        this.subject
      );
      Notify.success("Die Mail wurde erfolgreich gesendet.");
    } catch (error) {
      Notify.failure("Etwas ist schief gelaufen.");
    }
    this.isLoading = false;
    this.close.emit(true);
  }
}
