import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "src/app/data.service";

@Component({
  selector: "app-zeiterfassung",
  templateUrl: "./zeiterfassung.component.html",
  styleUrls: ["./zeiterfassung.component.scss"],
})
export class ZeiterfassungComponent implements OnInit {
  project_id: number | null = null;

  eventName: string = "";

  constructor(private route: ActivatedRoute, public data: DataService) {}

  ngOnInit(): void {
    if (this.route && this.route.parent) {
      this.route.parent.params.subscribe((routeParams) => {
        console.log("routeParams: ", routeParams);
        this.project_id = routeParams.id ? Number(routeParams.id) : null;
        console.log("this.project_id: ", this.project_id);
        this.eventName =
          this.data.currentProject?.internal_title ??
          this.data.currentProject?.titel ??
          "";
      });
    }
  }
}
