import { Injectable, OnDestroy } from "@angular/core";
import { Confirm, IConfirmOptions } from "notiflix";

@Injectable({
  providedIn: "root",
})
export class UpdateVersionService implements OnDestroy {
  private versionCheckInterval: number = 1000 * 60 * 30; // 30 minutes

  // used to filter out other script tags like: https://maps.googleapis.com/maps-api-v3/api/js/57/10/main.js
  // only allows angulars hashed main.js files like: main.1234567890abcdef.js
  private regex = /main\.[a-f0-9]{16}\.js/;

  constructor() {}

  intervalID?: ReturnType<typeof setInterval>;

  startVersionCheck() {
    this.checkForUpdate();
    this.intervalID = setInterval(() => {
      this.checkForUpdate();
    }, this.versionCheckInterval);
  }

  ngOnDestroy(): void {
    if (this.intervalID) {
      clearInterval(this.intervalID);
    }
  }

  /**
   * Check if there is a new version of the main script file available
   */
  async checkForUpdate() {
    const scripts = Array.from(document.querySelectorAll("script[src]"));
    const currentResource = scripts
      .map((el) => el.getAttribute("src") || el.getAttribute("href"))
      .find((el) => el !== null && el.includes("main") && this.regex.test(el));

    const currentResourcesOnServer =
      await this.getTheCurrentIndexHtmlResponseTypeText();

    console.log({ currentResource, currentResourcesOnServer });

    if (currentResource !== currentResourcesOnServer) {
      this.askForUpdate();
    }
  }

  /**
   * Get the current index.html file from the server and return the main script file name
   * @returns {Promise<string>} - The main script file name
   */
  async getTheCurrentIndexHtmlResponseTypeText() {
    // Add a random query string to avoid caching
    const response = await fetch(`index.html?random=${new Date().getTime()}`);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseText = await response.text();

    // Parse the response text to get the main script file name
    const parser = new DOMParser();
    const doc = parser.parseFromString(responseText, "text/html");
    const scripts = Array.from(doc.querySelectorAll("script[src]"));
    return scripts
      .map((el) => el.getAttribute("src") || el.getAttribute("href"))
      .find((el) => el !== null && el.includes("main") && this.regex.test(el));
  }

  async askForUpdate() {
    Confirm.show(
      "Es ist ein Update verfügbar",
      `Möchtest du die Seite jetzt neu laden? Falls du noch Änderungen speichern musst, klicke auf "Später" und lade die Seite anschließend manuell neu.`,
      "Später",
      "Jetzt neu laden",
      () => {
        console.log(`no`);
      },
      () => {
        window.location.reload();
      },
      {
        messageMaxLength: 1000000,
        plainText: false,
      } as IConfirmOptions
    );
  }
}
