<div class="app-modal" *ngIf="getApplicantsToCheckIndex > -1" @fade>
  <div class="navigation">
    <div
      class="left-nav"
      (click)="navBack()"
      *ngIf="getApplicantsToCheckIndex > 0"
    ></div>
    <div
      *ngFor="let app of applicants; let i = index"
      class="swipe-wrap"
      [@animImageSlider]="getApplicantsToCheckIndex"
      [ngClass]="{ 'width-0': i != getApplicantsToCheckIndex }"
    >
      <div
        class="modal-inner navigation width-100"
        *ngIf="i == getApplicantsToCheckIndex"
      >
        <app-bewerbercheck-modal
          (close)="getApplicantsToCheckIndex = -1"
          [arbeitskreafteEdit]="false"
          [customerPreview]="true"
          [incomingUser]="app"
          [showCustomerPreviewButtons]="
            !!project?.auswahl_durch_kunden_zulassen
          "
          (absage)="
            app.applications![0].status = 'auftraggeber_abgesagt'; navNext(true)
          "
          (zusage)="
            app.applications![0].status = 'auftraggeber_akzeptiert';
            navNext(true)
          "
          [project_uuid]="uuid"
        >
        </app-bewerbercheck-modal>
      </div>
    </div>
    <div
      class="right-nav"
      (click)="navNext()"
      *ngIf="
        getApplicantsToCheckIndex < (applicants ? applicants.length : 0) - 1
      "
    ></div>
  </div>
</div>

<div class="main" *ngIf="notValidAnymore">
  <div class="flex-center">
    <h3 class="white">Leider ist dieser Link nicht mehr gültig.</h3>
  </div>
</div>
<div class="main" *ngIf="!notValidAnymore">
  <div class="flex-center">
    <img src="assets/svgs/logo_claim_right.svg" alt="" />
  </div>

  <div class="main-head">
    <div class="left">
      <h2 class="white">Personalauswahl für {{ project?.titel }}</h2>
      <p class="white" *ngIf="ansprechperson && ansprechperson.profile">
        <span class="p-light">Ansprechperson</span>
        {{ ansprechperson.profile.vorname }}
        {{ ansprechperson.profile.nachname }}
      </p>
      <p class="white" *ngIf="ansprechperson?.profile?.tel">
        <span class="p-light">Tel.</span> {{ ansprechperson?.profile?.tel }}
      </p>
    </div>
    <div class="right">
      <div class="buttons">
        <app-button
          [lessHeight]="true"
          color="white"
          iconRight="cloud-download"
          (click)="csvExportKundenansicht()"
          >Auswahl exportieren</app-button
        >
        <app-button
          color="secondary-gradient"
          [lessHeight]="true"
          (click)="entscheidungUebermitteln()"
          [isLoading]="isLoading"
          [disabled]="isLoading || this.preview == '1'"
          *ngIf="project?.auswahl_durch_kunden_zulassen"
          >Entscheidung übermitteln</app-button
        >
      </div>
      <div class="left-right" *ngIf="project?.auswahl_durch_kunden_zulassen">
        <div>
          <app-svgs color="fail" type="remove-user"></app-svgs>
          <h2 class="white">{{ abgesagtCount }}</h2>
        </div>
        <div>
          <app-svgs color="success" type="user"></app-svgs>
          <h2 class="white">{{ zugesagtCount }}</h2>
        </div>
      </div>
    </div>
  </div>

  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Geschlecht</th>
          <th>Alter</th>
          <th *ngIf="project?.auswahl_durch_kunden_zulassen">Entscheidung</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let a of applicants; let i = index"
          [ngClass]="{
            'has_job_role':i == 0 && a.applications?.[0]?.job_role || i > 0 && a.applications?.[0]?.job_role != applicants?.[i-1]?.applications?.[0]?.job_role
          }"
          [attr.data-job_role]="a.applications?.[0]?.job_role"
        >
          <td (click)="openEditForm(a)">
            <div class="spacer"></div>
            <div class="required-dot" *ngIf="a?.has_golden_dot"></div>
            {{ a.profile?.vorname }} {{ a.profile?.nachname }}
          </td>
          <td (click)="openEditForm(a)">
            <div class="spacer"></div>
            {{ a.profile?.geschlecht ? Geschlecht[a.profile!.geschlecht] : "" }}
          </td>
          <td (click)="openEditForm(a)">
            <div class="spacer"></div>
            {{ a.alter }}
          </td>
          <td *ngIf="project?.auswahl_durch_kunden_zulassen">
            <div class="spacer"></div>
            <app-zusage-absage-toggle [a]="a"></app-zusage-absage-toggle>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
