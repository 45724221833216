import { BehaviorSubject, debounceTime, Subscription } from "rxjs";
import { Notify } from "notiflix";
import { Component, Input, OnInit } from "@angular/core";
import { User, Zulagen, ZulagenNeu } from "@ttc_types/types";
import { DataService } from "../../../../data.service";

@Component({
  selector: "app-zulagen",
  templateUrl: "./zulagen.component.html",
  styleUrls: ["./zulagen.component.scss"],
})
export class ZulagenComponent implements OnInit {
  zulagen: Zulagen & ZulagenNeu = {
    //neu
    Briefing_eur: 0,
    Nachtzuschlag_eur: 0,
    Vorschuss_eur: 0,
    Spesen_eur: 0,

    //alt
    Bonus_eur: 0,
    Auslage_fuer_description: "",
    Auslage_fuer_eur: 0,
    Freunde_Bonus_eur: 0,
    Durchhaltebonus_eur: 0,
    Kilometergeld_eur: 0,
    Fahrkosten_eur: 0,

    Aufschlag_h_eur: 0,
    Tagessatz_eur: 0,

    // Alte fallen raus.
    Kilometergeld_eur_km: 0,
    Kilometergeld_km: 0,
    Mitnahme_anderer_im_Fahrzeug_eur: 0,
  };

  @Input()
  user!: User;

  constructor(private data: DataService) {}

  debounceSaveZulagenSubscription: Subscription | undefined;

  ngOnInit(): void {
    if (
      this.user &&
      this.user.applications &&
      this.user.applications[0] &&
      this.user.applications[0].zulagen
    ) {
      // const originalKeys = Object.keys(this.zulagen);
      // for (const key in this.user.applications[0].zulagen) {
      //   if (!originalKeys.includes(key)) {
      //     delete this.user.applications[0].zulagen[key as keyof ZulagenNeu];
      //   }
      // }

      this.zulagen = Object.assign(
        this.zulagen,
        this.user.applications[0].zulagen
      );
    }

    if (!this.debounceSaveZulagenSubscription) {
      this.debounceSaveZulagenSubscription = this.saveZulagenClickedSubject
        .pipe(debounceTime(1000))
        .subscribe((value) => {
          if (value) {
            this.saveZulagen();
          }
        });
    }
  }

  ngOnDestroy(): void {
    if (this.debounceSaveZulagenSubscription) {
      this.debounceSaveZulagenSubscription.unsubscribe();
    }
  }

  get getSumme() {
    return (
      this.zulagen.Briefing_eur +
      // this.zulagen.Nachtzuschlag_eur +
      this.zulagen.Vorschuss_eur +
      this.zulagen.Spesen_eur +
      this.zulagen.Bonus_eur +
      this.zulagen.Auslage_fuer_eur +
      this.zulagen.Freunde_Bonus_eur +
      this.zulagen.Durchhaltebonus_eur +
      this.zulagen.Kilometergeld_eur +
      this.zulagen.Fahrkosten_eur
    ).toFixed(2);
  }

  saveZulagenClickedSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  async saveZulagen() {
    if (
      this.user &&
      this.user.applications &&
      this.user.applications[0] &&
      this.user.applications[0].id
    ) {
      try {
        await this.data.postRequest("/application/saveZulagen", {
          application_id: this.user.applications[0].id,
          zulagen: this.zulagen,
        });
        this.user.applications[0].zulagen = this.zulagen;
        Notify.success("Zulagen wurden gespeichert.");
      } catch (error) {
        Notify.failure("Zulagen konnten nicht gespeichert werden.");
      }
    }
  }
}
