<div
  class="zusage-absage"
  *ngIf="a.applications![0].role != 'ansprechperson'"
  [ngClass]="{
    disabled: [
      'warten_auf_vertrag',
      'vertrag_gegenzeichnen',
      'erledigt_verbindlich'
    ].includes(a.applications![0].status!)
  }"
>
  <div
    class="absage"
    [ngClass]="{
      active: ['auftraggeber_abgesagt'].includes(a.applications![0].status!)
    }"
    (click)="a.applications![0].status = 'auftraggeber_abgesagt'"
  >
    Absage
  </div>
  <div
    class="zusage"
    [ngClass]="{
      active: [
        'auftraggeber_akzeptiert',
        'warten_auf_vertrag',
        'vertrag_gegenzeichnen',
        'erledigt_verbindlich'
      ].includes(a.applications![0].status!)
    }"
    (click)="a.applications![0].status = 'auftraggeber_akzeptiert'"
  >
    Zusage
  </div>
</div>
