// https://de.wikipedia.org/wiki/Liste_deutscher_Krankenkassen
//table 4
export const gesetzlicheKK = [
  "AOK Baden-Württemberg",
  "AOK Bayern",
  "AOK Bremen/Bremerhaven",
  "AOK Hessen",
  "AOK Niedersachsen",
  "AOK Nordost",
  "AOK Nordwest",
  "AOK Plus",
  "AOK Rheinland/Hamburg",
  "AOK Rheinland-Pfalz/Saarland",
  "AOK Sachsen-Anhalt",
  "Barmer Ersatzkasse",
  "DAK-Gesundheit",
  "Handelskrankenkasse",
  "HEK – Hanseatische Krankenkasse",
  "HKK",
  "Kaufmännische Krankenkasse – KKH",
  "Techniker Krankenkasse",
  "Bundesinnungskrankenkasse Gesundheit",
  "IKK classic",
  "IKK gesund plus",
  "IKK Südwest",
  "IKK – Die Innovationskasse",
  "Innungskrankenkasse Brandenburg und Berlin",
  "Audi BKK",
  "Bahn-BKK",
  "Bertelsmann BKK",
  "Betriebskrankenkasse BPW Bergische Achsen KG – betriebsbezogen",
  "Betriebskrankenkasse der BMW AG – betriebsbezogen",
  "Betriebskrankenkasse der G. M. Pfaff AG Kaiserslautern",
  "Betriebskrankenkasse EVM – betriebsbezogen",
  "Betriebskrankenkasse Firmus",
  "Betriebskrankenkasse Groz-Beckert – betriebsbezogen",
  "Betriebskrankenkasse Mahle – betriebsbezogen",
  "Betriebskrankenkasse Miele – betriebsbezogen",
  "Betriebskrankenkasse Mobil",
  "Betriebskrankenkasse Schwarzwald-Baar-Heuberg",
  "Betriebskrankenkasse Vereinigte Deutsche Nickel-Werke",
  "Betriebskrankenkasse WMF Württembergische Metallwarenfabrik AG",
  "BKK24",
  "BKK B. Braun Aesculap – betriebsbezogen",
  "BKK Akzo Nobel Bayern",
  "BKK Deutsche Bank AG – betriebsbezogen",
  "BKK Diakonie",
  "BKK Dürkopp Adler",
  "BKK Euregio",
  "BKK EWE – betriebsbezogen",
  "BKK exklusiv",
  "BKK Faber-Castell & Partner",
  "BKK Freudenberg",
  "BKK Gildemeister Seidensticker",
  "BKK Herkules",
  "BKK Linde",
  "BKK Melitta HMR",
  "BKK MTU – betriebsbezogen",
  "BKK Pfalz",
  "BKK Provita",
  "BKK Public",
  "BKK PwC – betriebsbezogen",
  "BKK Rieker Ricosta Weisser – betriebsbezogen",
  "BKK Salzgitter – betriebsbezogen",
  "BKK Scheufelen",
  "BKK Stadt Augsburg – betriebsbezogen",
  "BKK Technoform",
  "BKK VBU",
  "BKK Verbundplus",
  "BKK Voralb Heller Index Leuze – betriebsbezogen",
  "BKK Werra-Meissner",
  "BKK Wirtschaft & Finanzen",
  "BKK Würth – betriebsbezogen",
  "BKK ZF & Partner",
  "Bosch BKK",
  "Continentale Betriebskrankenkasse",
  "Daimler Betriebskrankenkasse – betriebsbezogen",
  "Debeka BKK",
  "Die Bergische Krankenkasse",
  "Energie-Betriebskrankenkasse",
  "Ernst & Young BKK – betriebsbezogen",
  "Gemeinsame Betriebskrankenkasse der Gesellschaften der Textilgruppe Hof",
  "Heimat Krankenkasse",
  "Karl Mayer Betriebskrankenkasse – betriebsbezogen",
  "Koenig & Bauer BKK – betriebsbezogen",
  "Krones Betriebskrankenkasse – betriebsbezogen",
  "Merck BKK – betriebsbezogen",
  "Mhplus Betriebskrankenkasse",
  "Novitas BKK",
  "Pronova BKK",
  "R+V Betriebskrankenkasse",
  "Salus BKK",
  "Securvita BKK",
  "Siemens-Betriebskrankenkasse",
  "SKD BKK",
  "Südzucker BKK – betriebsbezogen",
  "TUI BKK",
  "Viactiv BKK",
  "Vivida BKK",
  "Knappschaft",
  "Landwirtschaftliche Krankenkasse",
];
