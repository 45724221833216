import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-editor",
  templateUrl: "./editor.component.html",
  styleUrls: ["./editor.component.scss"],
})
export class EditorComponent implements AfterViewInit {
  @Input()
  html: string = "";

  @Output()
  htmlChange = new EventEmitter<string>();

  @ViewChild("hostElement") editor: ElementRef<HTMLDivElement> | undefined;

  public config = {
    placeholder: "",
    tabsize: 2,
    height: 500,
    uploadImagePath: "/api/upload",
    dialogsInBody: true,
    toolbar: [
      ["misc", ["codeview"]],
      [
        "font",
        [
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "superscript",
          "subscript",
          "clear",
        ],
      ],
      ["fontsize", "color"],
      ["para", ["style", "ul", "ol", "paragraph", "height"]],
      ["insert", ["table", "link", "hr"]],
    ],
  };

  @Input()
  update() {
    this.htmlChange.emit(this.html);
  }

  ngAfterViewInit() {
    if (!this.editor) {
      return;
    }

    this.editor.nativeElement
      .querySelectorAll(".note-editable")
      .forEach((el) => {
        el.addEventListener("paste", (e: any) => {
          e.preventDefault();
          const text = (e.originalEvent || e).clipboardData.getData(
            "text/plain"
          );
          document.execCommand("insertText", false, text);
        });
      });
  }
}
