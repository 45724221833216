<div class="kontakt-liste">
  <div
    class="contact-card"
    *ngFor="let kontakt of kontaktliste"
    [ngClass]="{
      secondaryBG: kontakt.secondaryBG,
      primaryBG: kontakt.primaryBG
    }"
  >
    <div>
      <h4 *ngIf="kontakt.title">{{ kontakt.title }}</h4>
      <h5 *ngIf="kontakt.subtitle">{{ kontakt.subtitle }}</h5>
    </div>
    <div *ngIf="kontakt.tel" class="flex gap-12 flex-center-vertical">
      <app-svgs
        type="telephone"
        [color]="kontakt.secondaryBG || kontakt.primaryBG ? 'white' : 'primary'"
      ></app-svgs>
      <a href="tel:{{ kontakt.tel }}">{{ kontakt.tel }}</a>
    </div>
    <div *ngIf="kontakt.email" class="flex gap-12 flex-center-vertical">
      <app-svgs
        type="email"
        [color]="kontakt.secondaryBG || kontakt.primaryBG ? 'white' : 'primary'"
      ></app-svgs>
      <a href="mailto:{{ kontakt.email }}">{{ kontakt.email }}</a>
    </div>
  </div>
</div>
