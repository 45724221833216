import { NgModule } from "@angular/core";
import { RouterModule, Routes, ExtraOptions } from "@angular/router";
import { ImportComponent } from "./pages/import/import.component";
import { AblaufComponent } from "./pages/meine-projekte/edit/ablauf/ablauf.component";
import { ConfirmEmailComponent } from "./pages/confirm-email/confirm-email.component";
import { VorlagenComponent } from "./pages/vorlagen/vorlagen.component";
import { AuswahlComponent } from "./pages/auswahl/auswahl.component";
import { PersonalComponent } from "./pages/meine-projekte/edit/personal/personal.component";
import { JobDetailComponent } from "./pages/job-detail/job-detail.component";
import { ApplyComponent } from "./pages/apply/apply.component";
import { PasswordResetComponent } from "./pages/authentication/password-reset/password-reset.component";
import { InfosComponent } from "./pages/meine-projekte/edit/infos/infos.component";
import { ArbeitskraefteComponent } from "./pages/arbeitskraefte/arbeitskraefte.component";
import { MeineProjekteComponent } from "./pages/meine-projekte/meine-projekte.component";
import { LoginComponent } from "./pages/authentication/login/login.component";
import { DatenComponent } from "./pages/daten/daten.component";
import { MeineJobsComponent } from "./pages/meine-jobs/meine-jobs.component";
import { JobsucheComponent } from "./pages/jobsuche/jobsuche.component";

import { AuthGuard } from "./shared/auth.guard";
import { EditComponent } from "./pages/meine-projekte/edit/edit.component";
import { ZeiterfassungComponent } from "./pages/meine-projekte/edit/zeiterfassung/zeiterfassung/zeiterfassung.component";
import { ExporteComponent } from "./pages/exporte/exporte.component";
import { DeactivationGuard } from "./deactivation.guard";

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: "always",
};

const routes: Routes = [
  // regular
  {
    path: "jobs/:id?",
    component: JobsucheComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "jobs/apply/:id",
    component: ApplyComponent,
    canActivate: [AuthGuard],
    canDeactivate: [DeactivationGuard],
  },
  {
    path: "register",
    component: ApplyComponent,
    // canActivate: [AuthGuard],
    canDeactivate: [DeactivationGuard],
  },
  {
    path: "jobs/vervollstaendigen/:id",
    component: ApplyComponent,
    canActivate: [AuthGuard],
    canDeactivate: [DeactivationGuard],
  },

  {
    path: "meine-jobs",
    component: MeineJobsComponent,
    canActivate: [AuthGuard],
    // children are only for separate router outlet !!!
    children: [
      { path: "bewerbungen", component: MeineJobsComponent },
      { path: "bevorstehende-jobs", component: MeineJobsComponent },
      { path: "vergangene-jobs", component: MeineJobsComponent },
      // {path: 'detail/:id', component:DetailComponent}
    ],
  },
  {
    path: "meine-jobs/detail/:id",
    component: JobDetailComponent,

    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "infos",
      },
      { path: "infos", component: JobDetailComponent },
      { path: "unterlagen", component: JobDetailComponent },
      { path: "zeiterfassung", component: JobDetailComponent },
      // {path: 'detail/:id', component:DetailComponent}
    ],
  },
  { path: "daten", component: DatenComponent, canActivate: [AuthGuard] },
  {
    path: "daten/persoenlich",
    component: ApplyComponent,
    canActivate: [AuthGuard],
    canDeactivate: [DeactivationGuard],
  },
  {
    path: "daten/sed",
    component: ApplyComponent,
    canActivate: [AuthGuard],
    canDeactivate: [DeactivationGuard],
  },
  // pm
  {
    path: "meine-projekte",
    component: MeineProjekteComponent,
    canActivate: [AuthGuard],
    // children: [
    // { path: 'add', component: EditComponent},
    // { path: 'project/:id', component: EditComponent}
    // ],
  },
  {
    path: "meine-projekte/add",
    component: EditComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        redirectTo: "infos",
        pathMatch: "full",
      },
      {
        path: "infos",
        component: InfosComponent,
      },
    ],
  },
  {
    path: "meine-projekte/edit/:id",
    component: EditComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        redirectTo: "infos",
        pathMatch: "full",
      },
      {
        path: "infos",
        component: InfosComponent,
      },
      {
        path: "ablauf",
        component: AblaufComponent,
      },
      {
        path: "personal",
        component: PersonalComponent,
      },
      {
        path: "zeiterfassung",
        component: ZeiterfassungComponent,
      },
    ],
  },

  {
    path: "arbeitskraefte",
    component: ArbeitskraefteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "projektleitung",
    component: ArbeitskraefteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "auswahl/:uuid",
    component: AuswahlComponent,
  },

  // auth
  { path: "login", component: LoginComponent },
  { path: "vorlagen", component: VorlagenComponent },
  { path: "exporte", component: ExporteComponent },
  { path: "import", component: ImportComponent },
  { path: "password-reset", component: PasswordResetComponent },
  { path: "confirm-email", component: ConfirmEmailComponent },
  // { path: 'register', component: RegisterComponent },

  { path: "jobs", redirectTo: "/jobs/", pathMatch: "full" },
  { path: "", redirectTo: "/jobs/", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
