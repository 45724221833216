import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Confirm, IConfirmOptions, Notify, Report } from "notiflix";
import {
  Application,
  CheckInQrCodeResult,
  Document,
  Projekt,
  SelectableDay,
  TeamTimeSlotDay,
  TimetrackerSlot,
} from "@ttc_types/types";
import { DataService } from "src/app/data.service";
import { AuthService } from "src/app/shared/auth.service";
import { animate, style, transition, trigger } from "@angular/animations";
import { dayjs } from "src/app/shared/date-util";
import { SignaturePad } from "../signature-pad/angular2-signaturepad.component";

@Component({
  selector: "app-timetracker",
  templateUrl: "./timetracker.component.html",
  styleUrls: ["./timetracker.component.scss"],
  animations: [
    trigger("fade", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class TimetrackerComponent implements OnInit {
  @Input() inReview: boolean = false;

  @Input() extraClasses: string = "";

  applicationId: number | undefined;

  @Input() application: Application | undefined;

  @Input() projectId!: number | undefined;

  @Input() managerView: boolean = false;

  @Input() eventName: string = "";

  @HostBinding("class.in-review") isInReview: boolean = false;

  @ViewChild(SignaturePad) signaturePad!: SignaturePad;

  project?: Projekt;

  addTimeModalVisible: boolean = false;

  addTimeModalEditTime: boolean = false;

  addTimeModalEditBySupervisor: boolean = false;

  overMidnightHintModalVisible: boolean = false;

  customerSignModalVisible: boolean = false;

  customerSignModalEmail: string = "";

  myTimeSlots: TimetrackerSlot[] = [];

  myTimeOverall: number = 0;

  teamTimeSlots: TimetrackerSlot[] = [];

  currentVisibleTeamTimeSlots: TimetrackerSlot[] = [];

  teamTimeSlotDays: TeamTimeSlotDay[] = [];

  currentActiveTeamTimeSlotDayIndex: number = 0;

  teamTimeOverall: number = 0;

  formattedTeamTimeOverall: string = "";

  projectTimeOverall: number = 0;

  formattedProjectTimeOverall: string = "";

  possibleDaysFromTimestamp: number = dayjs()
    .tz("Europe/Berlin")
    .startOf("day")
    .valueOf();

  possibleDaysToTimestamp: number = dayjs()
    .tz("Europe/Berlin")
    .startOf("day")
    .valueOf();

  possibleDaysAmount: number = 0;

  selectableDays: SelectableDay[] = [];

  isExportAllVisible: boolean = false;

  // real time values:
  addTimeValues: TimetrackerSlot = {
    time_from: 0,
    time_to: 0,
    time_break: 0,
  };

  timeFromInputValue?: string;

  timeToInputValue?: string;

  overMidnightString: string = "";

  breakHoursInputValue: number = 0;

  breakMinutesInputValue: number = 0;

  formattedTimeDuration: string = "";

  minBreakHoursValue: number = 0;

  minBreakMinutesValue: number = 0;

  // fake time values
  fakeTimeFromInputValue?: string;

  fakeTimeToInputValue?: string;

  fakeOverMidnightString: string = "";

  fakeBreakHoursInputValue: number = 0;

  fakeBreakMinutesInputValue: number = 0;

  fakeFormattedTimeDuration: string = "";

  extraTimesList: TimetrackerSlot[] = [];

  // documents and signature
  signatureBase64: string = "";

  signaturePadOpts: Object | null = null;

  documents: Document[] = [];

  isUploading: boolean = false;

  endOfFestgeschriebenMonthTimestamp: number = 0;

  liveCheckinQrCodeString: string | undefined;

  showQrScannerModal: boolean = false;

  showAddTimeForTopteamerOverlay: boolean = false;

  selectedApplicantUserIdFromQrCode: number | undefined;

  selectedApplicantForCheckin: Application | undefined;

  hasPendingCheckIn: boolean = false;

  checkInCheckDate: Date = new Date();

  showCheckInModal: boolean = false;

  showCheckOutModal: boolean = false;

  isForCheckIn: boolean = true;

  saveTimeSlotInProgress: boolean = false;

  finalizedApplicationUsers: Application[] | undefined;

  isTodayASelectableDay: boolean = false;

  currentUserHasAdminPrivileges: boolean = false;

  constructor(public data: DataService, public auth: AuthService) {}

  /**
   * ngOnInit will initialize the component by initializing
   * the signature Pad and all data necesseray to display
   * the component in its set environment.
   */
  ngOnInit(): void {
    if (this.application?.id!) {
      this.applicationId = this.application.id;
    }

    this.isInReview = this.inReview;

    this.initSignaturePad();
    this.initializeData();
  }

  /**
   * initializeData will load all necessary data for the component
   * to work properly in its used environment.
   *
   * It can be called multiple times to refresh the seen data.
   */
  async initializeData() {
    if (this.application?.id!) {
      this.applicationId = this.application.id;
    }
    await this.loadProject();
    this.currentUserHasAdminPrivileges = await this.data.hasAdminPriviliges(
      this.applicationId,
      this.projectId
    );
    await this.loadTimeSlots();
    await this.loadDocuments();
    this.updateIfTodayIsSelectableDay();
    // if teamTimeSlots are not set, then the user is either missing permission or there are none
    if (this.teamTimeSlots.length > 0) {
      // generate teamTimeSlotDays from timeSlots
      this.generateTeamTimeSlotDays();
      this.refreshCurrentVisibleTeamTimeSlots(0);
      this.loadSelectableDays();
      this.selectableDayClicked(0);
    }
    this.refreshOverallTimes();
    this.setQrCodeString();
  }

  /**
   * setQrCodeString fills the qrCodeString with current projectId and the current user's id, the structure will be a json format.
   * The String format will look like:
   * ttConnectQrCheckinQrCode:{projectId:<projectId>,userId:<userId>}
   */
  setQrCodeString() {
    this.liveCheckinQrCodeString = `{"ttConnectQrCheckinQrCode":{"projectId":"${this.projectId}","userId":"${this.auth.currentUser?.id}"}}`;
  }

  /**
   * newQrCodeScanned gets triggered when the qrScanner detects a new qrCode, checks if it is for
   * checkIn or checkOut and opens the appropriate manual checkIn or checkOut modal, with a preselected user.
   * @param qrCodeData CheckInQrCodeResult
   */
  async newQrCodeScanned(qrCodeData: CheckInQrCodeResult) {
    this.selectedApplicantUserIdFromQrCode = Number(
      qrCodeData.ttConnectQrCheckinQrCode.userId
    );
    if (this.isForCheckIn) {
      this.openCheckInModal();
    } else {
      this.openCheckOutModal();
    }
    this.showQrScannerModal = false;
  }

  /**
   * loadProject loads the project data from the backend by calling
   * data.getProjectById with the given projectId and the boolean managerView.
   * It saves the project data in the component variable project and detemines
   * the possible days from and to timestamps and the possible days amount.
   */
  async loadProject() {
    this.project = await this.data.getProjectById(
      this.projectId!,
      this.managerView
    );
    if (this.project) {
      this.possibleDaysFromTimestamp = dayjs(this.project.zeitraum_von)
        .tz("Europe/Berlin")
        .startOf("day")
        .valueOf();

      this.possibleDaysToTimestamp = dayjs(this.project.zeitraum_bis)
        .tz("Europe/Berlin")
        .endOf("day")
        .valueOf();

      const diffTime =
        this.possibleDaysToTimestamp - this.possibleDaysFromTimestamp;
      this.possibleDaysAmount = Math.floor(diffTime / 1000 / 60 / 60 / 24 + 1);
    }
  }

  /**
   * initSignaturePad sets the options for the signature pad plugin to initialize it.
   */
  initSignaturePad() {
    // init signaturepad
    const minWidth = 290;
    const maxWidth = 325;
    const heightRatio = 0.4138;
    const incomingWidth = Math.min(window.screen.width - 30, maxWidth);
    const width = Math.max(minWidth, incomingWidth);
    const height = Math.round(width * heightRatio);
    this.signaturePadOpts = {
      minWidth: 1,
      canvasWidth: width,
      canvasHeight: height,
      backgroundColor: "#fff",
      penColor: "#4287f5",
    };
  }

  /**
   * signatureDrawFinish is a helper function of the signature pad plugin to
   * save the base64 string of the signature.
   * It will be saved in the component variable signatureBase64.
   */
  signatureDrawFinish() {
    this.signatureBase64 = this.signaturePad.toDataURL();
  }

  /**
   * signatureClear is a helper function of the signature pad plugin to
   * clear the signature pad and reset the base64 string of the signature.
   */
  signatureClear() {
    this.signaturePad.clear();
    this.signatureBase64 = "";
  }

  /**
   * Checks if either current user does not have the role "regular"
   * or if the application role is "teamleiter_vor_ort" or "additional_teamleads"
   * @returns boolean if current user is teamlead
   */
  isCurrentUserTeamlead() {
    return (
      this.auth.currentUser?.role !== "regular" ||
      this.application?.role === "teamleiter_vor_ort" ||
      this.application?.role === "additional_teamleads"
    );
  }

  /**
   * openCheckInModal opens the popover for checkin or checkout depending on the given parameter.
   */
  async openCheckInModal() {
    this.isForCheckIn = true;

    this.loadSelectableDays(
      dayjs().tz("Europe/Berlin").startOf("day").valueOf()
    );
    // load all applications of project to fill the selectable data
    if (!this.finalizedApplicationUsers) {
      await this.loadAllFinalizedApplications();
    }
    // select the user from qrCodeData
    this.updateSelectApplicantForCheckin();
    this.updateCheckInCheckDate();

    this.hasPendingCheckIn = this.doesCheckinExistForSelectedApplicant();
    if (this.hasPendingCheckIn) {
      this.initCheckoutModalValues(this.checkInCheckDate);
    }

    // set checkDate to the next quarter of an hour or the last quarter of an hour depending which one is closer
    // reset timeToInputValue
    this.timeToInputValue = undefined;
    const newTimeSlot: TimetrackerSlot = {
      time_from: this.checkInCheckDate.getTime(),
      time_break: 0,
    };
    this.addTimeValues = newTimeSlot;
    this.timeFromInputValue = this.formatTimeToInputString(
      this.checkInCheckDate
    );
    this.showCheckInModal = true;
  }

  /**
   * openCheckOutModal opens the popover for the checkout and preselects the current user for checkout
   * if the user is not already selected by qrCodeData.
   * @param timeSlot
   */
  async openCheckOutModal(timeSlot?: TimetrackerSlot) {
    this.isForCheckIn = true;

    // load all applications of project to fill the selectable data
    if (!this.finalizedApplicationUsers) {
      await this.loadAllFinalizedApplications();
    }

    // select the user from qrCodeData
    this.updateSelectApplicantForCheckin(timeSlot);

    this.hasPendingCheckIn = this.doesCheckinExistForSelectedApplicant();

    // set checkDate to the next quarter of an hour or the last quarter of an hour depending which one is closer
    this.updateCheckInCheckDate();
    this.initCheckoutModalValues(this.checkInCheckDate);
    this.showCheckOutModal = true;
  }

  /**
   * selfCheckIn is a wrapper function to preselect the current user for checkin and then open the checkin modal.
   */
  async selfCheckIn() {
    this.selectedApplicantUserIdFromQrCode = this.auth.currentUser?.id;
    await this.openCheckInModal();
  }

  /**
   * selfCheckOut is a wrapper function to preselect the current user for checkout and then open the checkout modal.
   */
  async selfCheckOut() {
    this.selectedApplicantUserIdFromQrCode = this.auth.currentUser?.id;
    await this.openCheckOutModal();
  }

  /**
   * updateSelectApplicantForCheckin will update the selectedApplicantForCheckin variable
   * depending on the selectedApplicantUserIdFromQrCode or the given timeSlot.
   * @param timeSlot
   */
  updateSelectApplicantForCheckin(timeSlot?: TimetrackerSlot) {
    if (!this.finalizedApplicationUsers) {
      return;
    }

    if (this.selectedApplicantUserIdFromQrCode) {
      this.selectedApplicantForCheckin = this.finalizedApplicationUsers.find(
        ({ user_id }) => user_id === this.selectedApplicantUserIdFromQrCode
      );
    } else if (timeSlot?.application_id) {
      this.selectedApplicantForCheckin = this.finalizedApplicationUsers.find(
        ({ id }) => id === timeSlot.application_id
      );
    }
  }

  /**
   * initCheckoutModalValues will initialize the values of the checkout modal.
   */
  initCheckoutModalValues(checkOutDateTime: Date) {
    let tmpCheckoutDateTime = checkOutDateTime;
    // check if applicant is selected
    if (!this.selectedApplicantForCheckin) {
      return;
    }

    // find latest timeslot of applicant
    const tTSlot = this.teamTimeSlots.find(
      (slot) =>
        slot.live_checkin &&
        !slot.time_to &&
        slot.application_id === this.selectedApplicantForCheckin?.id
    );

    if (!tTSlot) {
      return;
    }

    // set time input values
    const checkInDateTime = dayjs(tTSlot.time_from)
      .tz("Europe/Berlin")
      .toDate();
    this.timeFromInputValue = this.formatTimeToInputString(checkInDateTime);
    this.timeToInputValue = this.formatTimeToInputString(tmpCheckoutDateTime);

    // check if time_to is over midnight
    const isOverMidnight = tmpCheckoutDateTime < checkInDateTime;
    if (isOverMidnight) {
      tmpCheckoutDateTime = dayjs(tmpCheckoutDateTime).add(1, "day").toDate();
      this.overMidnightString = this.getNextDayStringIfOverMidnight(
        tTSlot.time_from,
        tmpCheckoutDateTime.getTime()
      );
    }

    // init addTimeValues
    this.addTimeValues = {
      ...tTSlot,
      time_break: 0,
      time_to: tmpCheckoutDateTime.getTime(),
    };

    // determine break times
    this.breakHoursInputValue = 0;
    this.breakMinutesInputValue = 0;
    this.updateMinBreakValue();
  }

  /**
   * will transform a given date to a string value for the time input fields.
   */
  formatTimeToInputString(date?: Date) {
    if (!date) {
      return undefined;
    }

    const tmpHours = date.getHours().toString().padStart(2, "0");
    const tmpMinutes = this.correctMinutesToNearestQuarter(date.getMinutes())
      .toString()
      .padStart(2, "0");
    return `${tmpHours}:${tmpMinutes}`;
  }

  /**
   * correctMinutesToNearestQuarter takes an integer values for minutes and corrects
   * the value to the nearest quarter of an hour.
   * @param minutes int value of minues 0-60
   * @returns 0, 15, 30 or 45 whatever is closest to given minute value
   */
  correctMinutesToNearestQuarter(minutes: number) {
    if (minutes < 8) {
      return 0;
    }
    if (minutes < 23) {
      return 15;
    }
    if (minutes < 38) {
      return 30;
    }
    if (minutes < 53) {
      return 45;
    }
    return 0;
  }

  /**
   * updateCheckInCheckDate will set the checkInCheckDate to the next quarter
   * of an hour or the last quarter of an hour depending which one is closer.
   */
  updateCheckInCheckDate() {
    this.checkInCheckDate = dayjs()
      .tz("Europe/Berlin")
      .set("seconds", 0)
      .set("milliseconds", 0)
      .toDate();

    const minutes = this.checkInCheckDate.getMinutes();
    const correctedMinutes = this.correctMinutesToNearestQuarter(minutes);
    this.checkInCheckDate.setMinutes(correctedMinutes);
  }

  /**
   * selectedApplicantForCheckOutChanged will fetch the timeslot for the new selected applicant
   * and updates the form values of the modal, also it will update the time_to value to current time
   * and the time_break value depending on how much time has passed since the checkin.
   * @param selectedApplicant
   */
  selectedApplicantForCheckOutChanged() {
    this.hasPendingCheckIn = this.doesCheckinExistForSelectedApplicant();
    if (this.hasPendingCheckIn) {
      this.updateCheckInCheckDate();
      this.initCheckoutModalValues(this.checkInCheckDate);
    }
  }

  /**
   * doesCheckinExistForSelectedApplicant will check if an open checkin timeslot for the
   * currently selected applicant already exists and returns true if so.
   * @returns boolean if open checkin timeslot exists
   */
  doesCheckinExistForSelectedApplicant() {
    if (!this.selectedApplicantForCheckin) {
      return false;
    }

    return this.teamTimeSlots.some(
      (tTSlot) =>
        tTSlot.live_checkin &&
        !tTSlot.time_to &&
        tTSlot.application_id === this.selectedApplicantForCheckin?.id
    );
  }

  /**
   * selectedApplicantForCheckInChanged will check if an open checkin timeslost already exists
   * for the selected applicant and if so, it will set the variable hasPendingCheckIn to true.
   * @param selectedApplicant
   */
  selectedApplicantForCheckInChanged() {
    // get latest timeslot of applicant
    // check if applicant already has an pending checkin
    let foundTimeSlot = false;
    this.teamTimeSlots.forEach((tTSlot) => {
      if (
        tTSlot.live_checkin &&
        !tTSlot.time_to &&
        tTSlot.application_id === this.selectedApplicantForCheckin?.id
      ) {
        foundTimeSlot = true;
      }
    });

    this.hasPendingCheckIn = foundTimeSlot;
  }

  /**
   * checkInStartTimeChanged will call the function refreshAddTimeValues if the changeEvent is triggered.
   */
  checkInStartTimeChanged() {
    this.refreshAddTimeValues();
  }

  /**
   * closeCheckinModal and resets the scanned userId and the selected applicant.
   */
  closeCheckInModal() {
    this.showCheckInModal = false;
    this.addTimeValues.time_to = undefined;
    this.addTimeValues.time_break = undefined;
    this.selectedApplicantUserIdFromQrCode = undefined;
    this.selectedApplicantForCheckin = undefined;
    this.hasPendingCheckIn = false;
  }

  /**
   * closeCheckOutModal and resets the scanned userId and the selected applicant.
   */
  closeCheckOutModal() {
    this.showCheckOutModal = false;
    this.addTimeValues.time_to = undefined;
    this.addTimeValues.time_break = undefined;
    this.selectedApplicantUserIdFromQrCode = undefined;
    this.selectedApplicantForCheckin = undefined;
    this.hasPendingCheckIn = false;
  }

  /**
   * saveCheckIn will create a new timeSlot for the selected user and the selected time.
   * It will create an entry with then new field isCheckIn so it is possible to filter the timeSlots.
   * Because we only save a checkin, we will set specific values for time_to and time_break.
   */
  async saveCheckIn() {
    if (!this.saveTimeSlotInProgress && this.selectedApplicantForCheckin) {
      this.saveTimeSlotInProgress = true;
      const newTimeSlot: TimetrackerSlot = {
        time_from: this.addTimeValues.time_from,
        time_break: 0,
        application_id: this.selectedApplicantForCheckin.id,
        live_checkin: true,
      };
      this.applicationId = this.selectedApplicantForCheckin.id;
      await this.addTimeSlot(newTimeSlot, this.selectedApplicantForCheckin);
      this.closeCheckInModal();
      this.saveTimeSlotInProgress = false;
    }
  }

  /**
   * saveCheckOut will update the existing timeSlot and finalize it's dataset by setting
   * time_to and time_break values.
   */
  async saveCheckOut() {
    if (!this.saveTimeSlotInProgress) {
      this.saveTimeSlotInProgress = true;
      await this.modalUpdateTimeClicked();
      this.saveTimeSlotInProgress = false;
      this.closeCheckOutModal();
    }
  }

  /**
   * openAddTimeForTopteamerOverlay opens the overlay for adding time for a topteamer.
   */
  async openAddTimeForTopteamerOverlay() {
    // initialize finalizedApplicationUsers
    await this.loadAllFinalizedApplications();
    this.addTimeModalEditBySupervisor = true;
    this.showAddTimeForTopteamerOverlay = true;
  }

  /**
   * selectedApplicantForAddTimeChanged needs to reload the selectable days
   * and the timeSlots of the selected applicant.
   * @param $event
   */
  async selectedApplicantForAddTimeChanged(selectedApplicant: Application) {
    this.isForCheckIn = false;
    this.saveTimeSlotInProgress = true;
    this.addTimeValues.application_id = selectedApplicant.id;
    this.applicationId = selectedApplicant.id;
    await this.loadTimeSlots();
    this.refreshAddTimeValues();

    const tmpDate = dayjs().tz("Europe/Berlin").startOf("day").toDate();

    // reset selectableDays
    this.loadSelectableDays(tmpDate.getTime());
    this.saveTimeSlotInProgress = false;
    this.scrollToNearestTimeSlot();
  }

  /**
   * saveNewTimeSlotForTopteamer validates if all values for new timeSlot
   * are set and an applicant got selected in the dropdown.
   * Then it will create a new timeSlot for the selected user and the selected time.
   */
  async saveNewTimeSlotForTopteamer() {
    // validate addTimeValues
    if (
      this.saveTimeSlotInProgress ||
      !this.addTimeValues.time_from ||
      !this.addTimeValues.time_to
    ) {
      return;
    }
    if (this.selectedApplicantForCheckin) {
      this.saveTimeSlotInProgress = true;
      this.applicationId = this.selectedApplicantForCheckin.id;
      this.addTimeValues.edited_by_admin = true;
      await this.addTimeSlot(this.addTimeValues);
      this.saveTimeSlotInProgress = false;
      this.closeAddTimeForTopteamerOverlay();
    }
  }

  /**
   * Closes the overlay for adding time for a topteamer.
   */
  closeAddTimeForTopteamerOverlay() {
    this.selectedApplicantForCheckin = undefined;
    this.addTimeModalEditBySupervisor = false;
    this.showAddTimeForTopteamerOverlay = false;
    this.timeToInputValue = undefined;
    this.timeFromInputValue = undefined;
    this.fakeTimeFromInputValue = undefined;
    this.fakeTimeToInputValue = undefined;
    this.addTimeValues = {
      time_from: 0,
      time_to: 0,
      time_break: 0,
    };
  }

  /**
   * Opens the QR Scanner overlay and determines if it is for checkin or checkout depending on the given parameter.
   * @param forCheckIn
   */
  async openQrScannerOverlay(forCheckIn: boolean = true) {
    this.isForCheckIn = forCheckIn;
    this.showQrScannerModal = true;
  }

  /**
   * loadDocuments loads all documents of the current project and
   * saves them in the component variable documents. Documents are
   * the signatures of the customers for specific days.
   */
  async loadDocuments() {
    this.documents = await this.data.getDocumentsOfProject(this.projectId!);
  }

  /**
   * modalAddTimeClicked will validate the input fields of the addTimeModal
   * and then create a new timeSlot with the given values of the addTimeValues variable.
   * @returns preemptively if no day is selected
   */
  modalAddTimeClicked() {
    if (this.selectableDays.filter((el) => el.selected).length == 0) {
      Notify.failure("Es muss ein Tag ausgewählt werden.");
      return;
    }
    if (
      this.addTimeValues.time_from &&
      (this.addTimeValues.time_to || this.addTimeValues.live_checkin)
    ) {
      this.addTimeSlot(this.addTimeValues);
      this.closeModalClicked();
    } else {
      Notify.failure("Es muss die vollständige Einsatzzeit angegeben werden.");
    }
  }

  /**
   * modalTimeFakeCheckboxChange will set the fake time values to the real time values
   * if the checkbox is checked and reset the fake time values if the checkbox has been unchecked.
   * @param checkboxChangeEvent
   */
  modalTimeFakeCheckboxChange(checkboxChangeEvent: any) {
    if (checkboxChangeEvent.target.checked) {
      this.addTimeValues.fake_time_from = this.addTimeValues.time_from;
      this.addTimeValues.fake_time_to = this.addTimeValues.time_to;
      this.addTimeValues.fake_time_break = this.addTimeValues.time_break;
    } else {
      this.addTimeValues.fake_time_from = null;
      this.addTimeValues.fake_time_to = null;
      this.addTimeValues.fake_time_break = null;
    }
    this.initAddTimeValues();
  }

  /**
   * loadTimesOfApplicationId will go through all teamTimeSlots and filter
   * the ones with the given applicationId and save them in the component variable extraTimesList.
   * @param applicationId the applicationId to filter the teamTimeSlots
   * @param excludeTimeSlotId is an optional parameter to exclude a specific timeSlot, useful for editing a timeSlot and only show the other timeSlots of the same application
   */
  loadTimesOfApplicationId(applicationId: number, excludeTimeSlotId?: number) {
    this.extraTimesList = [];
    this.teamTimeSlots.forEach((tTSlot) => {
      if (tTSlot.application_id && tTSlot.application_id === applicationId) {
        if (!excludeTimeSlotId || excludeTimeSlotId !== tTSlot.id) {
          this.extraTimesList.push(tTSlot);
        }
      }
    });
    this.extraTimesList.sort(this.sortTimeSlots);
  }

  /**
   * modalUpdateTimeClicked checks if addTimeValues has an id and then updates the timeSlot
   * with the given values of addTimeValues. If the timeSlot is not editable anymore, it will
   * show a warning and not update the timeSlot.
   * After updating the timeSlot, it will refresh the data by calling initializeData() and close the modal.
   */
  async modalUpdateTimeClicked() {
    // update time
    if (!this.addTimeValues.id) {
      this.closeModalClicked();
      return;
    }

    // check if timeslot has already been exported in the meantime
    const timeSlotObject = await this.data.getTimeSlot(this.addTimeValues.id);
    if (!this.isTimeslotEditable(timeSlotObject)) {
      Notify.failure("Zeiten können nicht mehr geändert werden");
      await this.initializeData();
      this.closeModalClicked();
      return;
    }

    try {
      const updatedTimeSlot = await this.data.updateTimeSlot(
        this.addTimeValues.id,
        this.addTimeValues,
        this.projectId!
      );
      this.updateTimeslotLocally(updatedTimeSlot);
      await this.initializeData();
      this.closeModalClicked();
    } catch (updateTimeSlotError) {
      Notify.failure("Update error");
      console.error("updateTimeSlotError: ", updateTimeSlotError);
    }
  }

  /**
   * modalDeleteTimeClicked will show a popover to confirm the deletion of a timeSlot.
   * If the user confirms the deletion, it will delete the timeSlot with the given id and applicationId
   * from the backend and then remove the timeSlot from the local data and close the modal.
   */
  modalDeleteTimeClicked() {
    Confirm.show(
      "Zeiteintrag wirklich löschen?",
      "Dieser Vorgang kann nicht rückgängig gemacht werden und wird im Protokoll festgehalten.",
      "Nein",
      "Ja",
      () => {},
      () => {
        // delete time
        if (this.addTimeValues.id && this.addTimeValues.application_id)
          this.data
            .deleteTimeSlot(
              this.addTimeValues.id,
              this.addTimeValues.application_id
            )
            .then(() => {
              this.removeDeletedTimeSlotsLocally(this.addTimeValues.id!);
              this.closeModalClicked();
            })
            .catch((err) => {
              Notify.failure("Some error happened");
              console.error("deleteTimeSlot error: ", err);
            });
      },
      {
        messageMaxLength: 1000000,
        plainText: false,
      } as IConfirmOptions
    );
  }

  /**
   * Shows popover with warning, that times can not be changed after sending the signature
   * @returns boolean true if user wants to send signature and times, false if not
   */
  showConfirmSendSignature(): Promise<boolean> {
    return new Promise((resolve: any) => {
      Confirm.show(
        "Achtung",
        "Sobald du die Zeiten abgeschickt hast, können keine weiteren Zeiten für diesen Tag mehr erfasst werden.",
        "Abbrechen",
        "Zeiten abschicken",
        () => {
          // no
          resolve(false);
        },
        () => {
          // yes
          resolve(true);
        },
        {}
      );
    });
  }

  /**
   * modalSignatureSendButtonClicked will validate the input fields of the signature modal and then
   * create a new document with the given values of the customerSignModalEmail and the signatureBase64.
   * Afterwards it will update all timeslots of the current day and send the time overview to the customer.
   * At the end it will reset all data by calling initializeData() and close the modal.
   * @returns preemptively if validation fails
   */
  async modalSignatureSendButtonClicked() {
    // validate inputs:
    if (!this.customerSignModalEmail || !this.signatureBase64) {
      Notify.failure(
        "Es muss eine E-Mail und eine Unterschrift angegeben werden."
      );
      return;
    }
    // show popover with warning, that times can not be changed after sending the signature
    let sendSignatureAndTimes: boolean = true;
    // check if current user is admin, if so, skip the warning
    if (this.auth.currentUser?.role === "regular") {
      sendSignatureAndTimes = await this.showConfirmSendSignature();
    }
    if (sendSignatureAndTimes) {
      // save in db
      this.isUploading = true;
      try {
        const newDocument: Document = {
          base64_signature: this.signatureBase64,
          contact_email: this.customerSignModalEmail,
          timestamp:
            this.teamTimeSlotDays[this.currentActiveTeamTimeSlotDayIndex]
              .startTimestamp,
          project_id: this.projectId!,
        };

        const body = {
          ...newDocument,
          ...(this.applicationId ? { application_id: this.applicationId } : {}),
          project_id: this.projectId,
        };

        // create the document
        const response = await this.data.postRequest(
          "/project/documents",
          body
        );

        this.teamTimeSlotDays[
          this.currentActiveTeamTimeSlotDayIndex
        ].document_ids.push(response.id!);
        this.isExportAllVisible = true;
        // send time overview
        await this.sendTimeOverview(
          this.teamTimeSlotDays[this.currentActiveTeamTimeSlotDayIndex]
        );
        await this.initializeData();
        this.customerSignModalVisible = false;
      } catch (error) {
        Notify.failure("Etwas ist schief gelaufen.s");
        console.error("createDocument error: ", error);
      }
      this.isUploading = false;
    }
  }

  /**
   * updateTimeslotLocally will update the timeSlot with the given id in the local data.
   * It will search for the timeSlot in myTimeSlots, teamTimeSlots and currentVisibleTeamTimeSlots
   * and update the timeSlot with the given updatedTimeslot.
   * Afterwards it will call the generateTeamTimeSlotDays() and refreshCurrentVisibleTeamTimeSlots() functions
   * @param updatedTimeslot the timeSlot to be updated
   */
  updateTimeslotLocally(updatedTimeslot: TimetrackerSlot) {
    // check myTimeSlots
    this.myTimeSlots.forEach((timeSlot, index) => {
      if (timeSlot.id === updatedTimeslot.id) {
        Object.assign(this.myTimeSlots[index], updatedTimeslot);
      }
    });
    // check teamTimeSlots
    this.teamTimeSlots.forEach((timeSlot, index) => {
      if (timeSlot.id === updatedTimeslot.id) {
        this.teamTimeSlots[index] = Object.assign(
          this.teamTimeSlots[index],
          updatedTimeslot
        );
      }
    });
    // check currentVisibleTeamTimeSlots
    this.currentVisibleTeamTimeSlots.forEach((timeSlot, index) => {
      if (timeSlot.id === updatedTimeslot.id) {
        this.currentVisibleTeamTimeSlots[index] = Object.assign(
          this.currentVisibleTeamTimeSlots[index],
          updatedTimeslot
        );
      }
    });
    this.generateTeamTimeSlotDays();
    this.refreshCurrentVisibleTeamTimeSlots(
      this.currentActiveTeamTimeSlotDayIndex
    );
  }

  /**
   * removeDeletedTimeSlotsLocally will remove the timeSlot with the given timeSlotId from the local data.
   * It will search for the timeSlot in myTimeSlots, teamTimeSlots and currentVisibleTeamTimeSlots
   * and remove the timeSlot from all arrays.
   * Afterwards it will call the generateTeamTimeSlotDays() and refreshCurrentVisibleTeamTimeSlots() functions
   * @param timeSlotId the id of the timeSlot to be removed
   */
  removeDeletedTimeSlotsLocally(timeSlotId: number) {
    let deleted = false;
    this.myTimeSlots.forEach((tSlot, i) => {
      if (!deleted && tSlot.id === timeSlotId) {
        this.myTimeSlots.splice(i, 1);
        deleted = true;
      }
    });
    deleted = false;
    this.teamTimeSlots.forEach((tSlot, i) => {
      if (!deleted && tSlot.id === timeSlotId) {
        this.teamTimeSlots.splice(i, 1);
        deleted = true;
      }
    });
    deleted = false;
    this.currentVisibleTeamTimeSlots.forEach((tSlot, i) => {
      if (!deleted && tSlot.id === timeSlotId) {
        this.currentVisibleTeamTimeSlots.splice(i, 1);
        deleted = true;
      }
    });
    this.generateTeamTimeSlotDays();
    this.refreshCurrentVisibleTeamTimeSlots(
      this.currentActiveTeamTimeSlotDayIndex
    );
  }

  /**
   * addTimeSlot will create a new timeSlot with the given values and save it in the backend.
   * If the liveCheckinApplicant is set, it will create a checkin timeSlot for the given applicant.
   * After creating the timeSlot, it will call the initializeData() function and refresh the overall times.
   * @param timeSlot
   * @param liveCheckinApplicant
   */
  async addTimeSlot(
    timeSlot: TimetrackerSlot,
    liveCheckinApplicant?: Application
  ) {
    if (this.applicationId) {
      // add timeslot to db
      if (liveCheckinApplicant) {
        try {
          await this.data.createCheckInTimeSlot(
            timeSlot,
            liveCheckinApplicant.id!,
            this.projectId!
          );

          await this.initializeData();
        } catch (createCheckInTimeSlotError) {
          Notify.failure("createCheckInTimeSlotError");
          console.error(
            "createCheckInTimeSlotError: ",
            createCheckInTimeSlotError
          );
        }
      } else {
        try {
          await this.data.createTimeSlot(timeSlot, this.applicationId);
          await this.initializeData();
        } catch (createTimeSlotError) {
          Notify.failure("createTimeSlotError");
          console.error("createTimeSlotError: ", createTimeSlotError);
        }
      }
      this.refreshOverallTimes();
    }
  }

  /**
   * loadTimeSlots will load all timeSlots for the current user and the current project and
   * saves them in the component variable myTimeSlots. If the current user is either a
   * teamleader or has admin privileges, it will also load all timeSlots of the team and
   * saves them in the component variable teamTimeSlots.
   */
  async loadTimeSlots() {
    this.endOfFestgeschriebenMonthTimestamp = await this.data.getRequest(
      "/datev-export/getEndOfFestgeschriebenMonthTimestamp"
    );
    if (this.applicationId) {
      // personal timeslots
      const response = await this.data.getTimeSlotsByApplication(
        this.applicationId
      );
      this.myTimeSlots = response;

      if (this.endOfFestgeschriebenMonthTimestamp) {
        this.myTimeSlots.forEach((timeSlot, index) => {
          if (timeSlot.time_from < this.endOfFestgeschriebenMonthTimestamp) {
            this.myTimeSlots[index].is_already_established_in_datev_export =
              true;
          }
        });
      }
      this.myTimeSlots.sort(this.sortTimeSlots);
    }

    // team timeslots:
    this.teamTimeSlots = [];
    if (
      this.projectId &&
      (this.auth.currentUser?.role != "regular" ||
        this.application?.role == "teamleiter_vor_ort" ||
        this.application?.role == "additional_teamleads" ||
        this.project?.jeder_mitarbeiter_darf_unterschrift_von_kunden_einholen)
    ) {
      try {
        const applicationsWithTimes: Application[] =
          await this.data.postRequest(
            `/project/applications/${this.projectId}`,
            {
              includeTimeslots: true,
            }
          );
        if (applicationsWithTimes) {
          applicationsWithTimes.forEach((appWTimes) => {
            if (appWTimes.timeslots) {
              appWTimes.timeslots.forEach((timeSlot) => {
                const tmpTimeslot = { ...timeSlot };

                if (this.endOfFestgeschriebenMonthTimestamp) {
                  if (
                    tmpTimeslot.time_from <
                    this.endOfFestgeschriebenMonthTimestamp
                  ) {
                    tmpTimeslot.is_already_established_in_datev_export = true;
                  }
                }

                if (appWTimes.user?.profile) {
                  tmpTimeslot.title = `${appWTimes.user.profile.vorname} ${appWTimes.user.profile.nachname}`;
                  tmpTimeslot.vorname = appWTimes.user.profile.vorname;
                  tmpTimeslot.nachname = appWTimes.user.profile.nachname;
                  this.teamTimeSlots.push(tmpTimeslot);
                }
              });
            }
          });
        }
      } catch (err) {
        console.error("getApplicationsOfProject error: ", err);
      }
    }
  }

  /**
   * sortTeamTimeSlots it a helperfunction to use the array.sort function.
   * It will sort the timeSlots by name and then by time.
   */
  sortTeamTimeSlots(a: TimetrackerSlot, b: TimetrackerSlot) {
    const nameComp = (a.title || "").localeCompare(b.title || "");
    if (nameComp === 0) {
      return this.sortTimeSlots(a, b);
    }
    return nameComp;
  }

  sortTimeSlots(a: TimetrackerSlot, b: TimetrackerSlot) {
    if (a.time_from < b.time_from) return 1;
    return a.time_to == b.time_from ? 0 : -1;
  }

  /**
   * loadAllFinalizedApplications will load all applications of the project that are accepted for the job.
   * It will save the applications in the component variable finalizedApplicationUsers.
   */
  async loadAllFinalizedApplications() {
    this.finalizedApplicationUsers = await this.data.getAllAcceptedApplicants(
      this.applicationId!,
      this.projectId!
    );

    this.finalizedApplicationUsers.sort((a, b) => {
      const userA = `${a.user?.profile?.vorname} ${a.user?.profile?.nachname}`;
      const userB = `${b.user?.profile?.vorname} ${b.user?.profile?.nachname}`;

      return userA.localeCompare(userB);
    });
  }

  /**
   * generateTeamTimeSlotDays will generate the teamTimeSlotDays array which is used to display the
   * timeSlots in the frontend. It will group the timeSlots by day and sort them by time.
   */
  generateTeamTimeSlotDays() {
    this.teamTimeSlotDays = [];
    this.teamTimeSlots.forEach((tTSlot) => {
      const tmpDate = dayjs(tTSlot.time_from).tz("Europe/Berlin");
      const startTimestamp = tmpDate.startOf("day").valueOf();

      const existingDay = this.teamTimeSlotDays.find(
        (ttsd) => ttsd.startTimestamp === startTimestamp
      );

      if (existingDay) {
        existingDay.timeSlots ??= [];
        existingDay.timeSlots.push(tTSlot);
        return;
      }

      const endTimestamp = tmpDate.endOf("day").valueOf();

      const tmpDay: TeamTimeSlotDay = {
        weekday: tmpDate.toDate().toLocaleDateString("de", {
          weekday: "long",
          timeZone: "Europe/Berlin",
        }),
        dateShort: tmpDate.toDate().toLocaleDateString("de", {
          month: "2-digit",
          day: "2-digit",
          timeZone: "Europe/Berlin",
        }),
        dateLong: tmpDate.toDate().toLocaleDateString("de", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          timeZone: "Europe/Berlin",
        }),
        startTimestamp,
        endTimestamp,
        overallTime: 0,
        collapsed: false,
        timeSlots: [tTSlot],
        document_ids: [],
        completeTimeslots: 0,
        openCheckouts: 0,
      };

      if (this.documents) {
        const document_ids = new Set<number>();

        this.documents
          .filter(
            ({ id, timestamp }) => timestamp === tmpDay.startTimestamp && id
          )
          .forEach((document) => document_ids.add(document.id!));

        // check if export all button should be visible
        // if there are documents for the day, dont overwrite if it is already true
        this.isExportAllVisible ||= document_ids.size > 0;
        tmpDay.document_ids = Array.from(document_ids);
      }

      this.teamTimeSlotDays.push(tmpDay);
    });

    for (const teamTimeSlotDay of this.teamTimeSlotDays) {
      const { completeTimeslots, openCheckouts } =
        this.countCheckinsOfTeamTimeSlotDay(teamTimeSlotDay);

      teamTimeSlotDay.completeTimeslots = completeTimeslots;
      teamTimeSlotDay.openCheckouts = openCheckouts;
      teamTimeSlotDay.timeSlots?.sort((a, b) => this.sortTeamTimeSlots(b, a));
    }

    // sort by date, so the days are in the correct order, first day first
    this.teamTimeSlotDays.sort((a, b) => b.startTimestamp - a.startTimestamp);
  }

  /**
   * countCheckinsOfTeamTimeSlotDay checks all timeSlots of a teamTimeSlotDay and counts the complete timeslots
   * and the open checkouts.
   * @param teamTimeSlotDay
   */
  countCheckinsOfTeamTimeSlotDay(teamTimeSlotDay: TeamTimeSlotDay) {
    const result = {
      completeTimeslots: 0,
      openCheckouts: 0,
    };
    teamTimeSlotDay.timeSlots?.forEach((timeSlot) => {
      if (timeSlot.time_to) {
        result.completeTimeslots += 1;
      } else {
        result.openCheckouts += 1;
      }
    });
    return result;
  }

  /**
   * refreshCurrentVisibleTeamTimeSlots will refresh the currentVisibleTeamTimeSlots array
   * with the timeSlots of the selected teamTimeSlotDay.
   * @param teamTimeSlotDayIndex the index of the selected teamTimeSlotDay
   */
  refreshCurrentVisibleTeamTimeSlots(teamTimeSlotDayIndex: number) {
    // refresh currentVisibleTeamTimeSlots
    this.currentVisibleTeamTimeSlots = [];
    const selectedDay = this.teamTimeSlotDays[teamTimeSlotDayIndex];
    this.teamTimeSlots.forEach((timeSlot) => {
      if (
        selectedDay.startTimestamp < timeSlot.time_from &&
        timeSlot.time_from < selectedDay.endTimestamp
      ) {
        this.currentVisibleTeamTimeSlots.push({ ...timeSlot });
      }
    });
    this.currentVisibleTeamTimeSlots.sort((a, b) =>
      this.sortTeamTimeSlots(a, b)
    );
    this.currentActiveTeamTimeSlotDayIndex = teamTimeSlotDayIndex;
    this.refreshOverallTimes();
  }

  /**
   * timeSlotDaySwitchClicked will switch the current teamTimeSlotDay to the next or previous day.
   * It will call the refreshCurrentVisibleTeamTimeSlots function with the new index.
   * @param next if true, the next day will be selected, if false, the previous day will be selected
   */
  timeSlotDaySwitchClicked(next: boolean) {
    let tmpNewIndex = this.currentActiveTeamTimeSlotDayIndex + 1;
    if (!next) {
      tmpNewIndex = this.currentActiveTeamTimeSlotDayIndex - 1;
    }
    if (tmpNewIndex > this.teamTimeSlotDays.length - 1) {
      tmpNewIndex = 0;
    } else if (tmpNewIndex < 0) {
      tmpNewIndex = this.teamTimeSlotDays.length - 1;
    }
    this.refreshCurrentVisibleTeamTimeSlots(tmpNewIndex);
  }

  /**
   * refreshOverallTimes will calculate the overall times of the current user and the team
   * so they can be displayed in the frontend. It saves the overall times in the component variables
   * myTimeOverall and teamTimeOverall, and the formatted strings in myTimeOverallFormatted and teamTimeOverallFormatted.
   * It also calculates the overall times for each day of the teamTimeSlotDays array and saves them in the
   * overallTime and overallTimeFormatted variables.
   * At last it calculates the projectTimeOverall and saves it in the formattedProjectTimeOverall variable.
   */
  refreshOverallTimes() {
    this.myTimeOverall = 0;
    this.myTimeSlots.forEach((timeSlot) => {
      if (
        timeSlot.time_to &&
        (timeSlot.time_break || timeSlot.time_break === 0)
      ) {
        this.myTimeOverall += Math.max(
          0,
          timeSlot.time_to - timeSlot.time_from - timeSlot.time_break
        );
      }
    });

    this.teamTimeOverall = 0;
    this.currentVisibleTeamTimeSlots.forEach((timeSlot) => {
      if (
        timeSlot.fake_time_from &&
        timeSlot.fake_time_to &&
        (timeSlot.fake_time_break || timeSlot.fake_time_break === 0)
      ) {
        this.teamTimeOverall += Math.max(
          0,
          timeSlot.fake_time_to - timeSlot.fake_time_from
        );
      } else if (
        timeSlot.time_to &&
        (timeSlot.time_break || timeSlot.time_break === 0)
      ) {
        this.teamTimeOverall += Math.max(
          0,
          timeSlot.time_to - timeSlot.time_from
        );
      }
    });

    this.formattedTeamTimeOverall = `${
      this.splitMilliseconds(this.teamTimeOverall).formatted
    } h`;

    this.teamTimeSlotDays.forEach((tTSDay) => {
      // reassign so eslint does not complain
      const day = tTSDay;

      day.overallTime = 0;
      if (tTSDay.timeSlots) {
        tTSDay.timeSlots.forEach((tTSlot) => {
          if (
            tTSlot.fake_time_from &&
            tTSlot.fake_time_to &&
            (tTSlot.fake_time_break || tTSlot.fake_time_break === 0)
          ) {
            const fakeDiff = tTSlot.fake_time_to - tTSlot.fake_time_from;
            day.overallTime += Math.max(0, fakeDiff);
          } else if (
            tTSlot.time_from &&
            tTSlot.time_to &&
            (tTSlot.time_break || tTSlot.time_break === 0)
          ) {
            day.overallTime += Math.max(0, tTSlot.time_to - tTSlot.time_from);
          }
        });
      }
      // format Duration
      day.overallTimeFormatted = `${
        this.splitMilliseconds(day.overallTime).formatted
      } h`;
    });

    // calculate project time overall:
    this.projectTimeOverall = 0;
    this.teamTimeSlots.forEach((timeSlot, tSIndex) => {
      if (
        timeSlot.time_to &&
        (timeSlot.time_break || timeSlot.time_break === 0)
      ) {
        let timeFrom = timeSlot.time_from;
        let timeTo = timeSlot.time_to;

        if (timeSlot.fake_time_from) timeFrom = timeSlot.fake_time_from;
        if (timeSlot.fake_time_to) timeTo = timeSlot.fake_time_to;

        const timeSlotDuration = Math.max(timeTo - timeFrom);

        this.projectTimeOverall += timeSlotDuration;
        if (this.managerView) {
          this.teamTimeSlots[tSIndex].formattedDuration =
            this.splitMilliseconds(timeSlotDuration).formatted;
          if (
            timeSlot.time_to &&
            (timeSlot.time_break || timeSlot.time_break === 0)
          ) {
            this.teamTimeSlots[tSIndex].formattedBreak = this.splitMilliseconds(
              timeSlot.fake_time_break || timeSlot.fake_time_break === 0
                ? timeSlot.fake_time_break
                : timeSlot.time_break
            ).formatted;
          }
        }
      }
    });

    this.formattedProjectTimeOverall = this.splitMilliseconds(
      this.projectTimeOverall
    ).formatted;
  }

  /**
   * splitMilliseconds will split the given milliseconds into hours and minutes and return them as an object.
   * It will also return a formatted string of the hours and minutes.
   * @param milliseconds the milliseconds to split
   * @returns {hours, minutes, formatted}
   */
  splitMilliseconds(milliseconds: number) {
    const minutes = milliseconds / 1000 / 60;
    const tmpHours = Math.floor(minutes / 60);
    const tmpMinutes = Math.floor(minutes % 60);
    const formatted = this.formatTime(tmpHours, tmpMinutes);
    return {
      hours: tmpHours,
      minutes: tmpMinutes,
      formatted,
    };
  }

  formatTime(hours: number, minutes: number) {
    const minStr = minutes.toString().padStart(2, "0");
    return `${hours}:${minStr}`;
  }

  /**
   * loadSelectableDays will load the selectable days for the current user and the current project.
   * It will save the selectableDay objects in the component variable selectableDays array.
   * If the current user is a teamleader or has admin privileges, it will check the teamTimeSlots
   * and compares them with the selectableDays. If a day is already selected by the currently selected
   * team member, it will be marked as not selectable.
   * Afterwards it will make days unselectable if they are before the festgeschrieben month which means they
   * already has been exported to datev.
   * At last it will select the first selectable day in the selectableDays array.
   * @param preSelectCorrectDayTime if given, the selectableDays array will be checked if the given time is selectable
   */
  loadSelectableDays(preSelectCorrectDayTime?: number) {
    this.selectableDays = [];
    let tmpDayjs = dayjs(this.possibleDaysFromTimestamp)
      .tz("Europe/Berlin")
      .startOf("day");

    for (let i = 0; i < this.possibleDaysAmount; i++) {
      const startOfToday = tmpDayjs.tz("Europe/Berlin").startOf("day").toDate();

      let weekdayShort = startOfToday.toLocaleDateString("de", {
        weekday: "short",
        timeZone: "Europe/Berlin",
      });

      // add dot at the end of the weekday, some browsers do not add it, some do
      if (!weekdayShort.endsWith(".")) {
        weekdayShort += ".";
      }

      const dateShort = startOfToday.toLocaleDateString("de", {
        month: "2-digit",
        day: "2-digit",
        timeZone: "Europe/Berlin",
      });

      const newSelectableDay: SelectableDay = {
        time: startOfToday.getTime(),
        weekdayShort,
        dateShort,
        selected: false,
        selectable: true,
      };

      // determine if day is selectable or not,
      // if the day is already in the myTimeSlots array, it is not selectable
      newSelectableDay.selectable = this.myTimeSlots.every((mTimeSlot) => {
        const startOfTimeSlot = dayjs(mTimeSlot.time_from)
          .tz("Europe/Berlin")
          .startOf("day")
          .toDate();

        const isSameDay = startOfTimeSlot.getTime() === newSelectableDay.time;
        return !isSameDay;
      });

      if (this.managerView || preSelectCorrectDayTime) {
        // only make the day selectable if its different from other timeSlots
        newSelectableDay.selectable = this.teamTimeSlots.every((tTimeSlot) => {
          const startOfTimeSlot = dayjs(tTimeSlot.time_from)
            .tz("Europe/Berlin")
            .startOf("day")
            .toDate();

          const isSameDay = startOfTimeSlot.getTime() === newSelectableDay.time;
          const isSameApplicant =
            tTimeSlot.application_id === this.addTimeValues.application_id;
          return !isSameDay || !isSameApplicant;
        });
      }

      this.selectableDays.push(newSelectableDay);
      tmpDayjs = tmpDayjs.add(1, "day");
    }

    // makes days unselectable if they are before the festgeschrieben month,
    // which means they are already exported to datev.
    const festDateTS = dayjs(this.endOfFestgeschriebenMonthTimestamp).tz(
      "Europe/Berlin"
    );

    for (const sDay of this.selectableDays) {
      const timeDateTS = dayjs(sDay.time).tz("Europe/Berlin");
      if (timeDateTS.isBefore(festDateTS, "day")) {
        sDay.selectable = false;
      }
    }

    // find and select the first selectable day, if preSelectCorrectDayTime is given,
    // select it if it is in the selectableDays array
    for (const sDay of this.selectableDays) {
      if (!sDay.selectable) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (!preSelectCorrectDayTime) {
        sDay.selected = true;
        break;
      }

      if (preSelectCorrectDayTime && sDay.time === preSelectCorrectDayTime) {
        this.updateDefaultTimesForSelectedDay(preSelectCorrectDayTime);
        sDay.selected = true;
        sDay.selectable = true;
        this.refreshAddTimeValues();
        break;
      }
    }
  }

  /**
   * updateDefaultTimesForSelectedDay will update the default time values for the selected day.
   * It will set the time_from to 8:00 and the time_to to 16:00 if they are not already set.
   * @param selectedCorrectDayTime
   */
  updateDefaultTimesForSelectedDay(selectedCorrectDayTime: number) {
    if (!this.addTimeValues.time_from && this.addTimeValues.time_from !== 0) {
      this.addTimeValues.time_from = dayjs(selectedCorrectDayTime)
        .tz("Europe/Berlin")
        .set("hour", 8)
        .valueOf();

      this.timeFromInputValue = this.formatTimeToInputString(
        new Date(this.addTimeValues.time_from)
      );
    }
    if (!this.addTimeValues.time_to && this.addTimeValues.time_to !== 0) {
      this.addTimeValues.time_to = dayjs(selectedCorrectDayTime)
        .tz("Europe/Berlin")
        .set("hour", 16)
        .valueOf();
      this.timeToInputValue = this.formatTimeToInputString(
        new Date(this.addTimeValues.time_to)
      );
    }
  }

  /**
   * updateIfTodayIsSelectableDay checks if today is inside the project date range (zeitraum_von and zeitraum_bis)
   * and saves the result in the component variable isTodayASelectableDay
   */
  updateIfTodayIsSelectableDay() {
    // check if today is in range of selectable days
    this.isTodayASelectableDay = false;
    if (this.project?.zeitraum_von && this.project?.zeitraum_bis) {
      const timeDateFromTS = dayjs(this.project.zeitraum_von)
        .tz("Europe/Berlin")
        .valueOf();
      const timeDateToTS = dayjs(this.project.zeitraum_bis)
        .tz("Europe/Berlin")
        .valueOf();
      const now = dayjs().tz("Europe/Berlin").valueOf();
      if (timeDateFromTS <= now && now <= timeDateToTS) {
        this.isTodayASelectableDay = true;
      }
    }
  }

  /**
   * getNextWeekday will return the next selectable weekday after the currently selected day.
   * If the currently selected day is the last selectable day, it will return the first selectable day.
   * @returns the weekdayShort value of the next selectable day
   */
  getNextWeekday() {
    let foundIndex = 1;
    this.selectableDays.forEach((sDay, index) => {
      if (sDay.selected) {
        foundIndex = index + 1;
      }
    });
    if (foundIndex > this.selectableDays.length - 1) {
      foundIndex = 0;
    }
    return this.selectableDays[foundIndex].weekdayShort;
  }

  /**
   * selectableDayClicked will be called when a selectable day is clicked. It will update the selected
   * day in the selectableDays array and calls the refreshAddTimeValues function.
   * @param index
   */
  selectableDayClicked(index: number) {
    if (!this.selectableDays[index]?.selectable) {
      return;
    }

    this.selectableDays.forEach((dayRef, sDayIndex) => {
      // eslint-disable-next-line no-param-reassign
      dayRef.selected = sDayIndex == index;
    });
    this.refreshAddTimeValues();
  }

  /**
   * addTimeClicked will be called when the add time button is clicked. It will call multiple functions
   * to prepare the add time modal and then show the modal by setting the addTimeModalVisible variable to true.
   * It calls the loadSelectableDays, initAddTimeValues and updateMinBreakValue functions.
   * At last it scrolls to the nearest time slot in the selectableDays array.
   */
  addTimeClicked() {
    this.loadSelectableDays();
    this.initAddTimeValues(true);
    this.updateMinBreakValue();
    this.addTimeModalVisible = true;
    this.scrollToNearestTimeSlot();
  }

  /**
   * updateMinBreakValue calculates and updates the current min break values
   *
   * @param updateInputValuesEvenForAdmin if true, the input values will be updated to meet the minimum criteria even for admin
   */
  updateMinBreakValue(updateInputValuesEvenForAdmin?: boolean) {
    // update min values
    if (this.addTimeValues.time_to === undefined) {
      return;
    }

    const isOverMidnight =
      this.addTimeValues.time_from > this.addTimeValues.time_to;

    // check if time_to is after midnight, if so add 24h to time_to
    const timeTo = isOverMidnight
      ? this.addTimeValues.time_to + 24 * 60 * 60 * 1000
      : this.addTimeValues.time_to;

    const timeDurationInHours =
      Math.max(0, timeTo - this.addTimeValues.time_from) / 3600 / 1000;

    this.minBreakHoursValue = 0;
    this.minBreakMinutesValue = 0;

    // cutofs should be at 6h 7m 30s and 9h 7m 30s
    const one8thHour = 7.5 / 60;
    const cutof6Hours = 6 + one8thHour;
    const cutof9Hours = 9 + one8thHour;

    if (
      timeDurationInHours > cutof6Hours &&
      timeDurationInHours <= cutof9Hours
    ) {
      this.minBreakHoursValue = 0;
      this.minBreakMinutesValue = 30;
    }

    if (timeDurationInHours > cutof9Hours) {
      this.minBreakHoursValue = 1;
      this.minBreakMinutesValue = 0;
    }

    const currentBreakMinutes =
      this.breakHoursInputValue * 60 + this.breakMinutesInputValue;
    const minimumBreak =
      this.minBreakHoursValue * 60 + this.minBreakMinutesValue;

    // check and update current values to meet minimum criteria
    const updateInputValues =
      !this.currentUserHasAdminPrivileges || updateInputValuesEvenForAdmin;
    if (currentBreakMinutes < minimumBreak && updateInputValues) {
      this.breakHoursInputValue = this.minBreakHoursValue;
      this.breakMinutesInputValue = this.minBreakMinutesValue;
      this.refreshAddTimeValues();
    }
  }

  /**
   * scrollToNearestTimeSlot will scroll to the nearest time slot in the selectableDays array.
   */
  scrollToNearestTimeSlot() {
    let index = 0;
    const date_now = new Date();
    for (let i = 0; i < this.selectableDays.length; i++) {
      if (this.selectableDays[i].time < date_now.getTime()) {
        index = i;
      }
    }
    setTimeout(() => {
      const element = document.getElementById(`day-pick-${index}`);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }, 500);
  }

  /**
   * editTimeClicked will be called when the edit time button is clicked. It will check if the timeSlot
   * is editable and then prepare the add time modal and show it by setting the addTimeModalVisible variable to true.
   * It calls the loadSelectableDays, initAddTimeValues and updateMinBreakValue functions.
   * At last it scrolls to the nearest time slot in the selectableDays array.
   * @param timeSlot the timeSlot to edit
   * @returns preemptively if the timeSlot is not editable or if the live checkin is active
   */
  editTimeClicked(timeSlot: TimetrackerSlot) {
    if (timeSlot.edited_by_admin) {
      return;
    }
    if (!this.isCurrentUserTeamlead() && timeSlot.live_checkin) {
      return;
    }
    if (this.isTimeslotEditable(timeSlot)) {
      this.addTimeValues = { ...timeSlot };
      const tmpDate = dayjs(timeSlot.time_from)
        .tz("Europe/Berlin")
        .startOf("day")
        .toDate();
      this.isForCheckIn = true;
      this.addTimeModalEditTime = true;
      this.loadSelectableDays(tmpDate.getTime());
      this.addTimeModalVisible = true;
      this.scrollToNearestTimeSlot();
      this.initAddTimeValues();
      this.refreshAddTimeValues();
    }
  }

  /**
   * editTimeBySupervisorClicked will be called when the edit time by supervisor button is clicked.
   * It will check if the timeSlot is editable and then prepare the add time modal and show it by setting
   * the addTimeModalVisible variable to true. It calls the loadSelectableDays, initAddTimeValues and updateMinBreakValue functions.
   * At last it scrolls to the nearest time slot in the selectableDays array.
   * @param timeSlot the timeSlot to edit
   * @returns preemptively if the timeSlot is not editable
   */
  editTimeBySupervisorClicked(timeSlot: TimetrackerSlot) {
    if (timeSlot.edited_by_admin && !this.managerView) {
      return;
    }
    if (this.isTimeslotEditable(timeSlot)) {
      this.addTimeValues = { ...timeSlot };
      const tmpDate = dayjs(timeSlot.time_from)
        .tz("Europe/Berlin")
        .startOf("day")
        .toDate();
      if (this.managerView) {
        this.loadTimesOfApplicationId(timeSlot.application_id!, timeSlot.id);
      }
      this.isForCheckIn = true;
      this.addTimeModalEditBySupervisor = true;
      this.addTimeModalEditTime = true;
      this.loadSelectableDays(tmpDate.getTime());
      this.addTimeModalVisible = true;
      this.scrollToNearestTimeSlot();
      this.initAddTimeValues();
      this.refreshAddTimeValues();
    }
  }

  /**
   * openSignModalClicked will be called when the open sign modal button is clicked.
   * It will prepare the customer sign modal and show it by setting the customerSignModalVisible variable to true.
   * It will also set the currentActiveTeamTimeSlotDayIndex to the index of the teamTimeSlotDay of the given timeSlot.
   * @param teamTimeSlotDay the teamTimeSlotDay which has to be signed
   */
  openSignModalClicked(teamTimeSlotDay?: TeamTimeSlotDay) {
    this.customerSignModalEmail = "";
    this.signatureBase64 = "";
    if (teamTimeSlotDay) {
      // determine this.currentActiveTeamTimeSlotDayIndex
      this.teamTimeSlotDays.forEach((tTSlot, index) => {
        if (tTSlot.startTimestamp === teamTimeSlotDay.startTimestamp) {
          this.currentActiveTeamTimeSlotDayIndex = index;
        }
      });
    }
    this.customerSignModalVisible = true;
  }

  /**
   * closeModalClicked will be called when the close modal button is clicked. It will close the modal
   * by setting the specific modal variable to false. If no specific modal is given, all modals will be closed.
   * @param specificModal the specific modal to close
   */
  closeModalClicked(specificModal: string = "") {
    if (specificModal) {
      if (specificModal === "midnight") {
        this.overMidnightHintModalVisible = false;
        return;
      }
      if (specificModal === "addtime") {
        this.addTimeModalVisible = false;
        this.addTimeModalEditTime = false;
        this.addTimeModalEditBySupervisor = false;
        this.initAddTimeValues(true);
      }
      if (specificModal === "customersign") {
        this.customerSignModalVisible = false;
      }
    } else {
      this.addTimeModalVisible = false;
      this.addTimeModalEditTime = false;
      this.addTimeModalEditBySupervisor = false;
      this.customerSignModalVisible = false;
      this.overMidnightHintModalVisible = false;
      if (this.showAddTimeForTopteamerOverlay) {
        this.closeAddTimeForTopteamerOverlay();
      }
    }
    this.initAddTimeValues(true);
  }

  /**
   * breakHoursValueChanged will be called when the break hours input value changes. It will update the
   * breakHoursInputValue and call the refreshAddTimeValues function.
   * @param newValue the new value of the break hours input
   */
  breakHoursValueChanged(newValue: any) {
    this.breakHoursInputValue = newValue;
    this.refreshAddTimeValues();
  }

  /**
   * breakMinutesValueChanged will be called when the break minutes input value changes. It will update the
   * breakMinutesInputValue and call the refreshAddTimeValues function.
   * @param newValue the new value of the break minutes input
   */
  breakMinutesValueChanged(newValue: any) {
    this.breakMinutesInputValue = newValue;
    this.refreshAddTimeValues();
  }

  /**
   * fakeBreakHoursValueChanged will be called when the fake break hours input value changes. It will update the
   * fakeBreakHoursInputValue and call the refreshAddTimeValues function.
   * @param newValue the new value of the fake break hours input
   */
  fakeBreakHoursValueChanged(newValue: any) {
    this.fakeBreakHoursInputValue = newValue;
    this.refreshAddTimeValues();
  }

  /**
   * fakeBreakMinutesValueChanged will be called when the fake break minutes input value changes. It will update the
   * fakeBreakMinutesInputValue and call the refreshAddTimeValues function.
   * @param newValue the new value of the fake break minutes input
   */
  fakeBreakMinutesValueChanged(newValue: any) {
    this.fakeBreakMinutesInputValue = newValue;
    this.refreshAddTimeValues();
  }

  /**
   * getNextDayStringIfOverMidnight will return the next day string if the time_from and time_to are on different days.
   * @param time_from timestamp of time_from
   * @param time_to timestamp of time_to
   * @returns the next day string if the time_from and time_to are on different days
   */
  getNextDayStringIfOverMidnight(time_from: number, time_to: number) {
    const dayFrom = new Date(time_from).toLocaleDateString("de", {
      weekday: "short",
      timeZone: "Europe/Berlin",
    });
    const dayTo = new Date(time_to).toLocaleDateString("de", {
      weekday: "short",
      timeZone: "Europe/Berlin",
    });
    if (dayFrom !== dayTo) {
      return ` ${dayTo}. `;
    }
    return "";
  }

  /**
   * exportCSVClicked will be called when the export CSV button is clicked. It will call the data.exportTimesCSV
   * function with the current teamTimeSlotDays array and the component variable eventName.
   * @param teamTimeSlotDay the teamTimeSlotDay to export, if not given, all teamTimeSlotDays will be exported
   */
  async exportCSVClicked(teamTimeSlotDay?: TeamTimeSlotDay) {
    await this.data.exportTimesCSV(
      teamTimeSlotDay,
      this.teamTimeSlotDays,
      this.eventName
    );
    this.ngOnInit();
  }

  /**
   * resendTimeDocumentClicked will be called when the resend time document button is clicked.
   * It will show a confirm dialog and if confirmed, it will call the sendTimeOverview function with the given teamTimeSlotDay.
   * @param teamTimeSlotDay the teamTimeSlotDay to resend the time document
   */
  resendTimeDocumentClicked(teamTimeSlotDay: TeamTimeSlotDay) {
    Confirm.show(
      "Zeiterfassung erneut versenden",
      "Soll die Zeiterfassung erneut versendet werden?",
      "Nein",
      "Ja",
      () => {},
      () => {
        this.sendTimeOverview(teamTimeSlotDay);
      }
    );
  }

  /**
   * sendTimeOverview will call the backend function sendTimeOverview with the given teamTimeSlotDay and all
   * document ids of the teamTimeSlotDay. It will show a success or failure notification afterwards.
   * @param teamTimeSlotDay the teamTimeSlotDay to send the time overview
   */
  async sendTimeOverview(teamTimeSlotDay: TeamTimeSlotDay) {
    const tmpTeamTimeSlotDay = { ...teamTimeSlotDay };
    if (tmpTeamTimeSlotDay && tmpTeamTimeSlotDay.document_ids.length) {
      const documents = await this.data.postRequest(`/documents/getDocuments`, {
        document_ids: tmpTeamTimeSlotDay.document_ids,
      });

      if (documents && documents.length) {
        if (tmpTeamTimeSlotDay && tmpTeamTimeSlotDay.timeSlots) {
          // preprocess timeslots:
          tmpTeamTimeSlotDay.timeSlots.forEach((tSlot, index) => {
            if (tSlot.time_to && (tSlot.time_break || tSlot.time_break === 0)) {
              let timeFrom = tSlot.time_from;
              let timeTo = tSlot.time_to;
              let timeBreak = tSlot.time_break;
              if (tSlot.fake_time_from) timeFrom = tSlot.fake_time_from;
              if (tSlot.fake_time_to) timeTo = tSlot.fake_time_to;
              if (tSlot.fake_time_break || tSlot.fake_time_break === 0)
                timeBreak = tSlot.fake_time_break;

              // update formatted duration
              const timeDuration = Math.max(0, timeTo - timeFrom);
              const midnight = new Date(timeFrom);
              midnight.setHours(0, 0, 0, 0);
              tmpTeamTimeSlotDay.timeSlots![index].formattedDuration =
                this.splitMilliseconds(timeDuration).formatted;

              tmpTeamTimeSlotDay.timeSlots![index].formattedBreak =
                this.splitMilliseconds(timeBreak).formatted;
            }
          });
        }

        const emailData: any = {
          data: {
            weekday: tmpTeamTimeSlotDay.weekday,
            dateLong: tmpTeamTimeSlotDay.dateLong,
            overallTimeFormatted: tmpTeamTimeSlotDay.overallTimeFormatted
              ? tmpTeamTimeSlotDay.overallTimeFormatted
              : this.formattedTeamTimeOverall,
            timeSlots: tmpTeamTimeSlotDay.timeSlots,
          },
          document_ids: tmpTeamTimeSlotDay.document_ids,
          project_id: this.projectId!,
        };
        if (this.applicationId) {
          emailData.application_id = this.applicationId;
        }
        try {
          const result = await this.data.postRequest(
            `/application/time/sendTimeOverview`,
            emailData
          );
          if (result && result.errors && result.errors.length > 0) {
            const emails = result.errors
              .map((e: any) => `${e.email} mit Begründung: ${e.message}`)
              .join("<br><br> ");

            Report.failure(
              "Fehler",
              `Folgende E-Mails konnten nicht versendet werden:<br><br> ${emails}`,
              "Okay",
              {
                svgSize: "20px",
                messageMaxLength: 9999,
                plainText: false,
              }
            );
            return;
          }
          Notify.success("E-Mail erfolgreich verschickt");
        } catch (error) {
          console.error(error);
          Notify.failure("E-Mail konnte nicht verschickt werden");
        }
      }
    }
  }

  /**
   * initAddTimeValues will initialize the addTimeValues object with default values.
   * If fresh is true, it will set all values to default, otherwise it will check if addTimeValues
   * already has values and set the input values accordingly.
   * @param fresh if true, all values will be set to default
   */
  initAddTimeValues(fresh: boolean = false) {
    const values = this.addTimeValues;

    if (fresh) {
      this.timeFromInputValue = undefined;
      this.timeToInputValue = undefined;
      values.time_from = 0;
      values.time_to = 0;
      values.time_break = 0;
      this.breakHoursInputValue = 0;
      this.breakMinutesInputValue = 0;
      this.overMidnightString = "";
      this.formattedTimeDuration = "";
      // fake values:
      this.fakeTimeFromInputValue = undefined;
      this.fakeTimeToInputValue = undefined;
      this.fakeOverMidnightString = "";
      this.fakeBreakHoursInputValue = 0;
      this.fakeBreakMinutesInputValue = 0;
      this.fakeFormattedTimeDuration = "";
      return;
    }

    // set init value for time_from
    let dateFrom = new Date(values.time_from);
    this.timeFromInputValue = this.formatTimeToInputString(dateFrom);
    const tmptDateTo = new Date();
    tmptDateTo.setMinutes(
      this.correctMinutesToNearestQuarter(tmptDateTo.getMinutes())
    );

    // update time_to if it is not set
    values.time_to ||= tmptDateTo.getTime();
    values.time_break ??= 0;

    // set init value for time_to
    let dateTo = new Date(values.time_to);
    this.timeToInputValue = this.formatTimeToInputString(dateTo);

    let splitRes = this.splitMilliseconds(values.time_break);
    this.breakHoursInputValue = splitRes.hours;
    this.breakMinutesInputValue = splitRes.minutes;

    // update overmidnight string
    this.overMidnightString = this.getNextDayStringIfOverMidnight(
      dateFrom.getTime(),
      dateTo.getTime()
    );

    // update formatted duration
    let timeDuration = Math.max(
      0,
      values.time_to - values.time_from - values.time_break
    );
    const { formatted } = this.splitMilliseconds(timeDuration);
    this.formattedTimeDuration = `${formatted} h`;

    // init fakedata:
    if (
      values.fake_time_from &&
      values.fake_time_to &&
      (values.fake_time_break || values.fake_time_break === 0)
    ) {
      dateFrom = new Date(values.fake_time_from);
      this.fakeTimeFromInputValue = this.formatTimeToInputString(dateFrom);
      // set init value for time_to
      dateTo = new Date(values.fake_time_to);
      this.fakeTimeToInputValue = this.formatTimeToInputString(dateTo);

      splitRes = this.splitMilliseconds(values.fake_time_break);
      this.fakeBreakHoursInputValue = splitRes.hours;
      this.fakeBreakMinutesInputValue = splitRes.minutes;

      this.fakeOverMidnightString = this.getNextDayStringIfOverMidnight(
        dateFrom.getTime(),
        dateTo.getTime()
      );

      // update formatted duration
      timeDuration = Math.max(
        0,
        values.fake_time_to - values.fake_time_from - values.fake_time_break
      );

      const { formatted: f } = this.splitMilliseconds(timeDuration);
      this.fakeFormattedTimeDuration = `${f} h`;
    } else {
      // fresh fake values:
      this.fakeTimeFromInputValue = this.timeFromInputValue;
      this.fakeTimeToInputValue = this.timeToInputValue;
      this.fakeOverMidnightString = this.overMidnightString;
      this.fakeBreakHoursInputValue = 0;
      this.fakeBreakMinutesInputValue = 0;
      this.fakeFormattedTimeDuration = this.formattedTimeDuration;
    }
  }

  /**
   * refreshAddTimeValues TODO
   * @param updateMinBreak
   */
  refreshAddTimeValues(
    updateMinBreak: boolean = true,
    awaysUpdateBreakInputValues = false
  ) {
    /**
     * Timestamp of the start of the selected day.
     *  Time values have to be pulled from other inputs.
     */
    let selectedDayTime = -1;
    const values = this.addTimeValues;

    if (this.timeFromInputValue) {
      const [fromHours, fromMinutes] = this.timeFromInputValue
        .split(":")
        .map((t: string) => parseInt(t, 10));

      let toHours: number | undefined;
      let toMinutes: number | undefined;
      if (this.timeToInputValue) {
        [toHours, toMinutes] = this.timeToInputValue
          .split(":")
          .map((t: string) => parseInt(t, 10));

        if (this.isForCheckIn) {
          selectedDayTime = dayjs // 00:00 of selected day
            .tz(values.time_from)
            .startOf("day")
            .valueOf();
        } else {
          const selectedDay = this.selectableDays.find((d) => d.selected);
          selectedDayTime = selectedDay?.time ?? -1;
        }
      } else {
        const selectedDay = this.selectableDays.find((d) => d.selected);
        if (selectedDay) {
          selectedDayTime = selectedDay.time;
        } else {
          selectedDayTime = dayjs // 00:00 of selected day
            .tz(values.time_from)
            .startOf("day")
            .valueOf();
        }
      }

      // create timestamp from the selected day and the time input values
      const fromDate = dayjs.tz(selectedDayTime).toDate();
      fromDate.setHours(fromHours, fromMinutes, 0, 0);
      values.time_from = fromDate.getTime();

      let toDate: Date | undefined;
      if (toHours !== undefined && toMinutes !== undefined) {
        toDate = dayjs.tz(selectedDayTime).toDate();
        toDate.setHours(toHours, toMinutes, 0, 0);
        values.time_to = toDate.getTime();

        // if the to time is before the from time, it is the next day
        const isOverMidnight =
          toHours < fromHours ||
          (toHours === fromHours && toMinutes < fromMinutes);

        if (isOverMidnight) {
          // update to the next day
          toDate.setDate(fromDate.getDate() + 1);
          this.addTimeValues.time_to = toDate.getTime();
        }
      }

      if (!toDate || toHours === undefined || toMinutes === undefined) {
        this.formattedTimeDuration = "";
      } else {
        // update overmidnight string
        this.overMidnightString = this.getNextDayStringIfOverMidnight(
          fromDate.getTime(),
          toDate.getTime()
        );

        // calculate breaktime in milliseconds
        const totalBreakMinutes =
          this.breakHoursInputValue * 60 + this.breakMinutesInputValue;
        values.time_break = totalBreakMinutes * 60 * 1000; // minutes to ms

        // update formatted duration
        const time_to = toDate.getTime();
        const timeDuration = Math.max(
          0,
          time_to - values.time_from - values.time_break
        );

        const { formatted } = this.splitMilliseconds(timeDuration);
        this.formattedTimeDuration = `${formatted} h`;
      }
    }

    // for fake formular:
    if (this.fakeTimeFromInputValue && this.fakeTimeToInputValue) {
      const [fromHours, fromMinutes] = this.fakeTimeFromInputValue
        .split(":")
        .map((t: string) => parseInt(t, 10));
      const [toHours, toMinutes] = this.fakeTimeToInputValue
        .split(":")
        .map((t: string) => parseInt(t, 10));

      if (this.isForCheckIn) {
        selectedDayTime = dayjs.tz(values.time_from).startOf("day").valueOf();
      } else {
        selectedDayTime =
          this.selectableDays.find((d) => d.selected)?.time ?? -1;
      }

      const fromDate = dayjs(selectedDayTime).tz("Europe/Berlin").toDate();
      const toDate = dayjs(selectedDayTime).tz("Europe/Berlin").toDate();

      if (
        toHours < fromHours ||
        (toHours === fromHours && toMinutes < fromMinutes)
      ) {
        toDate.setDate(fromDate.getDate() + 1);
      }

      // fix times of dates
      fromDate.setHours(fromHours, fromMinutes, 0, 0);
      toDate.setHours(toHours, toMinutes, 0, 0);
      values.fake_time_from = fromDate.getTime();

      values.fake_time_to = Math.max(toDate.getTime(), values.time_to ?? 0);

      // update overmidnight string
      this.fakeOverMidnightString = this.getNextDayStringIfOverMidnight(
        fromDate.getTime(),
        toDate.getTime()
      );

      // calculate breaktime
      values.fake_time_break =
        this.fakeBreakHoursInputValue * 60 * 60 * 1000 + // hours to ms
        this.fakeBreakMinutesInputValue * 60 * 1000; // minutes to ms

      // update formatted duration
      const timeDuration = Math.max(
        0,
        values.fake_time_to - values.fake_time_from - values.fake_time_break
      );

      const { formatted } = this.splitMilliseconds(timeDuration);
      this.fakeFormattedTimeDuration = `${formatted} h`;
    }

    if (updateMinBreak) {
      this.updateMinBreakValue(awaysUpdateBreakInputValues);
    }
  }

  /**
   * Minum break time is set by minBreakHoursValue and minBreakMinutesValue. This value is used to set the minumum value for the minute input field.
   * If the break hours are set to a higher than minBreakHoursValue, the minute input field will be set to 0.
   *
   * @returns break time minute value
   */
  get breakMinutesInputMin(): number {
    if (this.currentUserHasAdminPrivileges) {
      return 0;
    }

    if (this.breakHoursInputValue > this.minBreakHoursValue) {
      return 0;
    }

    return this.minBreakMinutesValue;
  }

  get breakHoursInputMin(): number {
    if (this.currentUserHasAdminPrivileges) {
      return 0;
    }

    return this.minBreakHoursValue;
  }

  get breakInputIsTooLow(): boolean {
    if (!this.currentUserHasAdminPrivileges) {
      return false;
    }

    const currentTime =
      this.breakHoursInputValue * 60 + this.breakMinutesInputValue;
    const minTime = this.minBreakHoursValue * 60 + this.minBreakMinutesValue;

    return currentTime < minTime;
  }

  /**
   * overMidnightStringClicked will be called when the over midnight string is clicked.
   * It will show a hint modal.
   */
  overMidnightStringClicked() {
    this.overMidnightHintModalVisible = true;
  }

  /**
   * isTimeslotEditable will check if the given timeSlot is editable.
   * @param timeSlot the timeSlot to check
   * @returns true if the timeSlot is editable
   */
  isTimeslotEditable(timeSlot: TimetrackerSlot) {
    return (
      (!timeSlot.document_id || this.currentUserHasAdminPrivileges) &&
      !timeSlot.is_already_established_in_datev_export
    );
  }

  /**
   * hasTimeslotsWithoutDocumentId will check if the given teamTimeSlotDay has timeSlots without a document_id.
   * @param teamTimeSlotDay the teamTimeSlotDay to check
   * @returns true if the teamTimeSlotDay has timeSlots without a document_id
   */
  hasTimeslotsWithoutDocumentId(teamTimeSlotDay: TeamTimeSlotDay) {
    return (
      (teamTimeSlotDay.timeSlots || []).filter((timeSlot) => {
        return !timeSlot.document_id;
      }).length > 0
    );
  }
}
