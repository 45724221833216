import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-header-w-backbtn-logo",
  templateUrl: "./header-w-backbtn-logo.component.html",
  styleUrls: ["./header-w-backbtn-logo.component.scss"],
})
export class HeaderWBackbtnLogoComponent implements OnInit {
  @Input()
  backBtn: boolean = false;

  @Output()
  backBtnClicked: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  constructor() {}

  ngOnInit(): void {}
}
