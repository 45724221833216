import { NgModule } from "@angular/core";
import * as Notiflix from "notiflix";

@NgModule({
  // declarations, imports, providers, etc...
})
export class CoreModule {
  constructor() {
    /**
     * Fix for Notiflix when successive invoked
     */

    const originalShow = Notiflix.Confirm.show;

    Notiflix.Confirm.show = (...args: any) => {
      setTimeout(() => {
        originalShow.apply(Notiflix.Confirm, args);
      }, 200);
    };

    const originalAsk = Notiflix.Confirm.ask;

    Notiflix.Confirm.ask = (...args: any) => {
      setTimeout(() => {
        originalAsk.apply(Notiflix.Confirm, args);
      }, 200);
    };

    const originalPromt = Notiflix.Confirm.prompt;

    Notiflix.Confirm.prompt = (...args: any) => {
      setTimeout(() => {
        originalPromt.apply(Notiflix.Confirm, args);
      }, 200);
    };

    const originalFailure = Notiflix.Report.failure;

    Notiflix.Report.failure = (...args: any) => {
      setTimeout(() => {
        originalFailure.apply(Notiflix.Report, args);
      }, 200);
    };

    const originalSuccess = Notiflix.Report.success;

    Notiflix.Report.success = (...args: any) => {
      setTimeout(() => {
        originalSuccess.apply(Notiflix.Report, args);
      }, 200);
    };

    const originalWarning = Notiflix.Report.warning;

    Notiflix.Report.warning = (...args: any) => {
      setTimeout(() => {
        originalWarning.apply(Notiflix.Report, args);
      }, 200);
    };

    const originalInfo = Notiflix.Report.info;

    Notiflix.Report.info = (...args: any) => {
      setTimeout(() => {
        originalInfo.apply(Notiflix.Report, args);
      }, 200);
    };

    // Prevent re-import of the CoreModule
    if (CoreModule.instance) {
      throw new Error(
        "CoreModule is already loaded. Import it in the AppModule only."
      );
    }
    CoreModule.instance = this;
  }

  // eslint-disable-next-line no-use-before-define
  private static instance: CoreModule;
}
